import { SVGProps } from "react";

const SendSVG = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none" {...props}>
		<path
			fill="#fff"
			fillRule="evenodd"
			d="m10.68 4.75-7.72 7.72 1.06 1.06 7.72-7.72v5.39h1.5V3.25H5.29v1.5h5.39Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default SendSVG;
