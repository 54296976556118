import { colors } from "@azify/ui-tokens";
import * as Styled from "../OtcOnboarding.styles";
import {
	ButtonGroup,
	Icon,
	IconButton,
	ItemList,
	ProgressBar,
	QuickButton,
	Tile,
	Typography
} from "@azify/ui-web";
import Dialog from "components/Modal/Dialog/Dialog.component";
import { useStepManager } from "hooks/useStepManager";
import { useRef, useState } from "react";

export const AdditionalDocumentStep = () => {
	const [openModalPolicy, setOpenModalPolicy] = useState(false);
	const [openModalProof, setOpenModalProof] = useState(false);
	const [openModalInvoicing, setOpenModalInvoicing] = useState(false);
	const [openModalDocuments, setOpenModalDocuments] = useState(false);
	const [files, setFiles] = useState<File[]>([]);
	const { setCurrentStep } = useStepManager();
	const goBack = () => setCurrentStep("StartStep");

	const inputRef = useRef<HTMLInputElement>(null);

	const openUpload = (id: number) => {
		if (inputRef.current) {
			inputRef.current.click();
		}
	};

	const saveFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files ? e.target.files[0] : null;

		if (file) {
			setFiles([...files, file]);
		}
	};

	const items = [
		{
			id: 0,
			label: "Políticas de KYC / AML",
			description: "Políticas de “Conheça seu Cliente” e “Anti-lavagem de Dinheiro”.",
			state: setOpenModalPolicy
		},
		{
			id: 1,
			label: "Comprovante IN 1.888/2019",
			description: "Instrução Normativa que estabelece regras a operações com criptoativos.",
			state: setOpenModalProof
		},
		{
			id: 2,
			label: "Faturamento médio dos últimos 12 meses",
			description: "Declaração emitida pelo contador.",
			state: setOpenModalInvoicing
		}
	];

	return (
		<>
			<Styled.MainStepContainer>
				<Styled.ScrollStepContainer>
					<Styled.CenterContent>
						<div className="step-header">
							<div className="container-arrow-progress">
								<IconButton
									icon="back-arrow-lg"
									onClick={goBack}
									color="inverted"
									marginRight={32}
								/>

								<ProgressBar currentStep={0} currentStepProgress={35} totalSteps={1} height={2} />
							</div>
							<Typography value="Documentação adicional" type="h1" isBold />
							<Typography
								type="body1"
								value="É importante que você anexe alguns documentos para garantir a segurança das operações realizadas em nossa plataforma."
								marginTop={20}
							/>
						</div>

						<Styled.ContainerAnimation paddingTop={230}>
							{items.map((item) => (
								<Tile
									key={item.id}
									leadingIconName="file-multiple-text"
									label={item.label}
									paragraph={files?.[item.id]?.name ? files[item.id].name : item.description}
									size="medium"
									backgroundColor={colors.white.light}
									trailingContent="customized"
									trailingContentCustomized={
										<QuickButton
											label={files?.[item.id]?.name ? "Excluir" : "O que é isto?"}
											leadingIcon={files?.[item.id]?.name ? undefined : "info-circle-small"}
											color={files?.[item.id]?.name ? "secondary" : "primary"}
											onClick={() => {
												if (files?.[item.id]?.name) {
													setFiles(files.filter((_, index) => index !== item.id));
												} else {
													item.state(true);
												}
											}}
										/>
									}
									onClick={() => openUpload(item.id)}
								/>
							))}
						</Styled.ContainerAnimation>
					</Styled.CenterContent>
				</Styled.ScrollStepContainer>

				<div className="step-footer">
					<div className="button-container">
						<ButtonGroup
							variant="default"
							titlePrimary="Continuar"
							titleSecondary="Não possuo esses documentos"
							isInverted
							onPressSecondary={() => setOpenModalDocuments(true)}
							onPressPrimary={() => setCurrentStep("RequestedDocumentsStep")}
							isDisabledPrimary={files.length < 3}
						/>
					</div>
				</div>
			</Styled.MainStepContainer>

			<Dialog
				openDialog={openModalPolicy}
				setOpenDialog={setOpenModalPolicy}
				buttonType="single"
				headerTitle="Políticas de KYC / AML"
				layout="alternative"
				genericButtonProps={{
					title: "Fechar",
					layout: "standard",
					color: "secondary",
					onClick: () => setOpenModalPolicy(false)
				}}>
				<div style={{ padding: 16 }}>
					<Typography
						type="body1"
						value="O KYC - Know Your Customer (Conheça seu Cliente) e AML - Anti-Money Laundering (Anti-Lavagem de Dinheiro), referem-se às políticas para o combate a lavagem de dinheiro e o financiamento de atividades criminosas."
					/>
				</div>
			</Dialog>

			<Dialog
				openDialog={openModalProof}
				setOpenDialog={setOpenModalProof}
				buttonType="single"
				headerTitle="Comprovante IN 1.888/2019"
				layout="alternative"
				genericButtonProps={{
					title: "Fechar",
					layout: "standard",
					color: "secondary",
					onClick: () => setOpenModalProof(false)
				}}>
				<div style={{ padding: 16 }}>
					<Typography
						type="body1"
						value="É uma regulamentação emitida pela Receita Federal do Brasil, que trata da obrigatoriedade de prestação de informações relativas às operações realizadas com criptoativos."
					/>
				</div>
			</Dialog>

			<Dialog
				openDialog={openModalInvoicing}
				setOpenDialog={setOpenModalInvoicing}
				buttonType="single"
				headerTitle="Faturamento médio dos últimos 12 meses"
				layout="alternative"
				genericButtonProps={{
					title: "Fechar",
					layout: "standard",
					color: "secondary",
					onClick: () => setOpenModalInvoicing(false)
				}}>
				<div style={{ padding: 16 }}>
					<Typography
						type="body1"
						value="A declaração do contador também deve incluir informações sobre o tipo de prestação de serviços ou venda de bens e serviços realizados pela empresa. Essas informações são importantes para fornecer contexto sobre a natureza das operações comerciais."
					/>
				</div>
			</Dialog>

			<Dialog
				openDialog={openModalDocuments}
				setOpenDialog={setOpenModalDocuments}
				buttonType="group"
				layout="standard"
				buttonGroupProps={{
					titlePrimary: "Falar com o suporte",
					titleSecondary: "Fechar",
					variant: "default",
					customColorBackgroundPrimaryButton: colors.black.main,
					customColorTextPrimaryButton: colors.white.light,
					customColorBackgroundTransparentButton: colors.white.light,
					customColorTextTransparentButton: colors.black.main,
					onPressPrimary: () => {},
					onPressSecondary: () => setOpenModalDocuments(false)
				}}>
				<div style={{ padding: "0 16px 16px 16px" }}>
					<Typography type="h1" value="Onde encontrar esses documentos?" marginBottom={20} isBold />
					<Typography
						type="body1"
						value="Caso não consiga encontrar os documentos solicitados, entre em contato com o nosso suporte para auxiliá-lo."
					/>
				</div>
			</Dialog>

			<input
				type="file"
				hidden
				ref={inputRef}
				accept="image/*, application/pdf"
				onChange={(e) => saveFile(e)}
			/>
		</>
	);
};
