import { colors } from "@azify/ui-tokens";
import styled from "styled-components";

export const Container = styled.div`
	padding: 40px 80px 0px;
	width: 100%;
	height: calc(100vh - 75px);
	display: flex;
	overflow: auto;
	background: ${colors.white.light};

	@media (max-width: 650px) {
		padding: 40px 32px 0px;
	}
`;
