import { QuickButton, Spinner, Typography } from "@azify/ui-web";
import * as Styled from "./LoaderStatus.styles";
import { LoaderStatusProps } from "./LoaderStatus.types";
import { Case, Switch, When } from "react-if";
import ErrorSVG from "assets/svg/error";
import SuccessSVG from "assets/svg/success";

export default function LoaderStatus(props: LoaderStatusProps) {
	const { status, title, subtitle, emphasis, onReceiptClick, heightContainer } = props;

	return (
		<Styled.Container heightContainer={heightContainer}>
			<div className="container-icon">
				<Switch>
					<Case condition={status === "loading"}>
						<Spinner size="xlarge" color="accent" />
					</Case>

					<Case condition={status === "success"}>
						<SuccessSVG />
					</Case>

					<Case condition={status === "error"}>
						<ErrorSVG />
					</Case>
				</Switch>
			</div>

			<When condition={!!title}>
				<Typography isBold textAlign="center" type="h2" value={title} color="primary" />
			</When>
			<When condition={!!subtitle}>
				<Typography
					textAlign="center"
					marginTop={20}
					type="body1"
					value={subtitle}
					color="primary"
				/>
			</When>
			<When condition={!!emphasis}>
				<Typography
					isBold
					marginTop={4}
					textAlign="center"
					marginBottom={40}
					type="h4"
					value={emphasis}
					color="accent"
				/>
			</When>
			<When condition={!!onReceiptClick}>
				<QuickButton
					color="primary"
					label="Baixar comprovante"
					leadingIcon="receipt-01-small"
					onClick={onReceiptClick}
				/>
			</When>
		</Styled.Container>
	);
}
