import * as Style from "./ModalSideBar.styles";
import { ModalProps } from "./ModalSideBar.types";
import { AnimatePresence } from "framer-motion";
import { IconButton, Typography } from "@azify/ui-web";
import { When } from "react-if";

const ModalSideBar = (props: ModalProps) => {
	const { open, onClose, children, fixedHeader, modalTitle, hiddenHeader, disableModalScroll } =
		props;

	const closeModal = () => {
		if (onClose) onClose();
	};

	const modalAnimation = {
		initial: { x: "100%" },
		animate: { x: 0 },
		exit: { x: "100%" },
		transition: { type: "spring", bounce: 0, duration: 0.6 }
	};

	return (
		<AnimatePresence mode="wait">
			{open && (
				<Style.ModalContainer
					{...modalAnimation}
					id="modal-side-bar"
					fixedHeader={fixedHeader}
					disableModalScroll={disableModalScroll}>
					<When condition={!hiddenHeader}>
						<div className="close-button-wrapper">
							<div className="container-close-header-component">
								<IconButton onClick={closeModal} color="inverted" icon="close" />
							</div>
							<Typography value={modalTitle} type="h1" isBold />
						</div>
					</When>

					<div className="container-scroll">{children}</div>
				</Style.ModalContainer>
			)}
		</AnimatePresence>
	);
};

export default ModalSideBar;
