import { useRef, useState } from "react";
import * as Styled from "./Header.styles";
import { Avatar, Icon, IconButton, QuickButton, Typography } from "@azify/ui-web";
import AzifySVG from "assets/svg/azify";
import { colors } from "@azify/ui-tokens";
import { useNavigate } from "react-router-dom";
import { When } from "react-if";
import { HeaderProps } from "./Header.types";
import { DropDown } from "components/DropDown";
import { DropDownItem } from "components/DropDown/DropDownItem";
import { useClick } from "@szhsin/react-menu";
import { DropDownDivider } from "components/DropDown/DropDownDivider";

const Header = ({ isAuth = true, newOrg }: HeaderProps) => {
	const navigate = useNavigate();
	const ref = useRef(null);
	const [showDropdownMenu, setShowDropdownMenu] = useState(false);
	const [toggleDropDownMenu, setToggleDropDownMenu] = useState(false);
	const anchorProps = useClick(showDropdownMenu, setShowDropdownMenu);

	return (
		<Styled.Container>
			<div className="help-button">
				<QuickButton
					color="primary"
					label="Ajuda"
					leadingIcon="bubble-chat-small"
					onClick={() => {}}
				/>
			</div>

			<div className="logo">
				<AzifySVG
					className={isAuth ? "pointer" : ""}
					onClick={() => {
						if (isAuth) navigate("/dashboard");
					}}
				/>
			</div>

			<div className="row-menu">
				<When condition={isAuth}>
					<IconButton
						tooltipTitle="Notificações"
						color="primary"
						size="medium"
						icon="bell"
						onClick={() => {}}
					/>

					<div ref={ref} {...anchorProps} className="business-container">
						<Avatar
							fullName="Azify LTDA"
							type="initials"
							size="large"
							textColor="inverted"
							backgroundColor={colors.black.main}
						/>
						<div className="business-content-text">
							<Typography value="Azify LTDA" type="subtitle1" isBold />

							<Typography value="Plano Basic" type="body3" color="accent" />
						</div>

						<Icon
							customColor={colors.gray.dark}
							color="primary"
							size="small"
							name="chevron-down-filled-small"
						/>
					</div>
				</When>

				<When condition={false}>
					<Avatar
						fullName="Nova Organização"
						type="userPJ"
						size="large"
						textColor="inverted"
						iconColor="accent"
						backgroundColor={colors.white.dark}
					/>
					<div className="business-content-text">
						<Typography value="Nova Organização" type="subtitle1" isBold />

						<Typography value="Plano Basic" type="body3" color="accent" />
					</div>

					<Icon
						customColor={colors.gray.dark}
						color="primary"
						size="small"
						name="chevron-down-filled-small"
					/>
				</When>
			</div>

			<Styled.DropDownContainer>
				<DropDown
					isOpen={showDropdownMenu}
					setOpen={setShowDropdownMenu}
					innerRef={ref}
					align="end"
					gap={20}
					widthContainer={350}
					content={
						<div className="dropdown">
							<div className="header">
								<Typography value="Patrimônio total estimado" type="body3" color="primary" />

								<Typography isBold value="R$ 15.240,33" type="h4" color="primary" />
							</div>

							<DropDownDivider />

							<div className="dropdown-container-copy">
								<div className="dropdown-content-copy">
									<div className="dropdown-copy-item">
										<Typography value="Banco" type="small" color="primary" />

										<Typography
											isBold
											value="853 - Azimut Investments S. A."
											type="small"
											color="secondary"
										/>
									</div>

									<div className="dropdown-copy-item">
										<Typography value="Agência" type="small" color="primary" />

										<Typography isBold value="0001" type="small" color="secondary" />
									</div>

									<div className="dropdown-copy-item">
										<Typography value="Conta" type="small" color="primary" />

										<Typography isBold value="8876430-7" type="small" color="secondary" />
									</div>

									<div className="dropdown-copy-item">
										<Typography value="CNPJ" type="small" color="primary" />

										<Typography isBold value="12.123.456/00001-12" type="small" color="secondary" />
									</div>
								</div>

								<IconButton
									tooltipTitle=""
									color="primary"
									size="small"
									icon="copy"
									onClick={() => {
										window.navigator.clipboard.writeText(
											`	853 - Azimut Investments S. A.
														Agencia: 0001
														Conta: 8876430-7
														CNPJ: 12.123.456/00001-12
													`
										);
									}}
								/>
							</div>

							<DropDownDivider />

							<DropDownItem
								icon={<Icon name="lightning-bolt" />}
								title="Faça Upgrade"
								onClick={() => {
									navigate("/settings/plans");
								}}
								chip
								chipBackgroundCustomColor={colors.primary.opacity["10p"]}
								colorTextCustom={colors.primary.main}
								chipTitle="Plano Basic"
								chipWidth="111px"
							/>
							<DropDownItem
								icon={<Icon name={toggleDropDownMenu ? "eye-on" : "eye-off"} />}
								title={toggleDropDownMenu ? "Exibir saldos" : "Ocultar saldos"}
								onClick={() => {}}
								toggle
								toggleValue={!!toggleDropDownMenu}
								toggleOnClick={() => setToggleDropDownMenu(!toggleDropDownMenu)}
							/>
							<DropDownItem
								icon={<Icon name="settings" />}
								title="Configurações"
								onClick={() => {
									navigate("/settings");
								}}
							/>
							<DropDownItem
								icon={<Icon name="exchange-02" />}
								title="Alternar Conta"
								onClick={() => {
									localStorage.removeItem("selectedOrganization");
									navigate("/organizations");
								}}
							/>
							<DropDownItem
								icon={<Icon name="log-out" color="negative" />}
								title="Sair"
								onClick={() => {
									navigate("/auth/sign-in");
									localStorage.clear();
								}}
								isRed
							/>

							<DropDownDivider />

							<div className="footer">
								<Typography isBold value="Termos e condições" type="small" color="primary" />

								<Typography isBold value="Privacidade" type="small" color="primary" />
							</div>
						</div>
					}
				/>
			</Styled.DropDownContainer>
		</Styled.Container>
	);
};

export default Header;
