import { colors } from "@azify/ui-tokens";
import styled from "styled-components";

export const Container = styled.div`
	background-color: ${colors.white.light};
	display: flex;
	flex-direction: column;
	width: 100%;
	height: calc(100vh - 75px);
`;
