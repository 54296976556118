import {
	Banner,
	GenericButton,
	IconButton,
	ItemList,
	ProgressBar,
	SectionHeading,
	Typography
} from "@azify/ui-web";
import { Chip } from "components/Chip";
import { useStepManager } from "hooks/useStepManager";
import { cameFromTheLeft } from "pages/Onboarding/Onboarding.styles";
import { useFormContext } from "react-hook-form";
import * as Styled from "../Kyb.styles";
import Dialog from "components/Modal/Dialog/Dialog.component";
import { useState } from "react";
import ModalSideBar from "components/ModalSideBar";
import AttachPersonalDocuments from "common/modals/sideRight/AttachPersonalDocuments/AttachPersonalDocuments.component";
import { PartnerFields } from "../Kyb.types";
import IdCheckImg from "assets/images/id-check.png";

function InputDocuments() {
	const [showDocumentsInfo, setShowDocumentsInfo] = useState(false);
	const [showWhyBiometrics, setShowWhyBiometrics] = useState(false);
	const [showAttachDocuments, setShowAttachDocuments] = useState(false);
	const { setCurrentStep } = useStepManager();
	const { getValues } = useFormContext();

	const goNext = () => setCurrentStep("WaitingBiometry");
	const goPrev = () => setCurrentStep("InputAndListPartners");

	const personalPartnersList = getValues("personalPartners") as PartnerFields[];

	return (
		<>
			<Styled.MainStepContainer>
				<Styled.ScrollStepContainer>
					<Styled.CenterContent>
						<div className="step-header">
							<div className="container-arrow-progress">
								<IconButton
									icon="back-arrow-lg"
									onClick={goPrev}
									color="inverted"
									marginRight={32}
								/>

								<ProgressBar currentStep={1} currentStepProgress={60} totalSteps={3} height={2} />
							</div>
							<Typography value="Envio de Documentos" type="h1" isBold marginBottom={16} />
						</div>

						<Styled.ContainerAnimation {...cameFromTheLeft}>
							<SectionHeading
								onClick={() => setShowDocumentsInfo(true)}
								behavior="default"
								title="Procuração de Douglas Pereira"
								subHeading="Em nome da empresa dando plenos poderes de representação."
							/>
							<ItemList
								behavior="display"
								label="Procuração de Representação"
								leadingIconName="file-multiple-text"
								leadingContent="icon"
								trailingContent="quickButton"
								trailingQuickButtonLabel="Anexar"
								trailingSupportContent="customized"
								trailingSupportContentCustomized={
									<Chip text="Pendente" backgroundColor="warning" />
								}
								trailingQuickButtonOnPress={() => setShowAttachDocuments(true)}
							/>

							<SectionHeading
								onClick={() => setShowDocumentsInfo(true)}
								behavior="informational"
								title="Minha Empresa"
							/>

							<Banner
								hierarchy="low"
								layout="inset"
								behavior="default"
								color="danger"
								artwork="icon"
								iconName="alert-triangle-filled"
								description="Ata de Eleição: O documento deve ser anexado com assinatura digital eletrônica ou procuração validada em cartório. O documento deve ser anexado com assinatura digital eletrônica ou procuração validada em cartório.

								Contrato Social: O documento deve ser anexado com assinatura digital eletrônica ou procuração validada em cartório.
								
								Certificado da condição de microempreendedor individual da BlockTech Brasil Soluções em Blockchain S.A.: O certificado está vencido. Anexe um certificado dos últimos 3 meses."
								title="Erros encontrados"
							/>

							<ItemList
								behavior="display"
								label="Certificado da condição de microempreendedor individual"
								leadingIconName="file-multiple-text"
								leadingContent="icon"
								trailingContent="quickButton"
								trailingQuickButtonLabel="Anexar"
								supportParagraph="CCMEI"
								trailingSupportContent="customized"
								trailingSupportContentCustomized={
									<Chip text="Pendente" backgroundColor="warning" />
								}
								trailingQuickButtonOnPress={() => setShowAttachDocuments(true)}
							/>

							<SectionHeading behavior="default" title="Sócios e representantes" />

							<Banner
								hierarchy="low"
								layout="inset"
								behavior="button"
								color="default"
								artwork="image"
								artworkComponent={<img width={64} height={64} src={IdCheckImg} />}
								quickButtonColor="primary"
								trailingActionLabel="Por quê?"
								trailingAction={() => setShowWhyBiometrics(true)}
								description="Por segurança, todas as pessoas que terão acesso à esta conta devem enviar suas documentações através do aplicativo Azify."
							/>

							<ItemList
								behavior="display"
								label="Felipe Santos Pereira"
								leadingIconName="file-multiple-text"
								leadingContent="avatar"
								leadingAvatarType="initials"
								leadingAvatarSize="medium"
								leadingAvatarFullName="Neilton"
								trailingContent="icon"
								trailingIconName="chevron-right-small"
								supportParagraph="BlockTech Brasil Soluções em Blockchain S.A."
								trailingSupportContent="customized"
								trailingSupportContentCustomized={
									<Chip text="Pendente" backgroundColor="warning" />
								}
							/>

							<ItemList
								behavior="display"
								label="Bruno Santos Pereira"
								leadingIconName="file-multiple-text"
								leadingContent="avatar"
								leadingAvatarType="initials"
								leadingAvatarSize="medium"
								leadingAvatarFullName="Neilton"
								trailingContent="icon"
								trailingIconName="chevron-right-small"
								supportParagraph="BlockTech Brasil Soluções em Blockchain S.A."
								trailingSupportContent="customized"
								trailingSupportContentCustomized={<Chip text="Anexado" backgroundColor="primary" />}
							/>

							<SectionHeading
								subHeading="BlockTech Brasil Soluções em BlockChain S.A"
								behavior="default"
								title=""
							/>

							<ItemList
								behavior="display"
								label="Certificado da condição de microempreendedor individual"
								leadingIconName="file-multiple-text"
								leadingContent="icon"
								trailingContent="quickButton"
								trailingQuickButtonLabel="Anexar"
								supportParagraph="CCMEI"
								trailingSupportContent="customized"
								trailingSupportContentCustomized={
									<Chip text="Pendente" backgroundColor="warning" />
								}
								trailingQuickButtonOnPress={() => setShowAttachDocuments(true)}
							/>
						</Styled.ContainerAnimation>
					</Styled.CenterContent>
				</Styled.ScrollStepContainer>

				<div className="step-footer">
					<div className="button-container">
						<GenericButton onClick={goNext} title="Continuar" layout="standard" color="primary" />
					</div>
				</div>
			</Styled.MainStepContainer>

			{/* Where find the documents of the company	*/}

			<Dialog
				openDialog={showDocumentsInfo}
				setOpenDialog={setShowDocumentsInfo}
				headerTitle="Onde encontro os documentos da empresa?"
				layout="alternative"
				buttonType="single"
				genericButtonProps={{
					onClick: () => setShowDocumentsInfo(false),
					title: "Entendi",
					color: "secondary",
					layout: "standard"
				}}>
				<div style={{ padding: "0 16px 16px 16px" }}>
					<Typography
						value="Para encontrar os documentos da sua empresa, é importante que você vá até o site do gov, efetue o login e busque por empresas abertas em seu nome para emitir os comprovantes necessários. <>"
						type="body1"
					/>
				</div>
			</Dialog>

			{/* Why biometrics	*/}

			<Dialog
				openDialog={showWhyBiometrics}
				setOpenDialog={setShowWhyBiometrics}
				headerTitle="Envio de documentações através do aplicativo Azify"
				layout="alternative"
				buttonType="single"
				genericButtonProps={{
					onClick: () => setShowWhyBiometrics(false),
					title: "Entendi",
					color: "secondary",
					layout: "standard"
				}}>
				<div style={{ padding: "0 16px 16px 16px" }}>
					<Typography
						value="Para garantir a segurança do processo, solicitamos que as pessoas enviem os documentos necessários para validarmos sua identidade. Isso é essencial para prevenir fraudes e garantir a autenticidade das informações fornecidas."
						type="body1"
					/>
				</div>
			</Dialog>

			{/* Attach partner with access docs	*/}

			<ModalSideBar open={showAttachDocuments} onClose={() => setShowAttachDocuments(false)}>
				<AttachPersonalDocuments partner={personalPartnersList[0]} />
			</ModalSideBar>
		</>
	);
}

export default InputDocuments;
