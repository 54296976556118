import { colors } from "@azify/ui-tokens";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	padding: 12px 32px;
	margin-top: 12px;

	height: 100%;

	.footer {
		display: flex;
		padding: 24px 0px;
		width: 100%;
		align-items: center;
		justify-content: center;
	}

	& .chip-container {
		padding: 21px 70px 16px 70px;
	}

	& .img-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		margin-top: 36px;

		.qr-container {
			border: 1px solid ${colors.gray.opacity["20p"]};
			padding: 10px;
			border-radius: 10px;
		}

		& .apps-links {
			display: flex;
			flex-direction: row;

			gap: 20px;
			margin-top: 36px;
			margin-bottom: 36px;

			& img {
				width: 150px;
				object-fit: contain;
			}
		}
	}
`;
