import { MerchantMethodsStep } from "./Steps/0.MerchantMethods.screen";
import { FinishStep } from "./Steps/1.Finish.screen";
import { useStepManager } from "hooks/useStepManager";
import { OnboardingExtensionGatewaySteps } from "./GatewayOnboarding.types";

export const GatewayOnboarding = () => {
	const { currentStep } = useStepManager();

	const typedCurrentStep = currentStep as OnboardingExtensionGatewaySteps;

	const STEP_RECORDS = {
		MerchantMethodsStep: <MerchantMethodsStep key="merchantMethodsStep" />,
		FinishStep: <FinishStep key="FinishStep" />
	};

	return <> {typedCurrentStep ? STEP_RECORDS[typedCurrentStep] : null}</>;
};
