import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;

	padding: 16px;
	border: 1px solid var(--Gray-Opacity-30p, rgba(158, 161, 185, 0.3));
	border-radius: 16px;
	width: 193px;
	height: 181px;
	cursor: pointer;
`;
