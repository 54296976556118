import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	width: 100%;
	gap: 4px;

	.item {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 4px;
	}

	.no-hover {
		cursor: unset;
	}
`;
