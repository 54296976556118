import { Chip, GenericButton, HeaderNavigation, ItemList } from "@azify/ui-web";
import TransactionalInput from "components/Input/TransactionalInput/TransactionalInput.component";
import { useStepManager } from "hooks/useStepManager";

function FillTransferValue() {
	const { setCurrentStep } = useStepManager();

	return (
		<div className="display-between">
			<div className="center-items">
				<HeaderNavigation
					buttonOnTitle={false}
					title="Enviar TED ou Pix Manual"
					quickButtonColor="accent"
					backIconClick={() => setCurrentStep("FillTransferDetails")}
				/>
				<div className="container-search">
					<ItemList
						trailingIconName="close"
						paragraph="BCO DO BRASIL S.A."
						label="Nayla Carreira Medeiros"
						supportParagraph="***.394.203/0001-**"
						behavior="default"
						trailingContent="icon"
						leadingIconColor="accent"
						trailingSupportContent="none"
						leadingContent="avatar"
						trailingIconButtonIcon="dots-ver"
						leadingAvatarFullName="Nayla Carreira Medeiros"
						leadingAvatarUserPhoto="https://avatars.githubusercontent.com/u/55929908?v=4"
					/>
				</div>

				<Chip
					id="chip-currency"
					hierarchy="high"
					title="R$ 3.849,10 disponível"
					width="intrinsec"
					iconRightColor="tertiary"
					avatarPhoto="https://i.pravatar.cc/300"
					iconRight="info-circle-small"
				/>

				<TransactionalInput placeHolder="R$ 00,00" containerHeight={200} disable={false} />
			</div>
			<GenericButton
				onClick={() => {
					setCurrentStep("ReviewTransferDetails");
				}}
				title="Continuar"
				layout="standard"
				color="primary"
			/>
		</div>
	);
}

export default FillTransferValue;
