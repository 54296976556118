import { ReactNode } from "react";

import { ModalSideBarContents } from "hooks/useOpenSideBar";
import { CreatePixKey } from "./CreatePixKey";
import { DownloadAzifyApp } from "./DownloadAzifyApp";

export const modalContent: Record<ModalSideBarContents, ReactNode | null> = {
	downloadAzifyApp: <DownloadAzifyApp />,
	pixKey: <CreatePixKey />,
	noContent: null
};
