import React from "react";

import * as Styled from "./Chip.styles";
import { ChipProps } from "./Chip.types";
import { colors } from "@azify/ui-tokens";
import { Typography } from "@azify/ui-web";

function Chip({ text, backgroundColor, width, onClick }: ChipProps) {
	return (
		<Styled.Container
			onClick={onClick}
			width={width}
			backgroundColor={backgroundColor || colors.primary.main}>
			<Typography value={text} color="inverted" type="subtitle2" isBold />
		</Styled.Container>
	);
}

export default Chip;
