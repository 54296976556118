import { Case, Switch } from "react-if";
import { useStepManager } from "hooks/useStepManager";
import * as Styled from "./ExchangeFounds.styles";
import { ExchangeFoundsSteps } from "./ExchangeFounds.types";
import { ExchangeInput } from "./Steps/0.ExchangeInput";
import { ExchangeSuccess } from "./Steps/1.ExchangeSuccess";

export const ExchangeFounds = () => {
	const { currentStep } = useStepManager();
	const typedCurrentStep = currentStep as ExchangeFoundsSteps;

	return (
		<Styled.Container>
			<Switch>
				<Case condition={typedCurrentStep === "ExchangeInputs"}>
					<ExchangeInput />
				</Case>

				<Case condition={typedCurrentStep === "ExchangeSuccess"}>
					<ExchangeSuccess />
				</Case>
			</Switch>
		</Styled.Container>
	);
};
