import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Chip,
	GenericButton,
	HeaderNavigation,
	ItemList,
	SearchField,
	Typography
} from "@azify/ui-web";
import { useStepManager } from "hooks/useStepManager";
import { Else, If, Then } from "react-if";
import TransactionalInput from "components/Input/TransactionalInput/TransactionalInput.component";
import Dialog from "components/Modal/Dialog/Dialog.component";

export const InputPixKeyAndValueStep = () => {
	const navigate = useNavigate();

	const { setCurrentStep, setParams } = useStepManager();
	const [searchValue, setSearchValue] = useState("");
	const [searchCompleted, setSearchCompleted] = useState(false);
	const [openDialog, setOpenDialog] = useState(true);

	return (
		<>
			<div className="display-between">
				<div className="center-items">
					<HeaderNavigation
						buttonOnTitle={false}
						title="Enviar Pix"
						quickButtonColor="accent"
						backIconClick={() => navigate("/pixArea")}
					/>

					<div className="container-search">
						<If condition={searchCompleted && searchValue.length > 3}>
							<Then>
								<ItemList
									trailingIconName="close"
									paragraph="BCO DO BRASIL S.A."
									label="Nayla Carreira Medeiros"
									supportParagraph="***.394.203/0001-**"
									behavior="default"
									trailingContent="icon"
									leadingIconColor="accent"
									trailingSupportContent="none"
									leadingContent="avatar"
									trailingIconButtonIcon="dots-ver"
									leadingAvatarFullName="Nayla Carreira Medeiros"
									leadingAvatarUserPhoto="https://avatars.githubusercontent.com/u/55929908?v=4"
								/>
							</Then>
							<Else>
								<SearchField
									isLoading={false}
									value={searchValue}
									placeholder=""
									hintText="Pode ser CPF/CNPJ, Telefone, E-mail ou Aleatória"
									onChangeInput={(v) => {
										setSearchValue(v);
										setSearchCompleted(true);
									}}
									onDelete={() => {
										setSearchValue("");
										setSearchCompleted(false);
									}}
								/>
							</Else>
						</If>
					</div>

					<Chip
						id="chip-currency"
						hierarchy="high"
						title="R$ 3.849,10 disponível"
						width="intrinsec"
						iconRightColor="tertiary"
						avatarPhoto="https://i.pravatar.cc/300"
						iconRight="info-circle-small"
					/>

					<TransactionalInput
						placeHolder="R$ 00,00"
						onChange={(v) => {
							console.log(v);
						}}
						containerHeight={200}
						disable={searchValue.length < 3}
					/>
				</div>

				<If condition={searchCompleted}>
					<Then>
						<GenericButton
							onClick={() => {
								setCurrentStep("ReviewPixKey");
								setParams({ pixKey: "oi" });
							}}
							title="Enviar para esse telefone"
							layout="standard"
							color="primary"
						/>
					</Then>

					<Else>
						<GenericButton
							onClick={() => {
								setCurrentStep("ReviewPixKey");
								setParams({ pixKey: "oi" });
							}}
							title="Continuar"
							layout="standard"
							color="primary"
						/>
					</Else>
				</If>
			</div>

			<Dialog
				openDialog={openDialog}
				setOpenDialog={setOpenDialog}
				headerTitle="O número digitado é um CPF ou celular?"
				layout="alternative"
				buttonGroupProps={{
					titlePrimary: "Este número é um CPF",
					titleSecondary: "Este número é um celular",
					isInverted: true,
					variant: "default"
				}}>
				<div style={{ padding: 16 }}>
					<Typography
						value="Não conseguimos identificar o tipo de chave que você digitou."
						type="body1"
					/>{" "}
				</div>
			</Dialog>
		</>
	);
};
