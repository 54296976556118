import { useStepManager } from "hooks/useStepManager";
import { useFormContext } from "react-hook-form";
import { AnimatePresence, motion } from "framer-motion";
import { cameFromTheBottom, cameFromTheLeft } from "pages/Onboarding/Onboarding.styles";
import {
	GenericButton,
	IconButton,
	ItemList,
	ProgressBar,
	SectionHeading,
	TextField,
	Typography
} from "@azify/ui-web";
import { moneyMask } from "helpers/currency";
import { useMemo, useState } from "react";
import { cellphoneMask } from "helpers/mask";
import * as Styled from "../Kyb.styles";
import ModalSideBar from "components/ModalSideBar";
import EditBusinessAddress from "common/modals/sideRight/EditBusinessAddress/EditBusinessAddress.component";

function InputBusinessData() {
	const [showEditPositionStep, setShowEditPositionStep] = useState("none");
	const [openModalSideBar, setOpenModalSideBar] = useState(false);

	const { setCurrentStep } = useStepManager();
	const {
		register,
		formState: { errors },
		trigger,
		setFocus,
		watch
	} = useFormContext();

	const goNext = () => {
		const fieldsToTrigger = [
			"phoneNumber",
			"email",
			"monthlyIncome",
			"cep",
			"city",
			"state",
			"address",
			"addressNumber",
			"neighborhood",
			"addressComplement"
		];

		trigger(fieldsToTrigger).then((isValid) => {
			if (isValid) {
				setCurrentStep("InputAndListPartners");
			}
		});
	};

	const goPrev = () => {
		setCurrentStep("InputBusinessDocumentNumber");
	};

	const onClickEdit = (position: string) => {
		if (showEditPositionStep === position) {
			setShowEditPositionStep("none");
		} else {
			setShowEditPositionStep(position);

			setTimeout(() => {
				setFocus(position);
			}, 500);
		}

		const hasToSomeValue = watch(position);

		if (hasToSomeValue) trigger(position);
	};

	const phoneError = errors.phoneNumber?.message as string;
	const emailError = errors.email?.message as string;
	const monthlyIncomeError = errors.monthlyIncome?.message as string;

	const addressFields = [
		"cep",
		"city",
		"state",
		"address",
		"addressNumber",
		"neighborhood",
		"addressComplement"
	];

	const hasAddressError = addressFields.some((field) => errors[field]);

	const closeSideModalEditAddress = () => setOpenModalSideBar(false);

	const addressLabel = useMemo(() => {
		const hasAllAddressFields = addressFields.every((field) => watch(field));
		const hasSomeIncompleteAddressFields = addressFields.some((field) => !watch(field));

		if (hasAllAddressFields) {
			return `${watch("address")}, ${watch("addressNumber")}, ${watch("neighborhood")} ${watch("cep")} - ${watch("city")}/${watch("state")}`;
		}

		if (hasSomeIncompleteAddressFields) {
			return "Endereço incompleto";
		}

		return "Não informado";
	}, [openModalSideBar]);

	return (
		<>
			<Styled.MainStepContainer>
				<Styled.ScrollStepContainer>
					<Styled.CenterContent>
						<div className="step-header">
							<div className="container-arrow-progress">
								<IconButton
									icon="back-arrow-lg"
									onClick={goPrev}
									color="inverted"
									marginRight={32}
								/>

								<ProgressBar currentStep={1} currentStepProgress={30} totalSteps={3} height={2} />
							</div>
							<Typography value="Dados da empresa" type="h1" isBold marginBottom={20} />
						</div>

						<Styled.ContainerAnimation {...cameFromTheLeft}>
							<ItemList
								behavior="default"
								eyebrow="Endereço comercial"
								leadingIconColor="accent"
								leadingIconName="marker"
								leadingContent="icon"
								trailingContent="quickButton"
								trailingQuickButtonLabel="Alterar"
								trailingQuickButtonOnPress={() => setOpenModalSideBar(true)}
								isDividerVisible={showEditPositionStep !== "comercialAddress"}
								onClick={() => {}}
								label={addressLabel}
								trailingSupportBadgeColor="danger"
								trailingSupportBadgeTitle="Não verificado"
								trailingSupportBadgeLayout="hint"
								trailingSupportContent={hasAddressError ? "badge" : "none"}
							/>

							<ItemList
								behavior="default"
								eyebrow="Telefone"
								leadingIconColor="accent"
								leadingIconName="phone"
								leadingContent="icon"
								trailingContent="quickButton"
								trailingQuickButtonLabel={
									showEditPositionStep === "phoneNumber" ? "Salvar" : "Alterar"
								}
								trailingQuickButtonOnPress={() => onClickEdit("phoneNumber")}
								label={watch("phoneNumber") || "Não informado"}
								isDisabled={!["none", "phoneNumber"].includes(showEditPositionStep)}
								isDividerVisible={showEditPositionStep !== "phoneNumber"}
								onClick={() => {}}
								trailingSupportBadgeColor="danger"
								trailingSupportBadgeTitle="Não verificado"
								trailingSupportBadgeLayout="hint"
								trailingSupportContent={phoneError ? "badge" : "none"}
							/>

							<AnimatePresence mode="wait" initial={false}>
								{showEditPositionStep === "phoneNumber" && (
									<motion.div {...cameFromTheBottom}>
										<TextField
											label="Telefone"
											maxLength={50}
											placeholder="(99) 99999-9999"
											hookForm={register("phoneNumber")}
											maskFunction={cellphoneMask}
											hintText={phoneError}
											hintVariant="danger"
										/>
									</motion.div>
								)}
							</AnimatePresence>

							<ItemList
								behavior="default"
								eyebrow="E-mail"
								leadingIconColor="accent"
								leadingIconName="mail"
								leadingContent="icon"
								trailingContent="quickButton"
								trailingQuickButtonLabel={showEditPositionStep === "email" ? "Salvar" : "Alterar"}
								trailingQuickButtonOnPress={() => onClickEdit("email")}
								label={watch("email") || "Não informado"}
								isDisabled={!["none", "email"].includes(showEditPositionStep)}
								isDividerVisible={showEditPositionStep !== "email"}
								onClick={() => {}}
								trailingSupportBadgeColor="danger"
								trailingSupportBadgeTitle="Não verificado"
								trailingSupportBadgeLayout="hint"
								trailingSupportContent={emailError ? "badge" : "none"}
							/>

							<AnimatePresence mode="wait" initial={false}>
								{showEditPositionStep === "email" && (
									<motion.div {...cameFromTheBottom}>
										<TextField
											label="E-mail"
											maxLength={50}
											placeholder="example@mail.com"
											hookForm={register("email")}
											hintText={emailError}
											hintVariant="danger"
										/>
									</motion.div>
								)}
							</AnimatePresence>

							<SectionHeading
								behavior="default"
								title="Faturamento médio mensal"
								subHeading="Caso sua empresa ainda não tenha gerado nenhum faturamento, informe a média do faturamento esperado para os próximos meses."
							/>

							<div className="mt-16">
								<TextField
									isDisabled={showEditPositionStep !== "none"}
									maxLength={50}
									leadingLabel="R$"
									placeholder="0,00"
									hookForm={register("monthlyIncome")}
									maskFunction={moneyMask}
									hintText={monthlyIncomeError}
									hintVariant="danger"
								/>
							</div>
						</Styled.ContainerAnimation>
					</Styled.CenterContent>
				</Styled.ScrollStepContainer>

				<div className="step-footer">
					<div className="button-container">
						<GenericButton onClick={goNext} title="Continuar" layout="standard" color="primary" />
					</div>
				</div>
			</Styled.MainStepContainer>

			{/* edit business address sideModal */}
			<ModalSideBar open={openModalSideBar} onClose={closeSideModalEditAddress}>
				<EditBusinessAddress closeSideModal={closeSideModalEditAddress} />
			</ModalSideBar>
		</>
	);
}

export default InputBusinessData;
