import * as Styled from "../OtcOnboarding.styles";
import { useNavigate } from "react-router-dom";
import OtcImage from "assets/images/otc-promo.png";
import { ButtonGroup, Typography } from "@azify/ui-web";
import { useStepManager } from "hooks/useStepManager";

export const StartStep = () => {
	const navigate = useNavigate();
	const { setCurrentStep } = useStepManager();

	return (
		<Styled.MainStepContainer>
			<Styled.ScrollStepContainer>
				<Styled.CenterContent>
					<img src={OtcImage} width={106} height={116} />

					<Typography
						type="h1"
						isBold
						marginTop={8}
						marginBottom={16}
						value="Quase lá! Seu negócio está prestes a começar a operar em nosso balcão"
					/>

					<Typography
						type="body1"
						value="Para iniciar suas operações no mercado OTC, pedimos por gentileza que anexe alguns documentos adicionais e responda algumas perguntas. Essas informações nos auxiliarão a compreender suas necessidades e a oferecer as melhores propostas para o seu negócio."
					/>
				</Styled.CenterContent>
			</Styled.ScrollStepContainer>

			<div className="step-footer">
				<div className="button-container">
					<ButtonGroup
						variant="default"
						titlePrimary="Vamos começar"
						titleSecondary="Voltar"
						isInverted
						onPressPrimary={() => setCurrentStep("AdditionalDocumentStep")}
						onPressSecondary={() => {
							navigate("/settings/plans/otc");
						}}
					/>
				</div>
			</div>
		</Styled.MainStepContainer>
	);
};
