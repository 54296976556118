import { colors } from "@azify/ui-tokens";
import * as Styled from "../OtcOnboarding.styles";
import { ButtonGroup, IconButton, ProgressBar, TextField, Tile, Typography } from "@azify/ui-web";
import { useStepManager } from "hooks/useStepManager";
import { useState } from "react";

export const PersonalDataStep = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [linkedin, setLinkedin] = useState("");
	const { setCurrentStep } = useStepManager();
	const goBack = () => setCurrentStep("RequestedDocumentsStep");

	return (
		<Styled.MainStepContainer>
			<Styled.ScrollStepContainer>
				<Styled.CenterContent>
					<div className="step-header">
						<div className="container-arrow-progress">
							<IconButton icon="back-arrow-lg" onClick={goBack} color="inverted" marginRight={32} />

							<ProgressBar currentStep={0} currentStepProgress={75} totalSteps={1} height={2} />
						</div>
						<Typography
							value="Preencha com o nome completo, e-mail e telefone da pessoa responsável por supervisionar as atividades de AML."
							type="h1"
							isBold
						/>
					</div>

					<Styled.ContainerAnimation paddingTop={230}>
						<TextField
							maxLength={100}
							placeholder="Ex. Luís Almeida Santos"
							label="Nome completo do responsável"
							value={name}
							onChange={(e) => setName(e)}
						/>
						<div className="mb-5" />
						<TextField
							maxLength={30}
							placeholder="Ex. luis@gmail.com"
							label="E-mail"
							value={email}
							onChange={(e) => setEmail(e)}
						/>
						<div className="mb-5" />
						<TextField
							maxLength={30}
							placeholder="Ex. luis.almeida"
							label="LinkedIn"
							value={linkedin}
							onChange={(e) => setLinkedin(e)}
						/>
					</Styled.ContainerAnimation>
				</Styled.CenterContent>
			</Styled.ScrollStepContainer>

			<div className="step-footer">
				<div className="button-container">
					<ButtonGroup
						variant="default"
						titlePrimary="Continuar"
						removeButton="transparentButton"
						onPressPrimary={() => setCurrentStep("SourceFundsStep")}
						isDisabledPrimary={!name && !email}
					/>
				</div>
			</div>
		</Styled.MainStepContainer>
	);
};
