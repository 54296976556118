import { create } from "zustand";
import { persist } from "zustand/middleware";
import { AvatarProps } from "@azify/ui-web";
import { ColorType } from "components/Chip/Chip.types";
import dayjs from "dayjs";

const selectMovementCurrencyList = ["USDT", "USDC", "BTC", "R$"] as const;

type selectMovementCurrency = (typeof selectMovementCurrencyList)[number];

const selectMovementsLabelsList = [
	"Compra de USDT",
	"Compra de USDC",
	"Compra de BTC",
	"Depósito de BRL",
	"Transferência entre contas"
] as const;

type selectMovementsLabels = (typeof selectMovementsLabelsList)[number];

const selectMovementsIconsLabelsList = [
	"main_account",
	"otc_account",
	"balance_USDT",
	"balance_USDC",
	"balance_BTC"
] as const;

type selectMovementsIconsLabels = (typeof selectMovementsIconsLabelsList)[number];

const selectMovementsIconsList = {
	main_account: {
		label: "Conta Principal",
		icon: {
			type: "userPJ" as AvatarProps["type"],
			size: "small" as AvatarProps["size"]
		}
	},
	otc_account: {
		label: "Saldo OTC",
		icon: {
			type: "userPJ" as AvatarProps["type"],
			size: "small" as AvatarProps["size"]
		}
	},
	balance_USDT: {
		label: "Saldo USDT",
		icon: {
			type: "currency" as AvatarProps["type"],
			currency: "USDT" as AvatarProps["currency"],
			size: "small" as AvatarProps["size"]
		}
	},
	balance_USDC: {
		label: "Saldo USDC",
		icon: {
			type: "currency" as AvatarProps["type"],
			currency: "USDC" as AvatarProps["currency"],
			size: "small" as AvatarProps["size"]
		}
	},
	balance_BTC: {
		label: "Saldo BTC",
		icon: {
			type: "currency" as AvatarProps["type"],
			currency: "BTC" as AvatarProps["currency"],
			size: "small" as AvatarProps["size"]
		}
	}
};

interface UseOtcProps {
	otcBalance: number;
	otcTether: number;
	otcUsdc: number;
	otcBitcoin: number;
	otcPending: number;
	otcPersonalizedLimit: number;
	otcOrderNumber: number;
	graphLabales: {
		formatter: (value: number) => string;
	};
	graphDate: string[];
	graphData: {
		data: number[];
	}[];
	movements: {
		id: number;
		type: string;
		date: string;
		origin: string;
		iconOrigin: {
			type: AvatarProps["type"];
			currency?: AvatarProps["currency"];
			size: AvatarProps["size"];
		};
		destiny: string;
		iconDestiny: {
			type: AvatarProps["type"];
			currency?: AvatarProps["currency"];
			size: AvatarProps["size"];
		};
		status: string;
		statusColor: ColorType;
		statusWidth: string;
		value: string;
	}[];
	dispatchOtc: (args: { type: string; by: number }) => void;
	dispatchOtcMovements: (args: {
		type: selectMovementsLabels;
		transactionOrigin: selectMovementsIconsLabels;
		transactionDestiny: selectMovementsIconsLabels;
		isoCode: selectMovementCurrency;
		value: string;
	}) => void;
}

const types = {
	increaseOtc: "INCREASE_OTC",
	decreaseOtc: "DECREASE_OTC",
	decreaseLimit: "DECREASE_LIMIT",
	increasePending: "INCREASE_PENDING",
	increaseTether: "INCREASE_TETHER",
	increaseUsdc: "INCREASE_USDC",
	increaseBitcoin: "INCREASE_BITCOIN"
};

const reducer = (
	state: Omit<UseOtcProps, "dispatchOtc">,
	{
		type,
		by
	}: {
		type: string;
		by: number;
	}
) => {
	switch (type) {
		case types.increaseOtc:
			return { otcBalance: state.otcBalance + by };
		// -----
		case types.decreaseOtc:
			if (state.otcBalance <= 0) return { otcBalance: state.otcBalance };
			if (state.otcBalance - by < 0) return { otcBalance: 0 };
			return { otcBalance: state.otcBalance - by };
		// -----
		case types.decreaseLimit:
			if (state.otcPersonalizedLimit <= 0)
				return { otcPersonalizedLimit: state.otcPersonalizedLimit };
			if (state.otcPersonalizedLimit - by < 0) return { otcPersonalizedLimit: 0 };
			return { otcPersonalizedLimit: state.otcPersonalizedLimit - by };
		// -----
		case types.increasePending:
			return { otcPending: state.otcPending + by };

		case types.increaseTether:
			return { otcTether: state.otcTether + by };

		case types.increaseUsdc:
			return { otcUsdc: state.otcUsdc + by };

		case types.increaseBitcoin:
			return { otcBitcoin: state.otcBitcoin + by };

		default:
			return state;
	}
};

const reducerMovements = (
	state: Pick<UseOtcProps, "movements">,
	{
		type,
		transactionOrigin,
		transactionDestiny,
		isoCode,
		value
	}: {
		type: selectMovementsLabels;
		transactionOrigin: selectMovementsIconsLabels;
		transactionDestiny: selectMovementsIconsLabels;
		isoCode: selectMovementCurrency;
		value: string;
	}
) => {
	return {
		movements: [
			...state.movements,
			{
				id: state.movements.length + 1,
				type: type,
				date: dayjs().locale("pt-br").format("DD MMM YYYY HH:mm:ss"),
				origin: selectMovementsIconsList[transactionOrigin].label,
				iconOrigin: {
					...selectMovementsIconsList[transactionOrigin].icon
				},
				destiny: selectMovementsIconsList[transactionDestiny].label,
				iconDestiny: {
					...selectMovementsIconsList[transactionDestiny].icon
				},
				status: "Concluído",
				statusColor: "success" as ColorType,
				statusWidth: "85px",
				value: `${isoCode} ${value}`
			}
		]
	};
};

export const useOtcActions = create(
	persist<UseOtcProps>(
		(set) => ({
			otcBalance: 275009.23,
			otcTether: 500.23,
			otcUsdc: 0,
			otcBitcoin: 0,
			otcPending: 0,
			otcPersonalizedLimit: 500000.0,
			otcOrderNumber: 0,
			graphLabales: {
				formatter: (value: number) => {
					return `${String(value).slice(0, 1)}.${String(value).slice(0, 1)} mi`;
				}
			},
			graphDate: ["Mar 24", "Apr 24", "Mai 24"],
			graphData: [
				{
					data: [26, 39, 44]
				},
				{
					data: [23, 55, 82]
				},
				{
					data: [40, 34, 78]
				}
			],
			movements: [
				{
					id: 1,
					type: "Compra de USDT",
					date: "28 abr 2024 09:02:14",
					origin: "Conta OTC",
					iconOrigin: {
						type: "currency" as AvatarProps["type"],
						currency: "BRL" as AvatarProps["currency"],
						size: "small" as AvatarProps["size"]
					},
					destiny: "Saldo USDT",
					iconDestiny: {
						type: "userPJ" as AvatarProps["type"],
						size: "small" as AvatarProps["size"]
					},
					status: "Concluído",
					statusColor: "success" as ColorType,
					statusWidth: "85px",
					value: "USDT 500.23"
				},
				{
					id: 1,
					type: "Transferência entre contas",
					date: "30 abr 2024 09:02:14",
					origin: "Conta Principal",
					iconOrigin: {
						type: "currency" as AvatarProps["type"],
						currency: "BRL" as AvatarProps["currency"],
						size: "small" as AvatarProps["size"]
					},
					destiny: "Conta OTC",
					iconDestiny: {
						type: "userPJ" as AvatarProps["type"],
						size: "small" as AvatarProps["size"]
					},
					status: "Concluído",
					statusColor: "success" as ColorType,
					statusWidth: "85px",
					value: "R$ 200.000,00"
				}
			],

			dispatchOtc: (args) => set((state) => reducer(state, args)),
			dispatchOtcMovements: (args) => set((state) => reducerMovements(state, args))
		}),
		{
			name: "otc-storage"
		}
	)
);
