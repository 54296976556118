import { Avatar, Icon, Typography } from "@azify/ui-web";
import * as S from "./ItemListCollapse.styles";
import { ItemListCollapseProps } from "./ItemListCollapse.types";
import { colors } from "@azify/ui-tokens";
import { When } from "react-if";
import { motion, AnimatePresence } from "framer-motion";

const ItemListCollapse = ({ open, collapseItems, setOpen }: ItemListCollapseProps) => {
	return (
		<S.Container>
			<div
				className="main-item-list"
				onClick={() => {
					setOpen(!open);
				}}>
				<div className="container-icon-text">
					<Avatar type="initials" fullName="Nayla Carreira Medeiros" />
					<div className="texts-container">
						<Typography type="subtitle1" isBold value="Nayla Carreira Medeiros" />
						<Typography colorCustom={colors.gray.dark} type="body3" value="2 contas salvas" />
					</div>
				</div>
				<Icon name={open ? "remove" : "add"} size="small" />
			</div>
			<AnimatePresence>
				<When condition={open}>
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.2 }}
						className="collapse-item-list">
						<div className="spacer">
							<div className="divider" />
						</div>

						<div className="item-list-container">{collapseItems?.map((i) => i)}</div>
					</motion.div>
				</When>
			</AnimatePresence>
		</S.Container>
	);
};

export default ItemListCollapse;
