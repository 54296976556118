import { ButtonGroup, Illustration, TextField, Typography } from "@azify/ui-web";
import * as Styled from "./ForgotId.styles";
import { Else, If, Then } from "react-if";
import { useState } from "react";
import { cpfMask, dateMask } from "helpers/mask";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
	cpf: Yup.string().required("Insira um CPF válido"),
	birthDate: Yup.string().required("Insira uma data de nascimento válida")
});

export const ForgotId = () => {
	const [showSendedEmail, setShowSendedEmail] = useState(false);
	const [formData, setFormData] = useState({
		cpf: "",
		birthDate: ""
	});
	const [errors, setErrors] = useState({
		cpf: "",
		birthDate: ""
	});

	const handleSubmit = () => {
		validationSchema
			.validate(formData, { abortEarly: false })
			.then(() => {
				setShowSendedEmail(true);
			})
			.catch((error) => {
				const newError: any = {};

				error.inner.forEach((err: any) => {
					newError[err.path] = err.message;
				});

				setErrors(newError);
			});
	};

	const navigate = useNavigate();

	return (
		<Styled.Container>
			<Styled.CenterContent>
				<If condition={!showSendedEmail}>
					<Then>
						<Typography value="Esqueci meu Azify ID" type="h1" isBold marginBottom={20} />
						<Typography
							value="Primeiro, digite o seu CPF e data de nascimento para recuperar seu Azify ID."
							type="body1"
							marginBottom={40}
						/>

						<div className="container-inputs">
							<TextField
								onChange={(e) => setFormData({ ...formData, cpf: e })}
								label="CPF"
								value={formData.cpf}
								placeholder="000.000.000-00"
								maxLength={14}
								maskFunction={cpfMask}
								hintVariant="danger"
								hintText={errors?.cpf}
								increaseBorderOnClick
							/>

							<TextField
								onChange={(e) => setFormData({ ...formData, birthDate: e })}
								label="Data de nascimento"
								value={formData.birthDate}
								placeholder="dd/mm/aaaa"
								maxLength={10}
								maskFunction={dateMask}
								hintVariant="danger"
								hintText={errors?.birthDate}
								increaseBorderOnClick
							/>
						</div>

						<div className="fixed-group-button-step-1">
							<ButtonGroup
								variant="default"
								titlePrimary="Recuperar meu Azify ID"
								onPressPrimary={handleSubmit}
								removeButton="transparentButton"
							/>
						</div>
					</Then>

					<Else>
						<Illustration name="smartphone-back-camera-md" width={140} height={120} />

						<Typography
							value="Seu Azify ID foi enviado para o seu e-mail!"
							type="h1"
							isBold
							marginTop={8}
						/>

						<Typography
							value="Enviamos o seu Azify ID para o endereço de 
							e-mail cadastrado (n*******e@g****.com). Caso não tenha recebido ou não tenha mais acesso a este e-mail, por favor, entre em contato com nossa equipe de suporte para receber assistência imediata."
							type="body1"
							marginTop={16}
						/>

						<div className="fixed-group-button">
							<ButtonGroup
								variant="default"
								isInverted
								titleSecondary="Preciso de Ajuda"
								titlePrimary="Ir para o início"
								onPressPrimary={() => navigate("/auth/sign-in")}
								onPressSecondary={() => {}}
							/>
						</div>
					</Else>
				</If>
			</Styled.CenterContent>
		</Styled.Container>
	);
};
