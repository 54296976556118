import { GenericButton } from "@azify/ui-web";
import LoaderStatus from "components/LoaderStatus/LoaderStatus.component";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Styled from "../ExchangeFounds.styles";
import { useStepManager } from "hooks/useStepManager";
import { useMainAccountActions } from "hooks/useMainAccountActions";
import { formatCurrencyFn } from "helpers/currency";
import { useGatewayActions } from "hooks/useGatewayActions";
import dayjs from "dayjs";

export const ExchangeSuccess = () => {
	const navigate = useNavigate();
	const { params } = useStepManager();
	const [isLoading, setIsLoading] = useState(true);
	const { dispatch: dispatchGateway, dispatchGatewayMovements } = useGatewayActions();
	const { dispatchMain } = useMainAccountActions();

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 1000);

		return () => clearTimeout(timer);
	}, []);

	const valuesToDisplay = {
		...params
	} as {
		amount: number;
	};

	const decreaseValue = () => {
		const { amount } = valuesToDisplay;

		dispatchMain({
			type: "INCREASE_MAIN",
			by: amount
		});

		dispatchGateway({
			type: "DECREASE_GATEWAY",
			by: amount
		});

		dispatchGatewayMovements({
			type: "CREATE_MOVEMENT",
			args: {
				date: dayjs().format("DD MMM, YYYY"),
				client: "Azify LTDA",
				status: "Recebido",
				method: "Troca de saldo",
				method_desc: "Transferência interna",
				value: amount,
				periodicity: "none",
				hour: dayjs().format("HH:mm"),
				recurrence: "N/A",
				recurrence_desc: "N/A",
				phone: "---"
			}
		});
	};

	const getSubtitle = "Conta cobrança";
	const getEmphasis = "Conta principal";

	return (
		<>
			<Styled.CenterContainer>
				<div className="display-between">
					<div className="center-items">
						<LoaderStatus
							title={isLoading ? "Efetuando transferência..." : "Transferência Realizada!"}
							subtitle={
								isLoading
									? ""
									: `Você transferiu ${formatCurrencyFn("BRL", String(valuesToDisplay.amount))} da ${getSubtitle} para a`
							}
							emphasis={isLoading ? "" : `${getEmphasis}`}
							status={isLoading ? "loading" : "success"}
						/>
					</div>

					<GenericButton
						onClick={() => {
							decreaseValue();
							navigate("/gateway");
						}}
						isDisabled={isLoading}
						title="Concluir"
						layout="standard"
						color="primary"
					/>
				</div>
			</Styled.CenterContainer>
		</>
	);
};
