import * as Styled from "./PixCode.styles";
import { Case, Switch } from "react-if";
import { useStepManager } from "hooks/useStepManager";
import VerifyCode from "./Steps/0.VerifyCode";
import ShowCodeDetails from "./Steps/1.ShowCodeDetails";
import StatusPixCode from "./Steps/2.StatusPixCode";
import { PixCodeSteps } from "./PixCode.types";
import { useLocation } from "react-router-dom";

export const PixCodeScreen = () => {
	const { currentStep } = useStepManager();
	const typedCurrentStep = currentStep as PixCodeSteps;

	const { state } = useLocation();

	return (
		<Styled.Container>
			<Styled.CenterContainer>
				<Switch>
					<Case condition={typedCurrentStep === "VerifyCode"}>
						<VerifyCode isQr={state?.type === "qrCode"} />
					</Case>

					<Case condition={typedCurrentStep === "ShowCodeDetails"}>
						<ShowCodeDetails />
					</Case>

					<Case condition={typedCurrentStep === "StatusPixCode"}>
						<StatusPixCode />
					</Case>
				</Switch>
			</Styled.CenterContainer>
		</Styled.Container>
	);
};
