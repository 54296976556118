import { colors } from "@azify/ui-tokens";
import { useStepManager } from "hooks/useStepManager";
import { useNavigate } from "react-router-dom";
import * as Styled from "../NewDebitCollection.styles";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
	EmptyState,
	HeaderNavigation,
	Icon,
	ItemList,
	SearchField,
	Typography
} from "@azify/ui-web";

import BoxEmptyContent from "assets/images/gateway-box-empty-content.png";
import { useState } from "react";

export const SelectClient = () => {
	const { setCurrentStep, setParams } = useStepManager();
	const navigate = useNavigate();
	const [searchValue, setSearchValue] = useState("");

	return (
		<>
			<Styled.CenterContainer>
				<HeaderNavigation
					buttonOnTitle={false}
					title="Cobrar"
					trailingActionsType="primaryPrimary"
					quickButtonColor="inverted"
					backIconClick={() => navigate("/gateway")}
				/>

				<div
					style={{
						marginTop: 24
					}}>
					<div className="search-container">
						<SearchField
							value={searchValue}
							onChangeInput={(v) => setSearchValue(v)}
							placeholder="Procurar..."
							onDelete={() => setSearchValue("")}
						/>
					</div>

					<ItemList
						behavior="default"
						label="Cobrar um novo cliente"
						trailingSupportContent="none"
						leadingContent="icon"
						leadingIconName="user-rounded"
						trailingContent="icon"
						onClick={() => {
							setCurrentStep("NewClient");
						}}
					/>

					<div className="list-content-content empty">
						<img src={BoxEmptyContent} alt="Empty Content" width="152px" height="115px" />

						<Typography
							type="h4"
							value="Seus clientes serão listados aqui"
							color="primary"
							isBold
							marginTop={16}
						/>
						<Typography
							type="body1"
							value="Você ainda não salvou nenhum. Inicie uma cobrança agora mesmo."
							color="primary"
							marginTop={16}
						/>
					</div>
				</div>
			</Styled.CenterContainer>
		</>
	);
};
