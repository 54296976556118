import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 12px 32px;
	margin-top: 12px;
	height: 100%;

	.container-docs {
		display: flex;
		flex-direction: column;
		gap: 16px;
		margin-top: 16px;
	}

	.footer {
		display: flex;
		padding: 24px 0px;
		width: 100%;
		align-items: center;
		justify-content: center;
	}

	& .tile-container {
		display: flex;
		justify-content: space-between;
		gap: 8px;

		margin-top: 36px;
	}

	& .date-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		gap: 16px;
		margin-top: 16px;
	}

	& .select-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;

		margin-top: 16px;
		margin-bottom: 20px;
	}
`;
