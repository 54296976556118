import { colors } from "@azify/ui-tokens";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: fit-content;
`;

export const CenterContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 1280px;
	height: 100%;

	.tooltip-style {
		font-family: Helvetica, sans-serif;
		font-size: 14px;
		font-weight: 500;
		border-radius: 8px;
		opacity: 1 !important;
		background-color: ${colors.black.main};
	}

	.container-custom-label-info-icon {
		display: flex;
		flex-direction: row;
		gap: 8px;
		justify-content: flex-start;
		align-items: center;
	}

	.container-icon-label {
		justify-content: center;
		align-items: center;
		display: flex;
		flex-direction: row;
		gap: 24px;
		margin-top: 24px;
	}

	.container-columns {
		display: flex;
		width: 100%;
		height: 100%;
		margin-bottom: 50px;

		.first-column {
			flex: 1.3;
			display: flex;
			flex-direction: column;
			gap: 16px;
		}

		.second-column {
			flex: 1;
			display: flex;
			justify-content: flex-end;
			padding-top: 40px;
			flex-direction: row;

			.offers-for-you-card {
				display: flex;
				width: 403px;
				height: fit-content;
				padding: 32px;
				flex-direction: column;
				align-items: flex-start;
				gap: 8px;
				border-radius: 16px;
				border: 1px solid ${colors.black.opacity["10p"]};
				margin-bottom: 32px;

				.explain-card {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 24px;
					margin-bottom: 24px;

					.container-icon-desc {
						display: flex;
						flex-direction: column;
						width: 100%;
						gap: 12px;
					}
				}

				.container-icon-title {
					display: flex;
					flex-direction: row;
					gap: 16px;
					justify-content: flex-start;
					align-items: center;
					margin-bottom: 32px;
				}
			}
		}
	}
`;
