import { useFormContext } from "react-hook-form";
import * as S from "./EditBusinessAddress.styles";
import { GenericButton, TextField, Typography } from "@azify/ui-web";
import { EditBusinessAddressProps } from "./EditBusinessAddress.types";
import { useEffect } from "react";

function EditBusinessAddress({ closeSideModal }: EditBusinessAddressProps) {
	const {
		register,
		trigger,
		formState: { errors },
		setFocus
	} = useFormContext();

	const saveInformation = () => {
		const fieldsToTrigger = [
			"cep",
			"city",
			"state",
			"address",
			"addressNumber",
			"neighborhood",
			"addressComplement"
		];

		trigger(fieldsToTrigger, { shouldFocus: true }).then((isValid) => {
			if (isValid) {
				closeSideModal();
			}
		});
	};

	useEffect(() => {
		setFocus("cep");
	}, [setFocus]);

	return (
		<S.Container>
			<div className="container-inputs">
				<Typography value="Editar Endereço Comercial" type="h1" isBold />

				<TextField
					label="CEP"
					maxLength={50}
					placeholder="00000-000"
					hookForm={register("cep")}
					hintText={errors.cep?.message as string}
					hintVariant="danger"
				/>

				<div className="grid-inputs">
					<TextField
						hookForm={register("city")}
						maxLength={50}
						label="Cidade"
						placeholder="São Paulo"
						type="select"
						hintText={errors.city?.message as string}
						hintVariant="danger"
					/>

					<TextField
						hookForm={register("state")}
						maxLength={50}
						label="Estado"
						placeholder="São Paulo"
						type="select"
						hintText={errors.state?.message as string}
						hintVariant="danger"
					/>
				</div>

				<TextField
					label="Endereço"
					maxLength={50}
					placeholder='Ex: "Rua, Avenida, etc."'
					hookForm={register("address")}
					hintText={errors.address?.message as string}
					hintVariant="danger"
				/>

				<div className="grid-inputs">
					<TextField
						hookForm={register("addressNumber")}
						maxLength={50}
						label="Número"
						placeholder="0000"
						type="select"
						hintText={errors.addressNumber?.message as string}
						hintVariant="danger"
					/>

					<TextField
						label="Bairro"
						maxLength={50}
						placeholder='Ex: "Vila Mariana"'
						hookForm={register("neighborhood")}
						hintText={errors.neighborhood?.message as string}
						hintVariant="danger"
					/>
				</div>

				<TextField
					hookForm={register("addressComplement")}
					maxLength={50}
					label="Complemento"
					placeholder="Apto, bloco, etc."
					type="select"
					hintText={errors.addressComplement?.message as string}
					hintVariant="danger"
				/>
			</div>

			<div className="footer">
				<GenericButton
					onClick={saveInformation}
					title="Atualizar informações"
					layout="standard"
					color="secondary"
				/>
			</div>
		</S.Container>
	);
}

export default EditBusinessAddress;
