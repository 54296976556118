import { CheckDataProps, CreatePixKeySteps } from "../CreatePixKey.types";
import * as S from "../CreatePixKey.styles";
import { GenericButton, TextField } from "@azify/ui-web";

const genericLabel = {
	phone: "Número do celular",
	email: "E-mail",
	cnpj: "",
	random: ""
};

const OtherKeyType = (props: CheckDataProps) => {
	const { type, setCurrentStep } = props;

	const handleNextStep = (value: CreatePixKeySteps) => {
		setCurrentStep && setCurrentStep(value);
	};

	return (
		<S.SubContent>
			<TextField
				onChange={() => {}}
				label={genericLabel[type]}
				value=""
				placeholder=""
				maxLength={11}
			/>

			<div className="footer">
				<GenericButton
					title="Obter código"
					color="secondary"
					layout="outlined"
					onClick={() => handleNextStep("ConfirmCode")}
				/>
			</div>
		</S.SubContent>
	);
};

export default OtherKeyType;
