import { borderRadius, borderWidths, colors, spacings } from "@azify/ui-tokens";
import styled from "styled-components";
import { GenericButtonProps } from "./GenericButton.types";

export const backgroundColors: Record<GenericButtonProps["color"], string> = {
	primary: colors.primary.main,
	secondary: colors.black.main,
	default: colors.white.dark,
	danger: colors.danger.main,
	inverted: colors.white.light
};

export const backgroundColorHover: Record<GenericButtonProps["color"], string> = {
	primary: colors.primary.dark,
	secondary: colors.black.dark,
	default: "#E7E8ED",
	danger: colors.danger.dark,
	inverted: colors.white.main
};

export const backgroundColorOnDisabled: Record<GenericButtonProps["color"], string> = {
	primary: colors.primary.opacity["30p"],
	secondary: colors.black.opacity["30p"],
	default: colors.white.opacity["30p"],
	danger: colors.danger.opacity["30p"],
	inverted: colors.white.opacity["30p"]
};

export const titleColor: Record<GenericButtonProps["color"], string> = {
	primary: colors.white.light,
	secondary: colors.white.light,
	default: colors.black.main,
	danger: colors.white.light,
	inverted: colors.black.main
};

export const titleColorOnDisabled: Record<GenericButtonProps["color"], string> = {
	primary: colors.white.light,
	secondary: colors.white.light,
	default: colors.black.opacity["30p"],
	danger: colors.white.light,
	inverted: colors.black.opacity["30p"]
};

export const outlinedAndGhostTitleColor: Record<GenericButtonProps["color"], string> = {
	primary: colors.primary.main,
	secondary: colors.black.main,
	default: colors.white.light,
	danger: colors.danger.main,
	inverted: colors.white.light
};

export const outlinedAndGhostTitleColorOnDisabled: Record<GenericButtonProps["color"], string> = {
	primary: colors.primary.opacity["30p"],
	secondary: colors.black.opacity["30p"],
	default: colors.white.opacity["30p"],
	danger: colors.danger.opacity["30p"],
	inverted: colors.white.opacity["30p"]
};

export const borderColor: Record<GenericButtonProps["color"], string> = {
	primary: colors.primary.main,
	secondary: colors.black.main,
	default: colors.white.light,
	danger: colors.danger.main,
	inverted: colors.white.light
};

export const borderColorOnDisabled: Record<GenericButtonProps["color"], string> = {
	primary: colors.primary.opacity["30p"],
	secondary: colors.black.opacity["30p"],
	default: colors.white.opacity["30p"],
	danger: colors.danger.opacity["30p"],
	inverted: colors.white.opacity["30p"]
};

export const Container = styled.button<
	Pick<
		GenericButtonProps,
		| "verticalPadding"
		| "widthButton"
		| "backgroundColor"
		| "backgroundColorHover"
		| "color"
		| "borderColor"
	>
>`
	opacity: 1;
	min-height: 38px;
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	padding: ${(p) => p.verticalPadding || 0}px 16px;
	width: ${(p) => (p.widthButton && p.widthButton) || "100%"};
	background-color: ${(p) =>
		p.backgroundColor ||
		(p.disabled ? backgroundColorOnDisabled[p.color] : backgroundColors[p.color])};
	border: ${borderWidths.xs}px solid
		${(p) => (p.borderColor ? borderColor[p.borderColor] : colors.transparent)};
	border-radius: 400px;
	cursor: pointer;

	& .icon-container.left {
		display: flex;
		align-items: center;

		margin-right: 6px;
	}

	& .icon-container.right {
		display: flex;
		align-items: center;

		margin-left: 6px;
	}

	&:hover {
		background-color: ${(p) =>
			p.backgroundColorHover ||
			(p.disabled ? backgroundColorOnDisabled[p.color] : backgroundColorHover[p.color])};
	}
`;
