import { useState } from "react";
import { useStepManager } from "hooks/useStepManager";
import { cameFromTheLeft } from "pages/Onboarding/Onboarding.styles";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
	Banner,
	GenericButton,
	IconButton,
	IconDefaultType,
	IconFilledType,
	ItemList,
	ProgressBar,
	TextField,
	Typography
} from "@azify/ui-web";
import ModalSideBar from "components/ModalSideBar";
import CreatePartner from "common/modals/sideRight/CreatePartner/CreatePartner.component";
import UpdatePartner from "common/modals/sideRight/UpdatePartner/UpdatePartner.component";
import * as Styled from "../Kyb.styles";
import { Partner } from "../Kyb.types";
import Dialog from "components/Modal/Dialog/Dialog.component";
import { percentageMask } from "helpers/mask";
import { When } from "react-if";
import UpdateManagerDetails from "common/modals/sideRight/UpdateManagerDetails/UpdateManagerDetails.component";

function InputAndListPartners() {
	const { control } = useFormContext();
	const [openModal, setOpenModal] = useState(true);
	const { setCurrentStep, setParams } = useStepManager();
	const [openAddPartner, setOpenAddPartner] = useState(false);
	const [openEditPartner, setOpenEditPartner] = useState(false);
	const [openEditManager, setOpenEditManager] = useState(false);
	const [showEditPercent, setShowEditPercent] = useState(false);
	const [action, setAction] = useState<{
		index: number;
		type: "" | "update-business" | "update-personal";
	}>({
		index: 0,
		type: ""
	});

	const fieldArrayName = {
		business: "businessPartners",
		personal: "personalPartners"
	};

	const personalPartners = useFieldArray({
		control,
		name: fieldArrayName.personal,
		keyName: "uniqueId"
	});

	const businessPartners = useFieldArray({
		control,
		name: fieldArrayName.business,
		keyName: "uniqueId"
	});

	const personalPartnersList = personalPartners.fields as Partner[];
	const businessPartnerList = businessPartners.fields as Partner[];

	const goAddPartnersOfPartners = (partner: Partner) => {
		const firstLevelEditingIndex = businessPartners.fields.findIndex(
			(businessPartners) => businessPartners.uniqueId === partner.uniqueId
		);

		setParams({ firstLevelEditing: partner, firstLevelEditingIndex });
		setCurrentStep("InputBusinessPartners");
	};

	const goNext = () => setCurrentStep("InputDocuments");
	const goPrev = () => setCurrentStep("InputBusinessData");

	const funcOptions: {
		label: string;
		value: string;
		helpText: string;
		action: () => void;
		icon: IconFilledType | IconDefaultType;
	}[] = [
		{
			label: "Sou o único sócio",
			value: "uniquePartner",
			helpText: "",
			icon: "user-rounded",
			action: () => {}
		},
		{
			label: "Sou procurador",
			value: "attorney",
			helpText: "",
			icon: "file-text",
			action: () => {}
		},
		{
			label: "A empresa possui outros sócios",
			value: "hasAnotherPartners",
			helpText: "",
			icon: "group",
			action: () => setShowEditPercent((prev) => !prev)
		}
	];

	const isManager = false;

	return (
		<>
			<Styled.MainStepContainer>
				<Styled.ScrollStepContainer>
					<Styled.CenterContent>
						<div className="step-header">
							<div className="container-arrow-progress">
								<IconButton
									icon="back-arrow-lg"
									onClick={goPrev}
									color="inverted"
									marginRight={32}
								/>
								<ProgressBar currentStep={1} currentStepProgress={45} totalSteps={3} height={2} />
							</div>

							<Typography value="Sócios e Representantes" type="h1" isBold marginBottom={16} />
						</div>

						<Styled.ContainerAnimation {...cameFromTheLeft}>
							<Typography
								value="É o momento de conhecer a equipe que compõe o quadro societário da empresa."
								type="body1"
								marginBottom={16}
							/>

							<Banner
								behavior="default"
								hierarchy="low"
								layout="inset"
								color="default"
								artwork="icon"
								title="Todos os sócios que possuem mais de 25% de participação devem ser adicionados nesta etapa."
							/>

							<div
								style={{
									marginTop: 16
								}}>
								{personalPartnersList.map((partner: Partner, index: number) => {
									return (
										<ItemList
											key={partner.uniqueId}
											behavior="default"
											isDividerVisible
											trailingContent="icon"
											leadingContent="avatar"
											trailingSupportContent="badge"
											trailingSupportBadgeTitle="Biometria pendente"
											trailingSupportBadgeLayout="label"
											trailingSupportBadgeColor="warning"
											leadingAvatarFullName={partner.name}
											paragraph="Sócio"
											label={partner.name}
											onClick={() => {
												setAction({
													index,
													type: "update-personal"
												});

												if (isManager) {
													setOpenEditManager(true);
												} else {
													setOpenEditPartner(true);
												}
											}}
										/>
									);
								})}

								{businessPartnerList.map((partner: Partner, index) => {
									const numOfSubPartnersBiz =
										businessPartnerList[index].businessPartners?.length || 0;

									const numOfSubPartnerPersonal =
										businessPartnerList[index].personalPartners?.length || 0;

									return (
										<ItemList
											key={partner.uniqueId}
											trailingSupportContent="none"
											isDividerVisible
											behavior="default"
											trailingContent="icon"
											supportParagraph={`${numOfSubPartnersBiz + numOfSubPartnerPersonal} sócios adicionados`}
											leadingContent="avatar"
											leadingAvatarFullName={partner.name}
											paragraph={`Sócio - ${partner.documentNumber}`}
											label={partner.name}
											onClick={() => goAddPartnersOfPartners(partner)}
										/>
									);
								})}
							</div>

							<ItemList
								behavior="default"
								trailingContent="icon"
								leadingContent="icon"
								leadingIconName="add"
								leadingIconColor="accent"
								trailingSupportContent="none"
								label="Adicionar Sócio ou Representante"
								onClick={() => setOpenAddPartner(true)}
							/>
						</Styled.ContainerAnimation>
					</Styled.CenterContent>
				</Styled.ScrollStepContainer>

				<div className="step-footer">
					<div className="button-container">
						<GenericButton onClick={goNext} title="Continuar" layout="standard" color="primary" />
					</div>
				</div>
			</Styled.MainStepContainer>

			{/* CreatePartner */}

			<ModalSideBar
				fixedHeader
				modalTitle="Novo Sócio ou Acionista"
				open={openAddPartner}
				onClose={() => setOpenAddPartner(false)}>
				<CreatePartner
					personalPartnersMethods={personalPartners}
					businessPartnersMethods={businessPartners}
					closeModal={() => setOpenAddPartner(false)}
					fieldArrayName={fieldArrayName}
				/>
			</ModalSideBar>

			{/* UpdatePartner */}

			<ModalSideBar
				hiddenHeader
				disableModalScroll
				open={openEditPartner}
				onClose={() => setOpenEditPartner(false)}>
				<UpdatePartner
					personalPartnersMethods={personalPartners}
					businessPartnersMethods={businessPartners}
					closeModal={() => setOpenEditPartner(false)}
					fieldArrayName={fieldArrayName}
					action={action}
				/>
			</ModalSideBar>

			{/* UpdateManagerDetails */}

			<ModalSideBar open={openEditManager} onClose={() => setOpenEditManager(false)}>
				<UpdateManagerDetails
					personalPartnersMethods={personalPartners}
					closeModal={() => setOpenEditManager(false)}
					action={action}
				/>
			</ModalSideBar>

			{/* Dialog ask owner */}

			<Dialog
				openDialog={openModal}
				setOpenDialog={setOpenModal}
				noCloseButton
				buttonType="single"
				headerTitle="Como a empresa está organizada hoje?"
				layout="alternative"
				genericButtonProps={{
					title: "Continuar",
					layout: "standard",
					color: "secondary",
					onClick: () => setOpenModal(false)
				}}>
				<div>
					{funcOptions.map((opt) => {
						return (
							<div key={opt.value}>
								<ItemList
									onClick={opt.action}
									trailingSupportContent="none"
									behavior="default"
									trailingContent="radio"
									leadingContent="icon"
									trailingRadioIsChecked={true}
									trailingRadioColor="default"
									leadingIconName={opt.icon}
									leadingIconColor="accent"
									label={opt.label}
									paragraph={opt.helpText}
								/>
							</div>
						);
					})}

					<When condition={showEditPercent}>
						<div
							style={{
								padding: 16
							}}>
							<TextField
								label="Qual seu percentual de participação?"
								maxLength={50}
								leadingLabel="%"
								placeholder="0,00"
								maskFunction={percentageMask}
								hintVariant="danger"
							/>
						</div>
					</When>
				</div>
			</Dialog>
		</>
	);
}

export default InputAndListPartners;
