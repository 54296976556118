import * as Styled from "../Kyc.styles";
import { useStepManager } from "hooks/useStepManager";
import { useFormContext } from "react-hook-form";
import { cpfMask } from "helpers/mask";
import { cameFromTheRight } from "pages/Onboarding/Onboarding.styles";
import Dialog from "components/Modal/Dialog/Dialog.component";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonGroup, IconButton, ProgressBar, TextField, Typography } from "@azify/ui-web";

function InputDocumentNumber() {
	const [currentOpenDialog, setCurrentOpenDialog] = useState("none");
	const { setCurrentStep } = useStepManager();
	const navigate = useNavigate();

	const {
		register,
		formState: { errors },
		trigger
	} = useFormContext();

	const goNext = () => {
		const fieldsToTrigger = ["documentNumber"];

		trigger(fieldsToTrigger, {
			shouldFocus: true
		}).then((isValid) => {
			if (isValid) setCurrentStep("InputPersonalData");
		});
	};

	return (
		<Styled.MainStepContainer>
			<Styled.ScrollStepContainer>
				<Styled.CenterContent>
					<div className="step-header">
						<div className="container-arrow-progress">
							<IconButton
								icon="back-arrow-lg"
								onClick={() => {
									navigate("/auth/sign-in");
								}}
								color="inverted"
								marginRight={32}
							/>

							<ProgressBar currentStep={0} currentStepProgress={40} totalSteps={3} height={2} />
						</div>

						<Typography value="Crie o seu login de acesso" type="h1" isBold marginBottom={16} />
					</div>

					<Styled.ContainerAnimation {...cameFromTheRight}>
						<Typography
							value="Preencha os dados solicitados para criar o seu login e acessar todas as plataformas e aplicativos da Azify."
							type="body1"
							marginBottom={24}
						/>

						<TextField
							maxLength={14}
							label="CPF"
							placeholder="000.000.000-00"
							hookForm={register("documentNumber")}
							maskFunction={cpfMask}
							hintText={errors?.documentNumber?.message as string}
							hintVariant="danger"
						/>
					</Styled.ContainerAnimation>
				</Styled.CenterContent>
			</Styled.ScrollStepContainer>

			<div className="step-footer">
				<div className="button-container">
					<ButtonGroup
						variant="default"
						titlePrimary="Continuar"
						titleSecondary="Tenho um convite"
						isInverted
						onPressPrimary={goNext}
						onPressSecondary={() => setCurrentOpenDialog("inviteAdvice")}
					/>
				</div>
			</div>

			{/* INVITE ADVICE */}

			<Dialog
				openDialog={currentOpenDialog === "inviteAdvice"}
				setOpenDialog={() => setCurrentOpenDialog("none")}
				headerTitle="Você possui um convite?"
				layout="alternative"
				buttonType="single"
				genericButtonProps={{
					title: "Entendi",
					onClick: () => setCurrentOpenDialog("none"),
					layout: "standard",
					color: "secondary",
					hugWidth: "100%"
				}}>
				<div style={{ padding: "0 16px 16px 16px" }}>
					<Typography
						value="Para continuar com o cadastro através do convite, é necessário que você baixe o nosso aplicativo em seu celular e insira o código que lhe foi enviado por e-mail."
						type="body1"
					/>
				</div>
			</Dialog>

			{/* HAS ACCOUNT */}

			<Dialog
				openDialog={currentOpenDialog === "hasAccount"}
				setOpenDialog={() => setCurrentOpenDialog("none")}
				headerTitle="Identificamos que você possui um cadastro ativo"
				layout="alternative"
				buttonType="group"
				buttonGroupProps={{
					titlePrimary: "Fazer login",
					titleSecondary: "Contatar Suporte",
					onPressPrimary: () => navigate("/auth/sign-in"),
					onPressSecondary: () => {},
					variant: "default",
					isInverted: true
				}}>
				<div style={{ padding: "0 16px 16px 16px" }}>
					<Typography
						value="Você está tentando criar uma nova conta, porém já possui um cadastro com a gente. Deseja fazer login?"
						type="body1"
					/>
				</div>
			</Dialog>

			{/* HAS AN INVITATION CODE */}

			<Dialog
				openDialog={currentOpenDialog === "hasInvitationCode"}
				setOpenDialog={() => setCurrentOpenDialog("none")}
				headerTitle="Identificamos que você possui um código de convidado"
				layout="alternative"
				buttonType="group"
				buttonGroupProps={{
					titleSecondary: "Nova organização do zero",
					titlePrimary: "Entrar com código",
					onPressPrimary: () => navigate("/onboarding/download-app"),
					onPressSecondary: () => {},
					variant: "default",
					isInverted: true
				}}>
				<div style={{ padding: "0 16px 16px 16px" }}>
					<Typography
						value="Você foi convidado para participar de uma organização, mas para continuar seu cadastro, é necessário que você baixe nosso aplicativo, ou crie uma organização do zero."
						type="body1"
					/>
				</div>
			</Dialog>
		</Styled.MainStepContainer>
	);
}

export default InputDocumentNumber;
