import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding-bottom: 24px;
	justify-content: center;
	align-items: center;

	.display-between {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;

		.container-value-and-beneficiary {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 8px;
			align-items: center;
			margin-top: 24px;
			margin-bottom: 24px;

			.container-input-edit {
				display: flex;
				flex-direction: row;
				width: 100%;
				justify-content: space-between;
				padding: 0 16px;
			}
		}

		.input-and-copy-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 32px;
			margin-top: 100px;
		}

		.gap {
			display: flex;
			gap: 24px;
			flex-direction: column;
		}
	}
`;

export const CenterContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 600px;
	height: 100%;
	padding-top: 16px;
`;
