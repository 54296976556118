import { colors } from "@azify/ui-tokens";
import { ButtonGroup, HeaderNavigation, QuickButton, Typography } from "@azify/ui-web";
import { useStepManager } from "hooks/useStepManager";
import { useRef, useState } from "react";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";

function ShowPixQrCodeResult() {
	const navigate = useNavigate();
	const { setCurrentStep } = useStepManager();
	const [code, setCode] = useState("");

	const goToPreviousStep = () => {
		setCurrentStep("CreateInfoDetails");
	};

	const copyCode = () => {
		navigator.clipboard.writeText(code);
	};

	const qrRef = useRef(null);

	const downloadQR = async () => {
		const qr = qrRef.current;

		if (!qr) return;

		const svgData = new XMLSerializer().serializeToString(qr);
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");
		const img = new Image();

		img.onload = function () {
			canvas.width = img.width + 40;
			canvas.height = img.height + 40;

			if (!ctx) return;

			ctx.strokeRect(0, 0, canvas.width, canvas.height);
			ctx.fillStyle = "#FFFFFF";
			ctx.fillRect(0, 0, canvas.width, canvas.height);
			ctx.drawImage(img, 20, 20);
			const pngFile = canvas.toDataURL("image/png", 1.0);

			const downloadLink = document.createElement("a");
			downloadLink.download = "qrcode";
			downloadLink.href = `${pngFile}`;
			downloadLink.target = "_blank";
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);

			downloadLink.remove();
		};

		img.src = "data:image/svg+xml;base64," + btoa(svgData);
	};

	return (
		<div className="display-between">
			<HeaderNavigation
				buttonOnTitle={false}
				title="Qr Code criado"
				quickButtonColor="accent"
				backIconClick={goToPreviousStep}
			/>
			<div className="container-qr-and-codes">
				<div className="container-qr-code">
					<QRCode
						ref={qrRef}
						size={180}
						value={
							"https://pix.bcb.gov.br/00020126580014br.gov.bcb.pix0136+5511999999999915204000053039865802BR5913João da Silva6008BRASILIA62070503***6304A8E9"
						}
					/>
				</div>

				<div className="container-codes">
					<Typography
						textAlign="center"
						type="body3"
						colorCustom={colors.gray.dark}
						value="00020126330014br.gov.bcb.pix0111062589735825204000053039865802BR5924Izabel Tabosa Tabanez 6009Aracaju62070503***63..."
					/>
				</div>

				<QuickButton
					label="Baixar QR Code"
					color="primary"
					leadingIcon="arrow-to-down-small"
					onClick={downloadQR}
				/>
			</div>

			<ButtonGroup
				variant="default"
				titlePrimary="Copiar código"
				titleSecondary="Voltar ao início"
				onPressPrimary={() => navigate("/pixArea")}
				onPressSecondary={copyCode}
			/>
		</div>
	);
}
export default ShowPixQrCodeResult;
