import * as React from "react";
import { SVGProps } from "react";

const AzifySVG = (props: SVGProps<SVGSVGElement>) => (
	<svg width={111} height={33} fill="none" {...props}>
		<g clipPath="url(#a)">
			<path
				fill="#272937"
				d="M53.695 6.789v18.49H49.83v-2.82a7.008 7.008 0 0 1-2.49 2.205c-1.013.577-2.205.831-3.54.831-1.697 0-3.213-.36-4.515-1.118a7.604 7.604 0 0 1-3.106-3.286c-.758-1.446-1.118-3.106-1.118-5.056 0-1.95.36-3.503 1.118-4.949a8.2 8.2 0 0 1 3.143-3.322c1.298-.795 2.818-1.192 4.478-1.192 1.372 0 2.564.29 3.576.794s1.807 1.23 2.454 2.13V6.79h3.864Zm-5.346 13.72c.975-1.118 1.479-2.6 1.479-4.404 0-1.803-.504-3.322-1.48-4.478-1.011-1.155-2.31-1.733-3.9-1.733-1.59 0-2.925.578-3.9 1.733-1.012 1.156-1.48 2.602-1.48 4.405 0 1.803.472 3.322 1.48 4.478.975 1.155 2.274 1.696 3.9 1.696 1.627 0 2.889-.541 3.9-1.696ZM73.55 6.789h3.9v18.49h-3.9V6.788ZM55.841 6.789h15.564V9.57L61.077 21.85h10.328v3.43H55.841v-2.782L66.17 10.218H55.841v-3.43Z"
			/>
			<path
				fill="#272937"
				fillRule="evenodd"
				d="M82.811 4.949v1.843h-3.216v3.43h3.216l1.906.01-1.906 2.249v12.797h3.937v-15.06h4.95v-3.43h-4.95V5.237c0-.615.107-1.082.398-1.373.29-.29.72-.434 1.372-.434h3.212V0h-3.757c-1.77 0-3.068.434-3.9 1.262-.868.832-1.262 2.057-1.262 3.683v.004Z"
				clipRule="evenodd"
			/>
			<path
				fill="#272937"
				fillRule="evenodd"
				d="M101.827 21.054 96.664 6.79h-4.971v3.429h2.145l5.895 15.204-.758 1.77c-.324.794-.614 1.335-.901 1.552-.291.218-.832.324-1.553.324h-2.745v3.43h4.187c.869 0 1.59-.144 2.131-.435.504-.29.975-.758 1.335-1.335.361-.578.758-1.446 1.193-2.565L111 6.785h-4.154l-5.019 14.265v.004Z"
				clipRule="evenodd"
			/>
			<path fill="#272937" d="M77.45 0h-3.9v3.426h3.9V0Z" />
			<path
				fill="#005EFF"
				fillRule="evenodd"
				d="M12.19 2.33a14.018 14.018 0 0 1 1.855-.122c.629 0 1.247.04 1.854.121v8.544c0 .28.033.548.092.81l6.587-6.587c.986.754 1.869 1.637 2.623 2.624l-6.586 6.586c.257.063.53.092.806.092h8.547c.081.607.121 1.225.121 1.854 0 .63-.04 1.248-.121 1.855h-8.547a7.231 7.231 0 0 1-7.23-7.23V2.329ZM2.888 7.715a14.058 14.058 0 0 1 2.624-2.624l3.724 3.724-2.624 2.624-3.724-3.724ZM.121 18.103A14.017 14.017 0 0 1 0 16.25c0-.63.04-1.248.121-1.855H8.67a7.231 7.231 0 0 1 7.23 7.23v8.544c-.607.081-1.225.122-1.854.122-.63 0-1.248-.04-1.855-.122v-8.543c0-.28-.033-.549-.092-.81l-6.586 6.586a14.058 14.058 0 0 1-2.624-2.623l6.587-6.586a3.629 3.629 0 0 0-.806-.093H.12v.004Zm25.08 6.678a14.059 14.059 0 0 1-2.623 2.624l-3.724-3.724 2.623-2.623 3.724 3.724Z"
				clipRule="evenodd"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h111v32.501H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default AzifySVG;
