import { colors } from "@azify/ui-tokens";
import styled, { css } from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
`;

export const ContainerDropDown = styled.div<{
	disable: boolean;
}>`
	width: 100%;
	${({ disable }) =>
		disable
			? css`
					pointer-events: none;
					opacity: 0.5;
				`
			: css``}

	.dropdown {
		margin: 0 auto;
		position: relative;

		.dropdown-btn {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 8px 0px;
			background: ${colors.white.light};
			border-bottom: 1px solid ${colors.gray.main};
			color: black;
			font-weight: 500;
			height: 48px;

			.icon {
				height: 100%;
				justify-content: center;
				display: flex;
				align-items: center;
			}

			input {
				width: 100%;
				height: 100%;
				border: none;
				outline: none;
			}

			input::placeholder {
				font-size: 17px;
				font-family: Aeonik-Regular;
				font-weight: 500;
			}
		}
	}

	.dropdown-content {
		position: absolute;
		left: 0;
		width: 100%;
		max-height: 150px;
		overflow-y: auto;
		background-color: ${colors.white.light};
		border: 1px solid ${colors.gray.opacity["50p"]};
		border-top: none;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		z-index: 9999;
		-ms-overflow-style: none;
		scrollbar-width: none;

		.container-list {
			display: flex;
			flex-direction: column;
		}

		.item {
			padding: 16px;
			cursor: pointer;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.item:hover {
			background: ${colors.gray.opacity["10p"]};
		}
	}

	.dropdown-content::-webkit-scrollbar {
		display: none;
	}

	button {
		z-index: -1;
		display: block;
		width: 300px;
		margin: 0 auto;
	}
`;
