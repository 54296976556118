import { useRef, useState } from "react";
import { useOpenModalSideBar } from "hooks/useOpenSideBar";
import { ButtonGroup, Icon, ItemList, Typography } from "@azify/ui-web";
import { colors } from "@azify/ui-tokens";
import * as S from "../PaymentMethod.styles";
import { DropDown } from "components/DropDown";
import { DropDownItem } from "components/DropDown/DropDownItem";
import { useClick } from "@szhsin/react-menu";
import { formatCurrencyFn } from "helpers/currency";

export default function CardPayment({ values }: { values?: string }) {
	const { setCurrentStep, params } = useOpenModalSideBar();
	const valuesConvert = values ? parseFloat(values.replace(",", ".")) : 0;

	const tax = [
		{
			id: 1,
			title: "À vista (4,2%)",
			tax: "4.2%",
			value: valuesConvert,
			valueWithTax: String(valuesConvert * 1.042),
			valueMinusTax: String(valuesConvert * 0.958)
		},
		{
			id: 2,
			title: "Em até 2x (6.09%)",
			tax: "6.09%",
			value: valuesConvert,
			valueWithTax: String(valuesConvert * 1.0609),
			valueMinusTax: String(valuesConvert * 0.9391)
		},
		{
			id: 3,
			title: "Em até 3x (7.01%)",
			tax: "7.01%",
			value: valuesConvert,
			valueWithTax: String(valuesConvert * 1.0701),
			valueMinusTax: String(valuesConvert * 0.9299)
		},
		{
			id: 4,
			title: "Em até 4x (7.91%)",
			tax: "7.91%",
			value: valuesConvert,
			valueWithTax: String(valuesConvert * 1.0791),
			valueMinusTax: String(valuesConvert * 0.9209)
		},
		{
			id: 5,
			title: "Em até 5x (8.8%)",
			tax: "8.8%",
			value: valuesConvert,
			valueWithTax: String(valuesConvert * 1.088),
			valueMinusTax: String(valuesConvert * 0.912)
		},
		{
			id: 6,
			title: "Em até 6x (9.67%)",
			tax: "9.67%",
			value: valuesConvert,
			valueWithTax: String(valuesConvert * 1.0967),
			valueMinusTax: String(valuesConvert * 0.9033)
		}
	];

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [selectedTax, setSelectedTax] = useState(tax[0]);
	const anchorProps = useClick(isMenuOpen, setIsMenuOpen);

	const ref = useRef(null);

	const handleSaveChanges = () => {
		if (setCurrentStep) {
			setCurrentStep("Start");
		}
	};

	const [checked, setChecked] = useState(false);

	return (
		<>
			<div className="container-inputs">
				<Typography value="Pagamento via cartão" type="h1" isBold marginBottom={10} />

				<Typography
					value="Quantas parcelas permitidas?"
					type="subtitle1"
					isBold
					marginBottom={10}
				/>

				<div ref={ref} {...anchorProps}>
					<S.InputSelect>
						<Typography
							value={selectedTax.title || "Selecione"}
							color="secondary"
							type="subtitle1"
							isBold
						/>

						<Icon
							color="secondary"
							size="small"
							name={isMenuOpen ? "chevron-up-filled-small" : "chevron-down-filled-small"}
						/>
					</S.InputSelect>
				</div>

				<DropDown
					isOpen={isMenuOpen}
					setOpen={setIsMenuOpen}
					innerRef={ref}
					align="end"
					widthContainer={440}
					content={
						<>
							{tax.map((item) => (
								<DropDownItem
									key={item.id}
									title={item.title}
									onClick={() => setSelectedTax(item)}
								/>
							))}
						</>
					}
				/>

				<ItemList
					behavior="default"
					label="Repassar taxas?"
					leadingContent="none"
					trailingContent="toggle"
					trailingToggleValue={checked}
					trailingToggleOnPress={() => {
						setChecked((prev) => !prev);
					}}
					trailingSupportContent="none"
				/>

				<S.CardContainer>
					<div className="card-item">
						<Typography value="Seu cliente paga" type="body3" color="secondary" />
						<div className="text-right">
							<Typography
								value={
									checked
										? formatCurrencyFn("BRL", selectedTax.valueWithTax)
										: formatCurrencyFn("BRL", values, false, true, true)
								}
								type="body3"
							/>
						</div>
					</div>

					<div className="card-item">
						<Typography value="Taxa da venda" type="body3" color="secondary" />
						<div className="text-right">
							<Typography value={selectedTax.tax} type="body3" />
						</div>
					</div>

					<div className="card-item">
						<Typography value="Você recebe" type="body3" color="secondary" />
						<div className="text-right">
							<Typography
								value={
									checked
										? formatCurrencyFn("BRL", values, false, true, true)
										: formatCurrencyFn("BRL", String(selectedTax.valueMinusTax))
								}
								type="body3"
							/>
						</div>
					</div>
				</S.CardContainer>
			</div>

			<div className="footer">
				<ButtonGroup
					variant="default"
					isInverted
					customColorBackgroundPrimaryButton={colors.black.main}
					customColorTextTransparentButton={colors.black.main}
					titleSecondary="Não permitir cartão"
					titlePrimary="Alterar"
					onPressPrimary={handleSaveChanges}
					onPressSecondary={handleSaveChanges}
				/>
			</div>
		</>
	);
}
