import { colors } from "@azify/ui-tokens";
import styled, { css } from "styled-components";

export const DropDownItemContainer = styled.div<{
	isDisabled?: boolean;
	disableHover?: boolean;
}>`
	display: flex;
	flex-direction: column;
	align-items: flex-start !important;
	justify-content: flex-start;
	width: 100%;
	z-index: 99999;
	cursor: pointer;

	.menu-button {
		border: none;
	}

	.menu-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 0px;
		font-family: "Helvetica Now Display Medium", sans-serif;
		font-size: 0.938rem;
		line-height: 120%;
		background-color: none;
		width: 100%;

		& .left-container {
			display: flex;
			flex-direction: row;
		}
	}

	.disabled {
		cursor: not-allowed;
		background-color: initial;
		opacity: 30%;
	}

	.szh-menu__item {
		padding: 13px 16px;
	}

	.szh-menu__item--hover {
		background-color: ${colors.white.main};

		${({ disableHover }) =>
			disableHover &&
			css`
				background-color: initial;
			`}
	}

	.is-red {
		color: ${colors.danger.dark};
	}

	.text-container {
		width: auto !important;
	}
`;

export const DropDownItemTitle = styled.p<{
	isNew?: boolean;
	isRed?: boolean;
}>`
	position: relative;
	display: inline-block;
	font-family: "Aeonik-Medium", sans-serif;
	font-size: 17px;
	font-style: normal;
	font-weight: 500;
	line-height: 135%;

	${({ isRed }) =>
		isRed &&
		css`
			color: ${colors.danger.dark};
		`}

	${({ isNew }) =>
		isNew &&
		css`
			::after {
				right: -6px;
				content: "";
				z-index: 999999;
				position: absolute;
				background-color: ${colors.primary.main};
				width: 5px;
				height: 5px;
				border-radius: 50%;
			}
		`}
`;

export const DropDownItemSubtitle = styled.p`
	color: ${colors.gray.dark};
`;

export const DropDownItemIconContainer = styled.div<{ isRed?: boolean }>`
	margin-right: 12px;
	margin-bottom: 1px;
	display: flex;
	width: auto !important;

	${({ isRed }) =>
		isRed &&
		css`
			color: ${colors.danger.dark};
		`}
`;
