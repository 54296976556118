import { colors } from "@azify/ui-tokens";
import { createGlobalStyle } from "styled-components";
import resetCSS from "./reset";

export default createGlobalStyle`

${resetCSS}

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

	.pointer{
		cursor: pointer;
	}

	.row{
		display: flex;
		flex-direction: row;
	}

  html, body, #root {
    height: 100%;
		width: 100%;
    overflow-y: hidden;
		display: flex;
		flex-direction: column;
  }

  body {
    background: ${colors.white.main};
    -webkit-font-smoothing: antialiased !important;
  }

  body {
    color: ${colors.black.main};
    font-size: 15px;
    line-height: 1.5;
    font-weight: 500;
  }

  /* TOAST - START */
	
  .Toastify__toast-container {
    width: 410px;
    bottom:-5px;
		
    @media (min-width: 0px) {
      transform: scale(0.6) translateX(-30%);
    }

    @media (min-width: 480px) {
      transform: scale(1)
    }
  }

  .Toastify__toast {
    padding: 14px;
    box-shadow: none;
  }

  .Toastify__toast-body {
    padding: 0 6px;
		display: flex;
		justify-content: space-between;
		flex: none;
		gap: 16px;
  }

  .Toastify__toast-theme--dark {
    background: ${colors.black.main};
		border-radius: 16px;
  }
  
  /* TOAST - END */


	/* MARGIN STYLE - START */
	.m-0      { margin: 0; }
  .m-5      { margin: 5px; }
  .m-10     { margin: 10px; }
  .m-15     { margin: 15px; }
  .m-20     { margin: 20px; }
  .m-24     { margin: 24px; }  
	.m-25     { margin: 25px; }
  .m-30     { margin: 30px; }
  .m-35     { margin: 35px; }
  .m-40     { margin: 40px; }
  .m-45     { margin: 45px; }
  .m-50     { margin: 50px; }
  .m-55     { margin: 55px; }
  .m-60     { margin: 60px; }
  .m-65     { margin: 65px; }
  .m-70     { margin: 70px; }
  .m-75     { margin: 75px; }
  .m-80     { margin: 80px; }
  .m-85     { margin: 85px; }
  .m-90     { margin: 90px; }
  .m-95     { margin: 95px; }
  .m-100    { margin: 100px; }

  .mt-0    { margin-top: 0px; }
  .mt-5    { margin-top: 5px; }
  .mt-7    { margin-top: 7px; }
  .mt-10   { margin-top: 10px; }
  .mt-15   { margin-top: 15px; }
	.mt-16	 { margin-top: 16px; }
  .mt-20   { margin-top: 20px; }
	.mt-24   { margin-top: 24px; }  
  .mt-25   { margin-top: 25px; }
  .mt-28   { margin-top: 28px; }
  .mt-30   { margin-top: 30px; }
  .mt-35   { margin-top: 35px; }
  .mt-40   { margin-top: 40px; }
  .mt-45   { margin-top: 45px; }
  .mt-50   { margin-top: 50px; }
  .mt-55   { margin-top: 55px; }
  .mt-60   { margin-top: 60px; }
  .mt-65   { margin-top: 65px; }
  .mt-70   { margin-top: 70px; }
  .mt-75   { margin-top: 75px; }
  .mt-80   { margin-top: 80px; }
  .mt-85   { margin-top: 85px; }
  .mt-90   { margin-top: 90px; }
  .mt-95   { margin-top: 95px; }
  .mt-100  { margin-top: 100px; }
  .mt-150  { margin-top: 150px; }
  .mt-270  { margin-top: 270px; }
  
  .mr-0    { margin-right: 0px; }
  .mr-5    { margin-right: 5px; }
  .mr-10   { margin-right: 10px; }
  .mr-15   { margin-right: 15px; }
  .mr-20   { margin-right: 20px; }
  .mr-25   { margin-right: 25px; }
  .mr-30   { margin-right: 30px; }
  .mr-35   { margin-right: 35px; }
  .mr-40   { margin-right: 40px; }
  .mr-45   { margin-right: 45px; }
  .mr-50   { margin-right: 50px; }
  .mr-55   { margin-right: 55px; }
  .mr-60   { margin-right: 60px; }
  .mr-65   { margin-right: 65px; }
  .mr-70   { margin-right: 70px; }
  .mr-75   { margin-right: 75px; }
  .mr-80   { margin-right: 80px; }
  .mr-85   { margin-right: 85px; }
  .mr-90   { margin-right: 90px; }
  .mr-95   { margin-right: 95px; }
  .mr-100  { margin-right: 100px; }

  .mb-0    { margin-bottom: 0px; }
  .mb-5    { margin-bottom: 5px; }
  .mb-10   { margin-bottom: 10px; }
  .mb-15   { margin-bottom: 15px; }
  .mb-20   { margin-bottom: 20px; }
  .mb-25   { margin-bottom: 25px; }
  .mb-30   { margin-bottom: 30px; }
  .mb-35   { margin-bottom: 35px; }
  .mb-40   { margin-bottom: 40px; }
  .mb-45   { margin-bottom: 45px; }
  .mb-50   { margin-bottom: 50px; }
  .mb-55   { margin-bottom: 55px; }
  .mb-60   { margin-bottom: 60px; }
  .mb-65   { margin-bottom: 65px; }
  .mb-70   { margin-bottom: 70px; }
  .mb-75   { margin-bottom: 75px; }
  .mb-80   { margin-bottom: 80px; }
  .mb-85   { margin-bottom: 85px; }
  .mb-90   { margin-bottom: 90px; }
  .mb-95   { margin-bottom: 95px; }
  .mb-100  { margin-bottom: 100px; }
  
  .ml-0    { margin-left: 0px; }
  .ml-5    { margin-left: 5px; }
  .ml-10   { margin-left: 10px; }
  .ml-15   { margin-left: 15px; }
  .ml-20   { margin-left: 20px; }
  .ml-25   { margin-left: 25px; }
  .ml-30   { margin-left: 30px; }
  .ml-35   { margin-left: 35px; }
  .ml-40   { margin-left: 40px; }
  .ml-45   { margin-left: 45px; }
  .ml-50   { margin-left: 50px; }
  .ml-55   { margin-left: 55px; }
  .ml-60   { margin-left: 60px; }
  .ml-65   { margin-left: 65px; }
  .ml-70   { margin-left: 70px; }
  .ml-75   { margin-left: 75px; }
  .ml-80   { margin-left: 80px; }
  .ml-85   { margin-left: 85px; }
  .ml-90   { margin-left: 90px; }
  .ml-95   { margin-left: 95px; }
  .ml-100  { margin-left: 100px; }
  /* MARGIN STYLE - END */


  /* PADDING STYLE - START */
  .p-0      { padding: 0; }
  .p-5      { padding: 5px; }
  .p-10     { padding: 10px; }
  .p-15     { padding: 15px; }
  .p-20     { padding: 20px; }
  .p-25     { padding: 25px; }
  .p-30     { padding: 30px; }
  .p-35     { padding: 35px; }
  .p-40     { padding: 40px; }
  .p-45     { padding: 45px; }
  .p-50     { padding: 50px; }
  .p-55     { padding: 55px; }
  .p-60     { padding: 60px; }
  .p-65     { padding: 65px; }
  .p-70     { padding: 70px; }
  .p-75     { padding: 75px; }
  .p-80     { padding: 80px; }
  .p-85     { padding: 85px; }
  .p-90     { padding: 90px; }
  .p-95     { padding: 95px; }
  .p-100    { padding: 100px; }

  .pt-0    { padding-top: 0px; }
  .pt-5    { padding-top: 5px; }
  .pt-10   { padding-top: 10px; }
  .pt-15   { padding-top: 15px; }
  .pt-20   { padding-top: 20px; }
  .pt-25   { padding-top: 25px; }
  .pt-30   { padding-top: 30px; }
  .pt-35   { padding-top: 35px; }
  .pt-40   { padding-top: 40px; }
  .pt-45   { padding-top: 45px; }
  .pt-50   { padding-top: 50px; }
  .pt-55   { padding-top: 55px; }
  .pt-60   { padding-top: 60px; }
  .pt-65   { padding-top: 65px; }
  .pt-70   { padding-top: 70px; }
  .pt-75   { padding-top: 75px; }
  .pt-80   { padding-top: 80px; }
  .pt-85   { padding-top: 85px; }
  .pt-90   { padding-top: 90px; }
  .pt-95   { padding-top: 95px; }
  .pt-100  { padding-top: 100px; }

  .pr-0    { padding-right: 0px; }
  .pr-5    { padding-right: 5px; }
  .pr-10   { padding-right: 10px; }
  .pr-15   { padding-right: 15px; }
  .pr-20   { padding-right: 20px; }
  .pr-25   { padding-right: 25px; }
  .pr-30   { padding-right: 30px; }
  .pr-35   { padding-right: 35px; }
  .pr-40   { padding-right: 40px; }
  .pr-45   { padding-right: 45px; }
  .pr-50   { padding-right: 50px; }
  .pr-55   { padding-right: 55px; }
  .pr-60   { padding-right: 60px; }
  .pr-65   { padding-right: 65px; }
  .pr-70   { padding-right: 70px; }
  .pr-75   { padding-right: 75px; }
  .pr-80   { padding-right: 80px; }
  .pr-85   { padding-right: 85px; }
  .pr-90   { padding-right: 90px; }
  .pr-95   { padding-right: 95px; }
  .pr-100  { padding-right: 100px; }

  .pb-0    { padding-bottom: 0px; }
  .pb-5    { padding-bottom: 5px; }
  .pb-10   { padding-bottom: 10px; }
  .pb-15   { padding-bottom: 15px; }
  .pb-20   { padding-bottom: 20px; }
  .pb-25   { padding-bottom: 25px; }
  .pb-30   { padding-bottom: 30px; }
  .pb-35   { padding-bottom: 35px; }
  .pb-40   { padding-bottom: 40px; }
  .pb-45   { padding-bottom: 45px; }
  .pb-50   { padding-bottom: 50px; }
  .pb-55   { padding-bottom: 55px; }
  .pb-60   { padding-bottom: 60px; }
  .pb-65   { padding-bottom: 65px; }
  .pb-70   { padding-bottom: 70px; }
  .pb-75   { padding-bottom: 75px; }
  .pb-80   { padding-bottom: 80px; }
  .pb-85   { padding-bottom: 85px; }
  .pb-90   { padding-bottom: 90px; }
  .pb-95   { padding-bottom: 95px; }
  .pb-100  { padding-bottom: 100px; }

  .pl-0    { padding-left: 0px; }
  .pl-5    { padding-left: 5px; }
  .pl-10   { padding-left: 10px; }
  .pl-15   { padding-left: 15px; }
  .pl-20   { padding-left: 20px; }
  .pl-25   { padding-left: 25px; }
  .pl-30   { padding-left: 30px; }
  .pl-35   { padding-left: 35px; }
  .pl-40   { padding-left: 40px; }
  .pl-45   { padding-left: 45px; }
  .pl-50   { padding-left: 50px; }
  .pl-55   { padding-left: 55px; }
  .pl-60   { padding-left: 60px; }
  .pl-65   { padding-left: 65px; }
  .pl-70   { padding-left: 70px; }
  .pl-75   { padding-left: 75px; }
  .pl-80   { padding-left: 80px; }
  .pl-85   { padding-left: 85px; }
  .pl-90   { padding-left: 90px; }
  .pl-95   { padding-left: 95px; }
  .pl-100  { padding-left: 100px; }

  /* PADDING STYLE - END */


	.tooltip-style {
		font-family: Helvetica, sans-serif;
		font-size: 14px;
		font-weight: 500;
		border-radius: 8px;
		opacity: 1 !important;
		background-color: ${colors.black.main};
	}

	.gap-8 { gap: 8px; }
`;
