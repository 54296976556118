import { MenuItem } from "@szhsin/react-menu";
import {
	DropDownItemTitle,
	DropDownItemContainer,
	DropDownItemIconContainer,
	DropDownItemSubtitle
} from "./DropDownItem.styles";
import { DropDownItemProps } from "./DropDownItem.types";
import { Chip, Toggle } from "@azify/ui-web";

const DropDownItem = ({
	title,
	subtitle,
	icon,
	isNew,
	isRed,
	onClick,
	isDisabled,
	disableHover,
	chip,
	chipTitle,
	chipWidth,
	chipBackgroundColor,
	chipBackgroundCustomColor,
	colorTextCustom,
	toggle,
	toggleOnClick,
	toggleValue
}: DropDownItemProps) => {
	return (
		<DropDownItemContainer disableHover={disableHover}>
			<MenuItem
				className={`menu-item ${isDisabled && "disabled"}`}
				onClick={onClick}
				disabled={isDisabled}>
				<div className="left-container">
					{icon && <DropDownItemIconContainer isRed={isRed}>{icon}</DropDownItemIconContainer>}
					<div className="text-container">
						<DropDownItemTitle isNew={isNew} isRed={isRed}>
							{title}
						</DropDownItemTitle>

						{subtitle && <DropDownItemSubtitle>{subtitle}</DropDownItemSubtitle>}
					</div>
				</div>

				{chip && (
					<Chip
						id={1}
						title={chipTitle || ""}
						hierarchy="high"
						width="intrinsec"
						chipWidth={chipWidth}
						backgroundColor={chipBackgroundColor}
						backgroundCustomColor={chipBackgroundCustomColor}
						colorTextCustom={colorTextCustom}
					/>
				)}

				{toggle && <Toggle onClick={() => toggleOnClick && toggleOnClick()} value={toggleValue} />}
			</MenuItem>
		</DropDownItemContainer>
	);
};

export default DropDownItem;
