import { GenericButton } from "@azify/ui-web";
import LoaderStatus from "components/LoaderStatus/LoaderStatus.component";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Styled from "../BuyOtc.styles";
import { useStepManager } from "hooks/useStepManager";
import { useOtcActions } from "hooks/useOtcActions";

export const ExchangeSuccess = () => {
	const navigate = useNavigate();
	const { params } = useStepManager();
	const [isLoading, setIsLoading] = useState(true);
	const { otcBalance, dispatchOtc, dispatchOtcMovements } = useOtcActions();

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 2000);

		return () => clearTimeout(timer);
	}, []);

	const valuesToDisplay = {
		...params
	} as {
		valueToExchange: string;
		currency: { isoCode: "USDT" | "USDC" | "BTC"; precision: number };
		valueInBrl: string;
		valueInBrlFormatted: string;
	};

	const increaseActionCoins = {
		USDT: "INCREASE_TETHER",
		USDC: "INCREASE_USDC",
		BTC: "INCREASE_BITCOIN"
	};

	const decreaseValue = () => {
		const { valueInBrl } = valuesToDisplay;

		if (Number(valueInBrl) > otcBalance) {
			const rest = Number(valueInBrl) - otcBalance;

			dispatchOtc({ type: "DECREASE_OTC", by: otcBalance });

			dispatchOtc({ type: "DECREASE_LIMIT", by: rest });
			dispatchOtc({ type: "INCREASE_PENDING", by: rest });
		} else {
			dispatchOtc({ type: "DECREASE_OTC", by: Number(valueInBrl) });
		}

		dispatchOtc({
			type: increaseActionCoins[valuesToDisplay.currency.isoCode],
			by: Number(valuesToDisplay.valueToExchange)
		});

		dispatchOtcMovements({
			type: `Compra de ${valuesToDisplay.currency.isoCode}`,
			transactionOrigin: "otc_account",
			transactionDestiny: `balance_${valuesToDisplay.currency.isoCode}`,
			isoCode: valuesToDisplay.currency.isoCode,
			value: valuesToDisplay.valueToExchange
		});
	};

	return (
		<>
			<Styled.CenterContainer>
				<div className="display-between">
					<div className="center-items">
						<LoaderStatus
							title={isLoading ? "Efetuando compra..." : "Compra Realizada!"}
							subtitle={
								isLoading ? "" : `Você utilizou ${valuesToDisplay.valueInBrlFormatted} para comprar`
							}
							emphasis={
								isLoading
									? ""
									: `${valuesToDisplay.valueToExchange} ${valuesToDisplay.currency.isoCode}`
							}
							status={isLoading ? "loading" : "success"}
						/>
					</div>

					<GenericButton
						onClick={() => {
							decreaseValue();
							navigate("/otc");
						}}
						isDisabled={isLoading}
						title="Concluir"
						layout="standard"
						color="primary"
					/>
				</div>
			</Styled.CenterContainer>
		</>
	);
};
