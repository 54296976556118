import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import PrivateRoute from "./rule/private";
import React, { memo, useLayoutEffect } from "react";
import { DashBoardPage } from "pages/Dashboard/Home/Home.screen";
import { GatewayPage } from "pages/Dashboard/Gateway/Gateway.screen";
import { When } from "react-if";
import MagicFooter from "components/MagicFooter/MagicFooter.component";
import { useMagicFooter } from "hooks/useMagicFooter";
import DashRouterLayout from "./layout/dash";
import { AuthRouter } from "./router/auth/Auth.router";
import { OnboardingRouter } from "./router/onboarding/Onboarding.router";
import { SettingsRouter } from "./router/settings/Settings.router";
import { TransferRouter } from "./router/transfer/Transfer.router";
import { PixAreaRouter } from "./router/pixArea/PixArea.router";
import { Organizations } from "pages/Organizations/Organizations.screen";
import AuthRouterLayout from "./layout/auth";
import { GatewayOnboarding } from "pages/OnboardingExtension/Gateway/GatewayOnboarding.screen";
import { OtcOnboarding } from "pages/OnboardingExtension/Otc/OtcOnboarding.screen";
import { StepManagerProvider } from "hooks/useStepManager";
import { DashboardOtc } from "pages/Dashboard/Otc/Otc.screen";
import { BuyOtcScreen } from "pages/Dashboard/Otc/BuyOtc/BuyOtc.screen";
import { NewDebitCollection } from "pages/Dashboard/Gateway/NewDebitCollection/NewDebitCollection.screen";
import { ExchangeFounds as ExchangeFoundsOtc } from "pages/Dashboard/Otc/ExchangeFounds/ExchangeFounds.screen";
import { ExchangeFounds as ExchangeFoundsGateway } from "pages/Dashboard/Gateway/ExchangeFounds/ExchangeFounds.screen";

const Wrapper = ({ children }: { children: React.JSX.Element }) => {
	const location = useLocation();
	const { openMagicFooter } = useMagicFooter();

	useLayoutEffect(() => {
		const body = document.getElementById("page-wrapper");

		if (body) {
			window.scrollTo(0, 0);
			body.scrollTo(0, 0);
		}
	}, [location.pathname]);

	return (
		<>
			{children}

			<When condition={openMagicFooter}>
				<MagicFooter />
			</When>
		</>
	);
};

const RouterRoot = () => {
	return (
		<BrowserRouter>
			<Wrapper>
				<Routes>
					{/* CUSTOM LAYOUT OUTLET */}

					<Route path="/auth/*" element={<AuthRouter />} />

					<Route path="/onboarding/*" element={<OnboardingRouter />} />

					<Route
						path="/organizations/*"
						element={
							<Routes>
								<Route element={<AuthRouterLayout newOrg />}>
									<Route
										index
										element={
											<PrivateRoute>
												<Organizations />
											</PrivateRoute>
										}
									/>
								</Route>
							</Routes>
						}
					/>

					<Route element={<DashRouterLayout noPagePadding />}>
						<Route
							path="/onboardingExtension/*"
							element={
								<Routes>
									<Route
										path="gateway"
										element={
											<PrivateRoute>
												<StepManagerProvider initialStep="MerchantMethodsStep">
													<GatewayOnboarding />
												</StepManagerProvider>
											</PrivateRoute>
										}
									/>

									<Route
										path="otc"
										element={
											<PrivateRoute>
												<StepManagerProvider initialStep="StartStep">
													<OtcOnboarding />
												</StepManagerProvider>
											</PrivateRoute>
										}
									/>

									<Route path="*" element={<Navigate to="/dashboard" replace />} />
								</Routes>
							}
						/>
					</Route>

					{/* GENERAL LAYOUT OUTLET */}
					<Route element={<DashRouterLayout />}>
						<Route path="/transfer/*" element={<TransferRouter />} />

						<Route path="/pixArea/*" element={<PixAreaRouter />} />

						<Route path="/settings/*" element={<SettingsRouter />} />

						<Route
							path="/dashboard"
							element={
								<PrivateRoute>
									<DashBoardPage />
								</PrivateRoute>
							}
						/>

						<Route
							path="/statement"
							element={
								<PrivateRoute>
									<p>statement</p>
								</PrivateRoute>
							}
						/>

						<Route
							path="/gateway/*"
							element={
								<Routes>
									<Route
										index
										element={
											<PrivateRoute>
												<GatewayPage />
											</PrivateRoute>
										}
									/>

									<Route
										path="new-debit-collection"
										element={
											<PrivateRoute>
												<StepManagerProvider initialStep="SelectClient">
													<NewDebitCollection />
												</StepManagerProvider>
											</PrivateRoute>
										}
									/>

									<Route
										path="exchange"
										element={
											<PrivateRoute>
												<StepManagerProvider initialStep="ExchangeInputs">
													<ExchangeFoundsGateway />
												</StepManagerProvider>
											</PrivateRoute>
										}
									/>

									<Route path="*" element={<Navigate to="/gateway" replace />} />
								</Routes>
							}
						/>

						<Route
							path="/otc/*"
							element={
								<Routes>
									<Route
										index
										element={
											<PrivateRoute>
												<DashboardOtc />
											</PrivateRoute>
										}
									/>

									<Route
										path="buy"
										element={
											<PrivateRoute>
												<StepManagerProvider initialStep="ExchangeInputs">
													<BuyOtcScreen />
												</StepManagerProvider>
											</PrivateRoute>
										}
									/>

									<Route
										path="exchange"
										element={
											<PrivateRoute>
												<StepManagerProvider initialStep="ExchangeInputs">
													<ExchangeFoundsOtc />
												</StepManagerProvider>
											</PrivateRoute>
										}
									/>

									<Route path="*" element={<Navigate to="/otc" replace />} />
								</Routes>
							}
						/>

						<Route path="*" element={<Navigate to="/dashboard" replace />} />
					</Route>
				</Routes>
			</Wrapper>
		</BrowserRouter>
	);
};

export default memo(RouterRoot);
