import { HeaderNavigation, IconButton, ItemList, SectionHeading, Typography } from "@azify/ui-web";
import * as S from "./PixArea.styles";
import { useNavigate } from "react-router-dom";
import { useOpenModalSideBar } from "hooks/useOpenSideBar";
import { DropDown } from "components/DropDown";
import { DropDownItem } from "components/DropDown/DropDownItem";
import { useRef, useState } from "react";
import { useClick } from "@szhsin/react-menu";
import { When } from "react-if";
import Dialog from "components/Modal/Dialog/Dialog.component";
import { colors } from "@azify/ui-tokens";

export const PixArea = () => {
	const navigate = useNavigate();
	const ref = useRef(null);

	const { setOpenModalSideBar, setModalSidebarContent, setCurrentStep } = useOpenModalSideBar();

	const [isDotsMenuOpen, setIsDotsMenuOpen] = useState(false);
	const [isWaitingPortabilityModal, setIsWaitingPortabilityModal] = useState(false);
	const [isShowingDeleteKeyModal, setIsShowingDeleteKeyModal] = useState(false);
	const anchorProps = useClick(isDotsMenuOpen, setIsDotsMenuOpen);

	const handleCreatePixKey = () => {
		setModalSidebarContent("pixKey");
		if (setCurrentStep) {
			setCurrentStep("CheckData");
		}
		setOpenModalSideBar(true);
	};

	return (
		<S.Container>
			<S.CenterContainer>
				<HeaderNavigation
					buttonOnTitle={false}
					title="Área Pix"
					onlyIcon="add-small"
					trailingActionsType="accentOnly"
					quickButtonColor="inverted"
					backIconClick={() => navigate("/dashboard")}
				/>
				<div className="icon-buttons-container">
					<div className="container-button-text">
						<IconButton
							onClick={() => navigate("/pixArea/sendPix")}
							color="primary"
							size="large"
							icon="pix"
						/>
						<Typography isBold textAlign="center" type="subtitle2" value="Transferir" />
					</div>

					<div className="container-button-text">
						<IconButton
							onClick={() =>
								navigate("/pixArea/pixCode", {
									state: {
										type: "copyAndPaste"
									}
								})
							}
							color="primary"
							size="large"
							icon="copy"
						/>
						<Typography isBold textAlign="center" type="subtitle2" value="Copia e Cola" />
					</div>

					<div className="container-button-text">
						<IconButton
							onClick={() =>
								navigate("/pixArea/pixCode", {
									state: {
										type: "qrCode"
									}
								})
							}
							color="primary"
							size="large"
							icon="qr-code"
						/>
						<Typography isBold textAlign="center" type="subtitle2" value="QR Code" />
					</div>

					<div className="container-button-text">
						<IconButton color="primary" size="large" icon="cash-in-01" />
						<Typography isBold textAlign="center" type="subtitle2" value="Depositar" />
					</div>
				</div>
				<SectionHeading
					behavior="default"
					title="Suas chaves"
					subHeading="2 de 5 chaves cadastradas"
				/>

				<ItemList
					label="Cadastrar chave"
					isDividerVisible
					trailingContent="icon"
					leadingContent="icon"
					leadingIconName="add"
					leadingIconColor="accent"
					behavior="default"
					trailingSupportContent="none"
					onClick={handleCreatePixKey}
				/>

				<ItemList
					label="CNPJ"
					supportParagraph="72.498.150/0001-87"
					leadingContent="icon"
					isDividerVisible
					leadingIconName="building"
					behavior="default"
					trailingContent="customized"
					trailingContentCustomized={
						<div ref={ref} {...anchorProps}>
							<IconButton color="primary" icon="dots-ver" />
						</div>
					}
					trailingIconButtonOnPress={() => setIsDotsMenuOpen(!isDotsMenuOpen)}
					onClick={() => {}}
					trailingSupportContent="customized"
				/>

				<ItemList
					label="E-mail"
					supportParagraph="joao@azify.com"
					leadingContent="icon"
					trailingContent="icon"
					leadingIconName="mail"
					trailingSupportBadgeTitle="Portabilidade"
					trailingSupportContent="badge"
					trailingSupportBadgeLayout="label"
					behavior="default"
					onClick={() => setIsWaitingPortabilityModal(!isWaitingPortabilityModal)}
				/>

				<SectionHeading behavior="default" title="Outras ações" />

				<ItemList
					leadingIconName="equalizer"
					label="Gerenciar limites"
					leadingContent="icon"
					isDividerVisible
					trailingContent="icon"
					behavior="default"
					trailingSupportContent="none"
				/>

				<ItemList
					leadingIconName="list-02"
					label="Extrato de movimentações"
					leadingContent="icon"
					trailingContent="icon"
					behavior="default"
					trailingSupportContent="none"
				/>
			</S.CenterContainer>

			<DropDown
				isOpen={isDotsMenuOpen}
				setOpen={setIsDotsMenuOpen}
				innerRef={ref}
				align="end"
				content={
					<>
						<DropDownItem
							// icon={<SwapIcon />}
							title="Criar QR Code"
							onClick={() => {}}
						/>

						<DropDownItem
							// icon={<SwapIcon />}
							title="Copiar chave"
							onClick={() => {}}
						/>

						<DropDownItem
							// icon={<LogoutIcon />}
							title="Excluir chave"
							onClick={() => setIsShowingDeleteKeyModal(!isShowingDeleteKeyModal)}
							isRed
						/>
					</>
				}
			/>

			<When condition={!!isShowingDeleteKeyModal}>
				<Dialog
					openDialog={isShowingDeleteKeyModal}
					setOpenDialog={setIsShowingDeleteKeyModal}
					headerTitle="Deseja excluir essa chave?"
					layout="alternative"
					buttonGroupProps={{
						titlePrimary: "Excluir",
						titleSecondary: "Cancelar",
						isInverted: false,
						variant: "default",
						onPressPrimary: () => setIsShowingDeleteKeyModal(false),
						customColorBackgroundPrimaryButton: colors.danger.main,
						customColorTextTransparentButton: colors.black.main
					}}>
					<div style={{ padding: 16 }}>
						<Typography
							value="Ao confirmar a remoção, essa chave não receberá mais transferências."
							type="body1"
						/>{" "}
					</div>
				</Dialog>
			</When>

			<When condition={!!isWaitingPortabilityModal}>
				<Dialog
					openDialog={isWaitingPortabilityModal}
					setOpenDialog={setIsWaitingPortabilityModal}
					headerTitle="Portabilidade em andamento"
					layout="alternative"
					buttonGroupProps={{
						titlePrimary: "Fechar",
						isInverted: true,
						variant: "default",
						onPressPrimary: () => setIsWaitingPortabilityModal(false),
						removeButton: "transparentButton",
						customColorBackgroundPrimaryButton: colors.black.main
					}}>
					<div style={{ padding: 16 }}>
						<Typography
							value="É necessário que você acesse sua conta da instituição: ACESSO SOLUÇÕES DE PAGAMENTOS S.A. e autorize a solicitação."
							type="body1"
						/>
					</div>
				</Dialog>
			</When>
		</S.Container>
	);
};
