import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	& .header-section {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		width: 1280px;

		& .left-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 10px;
		}

		& .right-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 10px;
		}
	}
`;

export const CardContent = styled.div`
	display: flex;
	flex-direction: row;
	gap: 20px;

	width: 1280px;
	margin-top: 40px;
`;

export const LargeCard = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	width: 420px;
	height: 162px;
	padding: 27px 24px;
	border-radius: 16px;
	border: 1px solid var(--Black-Opacity-10p, rgba(39, 41, 55, 0.1));

	.left-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
	}
`;

export const Card = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;

	min-width: 191px;
	width: 17%;
	height: 162px;
	padding: 24px;
	border-radius: 16px;
	border: 1px solid var(--Black-Opacity-10p, rgba(39, 41, 55, 0.1));

	& .card-header {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		width: 100%;
	}
`;

export const Content = styled.div`
	padding: 24px;
	border-radius: 16px;
	margin-top: 40px;
	border: 1px solid var(--Black-Opacity-10p, rgba(39, 41, 55, 0.1));

	width: 1280px;

	& .header-content-section {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		padding: 20px 0 30px 0;

		& .title-content-container {
			width: 50%;
		}
	}

	& table {
		width: 100%;
		margin-top: 50px;

		& .header-table {
			width: 20%;
		}

		& .header-table:first-child {
			width: 25%;
		}

		& .header-table:nth-child(2) {
			width: 17%;
		}

		& .header-table:nth-child(6) {
			width: 18%;
		}

		& .gap-table {
			& td {
				margin-bottom: 20px;
			}
		}

		& .client-section {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 18px;
		}
	}
`;
