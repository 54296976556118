import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
`;

export const CenterContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 1280px;

	.container-sides {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 100%;
		margin-top: 24px;

		.left-side {
			display: flex;
			flex-direction: column;
			height: 100%;
			flex: 1;

			.buttons-menu {
				display: flex;
				flex-direction: row;
				gap: 8px;
				margin-bottom: 67px;
			}

			.container-func {
				display: flex;
				flex-direction: column;
				gap: 5px;
				margin-top: 16px;
				margin-bottom: 36px;

				.func {
					display: flex;
					flex-direction: row;
					gap: 8px;
					align-items: center;
				}
			}

			.container-title {
				display: flex;
				flex-direction: row;
				gap: 8px;
				align-items: center;
				justify-content: center;
				margin-bottom: 16px;
			}
		}

		.right-side {
			display: flex;
			flex-direction: column;
			align-items: flex-end;

			flex: 1;
		}
	}
`;
