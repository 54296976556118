import { GenericButton } from "@azify/ui-web";
import LoaderStatus from "components/LoaderStatus/LoaderStatus.component";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Styled from "../ExchangeFounds.styles";
import { useStepManager } from "hooks/useStepManager";
import { useOtcActions } from "hooks/useOtcActions";
import { useMainAccountActions } from "hooks/useMainAccountActions";
import { formatCurrencyFn } from "helpers/currency";

export const ExchangeSuccess = () => {
	const navigate = useNavigate();
	const { params } = useStepManager();
	const [isLoading, setIsLoading] = useState(true);
	const { dispatchOtcMovements, dispatchOtc } = useOtcActions();
	const { dispatchMain } = useMainAccountActions();

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 2000);

		return () => clearTimeout(timer);
	}, []);

	const valuesToDisplay = {
		...params
	} as {
		direction: "in" | "out";
		amount: number;
	};

	const decreaseValue = () => {
		const { amount, direction } = valuesToDisplay;

		if (direction === "in") {
			dispatchMain({
				type: "DECREASE_MAIN",
				by: amount
			});

			dispatchOtc({
				type: "INCREASE_OTC",
				by: amount
			});
		} else {
			dispatchMain({
				type: "INCREASE_MAIN",
				by: amount
			});

			dispatchOtc({
				type: "DECREASE_OTC",
				by: amount
			});
		}

		dispatchOtcMovements({
			type: "Transferência entre contas",
			transactionOrigin: direction === "in" ? "main_account" : "otc_account",
			transactionDestiny: direction === "in" ? "otc_account" : "main_account",
			isoCode: "R$",
			value: String(amount)
		});
	};

	const getSubtitle = valuesToDisplay.direction === "in" ? "Conta principal" : "Conta OTC";
	const getEmphasis = valuesToDisplay.direction === "in" ? " Conta OTC" : "Conta principal";

	return (
		<>
			<Styled.CenterContainer>
				<div className="display-between">
					<div className="center-items">
						<LoaderStatus
							title={isLoading ? "Efetuando transferência..." : "Transferência Realizada!"}
							subtitle={
								isLoading
									? ""
									: `Você transferiu ${formatCurrencyFn("BRL", String(valuesToDisplay.amount))} da ${getSubtitle} para a`
							}
							emphasis={isLoading ? "" : `${getEmphasis}`}
							status={isLoading ? "loading" : "success"}
						/>
					</div>

					<GenericButton
						onClick={() => {
							decreaseValue();
							navigate("/otc");
						}}
						isDisabled={isLoading}
						title="Concluir"
						layout="standard"
						color="primary"
					/>
				</div>
			</Styled.CenterContainer>
		</>
	);
};
