import * as S from "./PaymentMethod.styles";
import { GenericButton, IconButton, ItemList, Typography } from "@azify/ui-web";
import { useOpenModalSideBar } from "hooks/useOpenSideBar";
import CardPayment from "./Steps/CardPayment.componen";
import CryptoPayment from "./Steps/CryptoPayment.component";
import { Case, Else, If, Switch, Then } from "react-if";
import { PaymentMethodProps } from "./PaymentMethod.types";
import { useEffect, useState } from "react";

function PaymentMethod({ closeSideModal, values }: PaymentMethodProps) {
	const { currentStep, setCurrentStep, setParams } = useOpenModalSideBar();

	const handleChangeStep = (step: string) => {
		if (setCurrentStep) {
			setCurrentStep(step);
		}
	};

	useEffect(() => {
		if (setCurrentStep) {
			setCurrentStep("Start");
		}
	}, []);

	const [data, setData] = useState({
		bill: false,
		pix: false
	});

	return (
		<>
			<S.ContainerButton>
				<div className="container-close-header-component">
					<If condition={currentStep !== "Start"}>
						<Then>
							<IconButton
								onClick={() => {
									if (setCurrentStep) {
										setCurrentStep("Start");
									}
								}}
								icon="back-arrow-lg"
								size="medium"
								color="inverted"
							/>
						</Then>
						<Else>
							<IconButton
								onClick={() => {
									if (closeSideModal) {
										closeSideModal(false);
									}
								}}
								color="inverted"
								icon="close"
							/>
						</Else>
					</If>
				</div>
			</S.ContainerButton>

			<S.Container>
				<Switch>
					<Case condition={currentStep === "Start"}>
						<div className="container-inputs">
							<Typography value="Método de pagamento" type="h1" isBold marginBottom={10} />

							<ItemList
								behavior="default"
								label="Boleto bancário"
								supportParagraph="Pagamento em até 3 dias úteis, com taxa de 2%"
								leadingIconColor="primary"
								leadingIconName="barcode"
								leadingContent="icon"
								trailingContent="toggle"
								trailingToggleValue={data.bill}
								trailingToggleOnPress={() => {
									setData({ ...data, bill: !data.bill });
								}}
								isDividerVisible
								trailingSupportContent="none"
							/>

							<ItemList
								behavior="default"
								label="Cartão de crédito"
								supportParagraph="Não definido"
								leadingIconColor="primary"
								leadingIconName="card"
								leadingContent="icon"
								trailingContent="quickButton"
								trailingQuickButtonLabel="Definir"
								trailingQuickButtonOnPress={() => handleChangeStep("CardPayment")}
								isDividerVisible
								trailingSupportBadgeColor="danger"
								trailingSupportBadgeTitle="Não verificado"
								trailingSupportBadgeLayout="hint"
								trailingSupportContent="none"
							/>

							<ItemList
								behavior="default"
								label="Criptomoedas"
								supportParagraph="Não definido"
								leadingIconColor="primary"
								leadingIconName="coin-crypto"
								leadingContent="icon"
								trailingContent="quickButton"
								trailingQuickButtonLabel="Definir"
								trailingQuickButtonOnPress={() => handleChangeStep("CryptoPayment")}
								isDividerVisible
								trailingSupportBadgeColor="danger"
								trailingSupportBadgeTitle="Não verificado"
								trailingSupportBadgeLayout="hint"
								trailingSupportContent="none"
							/>

							<ItemList
								behavior="default"
								label="Pix"
								supportParagraph="Pagamento instantâneo sem taxas"
								leadingIconColor="primary"
								leadingIconName="pix"
								leadingContent="icon"
								trailingContent="toggle"
								trailingToggleOnPress={() => {
									setData({ ...data, pix: !data.pix });
								}}
								trailingToggleValue={data.pix}
								isDividerVisible
								trailingSupportContent="none"
							/>
						</div>

						<div className="footer">
							<GenericButton
								onClick={() => {
									if (closeSideModal) {
										setParams({
											bill: data.bill,
											pix: data.pix
										});
										closeSideModal(false);
									}
								}}
								title="Alterar"
								layout="standard"
								color="secondary"
							/>
						</div>
					</Case>

					<Case condition={currentStep === "CardPayment"}>
						<CardPayment values={values} />
					</Case>

					<Case condition={currentStep === "CryptoPayment"}>
						<CryptoPayment values={values} />
					</Case>
				</Switch>
			</S.Container>
		</>
	);
}

export default PaymentMethod;
