import { createRoot } from "react-dom/client";
import App from "./App";
import { StrictMode } from "react";
import "./styles/fonts.css";

const container = document.getElementById("root") as HTMLElement;

const root = createRoot(container);

root.render(
	<StrictMode>
		<App />
	</StrictMode>
);
