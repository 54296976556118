import * as Styled from "./ManualTransfer.styles";
import { FillTransferDetails } from "./Steps/0.FillTransferDetails";
import { ReviewTransferDetails } from "./Steps/2.ReviewTransferDetails";
import { StatusTransfer } from "./Steps/3.StatusTransfer";
import { Case, Switch } from "react-if";
import { useStepManager } from "hooks/useStepManager";
import { TransferSteps } from "./ManualTransfer.types";
import FillTransferValue from "./Steps/1.FillTransferValue";

export const ManualTransferScreen = () => {
	const { currentStep } = useStepManager();
	const typedCurrentStep = currentStep as TransferSteps;

	return (
		<Styled.Container>
			<Styled.CenterContainer>
				<Switch>
					<Case condition={typedCurrentStep === "FillTransferDetails"}>
						<FillTransferDetails />
					</Case>

					<Case condition={typedCurrentStep === "FillTransferValue"}>
						<FillTransferValue />
					</Case>

					<Case condition={typedCurrentStep === "ReviewTransferDetails"}>
						<ReviewTransferDetails />
					</Case>

					<Case condition={typedCurrentStep === "StatusTransfer"}>
						<StatusTransfer />
					</Case>
				</Switch>
			</Styled.CenterContainer>
		</Styled.Container>
	);
};
