import { useStepManager } from "hooks/useStepManager";
import { useFormContext } from "react-hook-form";
import { cameFromTheLeft } from "pages/Onboarding/Onboarding.styles";
import {
	Banner,
	ButtonGroup,
	Chip,
	Icon,
	IconButton,
	Illustration,
	ItemList,
	SectionHeading,
	Typography
} from "@azify/ui-web";
import * as Styled from "../Kyb.styles";
import { PartnerFields } from "../Kyb.types";
import { nameCapitalize } from "@azify/ui-helpers";
import { DropDown } from "components/DropDown";
import { useRef, useState } from "react";
import { DropDownItem } from "components/DropDown/DropDownItem";
import { useClick } from "@szhsin/react-menu";
import Dialog from "components/Modal/Dialog/Dialog.component";
import { colors } from "@azify/ui-tokens";
import ModalSideBar from "components/ModalSideBar";
import AttachPersonalDocuments from "common/modals/sideRight/AttachPersonalDocuments/AttachPersonalDocuments.component";
import { ToastAlert } from "components/Toast/Toast.component";

function WaitingBiometry() {
	const [isDotsMenuOpen, setIsDotsMenuOpen] = useState(false);
	const [showResendInvite, setShowResendInvite] = useState(false);
	const [showSureToRevoke, setSureToRevoke] = useState(false);
	const [showAttachDocuments, setShowAttachDocuments] = useState(false);
	const [openRevokeWarning, setOpenAccountAccessModal] = useState(false);

	const { setCurrentStep } = useStepManager();
	const { getValues } = useFormContext();
	const anchorProps = useClick(isDotsMenuOpen, setIsDotsMenuOpen);
	const ref = useRef(null);

	const goNext = () => {
		setCurrentStep("SuccessSubmit");
	};

	const goPartnerList = () => {
		setCurrentStep("InputAndListPartners");
	};

	const personalPartnersList = getValues("personalPartners") as PartnerFields[];

	const personalPartnerListWithAccountAccess = personalPartnersList.filter(
		(partner) => partner.accountAccess
	);

	const resendInvite = () => {
		const userTimeOut = true;

		if (userTimeOut) {
			ToastAlert({
				title: "Um código de convite está ativo.",
				subtitle: "Aguarde 20 minutos para um novo envio.",
				autoCloseEnable: true,
				type: "info"
			});
		} else {
			ToastAlert({
				title: "Código de convite reenviado!",
				subtitle: "Fulano recebeu um novo convite.",
				autoCloseEnable: true,
				type: "info"
			});
		}

		setShowResendInvite(false);
	};

	return (
		<>
			<Styled.MainStepContainer>
				<Styled.ScrollStepContainer>
					<Styled.CenterContent>
						<Styled.ContainerAnimation paddingTop="24" {...cameFromTheLeft}>
							<Illustration name="selfie-man-sun-background-md" width={200} height={200} />

							<Typography
								marginBottom={20}
								isBold
								type="h1"
								value="Estamos quase lá, falta pouco!"
							/>

							<Typography
								marginBottom={20}
								type="body1"
								value="
						Para concluir a sua solicitação, todos os sócios devem finalizar o processo de verificação de conta no aplicativo Azify."
							/>

							<Banner
								behavior="default"
								hierarchy="low"
								layout="inset"
								color="default"
								artwork="icon"
								iconName="info-circle-filled"
								title="Não se preocupe, todos os dados que você enviou até agora estão salvos."
							/>

							<SectionHeading
								behavior="actionable"
								actionTitle="Atualizar lista"
								onClick={() => {}}
								title="Sócios com acesso à conta"
							/>

							{personalPartnerListWithAccountAccess.map((partner, index) => (
								<ItemList
									key={index}
									behavior="display"
									label={nameCapitalize(partner.name)}
									leadingIconName="building"
									leadingContent="avatar"
									leadingAvatarType="initials"
									leadingAvatarSize="medium"
									leadingAvatarFullName={partner.name}
									trailingContent="customized"
									trailingContentCustomized={
										<div ref={ref} {...anchorProps}>
											<IconButton color="primary" icon="dots-ver" />
										</div>
									}
									trailingIconButtonIcon="dots-ver"
									trailingSupportContent="customized"
									isDividerVisible
									trailingSupportContentCustomized={
										<Chip id="" hierarchy="low" title="Pendente" backgroundColor="warning" />
									}
								/>
							))}
						</Styled.ContainerAnimation>
					</Styled.CenterContent>
				</Styled.ScrollStepContainer>

				<div className="step-footer">
					<div className="button-container">
						<ButtonGroup
							variant="default"
							isInverted
							titleSecondary="Ir para Sócios e Representantes"
							titlePrimary="Finalizar cadastro"
							onPressPrimary={goNext}
							onPressSecondary={goPartnerList}
						/>
					</div>
				</div>
			</Styled.MainStepContainer>

			{/* Resend user invite */}

			<Dialog
				openDialog={showResendInvite}
				setOpenDialog={setShowResendInvite}
				headerTitle="Deseja reenviar o convite?"
				layout="alternative"
				buttonType="group"
				buttonGroupProps={{
					titlePrimary: "Reenviar convite",
					titleSecondary: "Fechar",
					variant: "default",
					onPressPrimary: resendInvite,
					onPressSecondary: () => setShowResendInvite(false),
					customColorBackgroundPrimaryButton: colors.black.main
				}}>
				<div style={{ padding: "0 16px 16px 16px" }}>
					<Typography
						value="O código enviado anteriormente para Gabriel não estará mais ativo. Um novo código será enviado."
						type="body1"
					/>
				</div>
			</Dialog>

			{/* Ask for sure revoke account access */}

			<Dialog
				openDialog={showSureToRevoke}
				setOpenDialog={setSureToRevoke}
				headerTitle="Deseja revogar acesso à conta para este sócio?"
				layout="alternative"
				buttonType="group"
				buttonGroupProps={{
					titlePrimary: "Revogar acesso",
					titleSecondary: "Fechar",
					variant: "default",
					onPressPrimary: () => {
						setSureToRevoke(false);
						setShowAttachDocuments(true);
					},
					onPressSecondary: () => setSureToRevoke(false),
					customColorBackgroundPrimaryButton: colors.danger.main
				}}>
				<div style={{ padding: "0 16px 16px 16px" }}>
					<Typography
						value="Ao confirmar, o sócio não poderá mais efetuar transações financeiras na plataforma, sendo necessário que você anexe a documentação dele."
						type="body1"
					/>
				</div>
			</Dialog>

			{/* Dots menu partner options */}

			<DropDown
				isOpen={isDotsMenuOpen}
				setOpen={setIsDotsMenuOpen}
				innerRef={ref}
				align="end"
				content={
					<>
						<DropDownItem
							icon={<Icon name="mail" />}
							title="Reenviar convite"
							onClick={() => setShowResendInvite(true)}
						/>
						<DropDownItem
							isRed
							icon={<Icon color="negative" name="shield" />}
							title="Revogar acesso"
							onClick={() => setSureToRevoke(true)}
						/>
					</>
				}
			/>

			{/* Attach partner with access docs	*/}

			<ModalSideBar open={showAttachDocuments} onClose={() => setOpenAccountAccessModal(true)}>
				<AttachPersonalDocuments revokeAlert partner={personalPartnerListWithAccountAccess[0]} />
			</ModalSideBar>

			{/* Remove docs upload warning	*/}

			<Dialog
				openDialog={openRevokeWarning}
				setOpenDialog={setOpenAccountAccessModal}
				headerTitle="Importante: Documentos necessários"
				layout="alternative"
				buttonType="group"
				buttonGroupProps={{
					onPressPrimary: () => setOpenAccountAccessModal(false),
					onPressSecondary: () => {
						setOpenAccountAccessModal(false);

						setShowAttachDocuments(false);
					},
					titlePrimary: "Entendi",
					customColorBackgroundPrimaryButton: colors.black.main,
					titleSecondary: "Continuar sem revogar acesso",
					variant: "default"
				}}>
				<div style={{ padding: 16 }}>
					<Typography
						type="body1"
						value={`Assegure-se de anexar os documentos de ${personalPartnerListWithAccountAccess[0]?.name}. Isso é necessário para proceder com a revogação do acesso à conta. `}
					/>
				</div>
			</Dialog>
		</>
	);
}

export default WaitingBiometry;
