import { colors } from "@azify/ui-tokens";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding-bottom: 24px;
	justify-content: center;
	align-items: center;

	.display-between {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;

		.container-codes {
			display: flex;
			padding: 16px;
			width: 393px;
			flex-direction: column;
			align-items: center;
			gap: 8px;
			border-radius: 8px;
			background: var(${colors.white.dark}, #f3f4f9);
			margin-bottom: 16px;

			p {
				overflow-wrap: break-word;
			}
		}

		.container-qr-and-codes {
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 100%;
			margin-bottom: 22px;
		}

		.container-value-and-beneficiary {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 8px;
			align-items: center;
			margin-top: 24px;
			margin-bottom: 24px;

			.container-input-edit {
				display: flex;
				flex-direction: row;
				width: 100%;
				justify-content: space-between;
				padding: 0 16px;
			}
		}

		.container-qr-code {
			display: flex;
			width: 200px;
			margin-top: 16px;
			margin-bottom: 16px;
			height: 200px;
			padding: 0px 1.719px 0px 1.281px;
			justify-content: center;
			align-items: center;
			flex-shrink: 0;
			border-radius: 8px;
			border: 1px solid ${colors.gray.opacity["20p"]};
		}
	}
`;

export const CenterContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 600px;
	height: 100%;
	padding-top: 16px;
`;
