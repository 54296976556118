import * as S from "../CreatePixKey.styles";
import { GenericButton, QuickButton, TextField } from "@azify/ui-web";
import { CheckDataProps, CreatePixKeySteps } from "../CreatePixKey.types";
import { useState } from "react";
import { When } from "react-if";
import Finish from "./3.Finish.component";

const genericLabel = {
	phone: "Número do celular",
	email: "E-mail",
	cnpj: "",
	random: ""
};

const ConfirmCode = (props: CheckDataProps) => {
	const { type, setCurrentStep } = props;
	const [openDialog, setOpenDialog] = useState(false);

	const handleFinish = () => {
		setOpenDialog(true);
	};

	return (
		<S.SubContent>
			<div className="form-content">
				<TextField
					onChange={() => {}}
					label={genericLabel[type]}
					value=""
					placeholder=""
					maxLength={11}
				/>

				<TextField
					onChange={() => {}}
					label="Código de verificação"
					value=""
					placeholder=""
					maxLength={11}
					type="stepper"
					hintText="Insira o código de verificação de 6 dígitos enviado via SMS."
				/>

				<QuickButton label="Reenviar código" color="primary" onClick={() => {}} />
			</div>

			<div className="footer">
				<GenericButton
					title="Cadastrar chave"
					color="primary"
					layout="standard"
					onClick={() => handleFinish()}
				/>
			</div>

			<When condition={!!openDialog}>
				<Finish type={type} />
			</When>
		</S.SubContent>
	);
};

export default ConfirmCode;
