import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
`;

export const CenterContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 600px;
	height: 100%;
	padding-top: 16px;

	@media (max-width: 500px) {
		width: 100%;
	}
`;
