import { Avatar, GenericButton, HeaderNavigation, ItemList, Typography } from "@azify/ui-web";
import InputPin from "components/Input/PinField/PinField.component";
import Dialog from "components/Modal/Dialog/Dialog.component";
import TransactionalInput from "components/Input/TransactionalInput/TransactionalInput.component";
import { useStepManager } from "hooks/useStepManager";
import { useState } from "react";

export const ReviewPixKeyStep = () => {
	const { setCurrentStep } = useStepManager();
	const [openDialog, setOpenDialog] = useState(false);

	return (
		<>
			<div className="display-between">
				<div>
					<HeaderNavigation
						buttonOnTitle={false}
						title="Revisar Pix"
						quickButtonColor="accent"
						backIconClick={() => setCurrentStep("InputPixKey")}
					/>

					<div className="container-value-and-beneficiary">
						<Avatar
							onClick={() => setOpenDialog(true)}
							fullName="Master Tecnologia"
							type="initials"
							size="xlarge"
						/>

						<TransactionalInput placeHolder="R$ 00,00" disable={false} />

						<Typography
							textAlign="center"
							isBold
							type="subtitle1"
							value="Izabel Tabosa Tabanez"
							color="accent"
						/>
					</div>

					<ItemList
						label="Chave Pix Celular"
						supportParagraph="(77) 548392-9123"
						leadingContent="icon"
						isDividerVisible
						leadingIconName="pix"
						behavior="default"
						trailingSupportContent="none"
					/>

					<ItemList
						label="Transferindo com"
						supportParagraph="Conta BRL"
						leadingContent="icon"
						isDividerVisible
						leadingIconName="coin-dollar"
						behavior="default"
						trailingSupportContent="none"
					/>

					<ItemList
						label="Quando"
						supportParagraph="Hoje (25/12/2023) ás 15:00"
						leadingContent="icon"
						isDividerVisible
						leadingIconName="calendar-empty"
						behavior="default"
						trailingSupportContent="customized"
						trailingContent="quickButton"
						trailingQuickButtonColor="primary"
						trailingQuickButtonIsDisabled
						trailingQuickButtonLabel="Agendar"
					/>
				</div>

				<GenericButton
					onClick={() => setCurrentStep("StatusSendPix")}
					title="Enviar"
					layout="standard"
					color="primary"
				/>
			</div>

			<Dialog
				openDialog={openDialog}
				setOpenDialog={setOpenDialog}
				headerTitle="Validação de segurança"
				layout="standard"
				buttonGroupProps={{
					titleSecondary: "Esqueceu seu PIN?",
					isInverted: true,
					variant: "textSecondary"
				}}>
				<InputPin type="password" size={4} />
			</Dialog>
		</>
	);
};
