import {
	Avatar,
	ButtonGroup,
	HeaderNavigation,
	IconButton,
	ItemList,
	Typography
} from "@azify/ui-web";
import TransactionalInput from "components/Input/TransactionalInput/TransactionalInput.component";
import { useStepManager } from "hooks/useStepManager";
import { useNavigate } from "react-router-dom";

function CreateInfoDetails() {
	const navigate = useNavigate();

	const { setCurrentStep } = useStepManager();

	const goToNextStep = () => {
		setCurrentStep("ShowPixQrCodeResult");
	};

	return (
		<div className="display-between">
			<HeaderNavigation
				buttonOnTitle={false}
				title="Resumo do QR Code"
				quickButtonColor="accent"
				backIconClick={() => navigate("/pixArea")}
			/>

			<div className="container-value-and-beneficiary">
				<Avatar onClick={() => {}} fullName="Master Tecnologia" type="initials" size="xlarge" />

				<div className="container-input-edit">
					<div style={{ width: 40 }} />

					<TransactionalInput placeHolder="R$ 00,00" disable={false} />

					<IconButton color="primary" size="medium" icon="pencil" onClick={() => {}} />
				</div>

				<Typography
					textAlign="center"
					isBold
					type="subtitle1"
					value="Izabel Tabosa Tabanez"
					color="accent"
				/>
			</div>

			<ItemList
				label="Chave Pix Celular"
				supportParagraph="(77) 548392-9123"
				leadingContent="icon"
				isDividerVisible
				leadingIconName="pix"
				behavior="default"
				trailingSupportContent="none"
			/>

			<ItemList
				label="CPF"
				supportParagraph="•••.654.175-••"
				leadingContent="icon"
				isDividerVisible
				leadingIconName="id-back"
				behavior="default"
				trailingSupportContent="none"
			/>

			<ItemList
				label="Instituição"
				supportParagraph="Azify"
				leadingContent="icon"
				isDividerVisible
				leadingIconName="bank-01"
				behavior="default"
				trailingSupportContent="none"
			/>

			<ButtonGroup
				variant="default"
				isInverted
				titleSecondary="Não definir valor"
				titlePrimary="Gerar código"
				onPressPrimary={goToNextStep}
				onPressSecondary={() => {}}
			/>
		</div>
	);
}

export default CreateInfoDetails;
