import { Case, Switch } from "react-if";
import { useStepManager } from "hooks/useStepManager";
import * as Styled from "./NewDebitCollection.styles";
import { NewDebitCollectionsProps } from "./NewDebitCollection.types";
import { SelectClient } from "./Steps/0.SelectClient";
import { NewClient } from "./Steps/1.NewClient";
import { ConfirmDataDebit } from "./Steps/2.ConfirmDataDebit";
import { Success } from "./Steps/3.Success";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createNewDebitCollectionSchema } from "../Validations/NewDebitCollection.validations";

export const NewDebitCollection = () => {
	const { currentStep } = useStepManager();
	const typedCurrentStep = currentStep as NewDebitCollectionsProps;

	const newDebitCollectionMethods = useForm({
		resolver: yupResolver(createNewDebitCollectionSchema),
		defaultValues: {
			name: "",
			documentNumber: "",
			phone: "",
			email: "",
			cep: ""
		}
	});

	return (
		<Styled.Container>
			<FormProvider {...newDebitCollectionMethods}>
				<Switch>
					<Case condition={typedCurrentStep === "SelectClient"}>
						<SelectClient />
					</Case>

					<Case condition={typedCurrentStep === "NewClient"}>
						<NewClient />
					</Case>

					<Case condition={typedCurrentStep === "ConfirmDataDebit"}>
						<ConfirmDataDebit />
					</Case>

					<Case condition={typedCurrentStep === "Success"}>
						<Success />
					</Case>
				</Switch>
			</FormProvider>
		</Styled.Container>
	);
};
