import { SVGProps } from "react";

const ArrowDownCompletedSVG = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none" {...props}>
		<path
			fill="#272937"
			fillRule="evenodd"
			d="m9.244 11.19 3.72-3.72.53-.53L14.554 8l-.53.53-5 5-.53.53-.53-.53-5-4.999-.53-.53 1.06-1.06.53.53 3.72 3.719V2.25h1.5v8.94Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default ArrowDownCompletedSVG;
