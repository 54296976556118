import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../../rule/private";
import SendTransfer from "pages/Dashboard/SendTransfer/SendTransfer.screen";
import { StepManagerProvider } from "hooks/useStepManager";
import { PayBillsScreen } from "pages/Dashboard/SendTransfer/PayBills/PayBills.screen";
import { ManualTransferScreen } from "pages/Dashboard/SendTransfer/ManualTransfer/ManualTransfer.screen";

export const TransferRouter = () => {
	return (
		<Routes>
			<Route>
				<Route
					index
					element={
						<PrivateRoute>
							<SendTransfer />
						</PrivateRoute>
					}
				/>

				<Route
					path="payBill"
					element={
						<PrivateRoute>
							<StepManagerProvider initialStep="VerifyBillCode">
								<PayBillsScreen />
							</StepManagerProvider>
						</PrivateRoute>
					}
				/>

				<Route
					path="manualTransfer"
					element={
						<PrivateRoute>
							<StepManagerProvider initialStep="FillTransferDetails">
								<ManualTransferScreen />
							</StepManagerProvider>
						</PrivateRoute>
					}
				/>
			</Route>
		</Routes>
	);
};
