import { useStepManager } from "hooks/useStepManager";
import { useNavigate } from "react-router-dom";
import * as Styled from "../ExchangeFounds.styles";
import { Tooltip as ReactTooltip } from "react-tooltip";
import InputConvert from "components/Input/ConvertInput/InputConvert.component";
import { useState } from "react";
import { HeaderNavigation, IconButton, ItemList, SlidingButton } from "@azify/ui-web";
import { formatCurrencyFn } from "helpers/currency";
import { useMainAccountActions } from "hooks/useMainAccountActions";
import { useGatewayActions } from "hooks/useGatewayActions";

export const ExchangeInput = () => {
	const navigate = useNavigate();
	const { setCurrentStep, setParams } = useStepManager();

	const [inputError, setInputError] = useState("");
	const [amount, setAmount] = useState("");

	const { gatewayBalance } = useGatewayActions();
	const { mainBalance } = useMainAccountActions();

	const disableButton = Number(amount) > gatewayBalance;

	const onChangeValueConvert = (value: string) => {
		const valueInBrl = parseFloat(value);
		const currentBalance = gatewayBalance;

		if (valueInBrl > currentBalance) {
			setInputError("Saldo insuficiente");
		} else {
			setInputError("");
		}

		setAmount(value);
	};

	const textDirection = "Para";
	const textDirectionInv = "Saindo de";

	const goNext = () => {
		setParams({
			amount: Number(amount)
		});
		setCurrentStep("ExchangeSuccess");
	};

	return (
		<>
			<Styled.CenterContainer>
				<HeaderNavigation
					buttonOnTitle={false}
					title="Transferir saldos"
					trailingActionsType="primaryPrimary"
					quickButtonColor="inverted"
					backIconClick={() => navigate("/gateway")}
				/>

				<div className="display-between">
					<div className="container-direction-input">
						<div
							className="row-exchange"
							style={{
								flexDirection: "row-reverse"
							}}>
							<ItemList
								trailingSupportContent="none"
								trailingContent="none"
								label="Conta Principal"
								behavior="default"
								leadingContent="avatar"
								leadingAvatarCurrency="BRL"
								leadingAvatarSize="small"
								leadingAvatarType="currency"
								eyebrow={textDirection}
								paragraph={formatCurrencyFn("BRL", String(mainBalance))}
								onClick={() => {}}
							/>

							<IconButton onClick={() => {}} color="primary" icon="right-arrow-lg" />

							<ItemList
								trailingSupportContent="none"
								trailingContent="none"
								label="Conta Cobrança"
								behavior="default"
								leadingContent="avatar"
								leadingAvatarType="currency"
								leadingAvatarCurrency="BRL"
								leadingAvatarSize="small"
								eyebrow={textDirectionInv}
								paragraph={formatCurrencyFn("BRL", String(gatewayBalance))}
								onClick={() => {}}
							/>
						</div>

						<div className="input-value">
							<InputConvert
								inputStyle={{
									textAlign: "center"
								}}
								placeHolder="R$ 0,00"
								value={amount}
								hasValue={!!amount}
								prefix="R$"
								precision={2}
								onChangeText={onChangeValueConvert}
								error={inputError}
							/>
						</div>
					</div>

					<SlidingButton
						disabled={!amount || disableButton}
						overlayText="Arraste para transferir"
						title="Arraste para transferir"
						onSlideDone={goNext}
					/>
				</div>
			</Styled.CenterContainer>

			<ReactTooltip className="tooltip-style" place="top-start" id="market-advice-credit" />
		</>
	);
};
