import { Icon, Tile, Typography } from "@azify/ui-web";
import * as Styled from "./Settings.styles";
import { useNavigate } from "react-router-dom";

export const SettingsPage = () => {
	const navigate = useNavigate();

	const tileDetailsList = [
		{
			title: "Dados da Organização",
			description: "Informações gerais da organização atual.",
			icon: "building",
			onclick: () => navigate("/settings/organization")
		},
		{
			title: "Informações pessoais",
			description: "Detalhes pessoais do usuário atual.",
			icon: "user-rounded",
			onclick: () => navigate("/settings/personal")
		},
		{
			title: "Notificações",
			description: "Escolha as preferências de notificações da sua conta.",
			icon: "bell",
			onclick: () => navigate("/settings/notifications")
		},
		{
			title: "Limites",
			description: "Alterar limites de seus ativos.",
			icon: "equalizer",
			onclick: () => navigate("/settings/limits")
		},
		{
			title: "Usuários",
			description: "Cadastre novos usuários e altere suas permissões.",
			icon: "group",
			onclick: () => navigate("/settings/users")
		},
		{
			title: "Segurança",
			description: "Atualize sua senha e proteja sua conta.",
			icon: "shield",
			onclick: () => navigate("/settings/security")
		},
		{
			title: "Planos e Serviços",
			description: "Descubra ainda mais funcionalidades.",
			icon: "lightning-bolt",
			onclick: () => navigate("/settings/plans")
		}
	];

	return (
		<Styled.Container>
			<Styled.CenterContent>
				<div className="container-icon-label">
					<Icon name="settings" size="large" />
					<Typography value="Configurações" isBold type="h1" />
				</div>

				<div className="container-tiles-options">
					{tileDetailsList.map((tileDetails, index) => (
						<div
							key={index}
							className={index === tileDetailsList.length - 1 ? "last-full-tile" : ""}>
							<Tile
								size="medium"
								labelCustomized={
									<Typography value={tileDetails.title} isBold type="subtitle1" marginBottom={2} />
								}
								paragraphCustomized={
									<Typography value={tileDetails.description} type="subtitle2" />
								}
								trailingContent="customized"
								backgroundColor="primary"
								leadingIconName={tileDetails.icon}
								leadingIconCustomSize={36}
								leadingIconColor="quaternary"
								onClick={tileDetails.onclick}
							/>
						</div>
					))}
				</div>
			</Styled.CenterContent>
		</Styled.Container>
	);
};
