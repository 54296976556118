import { DownloadAzifyApp } from "common/modals/sideRight/DownloadAzifyApp";
import { StepManagerProvider } from "hooks/useStepManager";
import Onboarding from "pages/Onboarding/Onboarding.screen";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import OnboardingRouterLayout from "routes/layout/onboarding";
import PrivateRoute from "../../rule/private";

export const OnboardingRouter = () => {
	const { state } = useLocation();

	const currentStep = state?.onboardingStep || "InputPersonalDocumentNumber";

	return (
		<Routes>
			<Route element={<OnboardingRouterLayout />}>
				<Route
					index
					element={
						<PrivateRoute>
							<StepManagerProvider initialStep={currentStep}>
								<Onboarding />
							</StepManagerProvider>
						</PrivateRoute>
					}
				/>

				<Route path="download-app" element={<DownloadAzifyApp />} />

				<Route path="*" element={<Navigate to="/onboarding" replace />} />
			</Route>
		</Routes>
	);
};
