import * as Yup from "yup";
import { validaCNPJ, validateCPF } from "helpers/validate";

export const createPartnerSchema = Yup.object().shape({
	name: Yup.string().required("Required").min(3, "Must be at least 3 characters"),
	email: Yup.string().email("Must be a valid email").required("Required"),
	phone: Yup.string().required("Required"),
	accountAccess: Yup.boolean().optional(),
	politicallyExposedPerson: Yup.boolean().optional(),
	partnerPercentage: Yup.string().required("Required"),
	documentNumber: Yup.string()
		.required("Required")
		.test("Is An Valid CPF or CNPJ", "Must be a valid document number", (val) => {
			return validaCNPJ(val) || validateCPF(val);
		})
});

export const updatePartnerSchema = Yup.object().shape({
	name: Yup.string().required("Required").min(3, "Must be at least 3 characters"),
	email: Yup.string().email("Must be a valid email").required("Required"),
	phone: Yup.string().required("Required"),
	accountAccess: Yup.boolean().optional(),
	politicallyExposedPerson: Yup.boolean().optional(),
	partnerPercentage: Yup.string().optional(),
	documentNumber: Yup.string()
		.required("Required")
		.test("Is An Valid CPF or CNPJ", "Must be a valid document number", (val) => {
			return validaCNPJ(val) || validateCPF(val);
		})
});

export const updateMangerSchema = Yup.object().shape({
	function: Yup.string().required("Required"),
	politicallyExposedPerson: Yup.boolean().optional(),
	partnerPercentage: Yup.string().optional()
});

export const createBusinessSchema = Yup.object().shape({
	cep: Yup.string().required("Required"),
	city: Yup.string().required("Required"),
	state: Yup.string().required("Required"),
	address: Yup.string().required("Required"),
	addressNumber: Yup.string().required("Required"),
	neighborhood: Yup.string().required("Required"),
	addressComplement: Yup.string().optional(),
	personalPartners: Yup.array(),
	businessPartners: Yup.array(),
	monthlyIncome: Yup.string().required("Required"),
	email: Yup.string().email("Must be a valid email").required("Required"),
	phoneNumber: Yup.string().required("Required")
});
