import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
`;

export const CenterContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 600px;
	height: 100%;
	padding-top: 16px;

	.container-button-text {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 8px;
	}

	.icon-buttons-container {
		display: flex;
		flex-direction: row;
		gap: 23px;
		align-items: center;
		margin-left: 30px;
		margin-top: 40px;
		margin-bottom: 24px;
	}
`;
