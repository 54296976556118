import { AxiosError } from "axios";
import { ToastAlert } from "components/Toast/Toast.component";
import { formatErrorMessage } from "./errorConstants";
import { errorsToAvoid } from "./errorConstantsAvoider";

interface ErrorResponse {
	error: string;
}

const isErrorResponse = (data: unknown): data is ErrorResponse => {
	return typeof data === "object" && data !== null && "error" in data;
};

export const isAxiosError = (data: unknown): data is AxiosError => {
	return typeof data === "object" && data !== null && "isAxiosError" in data;
};

export const handleErrors = async (errorObj: ErrorResponse) => {
	if (!isErrorResponse(errorObj)) return;

	if (errorsToAvoid.includes(errorObj.error)) return;

	const errorString = errorObj.error;
	const errorDetails = formatErrorMessage(errorString);

	if (errorDetails) {
		ToastAlert({
			title: errorDetails.title,
			subtitle: errorDetails.description,
			autoCloseEnable: true,
			type: "error"
		});
	} else {
		ToastAlert({
			title: "Ocorreu um erro, tente novamente",
			subtitle: "",
			autoCloseEnable: true,
			type: "error"
		});
	}
};
