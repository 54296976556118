import { useStepManager } from "hooks/useStepManager";
import { cameFromTheRight } from "pages/Onboarding/Onboarding.styles";
import * as Styled from "../Kyc.styles";
import { Chip, GenericButton, IconButton, ProgressBar, TextField, Typography } from "@azify/ui-web";

function ClaimAccountId() {
	const { setCurrentStep } = useStepManager();

	const goNext = () => {
		setCurrentStep("InputAccountPassword");
	};

	const goPrev = () => {
		setCurrentStep("VerifyPhoneNumber");
	};

	return (
		<Styled.MainStepContainer>
			<Styled.ScrollStepContainer>
				<Styled.CenterContent>
					<div className="step-header">
						<div className="container-arrow-progress">
							<IconButton icon="back-arrow-lg" onClick={goPrev} color="inverted" marginRight={32} />

							<ProgressBar currentStep={0} currentStepProgress={100} totalSteps={3} height={2} />
						</div>
						<Typography value="Criar Azify ID" type="h1" isBold marginBottom={16} />
					</div>

					<Styled.ContainerAnimation {...cameFromTheRight}>
						<Typography
							value="Ele será usado para acessar todas as plataformas e aplicativos da Azify."
							type="body1"
							marginBottom={24}
						/>
						<div className="container-name-validation">
							<TextField
								leadingArtworkIcon="user-rounded"
								leadingArtworkIconColor="accent"
								placeholder=""
								maxLength={50}
							/>

							<Chip
								id="chip-currency"
								hierarchy="high"
								title="Azify ID disponível"
								width="intrinsec"
								iconRightColor="positive"
								iconLeft="check-circle-filled-small"
							/>
						</div>
					</Styled.ContainerAnimation>
				</Styled.CenterContent>
			</Styled.ScrollStepContainer>

			<div className="step-footer">
				<div className="button-container">
					<GenericButton onClick={goNext} title="Continuar" layout="standard" color="primary" />
				</div>
			</div>
		</Styled.MainStepContainer>
	);
}

export default ClaimAccountId;
