import { ForgotId } from "pages/Sign/ForgotId/ForgotId.screen";
import ForgotPassword from "pages/Sign/ForgotPassword/ForgotPassword.screen";
import { SignInPage } from "pages/Sign/SignIn/SignIn.screen";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthRouterLayout from "routes/layout/auth";

export const AuthRouter = () => {
	return (
		<Routes>
			<Route element={<AuthRouterLayout />}>
				<Route path="/sign-in" element={<SignInPage />} />
				<Route path="/forgot-id" element={<ForgotId />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />

				<Route path="*" element={<Navigate to="/auth/sign-in" replace />} />
			</Route>
		</Routes>
	);
};
