import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../../rule/private";
import { PixArea } from "pages/Dashboard/PixArea/PixArea.screen";
import { StepManagerProvider } from "hooks/useStepManager";
import { SendPixScreen } from "pages/Dashboard/PixArea/SendPix/SendPix.screen";
import { PixCodeScreen } from "pages/Dashboard/PixArea/PixCode/PixCode.screen";
import CreatePixQrCode from "pages/Dashboard/PixArea/CreatePixQrCode/CreatePixQrCode.component";

export const PixAreaRouter = () => {
	return (
		<Routes>
			<Route>
				<Route
					index
					element={
						<PrivateRoute>
							<PixArea />
						</PrivateRoute>
					}
				/>

				<Route
					path="sendPix"
					element={
						<PrivateRoute>
							<StepManagerProvider initialStep="InputPixKey">
								<SendPixScreen />
							</StepManagerProvider>
						</PrivateRoute>
					}
				/>

				<Route
					path="pixCode"
					element={
						<PrivateRoute>
							<StepManagerProvider initialStep="VerifyCode">
								<PixCodeScreen />
							</StepManagerProvider>
						</PrivateRoute>
					}
				/>

				<Route
					path="createPixQrCode"
					element={
						<PrivateRoute>
							<StepManagerProvider initialStep="CreateInfoDetails">
								<CreatePixQrCode />
							</StepManagerProvider>
						</PrivateRoute>
					}
				/>
			</Route>
		</Routes>
	);
};
