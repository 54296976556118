import styled from "styled-components";
import { LoaderStatusProps } from "./LoaderStatus.types";

export const Container = styled.div<Pick<LoaderStatusProps, "heightContainer">>`
	width: 100%;
	height: ${(props) => props.heightContainer || "395px"};
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.container-icon {
		margin-bottom: 32px;
		width: 120px;
		height: 120px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
