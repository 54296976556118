import * as Styled from "./Gateway.styles";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "components/BreadCrumb/BreadCrumb.component";
import gatewayImage from "assets/images/gateway-promo.png";
import gatewayBanner from "assets/images/gateway-banner.png";
import { GenericButton, Icon, Typography } from "@azify/ui-web";

export const GatewayPage = () => {
	const navigate = useNavigate();

	return (
		<Styled.Container>
			<Styled.CenterContent>
				<BreadCrumb
					items={[
						{
							label: "Configurações",
							action: () => {
								navigate("/settings");
							}
						},
						{
							label: "Planos e Serviços",
							action: () => {
								navigate("/settings/plans");
							}
						},
						{
							label: "Gateway",
							action: () => {}
						}
					]}
					TextType="subtitle2"
				/>

				<div className="container-sides">
					<div className="left-side">
						<div className="container-title">
							<img width={25} height={25} src={gatewayImage} />

							<Typography type="h5" isBold value="Gateway de Pagamentos" />
						</div>

						<Typography
							type="h1"
							fontSize={50}
							lineHeight={60}
							isBold
							value={"Transforme pagamentos em oportunidades"}
						/>

						<Typography
							type="body2"
							marginTop={16}
							isBold
							value="Facilite o processo de aceitação de pagamentos proporcionando uma variedade de opções de pagamento. Amplie sua base de clientes e impulsione seus lucros."
						/>

						<div className="container-func">
							<div className="func">
								<Icon name="check-circle-filled" color="accent" size="medium" />
								<Typography type="body2" isBold value="Pagamento em cripto" />
							</div>

							<div className="func">
								<Icon name="check-circle-filled" color="accent" size="medium" />
								<Typography type="body2" isBold value="Antecipação do Valor" />
							</div>

							<div className="func">
								<Icon name="check-circle-filled" color="accent" size="medium" />
								<Typography type="body2" isBold value="Gestão de Clientes" />
							</div>

							<div className="func">
								<Icon name="check-circle-filled" color="accent" size="medium" />
								<Typography type="body2" isBold value="Links de Pagamentos" />
							</div>
						</div>
						<div className="buttons-menu">
							<GenericButton layout="outlined" color="secondary" title="Saiba mais" />

							<GenericButton
								onClick={() => {
									navigate("/onboardingExtension/gateway");
								}}
								layout="standard"
								color="primary"
								title="Começar a usar"
							/>
						</div>
					</div>

					<div className="right-side">
						<img width={482} height={412} src={gatewayBanner} />
					</div>
				</div>
			</Styled.CenterContent>
		</Styled.Container>
	);
};
