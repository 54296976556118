import { colors } from "@azify/ui-tokens";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 12px 32px;
	margin-top: 12px;
	height: 100%;

	.container-inputs {
		display: flex;
		flex-direction: column;
		gap: 10px;

		.tile-container {
			display: flex;
			gap: 8px;

			margin-top: 20px;
			margin-bottom: 16px;
		}
	}

	.footer {
		margin-top: 48px;
		padding-bottom: 24px;
	}

	.grid-inputs {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 16px;
	}
`;

export const ContainerButton = styled.div`
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	display: flex;

	padding: 0 28px;
	z-index: 1;

	.container-close-header-component {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
`;

export const InputSelect = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	padding: 8px 0;
	border-bottom: 1px solid ${colors.gray.main};
	cursor: pointer;
	user-select: none;
`;

export const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;

	width: 100%;
	padding: 16px;
	border-radius: 8px;
	background-color: ${colors.white.main};

	.card-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		width: 100%;

		& .text-right {
			text-align: right;

			width: 50%;
		}
	}
`;
