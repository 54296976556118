import { Avatar, GenericButton, HeaderNavigation, ItemList, Typography } from "@azify/ui-web";
import InputPin from "components/Input/PinField/PinField.component";
import Dialog from "components/Modal/Dialog/Dialog.component";
import { useStepManager } from "hooks/useStepManager";
import { useState } from "react";

export const ReviewTransferDetails = () => {
	const { setCurrentStep } = useStepManager();
	const [openDialog, setOpenDialog] = useState(false);

	return (
		<>
			<div className="display-between">
				<div>
					<HeaderNavigation
						buttonOnTitle={false}
						title="Revisar TED ou Pix Manual"
						quickButtonColor="accent"
						backIconClick={() => setCurrentStep("FillTransferValue")}
					/>

					<div className="container-value-and-beneficiary">
						<Avatar
							onClick={() => setOpenDialog(true)}
							fullName="Master Tecnologia"
							type="initials"
							size="xlarge"
						/>

						<Typography textAlign="center" isBold type="h1" value="R$ 100,00" color="primary" />

						<Typography
							textAlign="center"
							isBold
							type="subtitle1"
							value="Izabel Tabosa Tabanez"
							color="accent"
						/>
					</div>

					<ItemList
						label="Método de pagamento"
						supportParagraph="Conta BRL"
						leadingContent="icon"
						isDividerVisible
						leadingIconName="coin-dollar"
						behavior="default"
						trailingSupportContent="none"
					/>

					<ItemList
						label="Agência"
						supportParagraph="3803"
						leadingContent="icon"
						isDividerVisible
						leadingIconName="bank-01"
						behavior="default"
						trailingSupportContent="none"
					/>

					<ItemList
						label="Conta poupança"
						supportParagraph="8113-8"
						leadingContent="icon"
						isDividerVisible
						leadingIconName="vault"
						behavior="default"
						trailingSupportContent="none"
					/>
				</div>

				<GenericButton
					onClick={() => setCurrentStep("StatusTransfer")}
					title="Enviar"
					layout="standard"
					color="primary"
				/>
			</div>

			<Dialog
				openDialog={openDialog}
				setOpenDialog={setOpenDialog}
				headerTitle="Validação de segurança"
				layout="standard"
				buttonGroupProps={{
					titleSecondary: "Esqueceu seu PIN?",
					isInverted: true,
					variant: "textSecondary"
				}}>
				<InputPin type="password" size={4} />
			</Dialog>
		</>
	);
};
