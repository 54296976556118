import { Avatar, Icon, QuickButton, SearchField, Badge, Typography } from "@azify/ui-web";
import * as Styles from "./Gateway.styles";
import { colors } from "@azify/ui-tokens";
import { Chip } from "components/Chip";
import { ColorType } from "components/Chip/Chip.types";
import { formatCurrencyFn } from "helpers/currency";
import { useGatewayActions } from "hooks/useGatewayActions";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";

const data = [
	{
		id: 1,
		client: "Guiomar Picanço Vaz",
		phone: "(64) 99671-0124",
		status: "Pendente",
		statusColor: "warning" as ColorType,
		date: "23 abr, 2024",
		hour: "20:03 PM",
		method: "Cartão de crédito",
		method_desc: "Em até 1x s/ taxas",
		recurrence: "Mensal",
		recurrence_desc: "Limitada: 3x",
		value: "R$ 5.000,00"
	}
];

const sparkGraph: ApexCharts.ApexOptions = {
	colors: [colors.primary.main],
	chart: {
		type: "line",
		sparkline: {
			enabled: true
		}
	},
	tooltip: {
		fixed: {
			enabled: false
		},
		x: {
			show: false
		},
		marker: {
			show: false
		},
		custom: ({ series, seriesIndex, dataPointIndex }) => {
			return String(series[seriesIndex][dataPointIndex]);
		}
	},
	stroke: {
		show: true,
		curve: "smooth",
		width: 2,
		dashArray: 0
	},
	grid: {
		padding: {
			top: 0,
			left: 8,
			right: 0,
			bottom: 10
		}
	},
	fill: {
		type: "solid",
		colors: ["transparent"]
	},
	xaxis: {
		labels: {
			show: false
		},
		crosshairs: {
			show: false
		}
	}
};

const series = [
	{
		data: [0, 20890.78]
	}
];

export const GatewayPage = () => {
	const {
		gatewayBalance,
		gatewayRecValues,
		gatewayRecPending,
		gatewayRecCanceled,
		gatewayRecExpired,
		gatewayRecValuesCount,
		gatewayRecPendingCount,
		gatewayRecCanceledCount,
		gatewayRecExpiredCount,
		movements
	} = useGatewayActions();

	const navigate = useNavigate();

	const parseStatus = (status: string) => {
		switch (status) {
			case "Recebido":
				return "success";
			case "Pendente":
				return "warning";
			case "Cancelado":
				return "danger";
			case "Expirado":
				return "danger";
			default:
				return "primary";
		}
	};

	return (
		<Styles.Container>
			<div className="header-section">
				<div className="left-container">
					<Icon name="coin-dollar" size="large" />
					<Typography value="Gestão de cobranças" type="h1" isBold />
				</div>

				<div className="right-container">
					<QuickButton
						color="accent"
						label="Nova cobrança"
						leadingIcon="arrow-to-up-small"
						onClick={() => navigate("/gateway/new-debit-collection")}
					/>
					<QuickButton
						onClick={() => {
							navigate("/gateway/exchange");
						}}
						color="primary"
						label="Retirar"
						leadingIcon="up-arrow-lg-small"
					/>
					{/* <QuickButton color="primary" label="Clientes" leadingIcon="user-small" /> */}
				</div>
			</div>

			<Styles.CardContent>
				<Styles.LargeCard>
					<div className="left-container">
						<Typography value="Saldo disponível" type="subtitle2" isBold />

						<Typography value={formatCurrencyFn("BRL", String(gatewayBalance))} type="h2" isBold />
					</div>

					<ReactApexChart
						width={155}
						height={108}
						options={sparkGraph}
						series={series}
						type="area"
					/>
				</Styles.LargeCard>

				<Styles.Card>
					<div className="card-header">
						<div className="text-container">
							<Typography value="Recebidos" type="subtitle2" isBold />
							<Typography value="Abril" color="secondary" type="body3" />
						</div>

						<Badge title={String(gatewayRecValuesCount)} color="accent" layout="label" />
					</div>

					<Typography
						value={formatCurrencyFn("BRL", String(gatewayRecValues))}
						color="secondary"
						type="h4"
						isBold
					/>
				</Styles.Card>

				<Styles.Card>
					<div className="card-header">
						<div className="text-container">
							<Typography value="Pendentes" type="subtitle2" isBold />
							<Typography value="Abril" color="secondary" type="body3" />
						</div>

						<Badge title={String(gatewayRecPendingCount)} color="warning" layout="label" />
					</div>

					<Typography
						value={formatCurrencyFn("BRL", String(gatewayRecPending))}
						color="secondary"
						type="h4"
						isBold
					/>
				</Styles.Card>

				<Styles.Card>
					<div className="card-header">
						<div className="text-container">
							<Typography value="Cancelados" type="subtitle2" isBold />
							<Typography value="Abril" color="secondary" type="body3" />
						</div>

						<Badge title={String(gatewayRecCanceledCount)} color="danger" layout="label" />
					</div>

					<Typography
						value={formatCurrencyFn("BRL", String(gatewayRecCanceled))}
						color="secondary"
						type="h4"
						isBold
					/>
				</Styles.Card>

				<Styles.Card>
					<div className="card-header">
						<div className="text-container">
							<Typography value="Expirados" type="subtitle2" isBold />
							<Typography value="Abril" color="secondary" type="body3" />
						</div>

						<Badge title={String(gatewayRecExpiredCount)} color="secondary" layout="label" />
					</div>

					<Typography
						value={formatCurrencyFn("BRL", String(gatewayRecExpired))}
						color="secondary"
						type="h4"
						isBold
					/>
				</Styles.Card>
			</Styles.CardContent>

			<Styles.Content>
				<div className="header-content-section">
					<div className="title-content-container">
						<Typography value="Últimas cobranças" type="h2" isBold />
					</div>

					<QuickButton color="primary" label="Gerar relatório" />
				</div>

				<SearchField placeholder="Buscar cobranças" value="" onChangeInput={() => {}} />

				<table>
					<tr>
						<th align="left" className="header-table">
							<Typography value="Cliente" type="subtitle2" color="secondary" marginBottom={10} />
						</th>
						<th align="left" className="header-table">
							<Typography value="Status" type="subtitle2" color="secondary" marginBottom={10} />
						</th>
						<th align="left" className="header-table">
							<Typography
								value="Data da cobrança"
								type="subtitle2"
								color="secondary"
								marginBottom={10}
							/>
						</th>
						<th align="left" className="header-table">
							<Typography value="Método" type="subtitle2" color="secondary" marginBottom={10} />
						</th>
						<th align="left" className="header-table">
							<Typography
								value="Recorrência"
								type="subtitle2"
								color="secondary"
								marginBottom={10}
							/>
						</th>
						<th align="right" className="header-table">
							<Typography value="Valor R$" type="subtitle2" color="secondary" marginBottom={10} />
						</th>
					</tr>
					{movements.map((item) => {
						return (
							<tr key={item.id} className="gap-table">
								<td className="client-section">
									<Avatar
										size="medium"
										type="initials"
										fullName={item.client}
										backgroundColor={colors.black.main}
										textColor="inverted"
									/>
									<div className="column">
										<Typography value={item.client} type="subtitle1" isBold />
										<Typography value={item.phone} type="body3" color="secondary" />
									</div>
								</td>
								<td className="badge">
									<Chip
										text={item.status}
										backgroundColor={parseStatus(item.status)}
										width="80px"
									/>
								</td>
								<td className="column">
									<Typography value={item.date} type="subtitle1" isBold />
									<Typography value={item.hour} type="body3" color="secondary" />
								</td>
								<td className="column">
									<Typography value={item.method} type="subtitle1" isBold />
									<Typography value={item.method_desc} type="body3" color="secondary" />
								</td>
								<td className="column">
									<Typography value={item.recurrence} type="subtitle1" isBold />
									<Typography value={item.recurrence_desc} type="body3" color="secondary" />
								</td>
								<td align="right">
									<Typography
										value={formatCurrencyFn("BRL", String(item.value))}
										type="subtitle1"
										isBold
										width="165px"
									/>
								</td>
							</tr>
						);
					})}
				</table>
			</Styles.Content>
		</Styles.Container>
	);
};
