import {
	Avatar,
	Badge,
	Chip,
	CurrencyType,
	Icon,
	IconButton,
	ItemList,
	ProgressBar,
	QuickButton,
	SectionHeading,
	Typography
} from "@azify/ui-web";
import { Chip as LocalChip } from "components/Chip";
import * as Styled from "./Otc.styles";
import { useNavigate } from "react-router-dom";
import { colors } from "@azify/ui-tokens";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ReactApexChart from "react-apexcharts";
import { ColorType } from "components/Chip/Chip.types";
import { formatCurrencyFn } from "helpers/currency";
import { useOtcActions } from "hooks/useOtcActions";
import { useMainAccountActions } from "hooks/useMainAccountActions";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

export const DashboardOtc = () => {
	const navigate = useNavigate();
	const [percentage, setPercentage] = useState(0);
	const [timeLeft, setTimeLeft] = useState("0:0:0");

	const { mainBalance, dispatchMain } = useMainAccountActions();

	const {
		otcBalance,
		otcTether,
		otcBitcoin,
		otcUsdc,
		otcPending,
		otcPersonalizedLimit,
		otcOrderNumber,
		movements: data,
		graphLabales,
		graphDate,
		movements
	} = useOtcActions();

	const stocks = [
		{
			name: "Tether",
			symbol: "USDT",
			amount: formatCurrencyFn("USDT", String(otcTether), true)
		},
		{
			name: "USD Coin",
			symbol: "USDC",
			amount: formatCurrencyFn("USDC", String(otcUsdc), true)
		},
		{
			name: "Bitcoin",
			symbol: "BTC",
			amount: formatCurrencyFn("BTC", String(otcBitcoin), true)
		}
	];

	const graphData = [
		{
			data: [0, 0, 0]
		},
		{
			data: [0, 500.23, 0]
		},
		{
			data: [0, 0, 0]
		}
	];

	const options: ApexCharts.ApexOptions = {
		chart: {
			type: "bar",
			toolbar: {
				show: false
			}
		},
		legend: {
			show: false
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: "20%",
				borderRadius: 4
			}
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			show: true,
			width: 2,
			colors: ["transparent"]
		},
		yaxis: {
			labels: graphLabales
		},
		xaxis: {
			categories: graphDate
		},
		fill: {
			opacity: 1
		},
		tooltip: {}
	};

	const changePercentage = () => {
		setPercentage(Number((otcPending / 500000) * 100));
	};

	const getEndOfMarketInTime = () => {
		const now = dayjs();

		const endOfMarket = dayjs().startOf("day").set("hour", 17).set("minute", 0).set("second", 0);

		const difHours = endOfMarket.diff(now, "hours");
		const difMinutes = endOfMarket.diff(now, "minutes") - difHours * 60;
		const difSeconds = endOfMarket.diff(now, "seconds") - difHours * 3600 - difMinutes * 60;

		if (difHours < 0) {
			return "0:0:0";
		}

		return `${difHours}:${difMinutes}:${String(difSeconds).slice(0, 2)}`;
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setTimeLeft(getEndOfMarketInTime());
		}, 1000);

		return () => clearTimeout(timer);
	});

	useEffect(() => {
		changePercentage();
	}, [otcPending, otcPersonalizedLimit]);

	return (
		<Styled.Container>
			<div className="container-title-actions">
				<div className="icon-title">
					<Icon size="large" name="up-down-arrow" />
					<div className="center">
						<Typography value="Painel OTC" type="h1" isBold />
					</div>
					<a
						data-tooltip-id="market-advice"
						data-tooltip-html={
							timeLeft === "0:0:0" ? "Mercado Fechado" : `O mercado fecha em ${timeLeft}`
						}>
						<Chip
							id="chip-currency"
							hierarchy="high"
							title={timeLeft === "0:0:0" ? "Mercado Fechado" : `O mercado fecha em ${timeLeft}`}
							width="intrinsec"
							iconRightColor="tertiary"
							iconLeft="padlock-01-small"
							iconRight="info-circle-filled-small"
							backgroundCustomColor={colors.gray.opacity["10p"]}
						/>
					</a>
				</div>

				<div className="actions-buttons">
					<QuickButton
						leadingIcon="add-filled-small"
						label="Comprar"
						color="accent"
						onClick={() => navigate("/otc/buy")}
					/>

					<QuickButton
						leadingIcon="down-arrow-lg-filled-small"
						label="Transferir saldos"
						color="primary"
						onClick={() => navigate("/otc/exchange")}
					/>

					{/* <QuickButton
						leadingIcon="up-arrow-lg-filled-small"
						label="Retirar saldos"
						color="primary"
						onClick={() => {}}
					/> */}
				</div>
			</div>

			<div className="container-founds-wallets">
				<div className="container-founds-deposits">
					<div className="between">
						<Typography value="Saldo Real" type="subtitle2" isBold />

						<Typography value={formatCurrencyFn("BRL", String(otcBalance))} type="h2" isBold />
					</div>

					<div className="between">
						<Typography value="Depósitos pendentes" type="subtitle2" isBold />

						<div className="row-icon-amount">
							<Typography
								value={formatCurrencyFn("BRL", String(otcPending))}
								type="h2"
								color="negative"
								isBold
							/>

							<IconButton icon="info-circle-filled" color="primary" />
						</div>
					</div>
				</div>

				<div className="container-stocks">
					{stocks.map((stock) => (
						<div className="container-founds-deposits" key={stock.name}>
							<div className="between">
								<div className="row-between">
									<div>
										<Typography marginBottom={4} value={stock.name} type="subtitle2" isBold />
										<Typography color="secondary" value={stock.symbol} type="body3" isBold />
									</div>
									<Avatar type="currency" currency={stock.symbol as CurrencyType} />
								</div>

								<Typography
									color="secondary"
									value={`${stock.symbol} ${stock.amount}`}
									type="h5"
									isBold
								/>
							</div>
						</div>
					))}
				</div>
			</div>

			<div className="container-graphs">
				<div className="purchase-graph">
					<SectionHeading
						behavior="actionable"
						actionTitle={`${graphDate.length} meses`}
						title={`Compras dos últimos ${graphDate.length} meses`}
					/>

					<div className="row-badge-container">
						<div className="row-badge">
							<Badge layout="hint" color="success" />
							<Typography value="Tether" type="subtitle2" color="secondary" />
						</div>

						<div className="row-badge">
							<Badge layout="hint" color="accent" />
							<Typography value="USDC" type="subtitle2" color="secondary" />
						</div>

						<div className="row-badge">
							<Badge layout="hint" color="warning" />
							<Typography value="Bitcoin" type="subtitle2" color="secondary" />
						</div>
					</div>

					<div className="chart-container" id="chart">
						<ReactApexChart height={350} options={options} series={graphData} type="bar" />
					</div>
				</div>

				<div className="credit-limits">
					<SectionHeading
						behavior="informational"
						actionTitle="6 meses"
						title="Limites de crédito"
					/>

					<ProgressBar
						totalSteps={1}
						barColor={colors.black.main}
						currentStepProgress={Number(percentage.toPrecision(2))}
						height={13}
					/>

					<Typography
						marginBottom={16}
						textAlign="center"
						value={`${Number(percentage.toPrecision(2))}% utilizado`}
						type="body3"
						color="secondary"
					/>

					<ItemList
						behavior={"display"}
						paragraph="Seu limite personalizado"
						label=""
						trailingSupportContent="customized"
						trailingContent="customized"
						trailingSupportContentCustomized={
							<Typography
								textAlign="center"
								value={formatCurrencyFn("BRL", "500000")}
								type="subtitle1"
								isBold
							/>
						}
					/>

					<ItemList
						behavior={"display"}
						paragraph="Total devido"
						label=""
						trailingSupportContent="customized"
						trailingContent="customized"
						trailingSupportContentCustomized={
							<Typography
								textAlign="center"
								value={formatCurrencyFn("BRL", String(otcPending))}
								type="subtitle1"
								color="negative"
								isBold
							/>
						}
					/>

					<ItemList
						behavior={"display"}
						label=""
						paragraph="Disponível para compra"
						trailingSupportContent="customized"
						trailingContent="customized"
						trailingSupportContentCustomized={
							<Typography
								textAlign="center"
								value={formatCurrencyFn("BRL", String(500000 - otcPending))}
								type="subtitle1"
								isBold
							/>
						}
					/>

					<ItemList
						behavior={"display"}
						label=""
						paragraph="Ordens em aberto"
						trailingSupportContent="customized"
						trailingContent="customized"
						trailingSupportContentCustomized={
							<Typography
								textAlign="center"
								value={String(otcOrderNumber)}
								type="subtitle1"
								isBold
							/>
						}
					/>
				</div>
			</div>

			<ReactTooltip className="tooltip-style" place="top-end" id="market-advice" />

			<Styled.TableContainer>
				<SectionHeading behavior="actionable" actionTitle="Ver todas" title="Últimas ordens" />

				<table>
					<tr>
						<th align="left" className="header-table">
							<Typography value="Tipo" type="subtitle2" color="secondary" marginBottom={10} />
						</th>
						<th align="left" className="header-table">
							<Typography value="Data" type="subtitle2" color="secondary" marginBottom={10} />
						</th>
						<th align="left" className="header-table">
							<Typography value="Origem" type="subtitle2" color="secondary" marginBottom={10} />
						</th>
						<th align="left" className="header-table">
							<Typography value="Destino" type="subtitle2" color="secondary" marginBottom={10} />
						</th>
						<th align="left" className="header-table">
							<Typography value="Status" type="subtitle2" color="secondary" marginBottom={10} />
						</th>
						<th align="right" className="header-table">
							<Typography value="Valor" type="subtitle2" color="secondary" marginBottom={10} />
						</th>
					</tr>
					{data.map((item) => {
						return (
							<tr key={item.id} className="gap-table">
								<td className="client-section">
									<Icon name="cash-out-02" size="medium" color="primary" />
									<Typography value={item.type} type="subtitle1" isBold />
								</td>
								<td>
									<Typography value={item.date} type="subtitle1" isBold color="secondary" />
								</td>
								<td>
									<div className="row">
										<Avatar
											type={item.iconOrigin.type}
											currency={item.iconOrigin.currency}
											size={item.iconOrigin.size}
										/>
										<Typography value={item.origin} type="subtitle1" isBold marginLeft={10} />
									</div>
								</td>
								<td>
									<div className="row">
										<Avatar
											type={item.iconDestiny.type}
											currency={item.iconDestiny.currency}
											size={item.iconDestiny.size}
										/>
										<Typography value={item.destiny} type="subtitle1" isBold marginLeft={10} />
									</div>
								</td>
								<td className="badge">
									<LocalChip
										text={item.status}
										backgroundColor={item.statusColor}
										width={item.statusWidth}
									/>
								</td>
								<td align="right">
									<Typography value={item.value} type="subtitle1" isBold width="165px" />
								</td>
							</tr>
						);
					})}
				</table>
			</Styled.TableContainer>
		</Styled.Container>
	);
};
