import * as Yup from "yup";
import { validaCNPJ, validateCPF } from "helpers/validate";

export const createNewDebitCollectionSchema = Yup.object().shape({
	name: Yup.string().required("Nome é obrigatorio").min(4, "Deve conter no mínimo 4 caracteres"),
	documentNumber: Yup.string()
		.required("Documento é obrigatório")
		.test("Is An Valid CPF or CNPJ", "Documento inválido", (val) => {
			return validaCNPJ(val) || validateCPF(val);
		}),
	phone: Yup.string().required("Telefone é obrigatório"),
	email: Yup.string().email("Email invalido").optional(),
	cep: Yup.string().optional()
});
