import Dialog from "components/Modal/Dialog/Dialog.component";
import { CheckDataProps } from "../CreatePixKey.types";
import { useState } from "react";
import { ItemList, Typography } from "@azify/ui-web";

const Finish = (props: CheckDataProps) => {
	const { type } = props;
	const [openDialog, setOpenDialog] = useState(true);

	return (
		<Dialog
			openDialog={openDialog}
			setOpenDialog={setOpenDialog}
			headerTitle="Chave Pix em processo de portabilidade"
			layout="alternative"
			buttonGroupProps={{
				titlePrimary: "Fechar",
				isInverted: true,
				variant: "textPrimary",
				onPressPrimary: () => setOpenDialog(false)
			}}>
			<div style={{ padding: "0 16px 24px 16px" }}>
				<Typography
					value="Essa chave já está em processo de portabilidade e no momento ela não pode ser cadastrada."
					type="body1"
					marginBottom={16}
				/>

				<ItemList
					leadingContent="icon"
					leadingIconName="building"
					behavior={"display"}
					label="CNPJ"
					paragraph="43.394.203/0001-34"
					trailingSupportContent={"none"}
				/>
			</div>
		</Dialog>
	);
};

export default Finish;
