import {
	Avatar,
	GenericButton,
	HeaderNavigation,
	IconButton,
	ItemList,
	Typography
} from "@azify/ui-web";
import InputPin from "components/Input/PinField/PinField.component";
import Dialog from "components/Modal/Dialog/Dialog.component";
import TransactionalInput from "components/Input/TransactionalInput/TransactionalInput.component";
import { useStepManager } from "hooks/useStepManager";
import { useState } from "react";

function ShowCodeDetails() {
	const [openDialog, setOpenDialog] = useState(false);
	const { setCurrentStep } = useStepManager();

	const goToNextStep = () => {
		setCurrentStep("StatusPixCode");
	};

	const goToPreviousStep = () => {
		setCurrentStep("VerifyCode");
	};

	return (
		<>
			<div>
				<div className="display-between">
					<div>
						<HeaderNavigation
							buttonOnTitle={false}
							title="Revisar pagamento"
							quickButtonColor="accent"
							backIconClick={goToPreviousStep}
						/>
					</div>

					<div className="container-value-and-beneficiary">
						<Avatar
							onClick={() => setOpenDialog(true)}
							fullName="Master Tecnologia"
							type="initials"
							size="xlarge"
						/>

						<div className="container-input-edit">
							<div style={{ width: 40 }} />

							<TransactionalInput placeHolder="R$ 00,00" disable={false} />

							<IconButton color="primary" size="medium" icon="pencil" onClick={() => {}} />
						</div>

						<Typography
							textAlign="center"
							isBold
							type="subtitle1"
							value="Izabel Tabosa Tabanez"
							color="accent"
						/>
					</div>

					<ItemList
						label="Chave Pix Celular"
						supportParagraph="(77) 548392-9123"
						leadingContent="icon"
						isDividerVisible
						leadingIconName="pix"
						behavior="default"
						trailingSupportContent="none"
					/>

					<ItemList
						label="Transferindo com"
						supportParagraph="Conta BRL"
						leadingContent="icon"
						isDividerVisible
						leadingIconName="coin-dollar"
						behavior="default"
						trailingSupportContent="none"
					/>

					<ItemList
						label="Identificador"
						supportParagraph="u2DvEuDv7e2g7uTMMtVNr3cU2s"
						leadingContent="icon"
						isDividerVisible
						leadingIconName="search"
						behavior="default"
						trailingSupportContent="none"
					/>
				</div>

				<GenericButton onClick={goToNextStep} title="Enviar" layout="standard" color="primary" />
			</div>

			<Dialog
				openDialog={openDialog}
				setOpenDialog={setOpenDialog}
				headerTitle="Validação de segurança"
				layout="standard"
				buttonGroupProps={{
					titleSecondary: "Esqueceu seu PIN?",
					isInverted: true,
					variant: "textSecondary"
				}}>
				<InputPin type="password" size={4} />
			</Dialog>
		</>
	);
}
export default ShowCodeDetails;
