import { SVGProps } from "react";

const SideToSideArrowsSVG = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none" {...props}>
		<path
			fill={props.color || "#757893"}
			fillRule="evenodd"
			d="m8.5 3.586 4.707 4.707-1.414 1.414L9.5 7.414V18h-2V7.414L5.207 9.707 3.793 8.293 8.5 3.586Zm9 13 2.293-2.293 1.414 1.414-4.707 4.707-4.707-4.707 1.414-1.414 2.293 2.293V6h2v10.586Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default SideToSideArrowsSVG;
