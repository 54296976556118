import { Navigate, useLocation } from "react-router-dom";
import React, { memo } from "react";

interface PrivateRouter {
	children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouter> = ({ children }) => {
	const { pathname, state } = useLocation();

	const skipLogin = state?.skipLogin;

	const azifyId = localStorage.getItem("azifyId");
	const selectedOrganization = localStorage.getItem("selectedOrganization");

	const isOrganizationsPage = pathname.includes("/organizations");
	const isSignInPage = pathname.includes("/auth/sign-in");

	if (!azifyId && !skipLogin)
		return isSignInPage ? children : <Navigate to="/auth/sign-in" replace />;

	if (!selectedOrganization && !skipLogin)
		return isOrganizationsPage ? children : <Navigate to="/organizations" replace />;

	return children;
};

export default memo(PrivateRoute);
