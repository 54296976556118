import { SVGProps } from "react";

const PixSVG = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none" {...props}>
		<path
			fill="#272937"
			fillRule="evenodd"
			d="M6.044 2.775a.75.75 0 1 0 .015 1.5l3.162-.032-5.772 5.771a.75.75 0 1 0 1.06 1.061l7.072-7.071a.75.75 0 0 0-.538-1.28l-5 .051Zm5.066 10.592a.75.75 0 1 0-.015-1.5l-3.162.032 5.772-5.771a.75.75 0 0 0-1.061-1.06l-7.071 7.07a.75.75 0 0 0 .538 1.28l4.999-.051Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default PixSVG;
