import { colors } from "@azify/ui-tokens";
import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 204px;
	justify-content: center;
	align-items: center;
	gap: 16px;

	border-radius: 8px;
	border: 1px dashed ${colors.black.opacity["10p"]};
`;

export const HiddenInput = styled.input`
	display: none;
`;
