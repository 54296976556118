export const formatErrorMessage = (type: string) => {
	const ErrorConstants: Record<
		string,
		{
			title: string;
			description: string;
			action?: () => void;
		}
	> = {
		USER_ALREADY_HAVE_INITIATE_A_PROCESS: {
			title: "Processo já iniciado",
			description: "",
			action: () => {}
		}
	};

	return ErrorConstants[type];
};
