import { ReactNode, createContext, useContext } from "react";
import { createStore, useStore } from "zustand";
interface StepManagerProps {
	params: object;
	currentStep: string;
	setCurrentStep: (value: string) => void;
	setParams: (value: object) => void;
}

const createMyStore = (initialStep: string) =>
	createStore<StepManagerProps>((set) => ({
		params: {},
		currentStep: initialStep || "",
		setCurrentStep: (value) => set(() => ({ currentStep: value })),
		setParams: (value) => set(() => ({ params: { ...value } }))
	}));

const MyContext = createContext<ReturnType<typeof createMyStore> | null>(null);

export const StepManagerProvider = ({
	children,
	initialStep
}: {
	initialStep: string;
	children: ReactNode;
}) => {
	const store = createMyStore(initialStep);

	return <MyContext.Provider value={store}>{children}</MyContext.Provider>;
};

export const useStepManager = () => {
	const store = useContext(MyContext);

	if (store === null) {
		throw new Error("no provider");
	}
	return useStore(store);
};
