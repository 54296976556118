import { useStepManager } from "hooks/useStepManager";
import { useNavigate } from "react-router-dom";
import * as Styled from "../NewDebitCollection.styles";
import { ButtonGroup, HeaderNavigation, TextField } from "@azify/ui-web";
import { useFormContext } from "react-hook-form";
import { cellphoneMask, maskCpfOrCnpj } from "helpers/mask";

export const NewClient = () => {
	const { setCurrentStep, setParams } = useStepManager();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = useFormContext();

	const goToNextStep = () => {
		setParams({
			formDate: false
		});
		reset();
		setCurrentStep("ConfirmDataDebit");
	};

	const saveDetails = () => {
		setParams({
			formDate: true
		});
		setCurrentStep("ConfirmDataDebit");
	};

	return (
		<Styled.CenterContainer>
			<HeaderNavigation
				buttonOnTitle={false}
				title="Novo cliente"
				trailingActionsType="primaryPrimary"
				quickButtonColor="inverted"
				backIconClick={() => setCurrentStep("SelectClient")}
			/>

			<div
				className="display-between"
				style={{
					marginTop: 24
				}}>
				<div className="container-main-inputs">
					<TextField
						maxLength={50}
						label="Nome completo"
						placeholder="ex: João Vieira"
						hookForm={register("name")}
						hintText={errors.name?.message as string}
						hintVariant="danger"
					/>

					<TextField
						maxLength={50}
						label="CPF ou CNPJ"
						placeholder="Informe o CPF ou CNPJ"
						hookForm={register("documentNumber")}
						hintText={errors.documentNumber?.message as string}
						hintVariant="danger"
						maskFunction={maskCpfOrCnpj}
					/>

					<TextField
						maxLength={22}
						label="Telefone"
						placeholder="00 00000-0000"
						hookForm={register("phone")}
						hintText={errors.phone?.message as string}
						hintVariant="danger"
						maskFunction={cellphoneMask}
					/>

					<TextField
						maxLength={22}
						label="E-mail"
						placeholder="Opcional"
						hookForm={register("email")}
						hintText={errors.email?.message as string}
						hintVariant="danger"
					/>

					<TextField
						maxLength={22}
						label="Cep"
						placeholder="Opcional"
						hookForm={register("cep")}
						hintText={errors.cep?.message as string}
						hintVariant="danger"
					/>
				</div>

				<div style={{ paddingBottom: 20 }}>
					<ButtonGroup
						variant="default"
						isInverted
						titleSecondary="O cliente preenche"
						titlePrimary="Próximo"
						onPressPrimary={handleSubmit(saveDetails)}
						onPressSecondary={goToNextStep}
					/>
				</div>
			</div>
		</Styled.CenterContainer>
	);
};
