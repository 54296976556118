import { onlyNumbers } from "./string";

export function validaCNPJ(cnpj: string) {
	const cnpjRegex = /^[0-9]{14}$/;

	if (!cnpjRegex.test(onlyNumbers(cnpj))) {
		return false;
	}

	return true;
}

export function validateCPF(cpf: string) {
	let Soma = 0;
	let Resto;

	const strCPF = String(cpf).replace(/[^\d]/g, "");

	if (strCPF.length !== 11) return false;

	if (
		[
			"00000000000",
			"11111111111",
			"22222222222",
			"33333333333",
			"44444444444",
			"55555555555",
			"66666666666",
			"77777777777",
			"88888888888",
			"99999999999"
		].indexOf(strCPF) !== -1
	)
		return false;
	let i = 0;
	for (i = 1; i <= 9; i += 1) Soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);

	Resto = (Soma * 10) % 11;

	if (Resto === 10 || Resto === 11) Resto = 0;

	if (Resto !== parseInt(strCPF.substring(9, 10), 10)) return false;

	Soma = 0;

	for (i = 1; i <= 10; i += 1) Soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);

	Resto = (Soma * 10) % 11;

	if (Resto === 10 || Resto === 11) Resto = 0;

	if (Resto !== parseInt(strCPF.substring(10, 11), 10)) return false;

	return true;
}
