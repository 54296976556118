import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	padding: 8px 24px 12px 24px;

	.section-heading {
		padding: 11px 0;
	}
`;

export const Button = styled.button``;
