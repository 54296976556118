import { GenericButton } from "@azify/ui-web";
import LoaderStatus from "components/LoaderStatus/LoaderStatus.component";
import { useNavigate } from "react-router-dom";

export const StatusTransfer = () => {
	const navigate = useNavigate();

	return (
		<div className="display-between">
			<LoaderStatus
				title="Efetuando transferência..."
				subtitle="Aguarde enquanto enviamos o Pix para o seu cliente"
				status="success"
			/>

			<GenericButton
				onClick={() => navigate("/transfer")}
				title="Concluir"
				layout="standard"
				color="primary"
			/>
		</div>
	);
};
