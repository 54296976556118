import styled from "styled-components";

export const ToastCustom = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.title-subtitle {
		flex-direction: column;
		gap: 4px;
		align-items: flex-start;
	}
`;

export const IconInformationContainer = styled.div`
	display: flex;
	align-self: center;
	height: 100%;
`;

export const ButtonCloseToast = styled.div`
	display: flex;
	align-self: center;
	position: absolute;
	right: 16px;
`;
