import { GenericButton } from "@azify/ui-web";
import LoaderStatus from "components/LoaderStatus/LoaderStatus.component";
import { useStepManager } from "hooks/useStepManager";
import { useNavigate } from "react-router-dom";

function StatusPixCode() {
	const navigate = useNavigate();
	const { setCurrentStep } = useStepManager();

	const goToPreviousStep = () => {
		setCurrentStep("ShowCodeDetails");
	};

	return (
		<div className="display-between">
			<LoaderStatus
				title="Efetuando transferência..."
				subtitle="Aguarde enquanto enviamos o Pix para o seu cliente"
				status="success"
			/>

			<GenericButton
				onClick={() => navigate("/pixArea/")}
				title="Concluir"
				layout="standard"
				color="primary"
			/>
		</div>
	);
}
export default StatusPixCode;
