import styled from "styled-components";
import { colors } from "@azify/ui-tokens";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;

	.content {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		width: 1280px;

		.left-container {
			max-width: 50%;

			& .title {
				color: #1a1a1a;
				font-feature-settings: "ss02" on;
				font-family: "Aeonik-Medium", sans-serif;
				font-size: 3.125rem;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				margin-top: 19.5px;
			}

			& .subtitle {
				color: ${colors.black.main};
				font-family: "Helvetica Now Display", sans-serif;
				font-size: 0.956rem;
				font-style: normal;
				font-weight: 400;
				line-height: 175%;
			}

			.list-item {
				display: flex;
				flex-direction: row;
				align-items: center;

				margin: 7px 0;

				& p {
					margin-left: 12.5px;
				}
			}

			.button-container {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 10px;

				margin-top: 40px;
			}
		}

		.right-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
		}
	}
`;

export const CenterContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;

	width: 1280px;
`;
