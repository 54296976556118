import { colors } from "@azify/ui-tokens";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding-bottom: 24px;
	justify-content: center;
	align-items: center;
`;

export const CenterContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 600px;
	height: 100%;
	padding-top: 16px;

	.display-between {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;

		.center-items {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.grid-inputs {
				width: 100%;
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 16px;
				margin-top: 24px;
				margin-bottom: 24px;
			}

			.container-main-inputs {
				display: flex;
				flex-direction: column;
				width: 100%;
				gap: 24px;
			}
		}
	}

	.container-value-and-beneficiary {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 8px;
		align-items: center;
		margin-top: 24px;
		margin-bottom: 24px;
	}

	.container-search {
		margin-top: 24px;
		margin-bottom: 24px;
		display: flex;
		padding: 24px 16px;
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
		align-self: stretch;
		width: 100%;
		border-radius: 16px;
		border: 1px solid ${colors.black.opacity["10p"]};
	}
`;
