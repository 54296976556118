import { Typography } from "@azify/ui-web";
import * as Styles from "./SectionHeading.styles";
import { SectionHeadingProps } from "./SectionHeading.types";

import GenericButton from "components/Buttons/GenericButton";

const SectionHeading = ({ title, buttonText, onClick }: SectionHeadingProps) => {
	return (
		<Styles.Container>
			<div className="section-heading">
				<Typography type="h4" color="primary" isBold value={title} />
			</div>

			{buttonText && (
				<GenericButton
					color="default"
					onClick={onClick}
					textButton={buttonText}
					widthButton="30%"
				/>
			)}
		</Styles.Container>
	);
};

export default SectionHeading;
