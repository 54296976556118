import { create } from "zustand";
import { persist } from "zustand/middleware";
import { v4 } from "uuid";

const types = {
	increaseGateway: "INCREASE_GATEWAY",
	decreaseGateway: "DECREASE_GATEWAY",
	increasePendingCount: "INCREASE_PENDING_COUNT",
	increasePending: "INCREASE_PENDING"
};

interface MovementArgs {
	type: string;
	args: {
		date: string;
		client: string;
		status: "Recebido" | "Pendente" | "Cancelado" | "Expirado";
		method_desc: string;
		method: string;
		value: number;
		periodicity: string;
		hour: string;
		recurrence: string;
		recurrence_desc: string;
		phone: string;
	};
}

interface UseGatewayProps {
	gatewayBalance: number;
	gatewayRecValues: number;
	gatewayRecValuesCount: number;
	gatewayRecPending: number;
	gatewayRecPendingCount: number;
	gatewayRecCanceled: number;
	gatewayRecCanceledCount: number;
	gatewayRecExpired: number;
	gatewayRecExpiredCount: number;
	movements: {
		id: string;
		date: string;
		client: string;
		status: "Recebido" | "Pendente" | "Cancelado" | "Expirado";
		method_desc: string;
		method: string;
		value: number;
		periodicity: string;
		hour: string;
		recurrence: string;
		recurrence_desc: string;
		phone: string;
	}[];
	dispatch: (args: { type: string; by: number }) => void;
	dispatchGatewayMovements: (args: MovementArgs) => void;
}

const reducer = (
	state: Omit<UseGatewayProps, "dispatch">,
	{
		type,
		by
	}: {
		type: string;
		by: number;
	}
) => {
	switch (type) {
		case types.increaseGateway:
			return { gatewayBalance: state.gatewayBalance + by };
		case types.decreaseGateway:
			if (state.gatewayBalance <= 0) return { gatewayBalance: state.gatewayBalance };
			if (state.gatewayBalance - by < 0) return { mainBalance: 0 };
			return { gatewayBalance: state.gatewayBalance - by };
		case types.increasePendingCount:
			return { gatewayRecPendingCount: state.gatewayRecPendingCount + by };
		case types.increasePending:
			return { gatewayRecPending: state.gatewayRecPending + by };
		default:
			return state;
	}
};

const typesMovements = { createMovement: "CREATE_MOVEMENT" };

const reducerMovement = (
	state: Omit<UseGatewayProps, "dispatch">,
	{ type, args }: MovementArgs
) => {
	switch (type) {
		case typesMovements.createMovement:
			return {
				movements: [
					...state.movements,
					{
						id: v4(),
						date: args.date,
						client: args.client,
						status: args.status,
						method_desc: args.method_desc,
						method: args.method,
						value: args.value,
						periodicity: args.periodicity,
						hour: args.hour,
						recurrence: args.recurrence,
						recurrence_desc: args.recurrence_desc,
						phone: args.phone
					}
				]
			};
		default:
			return state;
	}
};

export const useGatewayActions = create(
	persist<UseGatewayProps>(
		(set) => ({
			gatewayBalance: 20860.78,
			gatewayRecValues: 20890.78,
			gatewayRecValuesCount: 1,
			gatewayRecPending: 1508.9,
			gatewayRecPendingCount: 2,
			gatewayRecCanceled: 1255,
			gatewayRecCanceledCount: 1,
			gatewayRecExpired: 35050.27,
			gatewayRecExpiredCount: 13,
			movements: [
				{
					id: v4(),
					date: "29 abr, 2024",
					client: "Azify LTDA",
					status: "Pendente",
					method: "PIX",
					method_desc: "Pagamento instantâneo",
					value: 20890.78,
					periodicity: "none",
					hour: "10:50",
					recurrence: "N/A",
					recurrence_desc: "N/A",
					phone: "(11) 96975-7533"
				}
			],
			dispatch: (args) => set((state) => reducer(state, args)),
			dispatchGatewayMovements: (args) => set((state) => reducerMovement(state, args))
		}),
		{
			name: "gateway-storage"
		}
	)
);
