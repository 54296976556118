import axios, { isAxiosError } from "axios";
import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import { handleErrors } from "./handleErrors/errorHandle";

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false
		}
	},
	queryCache: new QueryCache({
		onError: async (error) => {
			const errorTrace = error;

			if (isAxiosError(errorTrace) && errorTrace) {
				await handleErrors(errorTrace.response?.data);
			}
		}
	}),
	mutationCache: new MutationCache({
		onError: async (error) => {
			const errorTrace = error;

			if (isAxiosError(errorTrace) && errorTrace) {
				await handleErrors(errorTrace.response?.data);
			}
		}
	})
});

export const apiClient = axios.create({
	baseURL: "http://localhost:3001"
});
