import {
	GenericButton,
	Icon,
	IconButton,
	ItemList,
	ProgressBar,
	TextField,
	Typography
} from "@azify/ui-web";
import { useStepManager } from "hooks/useStepManager";
import { cameFromTheLeft } from "pages/Onboarding/Onboarding.styles";
import { useFormContext } from "react-hook-form";
import { cnpjMask } from "helpers/mask";
import { When } from "react-if";
import * as Styled from "../Kyb.styles";
import { useNavigate } from "react-router-dom";

function InputDocumentNumber() {
	const { register, watch } = useFormContext();
	const { setCurrentStep } = useStepManager();

	const navigate = useNavigate();

	const findCNPJ = watch("documentNumber");

	const goNext = () => setCurrentStep("InputBusinessData");

	return (
		<Styled.MainStepContainer>
			<Styled.ScrollStepContainer>
				<Styled.CenterContent>
					<div className="step-header">
						<div className="container-arrow-progress">
							<IconButton
								icon="back-arrow-lg"
								onClick={() => {
									navigate("/organizations");
								}}
								color="inverted"
								marginRight={32}
							/>

							<ProgressBar currentStep={1} currentStepProgress={15} totalSteps={3} height={2} />
						</div>
						<Typography value="Dados da empresa" type="h1" isBold marginBottom={16} />
					</div>

					<Styled.ContainerAnimation {...cameFromTheLeft}>
						<Typography
							value="Agora que nos conhecemos, precisamos de mais informações sobre o seu negócio para continuar o cadastro da sua conta PJ."
							type="body1"
							marginBottom={24}
						/>

						<div className="mb-40">
							<TextField
								label="CNPJ"
								maxLength={22}
								placeholder="00.000.000/0000-00"
								hookForm={register("documentNumber")}
								maskFunction={cnpjMask}
							/>
						</div>

						<When condition={findCNPJ}>
							<ItemList
								trailingSupportContent="none"
								behavior="default"
								eyebrow="Nome Empresarial"
								leadingContent="customized"
								leadingContentCustomized={
									<div className="rounded-icon-container">
										<Icon size="medium" name="building" color="accent" />
									</div>
								}
								label="CriptoSafe Brasil Serviços de Custódia S.A"
							/>
						</When>
					</Styled.ContainerAnimation>
				</Styled.CenterContent>
			</Styled.ScrollStepContainer>

			<div className="step-footer">
				<div className="button-container">
					<GenericButton
						isDisabled={!findCNPJ}
						onClick={goNext}
						title={findCNPJ ? "Continuar" : "Buscar"}
						layout="standard"
						color="primary"
					/>
				</div>
			</div>
		</Styled.MainStepContainer>
	);
}

export default InputDocumentNumber;
