import * as Styled from "./ForgotPassword.styles";

function ForgotPassword() {
	return (
		<Styled.Container>
			<div>ForgotPassword.screen</div>
		</Styled.Container>
	);
}

export default ForgotPassword;
