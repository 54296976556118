import { colors } from "@azify/ui-tokens";
import * as Styled from "./Home.styles";
import ReactApexChart from "react-apexcharts";
import { Avatar, Icon, QuickButton, Typography } from "@azify/ui-web";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { ToastAlert } from "components/Toast/Toast.component";
import GenericButton from "components/Buttons/GenericButton";

import SendSVG from "assets/svg/send";
import CashSVG from "assets/svg/Cash";
import ArrowDownCompletedSVG from "assets/svg/arrowDownCompleted";
import PixSVG from "assets/svg/pix";
import ArrowDownSVG from "assets/svg/arrowDown";
import ArrowDownFilledSVG from "assets/svg/arrowDownFilled";
import SectionHeading from "components/SectionHeading/SectionHeading.component";
import ItemList from "components/ItemList/ItemList.component";
import Tile from "components/Tile/Tile.component";
import { formatCurrencyFn, kFormatter } from "helpers/currency";
import "dayjs/locale/pt-br";
import { useMainAccountActions } from "hooks/useMainAccountActions";

export const DashBoardPage = () => {
	const navigate = useNavigate();

	const contactsName = [
		"Joao da Silva",
		"Maria da Silva",
		"Joao da Silva",
		"Maria da Silva",
		"Joao da Silva",
		"Maria da Silva",
		"Joao da Silva",
		"Maria da Silva",
		"Joao da Silva",
		"Maria da Silva"
	];

	const series = [
		{
			name: "Saldo",
			data: [15240.33, 30630, 15090, 9000, 6080, 2500, 0]
		}
	];

	const options: ApexCharts.ApexOptions | undefined = {
		chart: {
			type: "area",
			parentHeightOffset: 0,
			zoom: {
				enabled: false
			},
			toolbar: {
				show: false
			}
		},

		grid: {
			show: false,
			padding: { right: -10 }
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			curve: "smooth"
		},
		markers: {
			colors: [colors.primary.main]
		},
		xaxis: {
			type: "datetime",
			categories: [
				"2024-04-24T00:00:00.000Z",
				"2024-04-23T00:00:00.000Z",
				"2024-04-22T00:00:00.000Z",
				"2024-04-21T00:00:00.000Z",
				"2024-04-20T00:00:00.000Z",
				"2024-04-19T00:00:00.000Z",
				"2024-04-18T00:00:00.000Z"
			],
			labels: {
				formatter: function (value) {
					return dayjs(value).locale("pt-br").format("DD MMM");
				},
				style: {
					colors: colors.gray.dark,
					fontSize: "13px",
					fontWeight: "400"
				}
			},
			axisBorder: {
				show: false
			},
			axisTicks: {
				show: false
			},
			tooltip: {
				enabled: false
			}
		},
		yaxis: {
			labels: {
				formatter: function (value) {
					return `R$ ${kFormatter(value)}`;
				},
				style: {
					colors: colors.gray.dark,
					fontSize: "13px",
					fontWeight: "400"
				}
			}
		},
		fill: {
			type: "gradient",
			gradient: {
				shadeIntensity: 1,
				opacityFrom: 0.3,
				opacityTo: 0.9,
				stops: [0, 90, 100]
			}
		},
		colors: [colors.primary.main],
		tooltip: {
			custom: function ({ series, seriesIndex, dataPointIndex }) {
				const value = series[seriesIndex][dataPointIndex];

				return (
					"<div class='custom-tooltip'>" +
					"<span>" +
					formatCurrencyFn("BRL", value) +
					"</span>" +
					"</div>"
				);
			}
		}
	};

	const tryToast = () => {
		ToastAlert({
			title: "Label",
			subtitle: "Paragraph",
			buttonLabel: "Ver",
			buttonAction: () => {
				console.log("teste");
			}
		});
	};

	const { mainBalance } = useMainAccountActions();

	return (
		<Styled.Container>
			<div className="quick-actions-container">
				<GenericButton
					textButton="Enviar"
					color="primary"
					widthButton="107px"
					customTextColor="inverted"
					leftIcon={<SendSVG />}
					onClick={() => navigate("/transfer")}
				/>

				<GenericButton
					textButton="Receber"
					color="default"
					widthButton="123px"
					leftIcon={<ArrowDownCompletedSVG />}
					onClick={() => {}}
				/>

				<GenericButton
					textButton="Cobrar"
					color="default"
					widthButton="113px"
					leftIcon={<CashSVG />}
					onClick={() => {}}
				/>

				<GenericButton
					textButton="Área Pix"
					color="default"
					widthButton="122px"
					leftIcon={<PixSVG />}
					onClick={() => navigate("/pixArea")}
				/>
			</div>

			<div className="container-cards">
				<div className="first-column">
					<div className="first-item">
						<div className="tittle-between custom-padding">
							<div className="row-gap-balance">
								<div>
									<Typography
										isBold
										color="primary"
										type="h1"
										value={formatCurrencyFn("BRL", String(mainBalance))}
									/>

									<Typography
										marginTop={4}
										color="primary"
										type="subtitle1"
										value="Patrimônio total"
									/>
								</div>
								<div className="row-price-indicator">
									<div className="price-variation-indicator">
										<ArrowDownFilledSVG />
									</div>
									<Typography
										isBold
										colorCustom={colors.gray.dark}
										color="primary"
										type="subtitle2"
										value="+ R$ 312,00 Hoje, 24 abr"
									/>
								</div>
							</div>
							<div>
								<QuickButton color="primary" label="Mês" trailingIcon="chevron-down-small" />
							</div>
						</div>

						<div className="chart-container" id="chart">
							<ReactApexChart options={options} series={series} type="area" height={280} />
						</div>
					</div>

					<div className="second-item">
						<div className="tittle-between">
							<SectionHeading title="Atividade" buttonText="Ver todas" onClick={tryToast} />
						</div>

						<ItemList
							icon={<Icon name="cash-out-01" size="medium" />}
							title="Marina da Silva Soudre"
							subtitle="25 jan . Pix"
							value="R$ 5000,18"
						/>

						<ItemList
							icon={<Icon name="cash-out-01" size="medium" />}
							title="Ana Clara Crispin"
							subtitle="19 dez . Ted"
							value="R$ 1228,18"
						/>

						<ItemList
							icon={<Icon name="cash-out-01" size="medium" />}
							title="Amanda Tomazelli"
							subtitle="15 out . Pix"
							value="R$ 789,00"
						/>

						<ItemList
							icon={<Icon name="cash-out-01" size="medium" />}
							title="Nayla Carreira Medeiros"
							subtitle="08 out . Pix"
							value="R$ 1500,00"
						/>
					</div>
				</div>

				<div className="container-wallets">
					<Tile
						title="Conta Real"
						subtitle="R$ 14.240,33"
						icon={<Avatar size="medium" type="currency" currency="BRL" />}
					/>

					<Tile
						title="Bitcoin"
						subtitle="BTC 0.10648281"
						icon={<Avatar size="medium" type="currency" currency="BTC" />}
					/>

					<Tile
						title="Ethereum"
						subtitle="ETH 2.008462"
						icon={<Avatar size="medium" type="currency" currency="ETH" />}
					/>

					<Tile
						title="Axie Infinity"
						subtitle="AXS 15.056985"
						icon={<Avatar size="medium" type="currency" currency="AXS" />}
					/>

					<Tile
						title="USDT"
						subtitle="USDT 0.80649781"
						icon={<Avatar size="medium" type="currency" currency="USDT" />}
					/>

					<Tile subtitle="Adicionar" icon={<Icon name="add" size="medium" />} />
				</div>
			</div>
		</Styled.Container>
	);
};
