import styled from "styled-components";
import { colors } from "@azify/ui-tokens";
import { ChipProps } from "./Chip.types";

export const arrayColors: Record<ChipProps["backgroundColor"], string> = {
	primary: colors.primary.dark,
	warning: colors.warning.dark,
	danger: colors.danger.dark,
	success: colors.success.dark
};

export const Container = styled.div<Omit<ChipProps, "text">>`
	display: flex;
	align-items: center;
	justify-content: center;

	width: ${(props) => props.width || "auto"};
	padding: 2px 8px;
	background-color: ${(props) => arrayColors[props.backgroundColor]};
	border-radius: 50px;
`;
