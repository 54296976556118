import { GenericButton, QuickButton, Typography } from "@azify/ui-web";
import * as S from "../CreatePixKey.styles";
import { colors } from "@azify/ui-tokens";
import { When } from "react-if";
import { CheckDataProps, CreatePixKeySteps } from "../CreatePixKey.types";
import Finish from "./3.Finish.component";
import { useState } from "react";

const titleText = {
	phone: "+55 75 99103 5234",
	email: "joao@azify.com",
	cnpj: "43.394.203/0001-34",
	random: "Chave aleatória"
};

const genericButtonText = {
	phone: "Usar outro número",
	email: "Usar outro e-mail",
	cnpj: "",
	random: ""
};

const CheckData = (props: CheckDataProps) => {
	const { type, setCurrentStep } = props;
	const [openDialog, setOpenDialog] = useState(false);

	const handleNextStep = (value: CreatePixKeySteps) => {
		if (type === "cnpj" || type === "random") {
			setOpenDialog(true);
			return;
		}

		setCurrentStep && setCurrentStep(value);
	};

	return (
		<S.Content type={type}>
			<div className="header">
				<div className="value-container">
					<Typography value={titleText[type]} type="h1" isBold />
				</div>

				<When condition={type === "phone" || type === "email"}>
					<div className="generic-button-container">
						<QuickButton
							label={genericButtonText[type]}
							color="primary"
							onClick={() => handleNextStep("OtherKeyType")}
						/>
					</div>
				</When>
			</div>

			<div className="footer">
				<div className="info-container">
					<Typography
						value="Informações visíveis"
						type="subtitle2"
						isBold
						textAlign="center"
						marginBottom={8}
					/>
					<Typography
						value="Master Tecnologia e Serviços LTDA"
						type="body2"
						colorCustom={colors.gray.dark}
						textAlign="center"
					/>
					<Typography
						value="***.394.203/0001-**"
						type="body2"
						colorCustom={colors.gray.dark}
						textAlign="center"
					/>
				</div>

				<GenericButton
					title="Cadastrar chave"
					color="primary"
					layout="standard"
					onClick={() => handleNextStep("ConfirmCode")}
				/>
			</div>

			<When condition={!!openDialog}>
				<Finish type={type} />
			</When>
		</S.Content>
	);
};

export default CheckData;
