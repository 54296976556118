import * as S from "./AttachPersonalDocuments.styles";
import { Banner, GenericButton, Tile, Typography } from "@azify/ui-web";
import { AttachPersonalDocumentsProps } from "./AttachPersonalDocuments.types";
import { When } from "react-if";
import { nameCapitalize } from "@azify/ui-helpers";
import { ToastAlert } from "components/Toast/Toast.component";

function AttachPersonalDocuments({
	partner,
	errorText,
	revokeAlert
}: AttachPersonalDocumentsProps) {
	const partnerName = nameCapitalize(partner?.name || "");

	const update = () => {
		if (revokeAlert) {
			ToastAlert({
				title: `Você revogou o acesso de ${partnerName}.`,
				subtitle: "Agora ele não terá acesso.",
				autoCloseEnable: true,
				type: "info"
			});
		}
	};

	return (
		<S.Container>
			<div>
				<Typography value={partnerName} type="h1" isBold marginBottom={20} />

				<Typography
					value="Anexe uma foto do documento de identificação do sócio. Atual e legível."
					type="body1"
					marginBottom={16}
				/>

				<When condition={!!errorText}>
					<Banner
						behavior="default"
						hierarchy="low"
						layout="inset"
						color="danger"
						artwork="icon"
						iconName="alert-triangle-filled"
						title={errorText}
					/>
				</When>

				<div className="container-docs">
					<Tile
						size="large"
						eyebrow="Frente do documento"
						label="CNH, RG ou RNM"
						trailingContent="quickButton"
						leadingContent="icon"
						leadingAvatarSize="medium"
						leadingAvatarType="currency"
						backgroundColor="primary"
						leadingIconName="id-front"
						trailingQuickButtonLabel="Anexar"
						trailingQuickButtonOnPress={() => {}}
					/>

					<Tile
						size="large"
						eyebrow="Verso do documento"
						label="CNH, RG ou RNM"
						trailingContent="quickButton"
						leadingContent="icon"
						leadingAvatarSize="medium"
						leadingAvatarType="currency"
						backgroundColor="primary"
						leadingIconName="id-back"
						trailingQuickButtonLabel="Remover"
						trailingQuickButtonOnPress={() => {}}
					/>
				</div>
			</div>

			<div className="footer">
				<GenericButton
					onClick={update}
					title="Atualizar informações"
					layout="standard"
					color="secondary"
				/>
			</div>
		</S.Container>
	);
}

export default AttachPersonalDocuments;
