import { colors } from "@azify/ui-tokens";
import styled from "styled-components";
import { motion } from "framer-motion";

export const ContainerAnimation = styled(motion.div)`
	padding: 16px;
	padding-top: 130px;
	padding-bottom: 20px;
`;

export const MainStepContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	.step-footer {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding-top: 8px;
		padding-bottom: 24px;

		.button-container {
			width: 568px;
		}
	}
`;

export const ScrollStepContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
`;

export const CenterContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 600px;
	height: 100%;

	.step-header {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: fixed;
		backdrop-filter: blur(10px);
		background-color: ${colors.transparent};
		width: 600px;
		gap: 10px;
		z-index: 1;

		padding: 24px 12px 0 12px;

		.container-arrow-progress {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}
	}

	.fixed-group-button {
		height: 150px;
	}

	.container-name-validation {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
`;
