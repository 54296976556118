import { Banner, GenericButton, ItemList, TextField, Tile, Typography } from "@azify/ui-web";
import { Container } from "./UpdateManagerDetails.styles";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateMangerSchema } from "pages/Onboarding/Kyb/Validations/KybValidations";
import { Partner } from "pages/Onboarding/Kyb/Kyb.types";
import { UpdateManagerDetailsProps } from "./UpdateManagerDetails.types";
import { When } from "react-if";
import { percentageMask } from "helpers/mask";

function UpdateManagerDetails({
	personalPartnersMethods,
	bannerErrorMsg,
	closeModal,
	action
}: UpdateManagerDetailsProps) {
	const personalPartnersList = personalPartnersMethods.fields as Partner[];

	const defaultValues = personalPartnersList[action.index];

	const {
		handleSubmit,
		control,
		formState: { errors },
		watch,
		setValue
	} = useForm({
		shouldUnregister: true,
		resolver: yupResolver(updateMangerSchema),
		defaultValues: { ...defaultValues }
	});

	const makeUpdate = (data: Omit<Partner, "uniqueId">) => {
		personalPartnersMethods.update(action.index, {
			...data
		});

		closeModal();
	};

	return (
		<Container>
			<div>
				<Typography marginBottom={16} isBold type="h1" value="Editar suas informações" />

				<When condition={bannerErrorMsg}>
					<Banner
						hierarchy="low"
						layout="inset"
						behavior="default"
						description={bannerErrorMsg}
						color="danger"
						artwork="icon"
						iconName="alert-triangle-filled"
					/>
				</When>

				<div className="container-inputs">
					<div className="container-name-doc">
						<ItemList
							leadingContent="avatar"
							leadingAvatarFullName={defaultValues.name}
							label={defaultValues.name}
							paragraph={`Sócio - ${defaultValues.documentNumber}`}
							behavior="default"
							trailingSupportContent="none"
						/>
					</div>

					<Typography isBold type="h4" value="Qual sua função na empresa?" />

					<Controller
						control={control}
						name="function"
						render={() => (
							<div className="function-container">
								<Tile
									label="Sou sócio"
									size="medium"
									onClick={() => setValue("function", "socio")}
									leadingContent="icon"
									leadingIconName="user-rounded"
									backgroundColor="white"
									leadingIconColor="accent"
									trailingContent="customized"
									isSelected={watch("function") === "socio"}
								/>

								<Tile
									label="Sou procurador"
									size="medium"
									onClick={() => setValue("function", "procurador")}
									leadingContent="icon"
									leadingIconName="file-text"
									backgroundColor="white"
									leadingIconColor="accent"
									trailingContent="customized"
									isSelected={watch("function") === "procurador"}
								/>
							</div>
						)}
					/>

					<Controller
						control={control}
						name="partnerPercentage"
						render={({ field }) => (
							<TextField
								maxLength={22}
								label="Percentual de participação"
								placeholder="0,00"
								hookForm={field}
								hintText={errors.partnerPercentage?.message}
								hintVariant="danger"
								maskFunction={percentageMask}
								leadingLabel="%"
							/>
						)}
					/>
				</div>
			</div>

			<Typography marginTop={36} marginBottom={23} isBold type="h4" value="Outras informações" />

			<Controller
				control={control}
				name="politicallyExposedPerson"
				render={() => (
					<ItemList
						trailingSupportContent="customized"
						trailingContent="toggle"
						label="Você é uma Pessoa Politicamente Exposta?"
						behavior="default"
						trailingToggleValue={watch("politicallyExposedPerson")}
						trailingToggleOnPress={() => {
							setValue("politicallyExposedPerson", !watch("politicallyExposedPerson"));
						}}
					/>
				)}
			/>

			<div className="footer-button">
				<GenericButton
					color="secondary"
					title="Atualizar informações"
					layout="standard"
					onClick={handleSubmit(
						(data) => makeUpdate({ ...defaultValues, ...data }),
						(e) => {
							console.log(e);
						}
					)}
				/>
			</div>
		</Container>
	);
}

export default UpdateManagerDetails;
