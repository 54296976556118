import { ItemList, Typography } from "@azify/ui-web";
import InformationalErros from "common/modals/sideRight/InformationalErros/InformationalErros.component";
import { Chip } from "components/Chip";
import ModalSideBar from "components/ModalSideBar";

import { useStepManager } from "hooks/useStepManager";
import { cameFromTheLeft } from "pages/Onboarding/Onboarding.styles";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Styled from "../Kyb.styles";

function Status() {
	const { setCurrentStep } = useStepManager();
	const [openErrorDetails, setOpenErrorDetails] = useState(false);

	const { control } = useFormContext();

	const { t } = useTranslation();

	return (
		<>
			<Styled.MainStepContainer>
				<Styled.ScrollStepContainer>
					<Styled.CenterContent>
						<Styled.ContainerAnimation paddingTop="28" {...cameFromTheLeft}>
							<Typography
								value="Quase lá! Estamos abrindo sua conta..."
								type="h1"
								isBold
								marginBottom={16}
							/>

							<Typography
								value="Este é o resumo da criação da sua conta, onde você pode acompanhar todo o progresso. Nos vemos em breve."
								type="body1"
								marginBottom={16}
							/>

							<div className="pb-10">
								<ItemList
									behavior="display"
									label="Dados da empresa"
									leadingIconName="building"
									leadingContent="icon"
									leadingIconColor="accent"
									trailingContent="customized"
									trailingSupportContent="customized"
									onClick={() => setCurrentStep("InputBusinessDocumentNumber")}
									isDividerVisible
									paragraph="Etapa concluída"
								/>
							</div>

							<div className="pt-10 pb-10">
								<ItemList
									behavior="display"
									label="Sócios e Representantes"
									leadingIconName="user"
									leadingContent="icon"
									trailingContent="customized"
									leadingIconColor="accent"
									trailingSupportContent="customized"
									isDividerVisible
									onClick={() => setCurrentStep("InputAndListPartners")}
									trailingSupportContentCustomized={
										<Chip text="Em análise" backgroundColor="warning" width="90px" />
									}
								/>
							</div>

							<div className="pt-10 pb-10">
								<ItemList
									behavior="display"
									label="Envio de documentos"
									leadingIconName="file-multiple-text"
									leadingContent="icon"
									leadingIconColor="accent"
									trailingContent="icon"
									trailingIconName="chevron-right-small"
									trailingSupportContent="customized"
									isDividerVisible
									onClick={() => setCurrentStep("InputDocuments")}
									trailingSupportContentCustomized={
										<Chip text="Erro encontrado" backgroundColor="danger" width="128px" />
									}
								/>
							</div>

							<div className="pt-10 pb-20">
								<ItemList
									behavior="display"
									label="Validação da Biometria"
									leadingIconName="face-id"
									leadingIconColor="accent"
									leadingContent="icon"
									trailingContent="customized"
									trailingSupportContent="customized"
									isDividerVisible
									onClick={() => setCurrentStep("WaitingBiometry")}
									trailingSupportContentCustomized={
										<Chip text="Confirmado" backgroundColor="success" />
									}
								/>
							</div>

							<div className="pt-10 pb-20">
								<ItemList
									behavior="display"
									label="Informações Adicionais"
									leadingIconName="file-multiple-text"
									leadingIconColor="accent"
									leadingContent="icon"
									trailingContent="badge"
									trailingSupportContent="customized"
									onClick={() => setOpenErrorDetails(true)}
									trailingBadgeTitle="2"
									trailingBadgeColor="secondary"
									trailingBadgeLayout="label"
								/>
							</div>
						</Styled.ContainerAnimation>
					</Styled.CenterContent>
				</Styled.ScrollStepContainer>
			</Styled.MainStepContainer>

			<ModalSideBar onClose={() => setOpenErrorDetails(false)} open={openErrorDetails}>
				<InformationalErros />
			</ModalSideBar>
		</>
	);
}

export default Status;
