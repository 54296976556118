import { mask } from "remask";
import { validateCPF } from "./validate";

export const cellphoneMask = (value: string) => {
	if (!value) return "";

	const v = String(value);

	const maskNow = v.replace(/\D/g, "").length > 10 ? "(99) 9 9999-9999" : "(99) 9999-99999";

	return mask(value, maskNow);
};

export const rgMask = (value: string) => {
	if (!value) return "";

	const v = String(value);
	return mask(v, "9.999.999");
};

export const cnpjMask = (value: string) => {
	if (!value) return "";

	const v = String(value);
	return mask(v, "99.999.999/9999-99");
};

export const maskCpfOrCnpj = (value: string) => {
	if (!value) return "";

	const v = String(value);

	if (validateCPF(v)) {
		return mask(v, "999.999.999-99");
	} else {
		return mask(v, "99.999.999/9999-99");
	}
};

export const dateMask = (value: string) => {
	if (!value) return "";

	const v = String(value);
	return mask(v, "99/99/9999");
};

export const cnaeMask = (value: string) => {
	if (!value) return "";

	const v = String(value);
	return mask(v, "9999-9/99");
};

export const cepMask = (value: string) => {
	if (!value) return "";

	const v = String(value);
	return mask(v, "99.999-999");
};

export const cpfMask = (value: string) => {
	if (!value) return "";

	const v = String(value);
	return mask(v, "999.999.999-99");
};

export const percentageMask = (value: string) => {
	if (!value) return "";

	const v = String(value);
	if (v[0] === "1" && v[1] === "0" && v[2] === "0" && v !== "100,0") {
		return mask(v, "100,00");
	}
	return mask(v, "99,99");
};

export const formatCodeBar = (barCode: string) => {
	if (!barCode) return "";

	const codeBarPattern = "99999.99999 99999.999999 99999.999999 9 99999999999999";
	return mask(barCode, codeBarPattern);
};
