import React, { useMemo } from "react";
import { ButtonGroup, Tile, Typography } from "@azify/ui-web";
import { colors } from "@azify/ui-tokens";
import { useOpenModalSideBar } from "hooks/useOpenSideBar";
import * as S from "../PaymentMethod.styles";
import { formatCurrencyFn } from "helpers/currency";

export default function CryptoPayment({ values }: { values?: string }) {
	const { setCurrentStep } = useOpenModalSideBar();

	const handleSaveChanges = () => {
		if (setCurrentStep) {
			setCurrentStep("Start");
		}
	};

	const [selectedCryptos, setSelectedCryptos] = React.useState<string[]>([]);

	const setCriptoList = (cripto: string) => {
		if (selectedCryptos.includes(cripto)) {
			setSelectedCryptos(selectedCryptos.filter((item) => item !== cripto));
		} else {
			setSelectedCryptos([...selectedCryptos, cripto]);
		}
	};

	const valuesConvert = values ? parseFloat(values.replace(",", ".")) : 0;

	const tax = useMemo(() => {
		if (valuesConvert <= 1000) {
			return 2.99;
		} else if (valuesConvert > 1000 && valuesConvert <= 5000) {
			return 10.66;
		} else if (valuesConvert > 5000 && valuesConvert <= 10000) {
			return 15.5;
		} else {
			return 2.99;
		}
	}, [valuesConvert]);

	return (
		<>
			<div className="container-inputs">
				<Typography
					value="Quais criptomoedas serão permitidas?"
					type="h1"
					isBold
					marginBottom={10}
				/>

				<div className="tile-container">
					<Tile
						size="medium"
						backgroundColor=""
						label="Bitcoin"
						leadingContent="avatar"
						leadingAvatarType="currency"
						leadingAvatarCurrency="BTC"
						trailingContent="customized"
						isDisabled={selectedCryptos.includes("BTC")}
						onClick={() => setCriptoList("BTC")}
					/>

					<Tile
						size="medium"
						backgroundColor=""
						label="Tether"
						leadingContent="avatar"
						leadingAvatarType="currency"
						leadingAvatarCurrency="USDT"
						trailingContent="customized"
						isDisabled={selectedCryptos.includes("USDT")}
						onClick={() => setCriptoList("USDT")}
					/>

					<Tile
						size="medium"
						backgroundColor=""
						label="USD Coin"
						leadingContent="avatar"
						leadingAvatarType="currency"
						leadingAvatarCurrency="USDC"
						trailingContent="customized"
						isDisabled={selectedCryptos.includes("USDC")}
						onClick={() => setCriptoList("USDC")}
					/>
				</div>

				<S.CardContainer>
					<div className="card-item">
						<Typography value="Seu cliente paga" type="body3" color="secondary" />
						<div className="text-right">
							<Typography value={formatCurrencyFn("BRL", String(valuesConvert))} type="body3" />
						</div>
					</div>

					<div className="card-item">
						<Typography value="Taxa da venda" type="body3" color="secondary" />
						<div className="text-right">
							<Typography value={`- ${formatCurrencyFn("BRL", String(tax))}`} type="body3" />
						</div>
					</div>

					<div className="card-item">
						<Typography value="Você recebe" type="body3" color="secondary" />
						<div className="text-right">
							<Typography
								value={formatCurrencyFn("BRL", String(valuesConvert - tax))}
								type="body3"
							/>
						</div>
					</div>
				</S.CardContainer>
			</div>

			<div className="footer">
				<ButtonGroup
					variant="default"
					isInverted
					customColorBackgroundPrimaryButton={colors.black.main}
					customColorTextTransparentButton={colors.black.main}
					titleSecondary="Não permitir cripto"
					titlePrimary="Alterar"
					onPressPrimary={() => {
						handleSaveChanges();
					}}
					onPressSecondary={() => {
						handleSaveChanges();
						setSelectedCryptos([]);
					}}
				/>
			</div>
		</>
	);
}
