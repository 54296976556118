import { Typography } from "@azify/ui-web";
import * as Styles from "./GenericButton.styles";
import { GenericButtonProps } from "./GenericButton.types";

const GenericButton = (props: GenericButtonProps) => {
	const {
		textType,
		textBold,
		onClick,
		isDisabled,
		textButton,
		customTextColor,
		leftIcon,
		rightIcon
	} = props;

	return (
		<Styles.Container disabled={isDisabled} onClick={onClick} {...props}>
			{leftIcon && <div className="icon-container left">{leftIcon}</div>}

			<Typography
				type={textType || "body2"}
				color={customTextColor || "primary"}
				value={textButton}
				isBold={!!textBold}
			/>

			{rightIcon && <div className="icon-container right">{rightIcon}</div>}
		</Styles.Container>
	);
};

export default GenericButton;
