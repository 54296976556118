import { Else, If, Then } from "react-if";
import Kyc from "./Kyc/Kyc.main";
import Kyb from "./Kyb/Kyb.main";
import { AnimatePresence } from "framer-motion";

function Onboarding() {
	return (
		<AnimatePresence mode="wait" initial={false}>
			<If condition={true}>
				<Then>
					<Kyc />
					<Kyb />
				</Then>
				<Else>
					<p>Loading...</p>
				</Else>
			</If>
		</AnimatePresence>
	);
}

export default Onboarding;
