import { useState } from "react";
import { ButtonGroup, TextField, Typography } from "@azify/ui-web";
import * as Styled from "./SignIn.styles";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
	azifyId: Yup.string().required("Por favor, insira seu Azify ID"),
	password: Yup.number()
		.typeError("Deve conter apenas números")
		.required("Por favor insira sua senha")
		.min(6, "Deve conter 6 dígitos numéricos")
});

export const SignInPage = () => {
	const navigate = useNavigate();

	const [showPassword, setShowPassword] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [formData, setFormData] = useState({
		azifyId: "",
		password: ""
	});
	const [errors, setErrors] = useState({
		azifyId: "",
		password: ""
	});

	const handleSubmit = () => {
		setIsLoading(true);

		validationSchema
			.validate(formData, { abortEarly: false })
			.then(() => {
				setTimeout(() => {
					localStorage.setItem("azifyId", formData.azifyId);
					setIsLoading(false);

					navigate("/organizations");
				}, 1000);
			})
			.catch((error) => {
				const newError: any = {};

				error.inner.forEach((err: any) => {
					newError[err.path] = err.message;
				});

				setErrors(newError);
				setIsLoading(false);
			});
	};

	return (
		<Styled.Container>
			<Styled.CenterContent>
				<Typography value="Acessar minha conta" type="h1" isBold marginBottom={20} />

				<div className="row mb-40">
					<Typography value="Insira o seu Azify ID para fazer login." type="body1" />

					<div className="text-link" onClick={() => navigate("/auth/forgot-id")}>
						<Typography value="Esqueceu seu Azify ID?" type="body1" color="accent" />
					</div>
				</div>

				<div className="input-group">
					<TextField
						label="Azify ID"
						value={formData.azifyId}
						onChange={(e) => setFormData({ ...formData, azifyId: e })}
						leadingArtworkIcon="user-rounded"
						leadingArtworkIconColor="accent"
						placeholder=""
						maxLength={50}
						increaseBorderOnClick
						hintVariant="danger"
						hintText={errors?.azifyId}
						onClear={() => setFormData({ ...formData, azifyId: "" })}
					/>

					<TextField
						label="Senha"
						type="password"
						value={formData.password}
						onChange={(e) => setFormData({ ...formData, password: e })}
						placeholder="6 dígitos numéricos"
						maxLength={6}
						hintVariant="danger"
						increaseBorderOnClick
						hintText={errors?.password}
						onChangeSecureTextEntry={() => setShowPassword(!showPassword)}
						secureTextEntry={showPassword}
						onClear={() => setFormData({ ...formData, password: "" })}
					/>
				</div>

				<a href="/auth/forgot-password">
					<Typography
						marginTop={24}
						value="Esqueceu sua senha?"
						color="accent"
						type="body1"
						marginBottom={20}
					/>
				</a>

				<div className="fixed-group-button">
					<ButtonGroup
						fontBold
						variant="default"
						titleSecondary="Abrir minha conta"
						titlePrimary="Fazer login"
						isInverted
						onPressSecondary={() =>
							navigate("/onboarding", {
								state: {
									skipLogin: true
								}
							})
						}
						onPressPrimary={handleSubmit}
						isLoadingPrimary={isLoading}
					/>
				</div>
			</Styled.CenterContent>
		</Styled.Container>
	);
};
