import "./styles/fonts.css";
import GlobalStyles from "./styles/global";
import { ErrorBoundary } from "react-error-boundary";
import RouterRoot from "./routes";
import { ToastContainer } from "react-toastify";
import AppErrorFallBackScreen from "pages/AppErrorFallBack/AppErrorFallBack.screen";
import "react-toastify/dist/ReactToastify.css";
import "./i18n";
import { queryClient } from "api";
import { QueryClientProvider } from "@tanstack/react-query";
import ModalSideBar from "components/ModalSideBar";
import { useOpenModalSideBar } from "hooks/useOpenSideBar";
import { modalContent } from "common/modals/sideRight";

function App() {
	const { setOpenModalSideBar, openModalSideBar, content } = useOpenModalSideBar();

	const onCloseModal = () => setOpenModalSideBar(false);

	return (
		<QueryClientProvider client={queryClient}>
			<ErrorBoundary fallback={<AppErrorFallBackScreen />}>
				<ToastContainer
					className="custom-toast"
					position="bottom-left"
					newestOnTop
					closeOnClick
					rtl={false}
					draggable
					pauseOnHover
				/>

				<GlobalStyles />
				<RouterRoot />

				{/* <ReactQueryDevtools  initialIsOpen={false} /> */}

				<ModalSideBar open={openModalSideBar} onClose={onCloseModal}>
					{modalContent[content]}
				</ModalSideBar>
			</ErrorBoundary>
		</QueryClientProvider>
	);
}

export default App;
