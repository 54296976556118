import { Banner, ButtonGroup, IconButton, ItemList, ProgressBar, Typography } from "@azify/ui-web";
import { useStepManager } from "hooks/useStepManager";
import { useFieldArray, useFormContext } from "react-hook-form";
import { cameFromTheLeft } from "pages/Onboarding/Onboarding.styles";
import { useState } from "react";
import { Case, Switch } from "react-if";
import ModalSideBar from "components/ModalSideBar";
import CreatePartner from "common/modals/sideRight/CreatePartner/CreatePartner.component";
import UpdatePartner from "common/modals/sideRight/UpdatePartner/UpdatePartner.component";
import * as Styled from "../Kyb.styles";
import { LevelsParams, LevelsProps, Partner } from "../Kyb.types";
import BreadCrumb from "components/BreadCrumb/BreadCrumb.component";

function SecondLevel({ setLevel }: LevelsProps) {
	const [openAddPartner, setOpenAddPartner] = useState(false);
	const [openEditPartner, setOpenEditPartner] = useState(false);
	const [useSecondLevelEditing, setUseSecondLevelEditing] = useState(true);
	const [action, setAction] = useState<{
		index: number;
		type: "" | "update-business" | "update-personal";
	}>({
		index: 0,
		type: ""
	});

	const { setParams, params } = useStepManager();
	const { setCurrentStep } = useStepManager();
	const { control } = useFormContext();

	const levelsDetails = { ...params } as LevelsParams;

	const firstIndex = levelsDetails.firstLevelEditingIndex;

	const fieldArrayName = {
		business: `businessPartners.${firstIndex}.businessPartners`,
		personal: `businessPartners.${firstIndex}.personalPartners`
	};

	const secondBusinessPartners = useFieldArray({
		control,
		name: fieldArrayName.business,
		keyName: "uniqueId"
	});

	const secondPersonalPartners = useFieldArray({
		control,
		name: fieldArrayName.personal,
		keyName: "uniqueId"
	});

	const firstBusinessPartners = useFieldArray({
		control,
		name: "businessPartners",
		keyName: "uniqueId"
	});

	const secondPersonalPartnersList = secondPersonalPartners.fields as Partner[];
	const secondBusinessPartnersList = secondBusinessPartners.fields as Partner[];

	const firstPartnerList = firstBusinessPartners.fields as Partner[];
	const firstPartnerEditing = firstPartnerList[firstIndex];

	const goPartnerOfPartners = (partner: Partner) => {
		const secondLevelEditingIndex = secondBusinessPartners.fields.findIndex(
			(businessPartner) => businessPartner.uniqueId === partner.uniqueId
		);

		setParams({
			...params,
			firstLevelEditing: firstPartnerEditing,
			secondLevelEditing: partner,
			secondLevelEditingIndex
		});
		setLevel("third");
	};

	const goNext = () => setCurrentStep("InputDocuments");
	const goPrev = () => setCurrentStep("InputAndListPartners");

	return (
		<>
			<Styled.MainStepContainer>
				<Styled.ScrollStepContainer>
					<Styled.CenterContent>
						<div className="step-header">
							<div className="container-arrow-progress">
								<IconButton
									icon="back-arrow-lg"
									onClick={goPrev}
									color="inverted"
									marginRight={32}
								/>

								<ProgressBar currentStep={1} currentStepProgress={60} totalSteps={3} height={2} />
							</div>
							<Typography value="Sócios e Representantes" type="h1" isBold marginBottom={16} />
						</div>

						<Styled.ContainerAnimation {...cameFromTheLeft}>
							<BreadCrumb
								items={[
									{
										label: "Início",
										action: () => {
											setCurrentStep("InputAndListPartners");
										}
									},
									{
										label: firstPartnerEditing?.name,
										action: () => {}
									}
								]}
								TextType="subtitle2"
								truncate
							/>

							<div className="container-business-info">
								<ItemList
									trailingSupportContent="none"
									behavior="default"
									trailingContent="quickButton"
									trailingQuickButtonLabel="Editar"
									leadingContent="avatar"
									leadingAvatarFullName={firstPartnerEditing?.name}
									paragraph={`Empresa Associada - ${firstPartnerEditing?.documentNumber}`}
									label={firstPartnerEditing?.name}
									trailingQuickButtonOnPress={() => {
										setUseSecondLevelEditing(false);

										setAction({
											index: firstIndex,
											type: "update-business"
										});

										setOpenEditPartner(true);
									}}
								/>
							</div>

							<Banner
								behavior="default"
								hierarchy="low"
								layout="inset"
								color="default"
								artwork="icon"
								title="É obrigatório o cadastro de todos os sócios e acionistas que possuam acima de 25% de participação."
							/>

							<div className="container-list-add">
								{secondPersonalPartnersList.map((partner: Partner, index: number) => {
									return (
										<ItemList
											key={partner.uniqueId}
											trailingSupportContent="none"
											behavior="default"
											trailingContent="icon"
											leadingContent="avatar"
											leadingAvatarFullName={partner.name}
											paragraph={partner.email}
											label={partner.name}
											isDividerVisible
											onClick={() => {
												setUseSecondLevelEditing(true);

												setAction({
													index,
													type: "update-personal"
												});

												setOpenEditPartner(true);
											}}
										/>
									);
								})}

								{secondBusinessPartnersList.map((partner: Partner, index) => {
									const numOfSubPartnersBiz =
										secondBusinessPartnersList[index].businessPartners?.length || 0;

									const numOfSubPartnerPersonal =
										secondBusinessPartnersList[index].personalPartners?.length || 0;

									return (
										<ItemList
											key={partner.uniqueId}
											trailingSupportContent="none"
											supportParagraph={`${numOfSubPartnersBiz + numOfSubPartnerPersonal} sócios adicionados`}
											behavior="default"
											trailingContent="icon"
											isDividerVisible
											leadingContent="avatar"
											leadingAvatarFullName={partner.name}
											paragraph={`Sócio - ${partner.documentNumber}`}
											label={partner.name}
											onClick={() => goPartnerOfPartners(partner)}
										/>
									);
								})}

								<ItemList
									trailingSupportContent="none"
									behavior="default"
									trailingContent="icon"
									leadingContent="icon"
									leadingIconName="add"
									leadingIconColor="accent"
									label="Adicionar Sócio ou Representante"
									onClick={() => setOpenAddPartner(true)}
								/>
							</div>
						</Styled.ContainerAnimation>
					</Styled.CenterContent>
				</Styled.ScrollStepContainer>

				<div className="step-footer">
					<div className="button-container">
						<ButtonGroup
							variant="default"
							isInverted
							titleSecondary="Voltar para Sócios e Representantes"
							titlePrimary="Continuar"
							onPressPrimary={goNext}
							onPressSecondary={goPrev}
						/>
					</div>
				</div>
			</Styled.MainStepContainer>

			<ModalSideBar
				fixedHeader
				modalTitle="Novo Sócio ou Acionista"
				onClose={() => setOpenAddPartner(false)}
				open={openAddPartner}>
				<CreatePartner
					personalPartnersMethods={secondPersonalPartners}
					businessPartnersMethods={secondBusinessPartners}
					fieldArrayName={fieldArrayName}
					closeModal={() => setOpenAddPartner(false)}
					hideAccountControl
				/>
			</ModalSideBar>

			<ModalSideBar
				modalTitle="Editar Sócio"
				hiddenHeader
				disableModalScroll
				onClose={() => setOpenEditPartner(false)}
				open={openEditPartner}>
				<UpdatePartner
					closeModal={() => {
						setOpenEditPartner(false);
					}}
					goBackFunction={() => {
						if (!useSecondLevelEditing) {
							setCurrentStep("InputAndListPartners");
						}
					}}
					personalPartnersMethods={secondPersonalPartners}
					businessPartnersMethods={
						useSecondLevelEditing ? secondBusinessPartners : firstBusinessPartners
					}
					fieldArrayName={fieldArrayName}
					action={action}
					hideAccountControl
				/>
			</ModalSideBar>
		</>
	);
}

function ThirdLevel({ setLevel }: LevelsProps) {
	const [openAddPartner, setOpenAddPartner] = useState(false);
	const [openEditPartner, setOpenEditPartner] = useState(false);
	const [useThirdLevelEditing, setUseThirdLevelEditing] = useState(true);
	const [action, setAction] = useState<{
		index: number;
		type: "" | "update-business" | "update-personal";
	}>({
		index: 0,
		type: ""
	});

	const { params, setParams, setCurrentStep } = useStepManager();
	const { control } = useFormContext();

	const levelsDetails = { ...params } as LevelsParams;

	const firstIndex = levelsDetails.firstLevelEditingIndex;
	const secondIndex = levelsDetails.secondLevelEditingIndex;

	const fieldArrayName = {
		business: `businessPartners.${firstIndex}.businessPartners.${secondIndex}.businessPartners`,
		personal: `businessPartners.${firstIndex}.businessPartners.${secondIndex}.personalPartners`
	};

	const thirdBusinessPartners = useFieldArray({
		control,
		name: fieldArrayName.business,
		keyName: "uniqueId"
	});

	const thirdPersonalPartners = useFieldArray({
		control,
		name: fieldArrayName.personal,
		keyName: "uniqueId"
	});

	const secondBusinessPartners = useFieldArray({
		control,
		name: `businessPartners.${firstIndex}.businessPartners`,
		keyName: "uniqueId"
	});

	const thirdPersonalPartnersList = thirdPersonalPartners.fields as Partner[];
	const thirdBusinessPartnersList = thirdBusinessPartners.fields as Partner[];

	const secondPartnerList = secondBusinessPartners.fields as Partner[];
	const secondPartnerEditing = secondPartnerList[secondIndex];

	const goPartnerOfPartners = (partner: Partner) => {
		const thirdLevelEditingIndex = thirdBusinessPartners.fields.findIndex(
			(businessPartner) => businessPartner.uniqueId === partner.uniqueId
		);

		setParams({
			...params,
			secondLevelEditing: secondPartnerEditing,
			thirdLevelEditing: partner,
			thirdLevelEditingIndex
		});
		setLevel("fourth");
	};

	const goNext = () => setCurrentStep("InputDocuments");
	const goPrev = () => setCurrentStep("InputAndListPartners");

	return (
		<>
			<Styled.MainStepContainer>
				<Styled.ScrollStepContainer>
					<Styled.CenterContent>
						<div className="step-header">
							<div className="container-arrow-progress">
								<IconButton
									icon="back-arrow-lg"
									onClick={() => setLevel("second")}
									color="inverted"
									marginRight={10}
								/>

								<ProgressBar currentStep={2} currentStepProgress={60} totalSteps={3} height={2} />
							</div>
							<Typography value="Sócios e Representantes" type="h1" isBold marginBottom={16} />
						</div>

						<Styled.ContainerAnimation {...cameFromTheLeft}>
							<BreadCrumb
								items={[
									{
										label: "Início",
										action: () => {
											setCurrentStep("InputAndListPartners");
										}
									},
									{
										label: levelsDetails.firstLevelEditing?.name,
										action: () => {
											setLevel("second");
										}
									},
									{
										label: secondPartnerEditing?.name,
										action: () => {}
									}
								]}
								TextType="subtitle2"
								truncate
							/>

							<div className="container-business-info">
								<ItemList
									trailingSupportContent="none"
									behavior="default"
									trailingContent="quickButton"
									trailingQuickButtonLabel="Editar"
									leadingContent="avatar"
									leadingAvatarFullName={secondPartnerEditing?.name}
									paragraph={`Empresa Associada - ${secondPartnerEditing?.documentNumber}`}
									label={secondPartnerEditing?.name}
									trailingQuickButtonOnPress={() => {
										setUseThirdLevelEditing(false);

										setAction({
											index: secondIndex,
											type: "update-business"
										});

										setOpenEditPartner(true);
									}}
								/>
							</div>

							<Banner
								behavior="default"
								hierarchy="low"
								layout="inset"
								color="default"
								artwork="icon"
								title="É obrigatório o cadastro de todos os sócios e acionistas que possuam acima de 25% de participação."
							/>

							<div className="container-list-add">
								{thirdPersonalPartnersList.map((partner: Partner, index: number) => {
									return (
										<ItemList
											key={partner.uniqueId}
											trailingSupportContent="none"
											behavior="default"
											isDividerVisible
											trailingContent="icon"
											leadingContent="avatar"
											leadingAvatarFullName={partner.name}
											paragraph={partner.email}
											label={partner.name}
											onClick={() => {
												setUseThirdLevelEditing(true);

												setAction({
													index,
													type: "update-personal"
												});

												setOpenEditPartner(true);
											}}
										/>
									);
								})}

								{thirdBusinessPartnersList.map((partner: Partner, index) => {
									const numOfSubPartnersBiz =
										thirdBusinessPartnersList[index].businessPartners?.length || 0;

									const numOfSubPartnerPersonal =
										thirdBusinessPartnersList[index].personalPartners?.length || 0;

									return (
										<ItemList
											key={partner.uniqueId}
											trailingSupportContent="none"
											supportParagraph={`${numOfSubPartnersBiz + numOfSubPartnerPersonal} sócios adicionados`}
											isDividerVisible
											behavior="default"
											trailingContent="icon"
											leadingContent="avatar"
											leadingAvatarFullName={partner.name}
											paragraph={`Sócio - ${partner.documentNumber}`}
											label={partner.name}
											onClick={() => goPartnerOfPartners(partner)}
										/>
									);
								})}

								<ItemList
									trailingSupportContent="none"
									behavior="default"
									trailingContent="icon"
									leadingContent="icon"
									leadingIconName="add"
									leadingIconColor="accent"
									label="Adicionar Sócio ou Representante"
									onClick={() => setOpenAddPartner(true)}
								/>
							</div>
						</Styled.ContainerAnimation>
					</Styled.CenterContent>
				</Styled.ScrollStepContainer>

				<div className="step-footer">
					<div className="button-container">
						<ButtonGroup
							variant="default"
							isInverted
							titleSecondary="Voltar para Sócios e Representantes"
							titlePrimary="Continuar"
							onPressPrimary={goNext}
							onPressSecondary={goPrev}
						/>
					</div>
				</div>
			</Styled.MainStepContainer>

			<ModalSideBar
				fixedHeader
				modalTitle="Novo Sócio ou Acionista"
				onClose={() => setOpenAddPartner(false)}
				open={openAddPartner}>
				<CreatePartner
					personalPartnersMethods={thirdPersonalPartners}
					businessPartnersMethods={thirdBusinessPartners}
					closeModal={() => setOpenAddPartner(false)}
					fieldArrayName={fieldArrayName}
					hideAccountControl
				/>
			</ModalSideBar>

			<ModalSideBar
				modalTitle="Editar Sócio"
				hiddenHeader
				disableModalScroll
				onClose={() => setOpenEditPartner(false)}
				open={openEditPartner}>
				<UpdatePartner
					personalPartnersMethods={thirdPersonalPartners}
					businessPartnersMethods={
						useThirdLevelEditing ? thirdBusinessPartners : secondBusinessPartners
					}
					closeModal={() => {
						setOpenEditPartner(false);
					}}
					goBackFunction={() => {
						if (!useThirdLevelEditing) {
							setLevel("second");
						}
					}}
					fieldArrayName={fieldArrayName}
					action={action}
					hideAccountControl
				/>
			</ModalSideBar>
		</>
	);
}

function FourthLevel({ setLevel }: LevelsProps) {
	const [openAddPartner, setOpenAddPartner] = useState(false);
	const [openEditPartner, setOpenEditPartner] = useState(false);
	const [useFourthLevelEditing, setUseFourthLevelEditing] = useState(true);

	const [action, setAction] = useState<{
		index: number;
		type: "" | "update-business" | "update-personal";
	}>({
		index: 0,
		type: ""
	});

	const { params, setCurrentStep } = useStepManager();
	const { control } = useFormContext();

	const levelsDetails = { ...params } as LevelsParams;

	const firstIndex = levelsDetails.firstLevelEditingIndex;
	const secondIndex = levelsDetails.secondLevelEditingIndex;
	const thirdIndex = levelsDetails.thirdLevelEditingIndex;

	const fieldArrayName = {
		business: `businessPartners.${firstIndex}.businessPartners.${secondIndex}.businessPartners.${thirdIndex}.businessPartners`,
		personal: `businessPartners.${firstIndex}.businessPartners.${secondIndex}.businessPartners.${thirdIndex}.personalPartners`
	};

	const fourthBusinessPartners = useFieldArray({
		control,
		name: fieldArrayName.business,
		keyName: "uniqueId"
	});

	const fourthPersonalPartners = useFieldArray({
		control,
		name: fieldArrayName.personal,
		keyName: "uniqueId"
	});

	const thirdBusinessPartners = useFieldArray({
		control,
		name: `businessPartners.${firstIndex}.businessPartners.${secondIndex}.businessPartners`,
		keyName: "uniqueId"
	});

	const thirdPartnerList = thirdBusinessPartners.fields as Partner[];
	const thirdPartnerEditing = thirdPartnerList[thirdIndex];

	const fourthPersonalPartnersList = fourthPersonalPartners.fields as Partner[];
	const fourthBusinessPartnersList = fourthBusinessPartners.fields as Partner[];

	const goNext = () => setCurrentStep("InputDocuments");
	const goPrev = () => setCurrentStep("InputAndListPartners");

	return (
		<>
			<Styled.MainStepContainer>
				<Styled.ScrollStepContainer>
					<Styled.CenterContent>
						<div className="step-header">
							<div className="container-arrow-progress">
								<IconButton
									icon="back-arrow-lg"
									onClick={() => setLevel("third")}
									color="inverted"
									marginRight={10}
								/>

								<ProgressBar currentStep={2} currentStepProgress={60} totalSteps={3} height={2} />
							</div>

							<Typography value="Sócios e Representantes" type="h1" isBold marginBottom={16} />
						</div>

						<Styled.ContainerAnimation {...cameFromTheLeft}>
							<BreadCrumb
								items={[
									{
										label: "Início",
										action: () => {
											setCurrentStep("InputAndListPartners");
										}
									},
									{
										label: levelsDetails.firstLevelEditing?.name,
										action: () => {
											setLevel("second");
										}
									},
									{
										label: levelsDetails.secondLevelEditing?.name,
										action: () => {
											setLevel("third");
										}
									},
									{
										label: thirdPartnerEditing?.name,
										action: () => {}
									}
								]}
								TextType="subtitle2"
								truncate
							/>

							<div className="container-business-info">
								<ItemList
									trailingSupportContent="none"
									behavior="default"
									trailingContent="quickButton"
									trailingQuickButtonLabel="Editar"
									leadingContent="avatar"
									leadingAvatarFullName={thirdPartnerEditing?.name}
									paragraph={`Empresa Associada - ${thirdPartnerEditing?.documentNumber}`}
									label={thirdPartnerEditing?.name}
									trailingQuickButtonOnPress={() => {
										setUseFourthLevelEditing(false);

										setAction({
											index: thirdIndex,
											type: "update-business"
										});

										setOpenEditPartner(true);
									}}
								/>
							</div>

							<Banner
								behavior="default"
								hierarchy="low"
								layout="inset"
								color="default"
								artwork="icon"
								title="É obrigatório o cadastro de todos os sócios e acionistas que possuam acima de 25% de participação."
							/>

							<div className="container-list-add">
								{fourthPersonalPartnersList.map((partner: Partner, index: number) => {
									return (
										<ItemList
											key={partner.uniqueId}
											trailingSupportContent="none"
											isDividerVisible
											behavior="default"
											trailingContent="icon"
											leadingContent="avatar"
											leadingAvatarFullName={partner.name}
											paragraph={partner.email}
											label={partner.name}
											onClick={() => {
												setUseFourthLevelEditing(true);

												setAction({
													index,
													type: "update-personal"
												});

												setOpenEditPartner(true);
											}}
										/>
									);
								})}

								{fourthBusinessPartnersList.map((partner: Partner, index: number) => {
									return (
										<ItemList
											key={index}
											trailingSupportContent="none"
											behavior="default"
											isDividerVisible
											trailingContent="icon"
											leadingContent="avatar"
											leadingAvatarFullName={partner.name}
											paragraph={`Sócio - ${partner.documentNumber}`}
											label={partner.name}
											onClick={() => {
												setUseFourthLevelEditing(true);

												setAction({
													index,
													type: "update-business"
												});

												setOpenEditPartner(true);
											}}
										/>
									);
								})}

								<ItemList
									trailingSupportContent="none"
									behavior="default"
									trailingContent="icon"
									leadingContent="icon"
									leadingIconName="add"
									leadingIconColor="accent"
									label="Adicionar Sócio ou Representante"
									onClick={() => setOpenAddPartner(true)}
								/>
							</div>
						</Styled.ContainerAnimation>
					</Styled.CenterContent>
				</Styled.ScrollStepContainer>

				<div className="step-footer">
					<div className="button-container">
						<ButtonGroup
							variant="default"
							isInverted
							titleSecondary="Voltar para Sócios e Representantes"
							titlePrimary="Continuar"
							onPressPrimary={goNext}
							onPressSecondary={goPrev}
						/>
					</div>
				</div>
			</Styled.MainStepContainer>

			<ModalSideBar
				fixedHeader
				modalTitle="Novo Sócio ou Acionista"
				onClose={() => setOpenAddPartner(false)}
				open={openAddPartner}>
				<CreatePartner
					personalPartnersMethods={fourthPersonalPartners}
					businessPartnersMethods={fourthBusinessPartners}
					fieldArrayName={fieldArrayName}
					closeModal={() => setOpenAddPartner(false)}
					hideAccountControl
				/>
			</ModalSideBar>

			<ModalSideBar
				modalTitle="Editar Sócio"
				hiddenHeader
				disableModalScroll
				onClose={() => setOpenEditPartner(false)}
				open={openEditPartner}>
				<UpdatePartner
					hideAccountControl
					personalPartnersMethods={fourthPersonalPartners}
					businessPartnersMethods={
						useFourthLevelEditing ? fourthBusinessPartners : thirdBusinessPartners
					}
					fieldArrayName={fieldArrayName}
					closeModal={() => {
						setOpenEditPartner(false);
					}}
					goBackFunction={() => {
						if (!useFourthLevelEditing) {
							setLevel("third");
						}
					}}
					action={action}
				/>
			</ModalSideBar>
		</>
	);
}

function InputBusinessPartners() {
	const [level, setLevel] = useState<"second" | "third" | "fourth">("second");

	return (
		<Switch>
			<Case condition={level === "second"}>
				<SecondLevel key="second-level" setLevel={setLevel} />
			</Case>

			<Case condition={level === "third"}>
				<ThirdLevel key="third-level" setLevel={setLevel} />
			</Case>

			<Case condition={level === "fourth"}>
				<FourthLevel key="fourth-level" setLevel={setLevel} />
			</Case>
		</Switch>
	);
}

export default InputBusinessPartners;
