import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "../../rule/private";
import { SettingsPage } from "pages/Settings/Settings.screen";
import { PlansPage } from "pages/Settings/Plans/Plans.screen";
import { GatewayPage } from "pages/Settings/Plans/Gateway/Gateway.screen";
import { OtcPage } from "pages/Settings/Plans/Otc/Otc.screen";

export const SettingsRouter = () => {
	return (
		<Routes>
			<Route>
				<Route
					index
					element={
						<PrivateRoute>
							<SettingsPage />
						</PrivateRoute>
					}
				/>

				<Route path="plans">
					<Route index element={<PlansPage />} />

					<Route path="gateway" element={<GatewayPage />} />
					<Route path="otc" element={<OtcPage />} />
				</Route>

				<Route path="*" element={<Navigate to="/settings" replace />} />
			</Route>
		</Routes>
	);
};
