import { colors } from "@azify/ui-tokens";
import styled from "styled-components";

export const Container = styled.div<{ noPagePadding?: boolean; magicFooterPadding: number }>`
	padding: ${({ noPagePadding }) => (noPagePadding ? "0px" : "40px 80px 0px")};
	width: 100%;
	height: calc(100vh - 75px);
	display: flex;
	flex-direction: column;
	overflow: auto;
	background: ${colors.white.light};
	padding-bottom: ${({ magicFooterPadding }) => `${magicFooterPadding}px`};

	@media (max-width: 650px) {
		padding: 40px 32px 0px;
	}
`;
