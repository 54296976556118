import { useState } from "react";
import { Tile, Typography } from "@azify/ui-web";
import * as S from "./CreatePixKey.styles";

import { CheckData, OtherKeyType, ConfirmCode, Finish } from "./Steps";
import { useOpenModalSideBar } from "hooks/useOpenSideBar";
import { Case, Switch, When } from "react-if";
import { CheckDataStrings } from "./CreatePixKey.types";

const CreatePixKey = () => {
	const { currentStep, setCurrentStep } = useOpenModalSideBar();
	const [type, setType] = useState<CheckDataStrings>("phone");

	const handleCreatePixKey = (value: CheckDataStrings) => {
		setType(value);
		setCurrentStep && setCurrentStep("CheckData");
	};

	return (
		<S.Container>
			<Typography value="Cadastre uma nova chave" type="h1" isBold />

			<div className="tile-container">
				<Tile
					label="Celular"
					size="medium"
					leadingIconName="phone"
					trailingContent="customized"
					backgroundColor="white"
					onClick={() => handleCreatePixKey("phone")}
				/>

				<Tile
					label="E-mail"
					size="medium"
					leadingIconName="mail"
					trailingContent="customized"
					backgroundColor="white"
					onClick={() => handleCreatePixKey("email")}
				/>

				<Tile
					label="CNPJ"
					size="medium"
					leadingIconName="building"
					trailingContent="customized"
					backgroundColor="white"
					onClick={() => handleCreatePixKey("cnpj")}
				/>

				<Tile
					label="Aleatória"
					size="medium"
					leadingIconName="shield"
					trailingContent="customized"
					backgroundColor="white"
					onClick={() => handleCreatePixKey("random")}
				/>
			</div>

			<Switch>
				<Case condition={currentStep === "CheckData"}>
					<CheckData type={type} setCurrentStep={setCurrentStep} />
				</Case>

				<Case condition={currentStep === "OtherKeyType"}>
					<OtherKeyType type={type} setCurrentStep={setCurrentStep} />
				</Case>

				<Case condition={currentStep === "ConfirmCode"}>
					<ConfirmCode type={type} setCurrentStep={setCurrentStep} />
				</Case>
			</Switch>
		</S.Container>
	);
};

export default CreatePixKey;
