import styled from "styled-components";

export const Container = styled.div`
	padding: 0 32px;
	margin-top: 12px;

	height: 100%;

	display: flex;
	justify-content: space-between;
	flex-direction: column;

	.container-inputs {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	& .tile-container {
		display: flex;
		justify-content: space-between;
		gap: 8px;

		margin-top: 36px;
	}

	.footer-button {
		display: flex;
		padding: 24px 0px;
		width: 100%;
		align-items: center;
		justify-content: center;
	}

	.radios-container {
		display: flex;
		flex-direction: row;
		gap: 16px;
		margin-top: 20px;
		margin-bottom: 16px;

		.radio-label {
			display: flex;
			flex-direction: row;
			gap: 8px;
			align-items: center;
			justify-content: center;
		}
	}
`;
