import { Chip, GenericButton, Typography } from "@azify/ui-web";
import * as S from "./DownloadAzifyApp.styles";

import AppStoreImg from "assets/images/app-store.png";
import PlayStoreImg from "assets/images/google-play.png";
import QRCode from "react-qr-code";

function DownloadAzifyApp() {
	return (
		<S.Container>
			<div>
				<Typography value="Baixe o aplicativo da Azify" type="h1" isBold marginBottom={20} />

				<Typography
					value="Para validar a biometria, é necessário que você baixe nosso aplicativo e faça o login na conta."
					type="body1"
					marginBottom={16}
				/>

				<div className="chip-container">
					<Chip
						id=""
						title="Aponte a câmera do seu celular"
						hierarchy="high"
						iconLeft="camera-small"
					/>
				</div>

				<div className="img-container">
					<div className="qr-container">
						<QRCode value="https://azify.com.br" size={200} />
					</div>

					<div className="apps-links">
						<img src={AppStoreImg} alt="app-store" />
						<img src={PlayStoreImg} alt="play-store" />
					</div>
				</div>
			</div>

			<div className="footer">
				<GenericButton onClick={() => {}} title="Fechar" layout="standard" color="secondary" />
			</div>
		</S.Container>
	);
}

export default DownloadAzifyApp;
