import { colors } from "@azify/ui-tokens";
import { GeneralStyleProps } from "../../../@types/general";

import styled, { css } from "styled-components";

export const ModalBackground = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 999999999;
	background-color: rgba(0, 0, 0, 0.35);
	top: 0;
	left: 0;
`;

export const ModalContainer = styled.div<
	GeneralStyleProps & {
		layout?: "standard" | "alternative";
		noCloseButton?: boolean;
	}
>`
	display: flex;
	position: relative;
	width: 457px;
	height: ${({ height }) => (height ? `${height}px` : "auto")};
	flex-direction: column;
	max-height: 647px;
	border-radius: 16px;
	background-color: ${colors.white.light};
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	z-index: 999999999999;

	.spacer {
		width: 40px;
	}

	.dialog-header {
		display: flex;
		padding: 8px 16px 20px 16px;
		flex-direction: column;
		align-items: center;
		align-self: stretch;
		border-radius: 16px 16px 0px 0px;

		${({ noCloseButton }) =>
			noCloseButton
				? css`
						margin-top: 28px;
					`
				: css``}

		.row-title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			${({ layout }) =>
				layout === "alternative"
					? css`
							flex-direction: column;
							align-items: flex-start;
							gap: 8px;
						`
					: css`
							flex-direction: row;
						`}
		}
	}

	.dialog-content {
		display: flex;
		flex-direction: column;
	}

	.dialog-bottom {
		display: flex;
		flex-direction: column;
		align-items: center;
		backdrop-filter: blur(22px);
		border-radius: 0px 0px 16px 16px;
		padding: 16px;
		width: 100%;

		.slider-container {
			width: 100%;
		}
	}
`;
