import { Banner, GenericButton, ItemList, Typography } from "@azify/ui-web";
import * as Styled from "./InformationalErros.styles";

function InformationalErros() {
	return (
		<Styled.Container>
			<div className="display-between-erros">
				<div className="padding-bottom-to-button">
					<Typography value="Houve algum erro por aqui" type="h1" isBold marginBottom={20} />

					<Typography
						value="É necessário que você corrija-os para finalizarmos seu cadastro."
						type="body1"
						marginBottom={16}
					/>

					<ItemList
						behavior="display"
						label="Certificado da condição de microempreendedor individual - CCMEI"
						leadingIconName="file-multiple-text"
						leadingContent="icon"
						trailingIconName="chevron-right-small"
						trailingSupportContent="none"
						paragraph="BlockTech Brasil Soluções em Blockchain S.A."
					/>

					<ItemList
						behavior="display"
						label="Certificado da condição de microempreendedor individual - CCMEI"
						leadingIconName="file-multiple-text"
						leadingContent="icon"
						trailingIconName="chevron-right-small"
						trailingSupportContent="none"
						paragraph="BlockTech Brasil Soluções em Blockchain S.A."
					/>

					<ItemList
						behavior="display"
						label="Certificado da condição de microempreendedor individual - CCMEI"
						leadingIconName="file-multiple-text"
						leadingContent="icon"
						trailingIconName="chevron-right-small"
						trailingSupportContent="none"
						paragraph="BlockTech Brasil Soluções em Blockchain S.A."
					/>

					<div className="mt-20">
						<Banner
							behavior="default"
							hierarchy="low"
							layout="inset"
							color="default"
							artwork="icon"
							title="Erros podem ocorrer devido à ilegibilidade de alguns documentos ou à inserção de dados incorretos."
						/>
					</div>
				</div>

				<GenericButton
					onClick={() => {}}
					title="Corrigir erro(s)"
					layout="standard"
					color="secondary"
				/>
			</div>
		</Styled.Container>
	);
}

export default InformationalErros;
