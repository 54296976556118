import { colors } from "@azify/ui-tokens";
import { useStepManager } from "hooks/useStepManager";
import { useNavigate } from "react-router-dom";
import * as Styled from "../BuyOtc.styles";
import { Tooltip as ReactTooltip } from "react-tooltip";
import InputConvert from "components/Input/ConvertInput/InputConvert.component";
import { useEffect, useState } from "react";
import { formatCurrencyFn } from "helpers/currency";
import { useOtcActions } from "hooks/useOtcActions";
import { Chip, HeaderNavigation, ItemList, SlidingButton, Typography } from "@azify/ui-web";
import axios from "axios";
import { motion } from "framer-motion";

const subMenuAnimate = {
	enter: {
		opacity: 1,
		display: "block"
	},
	exit: {
		opacity: 1,
		transitionEnd: {
			display: "none"
		}
	}
};

export const ExchangeInputs = () => {
	const { setCurrentStep, setParams } = useStepManager();
	const navigate = useNavigate();
	const [inputError, setInputError] = useState("");

	const { otcPersonalizedLimit, otcBalance } = useOtcActions();

	const balanceMoreCredit = otcBalance + otcPersonalizedLimit;

	const [quotes, setQuotes] = useState({
		USDT: 0,
		BTC: 0,
		USDC: 0
	});

	const [selectedCurrency, setSelectedCurrency] = useState<{
		isoCode: "USDT" | "BTC" | "USDC";
		precision: number;
	}>({
		isoCode: "USDT",
		precision: 2
	});

	const getExchangesRates = async (isoCode?: "eUSDT" | "BTC" | "USDC") => {
		const response = await axios.get(
			`https://api.capitual.com/api/v1.0/currencies/currencyExchange?from_currency=${isoCode}&to_currency=BRL&amount=1`
		);

		return response.data;
	};

	useEffect(() => {
		getExchangesRates("eUSDT").then((response) => {
			setQuotes((prev) => ({ ...prev, USDT: response.data }));
		});

		getExchangesRates("BTC").then((response) => {
			setQuotes((prev) => ({ ...prev, BTC: response.data }));
		});

		getExchangesRates("USDC").then((response) => {
			setQuotes((prev) => ({ ...prev, ETH: response.data }));
		});
	}, []);

	const [valueToExchange, setValueToExchange] = useState("");

	const quotesInBrlUnity = {
		USDT: quotes.USDT || 5.2,
		BTC: quotes.BTC || 300476.37,
		USDC: quotes.USDC || 5.2
	};

	const getValueInBrl = (value: string, currency: "USDT" | "BTC" | "USDC") => {
		const valueInBrl = parseFloat(value) * quotesInBrlUnity[currency];

		return formatCurrencyFn("BRL", String(valueInBrl));
	};

	const onChangeValueConvert = (value: string) => {
		const valueInBrl = parseFloat(value) * quotesInBrlUnity[selectedCurrency.isoCode];

		if (valueInBrl > balanceMoreCredit) {
			setInputError("Saldo insuficiente");
		} else {
			setInputError("");
		}

		setValueToExchange(value);
	};

	const [isHover, toggleHover] = useState(false);

	const toggleHoverMenu = () => {
		toggleHover(!isHover);
	};

	const getCurrencyName = (isoCode: "USDT" | "BTC" | "USDC") => {
		switch (isoCode) {
			case "USDT":
				return "Tether (USDT)";
			case "BTC":
				return "Bitcoin (BTC)";
			case "USDC":
				return "USD Coin (USDC)";
			default:
				return "Tether (USDT)";
		}
	};

	const goNext = () => {
		setCurrentStep("ExchangeSuccess");
		const valueInBrl = parseFloat(valueToExchange) * quotesInBrlUnity[selectedCurrency.isoCode];

		setParams({
			valueToExchange,
			currency: selectedCurrency,
			valueInBrlFormatted: getValueInBrl(valueToExchange, selectedCurrency.isoCode),
			valueInBrl
		});
	};

	return (
		<>
			<Styled.CenterContainer>
				<HeaderNavigation
					buttonOnTitle={false}
					title="Comprar"
					trailingActionsType="primaryPrimary"
					quickButtonColor="inverted"
					backIconClick={() => navigate("/otc")}
				/>

				<div
					className="display-between"
					style={{
						marginTop: 24
					}}>
					<div>
						<motion.div className="menu-item" onClick={toggleHoverMenu}>
							<ItemList
								trailingSupportContent="customized"
								trailingContent="icon"
								trailingIconName={isHover ? "chevron-up-filled-small" : "chevron-down-filled-small"}
								label={getCurrencyName(selectedCurrency.isoCode)}
								behavior="default"
								leadingContent="avatar"
								leadingAvatarCurrency={selectedCurrency.isoCode}
								leadingAvatarType="currency"
								containerStyles={{
									border: `1px solid ${colors.gray.opacity["30p"]}`,
									borderRadius: 16,
									padding: "0 8px"
								}}
							/>
							<motion.div
								className="sub-menu"
								initial="exit"
								animate={isHover ? "enter" : "exit"}
								variants={subMenuAnimate}>
								<div className="sub-menu-container">
									<div className="sub-menu-item">
										<ItemList
											trailingSupportContent="customized"
											trailingContent="icon"
											trailingIconName="chevron-right-filled-small"
											label="Bitcoin (BTC)"
											behavior="default"
											leadingContent="avatar"
											leadingAvatarCurrency="BTC"
											leadingAvatarType="currency"
											isDividerVisible
											onClick={() => {
												setValueToExchange("");
												setSelectedCurrency({ isoCode: "BTC", precision: 8 });
											}}
										/>
									</div>
									<div className="sub-menu-item">
										<ItemList
											trailingSupportContent="customized"
											trailingContent="icon"
											trailingIconName="chevron-right-filled-small"
											label="Tether (USDT)"
											behavior="default"
											leadingContent="avatar"
											leadingAvatarCurrency="USDT"
											leadingAvatarType="currency"
											isDividerVisible
											onClick={() => {
												setValueToExchange("");
												setSelectedCurrency({ isoCode: "USDT", precision: 2 });
											}}
										/>
									</div>
									<div className="sub-menu-item">
										<ItemList
											trailingSupportContent="customized"
											trailingContent="icon"
											trailingIconName="chevron-right-filled-small"
											label="USD Coin (USDC)"
											behavior="default"
											leadingContent="avatar"
											leadingAvatarCurrency="USDC"
											leadingAvatarType="currency"
											onClick={() => {
												setValueToExchange("");
												setSelectedCurrency({ isoCode: "USDC", precision: 2 });
											}}
										/>
									</div>
								</div>
							</motion.div>
						</motion.div>

						<div className="center-chip-credit">
							<a
								data-tooltip-id="market-advice-credit"
								data-tooltip-html="É a soma do seu limite de crédito e saldo disponível.">
								<Chip
									id="chip-credit"
									hierarchy="high"
									title={`${formatCurrencyFn(
										"BRL",
										String(balanceMoreCredit)
									)} disponível (Saldo + Crédito)`}
									width="intrinsec"
									iconRightColor="tertiary"
									iconRight="info-circle-filled-small"
									iconLeft="up-down-arrow-small"
								/>
							</a>
						</div>
						<Typography
							marginTop={24}
							value="Valor da comprar"
							type="subtitle1"
							color="primary"
							marginBottom={12}
						/>

						<InputConvert
							placeHolder="0.00"
							value={valueToExchange}
							prefix={selectedCurrency.isoCode}
							precision={selectedCurrency.precision}
							onChangeText={onChangeValueConvert}
							error={inputError}
							hasValue={!!valueToExchange?.length}
						/>

						<div className="center-with-lines-horizontal">
							<div className="center-line" />
							<Chip
								id="chip-quote"
								hierarchy="high"
								title={`Cotação por unidade: R$ ${quotesInBrlUnity[selectedCurrency.isoCode]}`}
								width="intrinsec"
								iconRightColor="tertiary"
								iconRight="info-circle-filled-small"
								iconLeft="up-down-arrow-small"
								backgroundCustomColor={"#F8F9FB"}
							/>
						</div>

						<Typography marginBottom={12} value="Total a pagar" type="subtitle1" color="primary" />

						<Typography
							marginBottom={12}
							value={`${getValueInBrl(valueToExchange, selectedCurrency.isoCode)}`}
							isBold
							type="h1"
							colorCustom={valueToExchange?.length ? undefined : colors.gray.light}
						/>
					</div>

					<SlidingButton
						disabled={inputError.length > 0 || valueToExchange?.length === 0}
						overlayText="Arraste para comprar"
						title="Arraste para comprar"
						onSlideDone={goNext}
					/>
				</div>
			</Styled.CenterContainer>

			<ReactTooltip className="tooltip-style" place="top-start" id="market-advice-credit" />
		</>
	);
};
