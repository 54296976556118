import { ButtonCloseToast, IconInformationContainer, ToastCustom } from "./Toast.styles";
import { Slide, ToastOptions, toast } from "react-toastify";
import { IconButton, QuickButton, Typography } from "@azify/ui-web";
import { When } from "react-if";
import InfoToastSVG from "assets/svg/infoToast";
import ErrorToastSVG from "assets/svg/errorToast";

interface IToastComponentProps extends ToastOptions {
	title: string;
	subtitle?: string;
	autoCloseEnable?: boolean;
	buttonLabel?: string;
	typeIcon?: "info" | "error";
	buttonAction?: () => void;
}

const ToastComponent = ({
	title,
	subtitle
}: {
	title: string;
	subtitle?: string;
	buttonLabel?: string;
	buttonAction?: () => void;
}) => {
	return (
		<ToastCustom>
			<div className="title-subtitle">
				<Typography value={title} type="subtitle1" />

				<When condition={!!subtitle}>
					<Typography value={subtitle} type="body3" />
				</When>
			</div>
		</ToastCustom>
	);
};

export const ToastAlert = ({
	title,
	subtitle,
	autoCloseEnable,
	buttonLabel,
	buttonAction,
	typeIcon = "info",
	...rest
}: IToastComponentProps) => {
	toast.dark(
		() => {
			return (
				<ToastComponent
					buttonAction={buttonAction}
					buttonLabel={buttonLabel}
					title={title}
					subtitle={subtitle}
				/>
			);
		},
		{
			icon: () => (
				<IconInformationContainer>
					{typeIcon === "info" ? <InfoToastSVG /> : <ErrorToastSVG />}
				</IconInformationContainer>
			),
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			hideProgressBar: true,
			autoClose: autoCloseEnable ? 3000 : false,
			bodyStyle: {
				alignItems: subtitle ? "flex-start" : "center"
			},
			transition: Slide,
			position: "bottom-left",
			toastId: title,
			closeButton: () => {
				if (buttonLabel?.length) {
					return <QuickButton onClick={buttonAction} label={buttonLabel} color="secondary" />;
				}

				if (!buttonLabel?.length) {
					return (
						<ButtonCloseToast>
							<IconButton icon="close" color="secondary" />
						</ButtonCloseToast>
					);
				}

				return null;
			},

			...rest
		}
	);
};
