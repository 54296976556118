import { useStepManager } from "hooks/useStepManager";
import { FormProvider, useForm } from "react-hook-form";
import { OnboardingKybSteps } from "../Onboarding.types";
import InputDocumentNumber from "./Steps/0.InputDocumentNumber";
import InputBusinessData from "./Steps/1.InputBusinessData";
import InputAndListPartners from "./Steps/2.InputAndListPartners";
import InputBusinessPartners from "./Steps/3.InputBusinessPartners";
import InputDocuments from "./Steps/4.InputDocuments";
import Status from "./Steps/7.Status";
import { yupResolver } from "@hookform/resolvers/yup";
import { createBusinessSchema } from "./Validations/KybValidations";
import WaitingBiometry from "./Steps/5.WaitingBiometry";
import SuccessSubmit from "./Steps/6.SuccessSubmit";

function Kyb() {
	const { currentStep } = useStepManager();

	const kybMethods = useForm({
		resolver: yupResolver(createBusinessSchema),
		defaultValues: {
			personalPartners: [],
			businessPartners: []
		}
	});

	const typedCurrentStep = currentStep as OnboardingKybSteps;

	const STEP_RECORDS = {
		InputBusinessDocumentNumber: <InputDocumentNumber />,
		InputBusinessData: <InputBusinessData />,
		InputAndListPartners: <InputAndListPartners />,
		InputBusinessPartners: <InputBusinessPartners />,
		InputDocuments: <InputDocuments />,
		WaitingBiometry: <WaitingBiometry />,
		SuccessSubmit: <SuccessSubmit />,
		Status: <Status />
	};

	return (
		<FormProvider {...kybMethods}>
			{typedCurrentStep ? STEP_RECORDS[typedCurrentStep] : null}
		</FormProvider>
	);
}

export default Kyb;
