import * as Styled from "./BreadCrump.styles";
import { Icon, Typography } from "@azify/ui-web";
import { colors } from "@azify/ui-tokens";
import { When } from "react-if";
import { firstLetterCapitalized, truncateStrings } from "helpers/string";
import { BreadCrumbProps } from "./BreadCrumb.types";

function BreadCrumb({ TextType, items, truncate, truncateLimit = 13 }: BreadCrumbProps) {
	return (
		<Styled.Container>
			{items.map((item, index) => {
				const isLastItem = index === items.length - 1;

				const label = truncate ? truncateStrings(item.label, truncateLimit) : item.label;

				return (
					<div className={`item ${isLastItem ? "no-hover" : ""}`} key={index} onClick={item.action}>
						<Typography
							colorCustom={isLastItem ? colors.black.main : colors.gray.dark}
							type={TextType}
							value={firstLetterCapitalized(label)}
							isBold
						/>

						<When condition={!isLastItem}>
							<Icon customColor={colors.gray.dark} name="chevron-right-small" />
						</When>
					</div>
				);
			})}
		</Styled.Container>
	);
}

export default BreadCrumb;
