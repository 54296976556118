import { CurrencyTypes } from "../@types/currency.types";
import c from "currency.js";

export const currencyPrecisions = {
	ETH: 6,
	BTC: 8,
	LTC: 8,
	XRP: 8,
	DSH: 8,
	EUSDT: 2,
	BUSD: 2,
	DAI: 2,
	USDC: 2,
	XAU: 6,
	AXS: 6,
	BTT: 6,
	CAKE: 6,
	CHZ: 6,
	DOGE: 8,
	BCH: 6,
	UNI: 6,
	LINK: 6,
	BNB: 6,
	DOT: 6,
	ADA: 6,
	USDT: 6,
	SHIB: 6,
	MATIC: 6,
	FTM: 6,
	HT: 6,
	KCS: 6,
	TRX: 6,
	TUSDT: 6
};

const cryptoFormatLogic = (
	value: number | string,
	isoCode: string,
	removeSymbol: boolean,
	invertSymbol: boolean
) => {
	const precision = currencyPrecisions[isoCode as keyof typeof currencyPrecisions];

	const result = c(value, { precision }).format({ symbol: "", precision });

	if (removeSymbol) return result;

	return invertSymbol ? `${isoCode} ${result}` : `${result} ${isoCode}`;
};

const fiatFormatLogic = (
	value: string,
	isoCode: string,
	removeSymbol: boolean,
	fromCents?: boolean
) => {
	const symbol = {
		BRL: "R$ ",
		EUR: "€ ",
		USD: "$ ",
		GBP: "£ "
	};
	if (isoCode === "BRL") {
		return c(value, { fromCents }).format({
			symbol: removeSymbol ? "" : symbol[isoCode],
			decimal: ",",
			separator: "."
		});
	}

	return c(value).format({
		symbol: removeSymbol ? "" : symbol[isoCode as keyof typeof symbol],
		decimal: ".",
		separator: ","
	});
};

export const isFiatCurrency = (isoCode: CurrencyTypes) => {
	return ["BRL", "USD", "EUR", "GBP"].includes(isoCode);
};

export const formatCurrencyFn = (
	isoCode: string | CurrencyTypes | undefined,
	value?: string | undefined,
	removeSymbol?: boolean,
	invertSymbol?: boolean,
	fromCents?: boolean
) => {
	try {
		if (!value || !isoCode) {
			return "--";
		}

		if (isFiatCurrency(isoCode as CurrencyTypes)) {
			return fiatFormatLogic(value, isoCode.toLocaleUpperCase(), removeSymbol || false, fromCents);
		}

		return cryptoFormatLogic(
			value,
			isoCode.toLocaleUpperCase(),
			removeSymbol || false,
			invertSymbol || false
		);
	} catch (error) {
		return "--";
	}
};

export const formatExponentialNumber = (
	value: string | number | null | undefined,
	precision: number
) => {
	if (typeof value !== "string" || typeof precision !== "number" || value === "") {
		return "-";
	}
	return Number.parseFloat(value).toFixed(precision);
};

export const isExponentialNumber = (value: string) =>
	!!value?.toString().includes("e-") || !!value?.toString().includes("e+");

export const moneyMask = (value: string, symbol?: string) => {
	let valueMod = "";

	valueMod = value.replace(".", "").replace(",", "").replace(/\D/g, "");

	const options = { minimumFractionDigits: 2 };
	const result = new Intl.NumberFormat("pt-BR", options).format(parseFloat(valueMod) / 100);

	return `${symbol || ""} ${result === "NaN" ? 0 : result}`;
};

const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

export function kFormatter(number: number) {
	const tier = (Math.log10(Math.abs(number)) / 3) | 0;

	if (tier == 0) return number;

	const suffix = SI_SYMBOL[tier];
	const scale = Math.pow(10, tier * 3);

	const scaled = number / scale;

	return scaled.toFixed(0) + suffix;
}
