import { colors } from "@azify/ui-tokens";
import * as Styled from "../OtcOnboarding.styles";
import { ButtonGroup, IconButton, ProgressBar, TextField, Tile, Typography } from "@azify/ui-web";
import { useStepManager } from "hooks/useStepManager";
import { useState } from "react";

export const SourceFundsStep = () => {
	const [input, setInput] = useState("");
	const { setCurrentStep } = useStepManager();
	const goBack = () => setCurrentStep("PersonalDataStep");

	return (
		<Styled.MainStepContainer>
			<Styled.ScrollStepContainer>
				<Styled.CenterContent>
					<div className="step-header">
						<div className="container-arrow-progress">
							<IconButton icon="back-arrow-lg" onClick={goBack} color="inverted" marginRight={32} />

							<ProgressBar currentStep={0} currentStepProgress={95} totalSteps={1} height={2} />
						</div>
						<Typography
							value="Forneça informações sobre a origem dos fundos que serão usados para negociar em nosso balcão."
							type="h1"
							isBold
						/>
						<Typography
							type="body1"
							value="Por favor, especifique a origem dos fundos, que podem ser de várias fontes, como economias pessoais, retornos de investimentos, atividades comerciais."
							marginTop={20}
						/>
					</div>

					<Styled.ContainerAnimation paddingTop={350}>
						<TextField
							maxLength={300}
							placeholder=""
							label="Digite sua resposta..."
							value={input}
							onChange={(e) => setInput(e)}
						/>
					</Styled.ContainerAnimation>
				</Styled.CenterContent>
			</Styled.ScrollStepContainer>

			<div className="step-footer">
				<div className="button-container">
					<ButtonGroup
						variant="default"
						titlePrimary="Continuar"
						removeButton="transparentButton"
						onPressPrimary={() => setCurrentStep("FinishStep")}
						isDisabledPrimary={!input}
					/>
				</div>
			</div>
		</Styled.MainStepContainer>
	);
};
