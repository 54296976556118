import { MenuContainer } from "./DropDown.styles";
import { DropDownPositionContainerProps } from "./DropDown.types";
import { ControlledMenu } from "@szhsin/react-menu";

const DropDown = ({
	isOpen,
	setOpen,
	innerRef,
	content,
	widthContainer,
	fixedGoToUp,
	align,
	gap
}: DropDownPositionContainerProps) => {
	return (
		<MenuContainer widthContainer={widthContainer}>
			<ControlledMenu
				state={isOpen ? "open" : "closed"}
				anchorRef={innerRef}
				onClose={() => setOpen(false)}
				className="menu"
				direction={fixedGoToUp ? "top" : "bottom"}
				align={align || "start"}
				gap={gap || 0}>
				{content}
			</ControlledMenu>
		</MenuContainer>
	);
};

export default DropDown;
