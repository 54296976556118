import { colors } from "@azify/ui-tokens";
import styled from "styled-components";
import { transparentize } from "polished";

export const Container = styled.div`
	height: 75px;
	border-bottom: 1px solid ${transparentize(0.9, colors.black.dark)};
	background: ${colors.white.light};
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	& .help-button {
		flex: 1;
		display: flex;
		padding-left: 24px;
	}

	& .logo {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	& .row-menu {
		display: flex;
		align-items: center;
		flex-direction: row;
		gap: 16px;
		padding-right: 20px;
		flex: 1;
		justify-content: flex-end;

		& .business-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 16px;

			cursor: pointer;
			z-index: 9999;

			& .business-content-text {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
		}
	}
`;

export const DropDownContainer = styled.div`

& .dropdown {
	display: flex;
	flex-direction: column;

	padding: 20px 0px 16px 0px;

	& .header {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		padding: 0px 16px 8px 16px;

		& p {
			width: auto;
		}
	}

	& .footer {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;

		padding: 4px 16px 0px 16px;

		& p {
			width: auto;
		}
	}

	& .dropdown-container-copy {
		display: flex;
		align-items: center;
		justify-content: space-between;

		padding: 0px 16px;

		& .dropdown-content-copy {
			display: flex;
			flex-direction: column;

			padding: 16px 0;
	
		& .dropdown-copy-item {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 16px;

			padding: 6px 0;

			& p:first-child {
				width: 70px;
			}
		}
	}
}
`;
