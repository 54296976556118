import { colors } from "@azify/ui-tokens";
import { breakpoints } from "styles/metrics";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";

export const ModalContainer = styled(motion.aside)<{
	fixedHeader?: boolean;
	disableModalScroll?: boolean;
}>`
	display: flex;
	flex-direction: column;
	position: fixed;
	width: 500px;
	height: calc(100% - 75px);
	background-color: ${colors.white.light};
	z-index: 99;
	bottom: 0;
	right: 0;

	border-left: 1px solid rgba(0, 0, 0, 0.1);

	@media (max-width: ${breakpoints.tablet}px) {
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		height: 100%;
	}

	@media (max-width: ${breakpoints.phone}px) {
		width: 100%;
		height: 100%;
	}

	${({ fixedHeader, disableModalScroll }) =>
		fixedHeader || disableModalScroll
			? css`
					overflow-y: unset;
				`
			: css`
					overflow-y: auto;
					padding-top: 32px;
				`}

	.container-scroll {
		display: flex;
		flex-direction: column;
		height: 100%;

		${({ fixedHeader }) =>
			fixedHeader
				? css`
						overflow-y: auto;
					`
				: css`
						overflow-y: unset;
					`}
	}

	.close-button-wrapper {
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		display: flex;
		gap: 8px;

		${({ fixedHeader }) =>
			fixedHeader
				? css`
						padding: 28px 32px 20px 28px;
						z-index: 1;
					`
				: css`
						padding: 0 28px;
					`}

		.container-close-header-component {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}
	}
`;
