import * as React from "react";
import { SVGProps } from "react";

const InfoToastSVG = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={38} height={38} fill="none" {...props}>
		<rect width={38} height={38} fill="#0264DC" rx={19} />
		<path
			fill="#fff"
			fillRule="evenodd"
			d="M19 9C13.477 9 9 13.477 9 19s4.477 10 10 10 10-4.477 10-10S24.523 9 19 9Zm1 10v-1h-2v6h2v-5Zm-2-3.5a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default InfoToastSVG;
