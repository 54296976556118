import React from "react";
import * as Styled from "./InputArea.styles";
import { InputAreaProps } from "./InputArea.types";

function InputArea({ id, setValue, placeholder }: InputAreaProps) {
	return (
		<Styled.Container>
			<textarea onChange={(e) => setValue(e.target.value)} id={id} placeholder={placeholder} />
		</Styled.Container>
	);
}
export default InputArea;
