import { create } from "zustand";

interface MagicFooterProps {
	params: any;
	openMagicFooter: boolean;
	setOpenMagicFooter: (value: boolean) => void;
	setParams: (value: object) => void;
}

export const useMagicFooter = create<MagicFooterProps>((set) => ({
	params: {},
	openMagicFooter: true,
	setOpenMagicFooter: (value) => set(() => ({ openMagicFooter: value })),
	setParams: (value) => set(() => ({ params: { ...value } }))
}));
