import { colors } from "@azify/ui-tokens";
import { ButtonGroup, Icon, ItemList, Radio, TextField, Typography } from "@azify/ui-web";
import * as S from "./RecurringDebit.styles";
import { RecurringDebitProps } from "./RecurringDebit.types";
import { useRef, useState } from "react";
import { useClick } from "@szhsin/react-menu";
import { DropDown } from "components/DropDown";
import { DropDownItem } from "components/DropDown/DropDownItem";

const frequency = [
	{
		id: 1,
		title: "Diária"
	},
	{
		id: 2,
		title: "Semanal"
	},
	{
		id: 3,
		title: "Quinzenal"
	},
	{
		id: 4,
		title: "Mensal"
	},
	{
		id: 5,
		title: "Bimestral"
	}
];

export default function RecurringDebit({ closeSideModal }: RecurringDebitProps) {
	const [checked, setChecked] = useState("limited");
	const [selectedFrequency, setSelectedFrequency] = useState("");
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const anchorProps = useClick(isMenuOpen, setIsMenuOpen);

	const ref = useRef(null);

	return (
		<S.Container>
			<div className="container-inputs">
				<Typography value="Cobrança recorrente" type="h1" isBold marginBottom={10} />

				<Typography
					value="Qual a frequência da cobrança?"
					type="subtitle1"
					isBold
					marginBottom={10}
				/>

				<div ref={ref} {...anchorProps}>
					<S.InputSelect>
						<Typography
							value={selectedFrequency ? selectedFrequency : "Selecione"}
							color="secondary"
							type="subtitle1"
							isBold
						/>

						<Icon
							color="secondary"
							size="small"
							name={isMenuOpen ? "chevron-up-filled-small" : "chevron-down-filled-small"}
						/>
					</S.InputSelect>
				</div>

				<DropDown
					isOpen={isMenuOpen}
					setOpen={setIsMenuOpen}
					innerRef={ref}
					align="end"
					widthContainer={440}
					content={
						<>
							{frequency.map((item) => (
								<DropDownItem
									key={item.id}
									onClick={() => {
										setSelectedFrequency(item.title);
									}}
									title={item.title}
								/>
							))}
						</>
					}
				/>

				<Typography
					value="Qual o tipo da cobrança?"
					type="subtitle1"
					isBold
					marginBottom={10}
					marginTop={16}
				/>

				<div className="row">
					<div className="row pointer" onClick={() => setChecked("limited")}>
						<Radio isChecked={checked === "limited"} color="default" />

						<Typography value="Limitada" type="subtitle1" isBold marginLeft={10} />
					</div>

					<div className="row pointer ml-20" onClick={() => setChecked("ilimited")}>
						<Radio isChecked={checked === "ilimited"} color="default" />

						<Typography value="Ilimitada" type="subtitle1" isBold marginLeft={10} />
					</div>
				</div>

				<Typography
					value="Qual a quantidade?"
					type="subtitle1"
					isBold
					marginBottom={10}
					marginTop={25}
				/>

				<S.InputNumber placeholder="ex: 3" type="number" min="1" max="100" />
			</div>

			<div className="footer">
				<ButtonGroup
					variant="default"
					isInverted
					customColorBackgroundPrimaryButton={colors.black.main}
					customColorTextTransparentButton={colors.black.main}
					titleSecondary="Não quero recorrência"
					titlePrimary="Alterar"
					onPressPrimary={() => closeSideModal(false)}
					onPressSecondary={() => {
						closeSideModal(false);
					}}
				/>
			</div>
		</S.Container>
	);
}
