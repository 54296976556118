import { colors } from "@azify/ui-tokens";
import { transparentize } from "polished";
import styled, { css } from "styled-components";

export const PinEachFieldWrapper = styled.div<{
	showBorder: boolean;
}>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;

	${({ showBorder }) =>
		showBorder
			? css`
					border-bottom: 1px solid ${colors.primary.main};
					animation: blink 1s;
					animation-iteration-count: infinite;
				`
			: css`
					border: none;
				`};

	.dot-absolutely {
		width: 12px;
		height: 12px;
		border-radius: 50%;
		text-align: baseline;
		background-color: ${colors.gray.main};
		position: absolute;
	}

	.dot-absolutely-filled {
		width: 12px;
		height: 12px;
		border-radius: 50%;
		text-align: baseline;
		background-color: ${colors.primary.main};
		position: absolute;
	}
`;

export const ContainerAllFields = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
	width: 100%;
	justify-content: center;
	align-items: center;

	@keyframes blink {
		50% {
			border-color: ${transparentize(0.8, colors.primary.main)};
		}
	}
`;

export const FieldInput = styled.input<{
	isPassword: boolean;
}>`
	z-index: 1;
	background-color: transparent;
	border: none;
	text-align: center;
	caret-color: transparent;

	${({ isPassword }) =>
		isPassword
			? css`
					color: transparent;
				`
			: css`
					color: ${colors.black.main};
					font-size: 29px;
					font-style: normal;
					font-weight: 400;
					line-height: 120%;
				`}
`;
