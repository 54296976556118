import * as Styled from "./CreatePixQrCode.styles";
import { Case, Switch } from "react-if";
import { useStepManager } from "hooks/useStepManager";
import { useLocation } from "react-router-dom";
import { CreatePixQrCodeSteps } from "./CreatePixQrCode.types";
import CreateInfoDetails from "./Steps/0.CreateInfoDetails";
import ShowPixQrCodeResult from "./Steps/1.ShowPixQrCodeResult";

const CreatePixQrCode = () => {
	const { currentStep } = useStepManager();
	const typedCurrentStep = currentStep as CreatePixQrCodeSteps;

	const { state } = useLocation();

	return (
		<Styled.Container>
			<Styled.CenterContainer>
				<Switch>
					<Case condition={typedCurrentStep === "CreateInfoDetails"}>
						<CreateInfoDetails />
					</Case>

					<Case condition={typedCurrentStep === "ShowPixQrCodeResult"}>
						<ShowPixQrCodeResult />
					</Case>
				</Switch>
			</Styled.CenterContainer>
		</Styled.Container>
	);
};

export default CreatePixQrCode;
