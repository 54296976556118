import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 12px 32px;
	margin-top: 12px;
	height: 100%;

	.container-inputs {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	.footer {
		margin-top: 48px;
		padding-bottom: 24px;
	}

	.grid-inputs {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 16px;
	}
`;
