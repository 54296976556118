import * as S from "./MagicFooter.styles";
import { motion } from "framer-motion";
import { MagicFooterProps } from "./MagicFooter.types";
import { useMatch, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { Icon } from "@azify/ui-web";
import { colors } from "@azify/ui-tokens";
import { When } from "react-if";
import SideToSideArrowsSVG from "assets/svg/sideToSideArrow";

const MagicFooter = () => {
	const [animCompleted, setAnimCompleted] = useState(false);

	const [selectedId, setSelectedId] = useState<number>();

	const navigate = useNavigate();

	const currentRoute = window.location.pathname.split("/")[1];

	const items = useMemo(() => {
		return [
			{
				id: 1,
				route: "/dashboard",
				icon: (
					<Icon
						customColor={currentRoute === "dashboard" ? colors.black.main : colors.gray.dark}
						size="medium"
						name={currentRoute === "dashboard" ? "home-filled" : "home"}
					/>
				)
			},
			{
				id: 2,
				route: "/statement",
				icon: (
					<Icon
						customColor={currentRoute === "statement" ? colors.black.main : colors.gray.dark}
						size="medium"
						name="list-02"
					/>
				)
			},
			{
				id: 3,
				route: "/gateway",
				icon: (
					<Icon
						customColor={currentRoute === "gateway" ? colors.black.main : colors.gray.dark}
						size="medium"
						name="coin-dollar"
					/>
				)
			},
			{
				id: 4,
				route: "/otc",
				icon: (
					<SideToSideArrowsSVG
						color={currentRoute === "otc" ? colors.black.main : colors.gray.dark}
					/>
				)
			}
		];
	}, [currentRoute, selectedId]);

	const goToRouter = (selectedItem: MagicFooterProps["items"][0]) => {
		if (selectedItem.route !== "/statement") {
			navigate(selectedItem.route);
		}
	};

	const IsDash = useMatch("/dashboard");
	const IsGateway = useMatch("/gateway");
	const IsOtc = useMatch("/otc");

	const inThisRouters = !!IsDash || !!IsGateway || !!IsOtc;

	return (
		<When condition={inThisRouters}>
			<S.Container id="magic-footer">
				<S.Content>
					{items?.map((item, index) => {
						const isSelected = currentRoute === item.route.replace("/", "");

						return (
							<motion.div
								key={item.id}
								initial={{ opacity: 0, y: 100 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{
									duration: 0.5,
									delay: index * 0.1
								}}
								onAnimationComplete={() => {
									setAnimCompleted(true);
									setSelectedId(item.id);
								}}>
								<div style={{}} onClick={() => goToRouter(item)} className={"round-container"}>
									{item.icon}
								</div>

								{isSelected ? (
									<motion.div
										style={{
											marginTop: -60
										}}
										layoutId="selected"
										className={`round-container ${animCompleted ? "selected" : ""}`}
										onClick={() => goToRouter(item)}
									/>
								) : null}
							</motion.div>
						);
					})}
				</S.Content>
			</S.Container>
		</When>
	);
};

export default MagicFooter;
