const SuccessSVG = () => (
	<svg
		width={116}
		height={115}
		viewBox="0 0 116 115"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<rect
			x={3.75}
			y={3.25}
			width={108.5}
			height={108.5}
			rx={54.25}
			stroke="#026FF4"
			strokeOpacity={0.05}
			strokeWidth={6}
		/>
		<path d="M36.5 58.6567L50.4184 73L80.5 42" stroke="#026FF4" strokeWidth={6} />
	</svg>
);
export default SuccessSVG;
