import Header from "components/Header/Header.component";
import * as Styled from "./styles";
import { Outlet } from "react-router-dom";

const AuthRouterLayout = ({ newOrg }: { newOrg?: boolean }) => {
	return (
		<>
			<Header isAuth={false} newOrg={newOrg} />
			<Styled.Container id="page-wrapper">
				<Outlet />
			</Styled.Container>
		</>
	);
};

export default AuthRouterLayout;
