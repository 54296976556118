import * as Styled from "../GatewayOnboarding.styles";
import { useNavigate } from "react-router-dom";
import gatewayImage from "assets/images/gateway-promo.png";
import { ButtonGroup, Check, Typography } from "@azify/ui-web";
import { useStepManager } from "hooks/useStepManager";
import { useState } from "react";

export const MerchantMethodsStep = () => {
	const navigate = useNavigate();
	const { setCurrentStep } = useStepManager();
	const [isTermsAccepted, setIsTermsAccepted] = useState(false);

	return (
		<Styled.MainStepContainer>
			<Styled.ScrollStepContainer>
				<Styled.CenterContent>
					<img src={gatewayImage} width={120} height={120} />

					<Typography
						type="h1"
						isBold
						marginTop={8}
						marginBottom={16}
						value="Seu negócio está a um passo de aceitar múltiplos pagamentos!"
					/>

					<Typography
						type="body1"
						value="Para garantir a integração do gateway de pagamentos em seu negócio, pedimos que você leia e aceite nossos termos de uso. Isso nos ajudará a entender melhor suas necessidades e a personalizar nossa solução de acordo com a sua empresa."
						marginBottom={24}
					/>

					<div className="terms-of-use">
						<div className="pointer">
							<Check
								isChecked={isTermsAccepted}
								onToggle={() => {
									setIsTermsAccepted((prev) => !prev);
								}}
							/>
						</div>
						<div className="row-texts">
							<Typography width="auto" type="body1" value="Li e concordo com os" />
							<div className="pointer">
								<Typography
									width="auto"
									type="body1"
									color="accent"
									value="Termos de Integração ao Serviço de Gateway."
								/>
							</div>
						</div>
					</div>
				</Styled.CenterContent>
			</Styled.ScrollStepContainer>

			<div className="step-footer">
				<div className="button-container">
					<ButtonGroup
						variant="default"
						titlePrimary="Vamos começar"
						titleSecondary="Voltar"
						isInverted
						isDisabledPrimary={!isTermsAccepted}
						onPressPrimary={() => setCurrentStep("FinishStep")}
						onPressSecondary={() => {
							navigate("/settings/plans/gateway");
						}}
					/>
				</div>
			</div>
		</Styled.MainStepContainer>
	);
};
