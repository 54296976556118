import * as Styled from "../NewDebitCollection.styles";
import {
	Avatar,
	GenericButton,
	HeaderNavigation,
	IconButton,
	ItemList,
	QuickButton,
	TextField,
	Typography
} from "@azify/ui-web";
import TransactionalInput from "components/Input/TransactionalInput/TransactionalInput.component";
import { useStepManager } from "hooks/useStepManager";
import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import { useFormContext } from "react-hook-form";
import { useState } from "react";
import ModalSideBar from "components/ModalSideBar";
import PaymentMethod from "common/modals/sideRight/PaymentMethod/PaymentMethod.component";
import RecurringDebit from "common/modals/sideRight/RecurringDebit/RecurringDebit.component";
import { dateMask } from "helpers/mask";

export const ConfirmDataDebit = () => {
	const { setCurrentStep, params, setParams } = useStepManager();

	const [values, setValues] = useState("");
	const [showEditPositionStep, setShowEditPositionStep] = useState("none");
	const [openPaymentModalSideBar, setOpenPaymentModalSideBar] = useState(false);
	const [openRecurringDebitModalSideBar, setOpenRecurringDebitModalSideBar] = useState(false);

	const closeSideModalPaymentMethod = () => setOpenPaymentModalSideBar(false);
	const closeSideModalRecurringDebit = () => setOpenRecurringDebitModalSideBar(false);

	const { trigger, setFocus, watch } = useFormContext();

	const onClickEdit = (position: string) => {
		if (showEditPositionStep === position) {
			setShowEditPositionStep("none");
		} else {
			setShowEditPositionStep(position);

			setTimeout(() => {
				setFocus(position);
			}, 500);
		}

		const hasToSomeValue = watch(position);

		if (hasToSomeValue) trigger(position);
	};

	const goToNextStep = () => {
		setParams({
			values: values
		});
		setCurrentStep("Success");
	};

	const [expirationDate, setExpirationDate] = useState("");
	const [msg, setMsg] = useState("");

	const payParams = {
		...params
	} as {
		bill: boolean;
		pix: boolean;
	};

	const label = payParams.bill ? "Boleto" : payParams.pix ? "Pix" : "Diversos";

	return (
		<>
			<Styled.CenterContainer>
				<div className="display-between">
					<HeaderNavigation
						buttonOnTitle={false}
						title="Nova cobrança"
						quickButtonColor="accent"
						backIconClick={() => setCurrentStep("NewClient")}
					/>

					<div className="container-value-and-beneficiary">
						<Avatar
							onClick={() => {}}
							fullName={(watch("name") as string) || "Novo Cliente"}
							type="initials"
							size="xlarge"
						/>

						<div className="container-input-edit">
							<div style={{ width: 40 }} />

							<TransactionalInput
								placeHolder="R$ 00,00"
								onChange={(v) => {
									setValues(v);
								}}
							/>

							<IconButton color="primary" size="medium" icon="pencil" onClick={() => {}} />
						</div>

						<Typography
							textAlign="center"
							isBold
							type="subtitle1"
							value={(watch("name") as string) || "Novo Cliente"}
							color="accent"
							marginTop={8}
						/>
					</div>

					<div className="options-container">
						<ItemList
							behavior="default"
							label="Método de pagamento"
							supportParagraph={Object.keys(payParams).length >= 1 ? label : "Não configurado"}
							leadingIconColor="primary"
							leadingIconName="money"
							leadingContent="icon"
							trailingContent="quickButton"
							trailingQuickButtonLabel="Alterar"
							trailingQuickButtonOnPress={() => {
								setParams({
									value: values
								});
								setOpenPaymentModalSideBar(true);
							}}
							isDividerVisible
							trailingSupportBadgeColor="danger"
							trailingSupportBadgeTitle="Não verificado"
							trailingSupportBadgeLayout="hint"
							trailingSupportContent="none"
						/>

						<ItemList
							behavior="default"
							label="Validade"
							supportParagraph={
								expirationDate
									? `Até (${expirationDate})`
									: `Até (${dayjs().locale("pt-br").add(1, "day").format("DD/MM/YYYY")})`
							}
							leadingIconColor="primary"
							leadingIconName="calendar-empty"
							leadingContent="icon"
							trailingContent="quickButton"
							trailingQuickButtonLabel={
								showEditPositionStep === "validade" ? "Cancelar" : "Alterar"
							}
							trailingQuickButtonOnPress={() => onClickEdit("validade")}
							isDividerVisible
							trailingSupportBadgeColor="danger"
							trailingSupportBadgeTitle="Não verificado"
							trailingSupportBadgeLayout="hint"
							trailingSupportContent="none"
						/>

						<AnimatePresence mode="wait" initial={false}>
							{showEditPositionStep === "validade" && (
								<motion.div style={{ padding: "0px 32px 10px 32px" }}>
									<TextField
										maxLength={50}
										placeholder={`ex: ${dayjs().locale("pt-br").add(2, "day").format("DD/MM/YYYY")}`}
										hintText="Insira a data de expiração deste link."
										hintVariant="info"
										onChange={(v) => setExpirationDate(dateMask(v))}
										value={expirationDate}
										onClear={() => setExpirationDate("")}
									/>
									<br />
									<QuickButton
										onClick={() => {
											setShowEditPositionStep("none");
										}}
										color="accent"
										label="Salvar data"
									/>
								</motion.div>
							)}
						</AnimatePresence>

						<ItemList
							behavior="default"
							label="Cobrança recorrente"
							supportParagraph="Não configurado"
							leadingIconColor="primary"
							leadingIconName="circular-arrow-left"
							leadingContent="icon"
							trailingContent="quickButton"
							trailingQuickButtonLabel="Alterar"
							trailingQuickButtonOnPress={() => setOpenRecurringDebitModalSideBar(true)}
							isDividerVisible
							trailingSupportBadgeColor="danger"
							trailingSupportBadgeTitle="Não verificado"
							trailingSupportBadgeLayout="hint"
							trailingSupportContent="none"
						/>

						<ItemList
							behavior="default"
							label="Mensagem"
							supportParagraph="(Opcional)"
							leadingIconColor="primary"
							leadingIconName="bubble-chat"
							leadingContent="icon"
							trailingContent="quickButton"
							trailingQuickButtonLabel="Alterar"
							trailingQuickButtonOnPress={() => onClickEdit("mensagem")}
							isDividerVisible
							trailingSupportBadgeColor="danger"
							trailingSupportBadgeTitle="Não verificado"
							trailingSupportBadgeLayout="hint"
							trailingSupportContent="none"
						/>

						<AnimatePresence mode="wait" initial={false}>
							{showEditPositionStep === "mensagem" && (
								<motion.div style={{ padding: "0px 32px 10px 32px" }}>
									<TextField
										onChange={(v) => setMsg(v)}
										value={msg}
										maxLength={140}
										placeholder="..."
										hintText="0/140 caracteres."
										onClear={() => setMsg("")}
									/>
									<br />
									<QuickButton
										onClick={() => {
											setShowEditPositionStep("none");
										}}
										color="accent"
										label="Salvar data"
									/>
								</motion.div>
							)}
						</AnimatePresence>
					</div>
					<div style={{ paddingBottom: 20 }}>
						<GenericButton
							onClick={goToNextStep}
							isDisabled={!values || values === "0,00"}
							title="Gerar cobrança"
							layout="standard"
							color="primary"
						/>
					</div>
				</div>
			</Styled.CenterContainer>

			<ModalSideBar
				open={openPaymentModalSideBar}
				onClose={closeSideModalPaymentMethod}
				hiddenHeader>
				<PaymentMethod key={values} values={values} closeSideModal={setOpenPaymentModalSideBar} />
			</ModalSideBar>

			<ModalSideBar open={openRecurringDebitModalSideBar} onClose={closeSideModalRecurringDebit}>
				<RecurringDebit closeSideModal={setOpenRecurringDebitModalSideBar} />
			</ModalSideBar>
		</>
	);
};
