import { create } from "zustand";

export type ModalSideBarContents = "downloadAzifyApp" | "pixKey" | "noContent";

interface OpenModalSideBarProps {
	openModalSideBar: boolean;
	setOpenModalSideBar: (boolean: boolean) => void;
	content: ModalSideBarContents;
	setModalSidebarContent: (content: ModalSideBarContents) => void;
	params: {
		[key: string]: any;
	};
	setParams: (value: object) => void;
	currentStep?: string;
	setCurrentStep?: (value: string) => void;
}

export const useOpenModalSideBar = create<OpenModalSideBarProps>((set) => ({
	openModalSideBar: false,
	content: "noContent",
	params: {},
	currentStep: "",
	setCurrentStep: (value) => set(() => ({ currentStep: value })),
	setParams: (value) => set(() => ({ params: { ...value } })),
	setOpenModalSideBar: (boolean) => set(() => ({ openModalSideBar: boolean })),
	setModalSidebarContent: (content) => set(() => ({ content }))
}));
