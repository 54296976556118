import {
	Divider,
	GenericButton,
	Icon,
	IconDefaultType,
	IconFilledType,
	ItemList,
	QuickButton,
	SectionHeading,
	Typography
} from "@azify/ui-web";
import * as Styled from "./Plans.styles";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "components/BreadCrumb/BreadCrumb.component";
import { Tooltip as ReactTooltip } from "react-tooltip";
import otcImage from "assets/images/otc-promo.png";
import gatewayImage from "assets/images/gateway-promo.png";

export const PlansPage = () => {
	const navigate = useNavigate();

	const goToOtcPlans = () => navigate("/settings/plans/otc");
	const goToGateway = () => navigate("/settings/plans/gateway");

	const menuItems: {
		label: string;
		icon: IconFilledType | IconDefaultType;
		trailingText: string;
	}[] = [
		{
			label: "Transferências Pix",
			icon: "pix",
			trailingText: "R$ 1,99 por envio"
		},
		{
			label: "Transferências TED",
			icon: "bank-01",
			trailingText: "R$ 5.00 por envio"
		},
		{
			label: "Criptoativos",
			icon: "coin-crypto",
			trailingText: "Grátis"
		},
		{
			label: "Investimentos",
			icon: "chart-up-02",
			trailingText: "Grátis"
		},
		{
			label: "Pagamento de Boletos",
			icon: "barcode",
			trailingText: "Grátis"
		},
		{
			label: "Cartão Físico e Virtual",
			icon: "cards",
			trailingText: "Grátis"
		},
		{
			label: "Usuários",
			icon: "group",
			trailingText: "até 02"
		}
	];

	const menuItensWithButton: {
		icon: IconFilledType | IconDefaultType;
		label: string;
		subLabel: string;
		type: "available" | "unavailable";
		buttonText: string;
		buttonAction: () => void;
		popUpText: string;
	}[] = [
		{
			label: "Pagamento em lote",
			subLabel: "Indisponível no plano atual",
			type: "unavailable",
			buttonText: "Upgrade de plano",
			buttonAction: () => {},
			icon: "up-down-arrow",
			popUpText: ""
		},
		{
			label: "Gerente de Conta",
			subLabel: "Indisponível no plano atual",
			type: "unavailable",
			buttonText: "Upgrade de plano",
			buttonAction: () => {},
			icon: "user",
			popUpText:
				"Automatize o pagamento dos seus<br /> clientes com opções de boleto, Pix,<br /> cartão e muito mais."
		},
		{
			label: "Mesa de Operações OTC",
			subLabel: "Disponível gratuitamente após aprovação",
			type: "available",
			buttonText: "Solicitar agora",
			icon: "coin-pair-03",
			buttonAction: goToOtcPlans,
			popUpText:
				"A mesa de operações da Azify, é um<br /> serviço que permite negociações over-<br />the-counter (OTC) de criptoativos."
		},
		{
			label: "Gateway de Pagamentos",
			subLabel: "Disponível gratuitamente após aprovação",
			type: "available",
			buttonText: "Solicitar agora",
			icon: "bar-group-01",
			buttonAction: goToGateway,
			popUpText:
				"Um gerente exclusivo focado nas<br /> necessidades específicas da sua<br /> empresa."
		}
	];

	return (
		<Styled.Container>
			<Styled.CenterContent>
				<BreadCrumb
					items={[
						{
							label: "Configurações",
							action: () => {
								navigate("/settings");
							}
						},
						{
							label: "Planos e Serviços",
							action: () => {}
						}
					]}
					TextType="subtitle2"
				/>

				<div className="container-icon-label">
					<Icon name="group" size="large" />
					<Typography value="Planos e Serviços" isBold type="h1" />
				</div>

				<div className="container-columns">
					<div className="first-column">
						<SectionHeading
							behavior="default"
							title="Plano Basic"
							subHeading="Este é o seu plano atual"
						/>
						<div>
							{menuItems.map((item) => (
								<ItemList
									key={item.label}
									trailingSupportContent="none"
									behavior="default"
									label={item.label}
									leadingContent="icon"
									leadingIconName={item.icon}
									trailingContent="label"
									trailingLabel={item.trailingText}
									trailingLabelHierarchy="secondary"
									cursor="default"
									isDividerVisible
								/>
							))}

							{menuItensWithButton.map((item, index) => (
								<ItemList
									key={item.label}
									trailingQuickButtonOnPress={item.buttonAction}
									trailingSupportContent="none"
									behavior="default"
									label=""
									leadingContent="icon"
									leadingIconName={item.icon}
									isDividerVisible={index !== menuItems.length - 1}
									trailingContent="quickButton"
									trailingQuickButtonLabel={item.buttonText}
									trailingQuickButtonColor={item.type === "available" ? "accent" : "primary"}
									paragraph={item.subLabel}
									paragraphColor={item.type === "available" ? "accent" : "negative"}
									labelCustomized={
										<div className="container-custom-label-info-icon">
											<Typography value={item.label} type="subtitle1" isBold />

											{item.popUpText && (
												<a data-tooltip-id="account-manager" data-tooltip-html={item.popUpText}>
													<Icon name="info-circle-filled" color="tertiary" size="small" />
												</a>
											)}
										</div>
									}
								/>
							))}

							<ReactTooltip className="tooltip-style" place="top" id="account-manager" />

							<GenericButton
								marginTop={32}
								layout="outlined"
								color="secondary"
								title="Comparar e fazer upgrade"
							/>
						</div>
					</div>

					<div className="second-column">
						<div className="offers-for-you-card">
							<div className="container-icon-title">
								<Icon name="discount-02" size="medium" />

								<Typography value="Ofertas para você" isBold type="h4" />
							</div>

							<div className="explain-card">
								<img width={64} height={64} src={otcImage} />

								<div className="container-icon-desc">
									<Typography isBold value="Mesa de Operações OTC" type="h5" />

									<Typography
										value="Opera com grandes volumes de criptoativos? Tenha acesso a cotações exclusivas com liquidez garantida para o seu negócio."
										type="body2"
										color="secondary"
									/>
								</div>
							</div>

							<QuickButton onClick={goToOtcPlans} color="secondary" label="Solicitar agora" />

							<Divider gap="lg" paddingBottom paddingTop />

							<div className="explain-card">
								<img width={64} height={64} src={gatewayImage} />

								<div className="container-icon-desc">
									<Typography isBold value="Gateway de Pagamentos" type="h5" />

									<Typography
										value="Um gateway de pagamentos é o parceiro ideal para impulsionar o seu negócio, oferecendo uma variedade de opções de pagamento."
										type="body2"
										color="secondary"
									/>
								</div>
							</div>

							<QuickButton onClick={goToGateway} color="secondary" label="Solicitar agora" />
						</div>
					</div>
				</div>
			</Styled.CenterContent>
		</Styled.Container>
	);
};
