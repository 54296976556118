import styled from "styled-components";

export const Container = styled.div`
	padding: 12px 32px;
	margin-top: 12px;
	height: 100%;

	.display-between-erros {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		height: 100%;

		.padding-bottom-to-button {
			padding-bottom: 20px;
		}
	}
`;
