import * as Styled from "../GatewayOnboarding.styles";
import { useNavigate } from "react-router-dom";
import OtcFinishImage from "assets/images/otc-waiting-approve.png";
import { ButtonGroup, Typography } from "@azify/ui-web";

export const FinishStep = () => {
	const navigate = useNavigate();

	return (
		<Styled.MainStepContainer>
			<Styled.ScrollStepContainer>
				<Styled.CenterContent>
					<img src={OtcFinishImage} width={100} height={121} />

					<Typography
						type="h1"
						isBold
						marginTop={12}
						marginBottom={16}
						value="Tudo certo, agora é só aguardar!"
					/>

					<Typography
						type="body1"
						value="Nosso time irá analisar a sua solicitação para ter acesso ao nosso Serviço de Gateway de Pagamentos. Ao término da análise, você será notificado(a) aqui mesmo pela plataforma e pelo e-mail."
					/>
				</Styled.CenterContent>
			</Styled.ScrollStepContainer>

			<div className="step-footer">
				<div className="button-container">
					<ButtonGroup
						variant="default"
						titlePrimary="Ir para planos e serviços"
						removeButton="transparentButton"
						onPressPrimary={() => {
							navigate("/settings/plans");
						}}
					/>
				</div>
			</div>
		</Styled.MainStepContainer>
	);
};
