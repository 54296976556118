import { validateCPF } from "helpers/validate";
import * as Yup from "yup";

export const KYCValidationSchema = Yup.object().shape({
	documentNumber: Yup.string()
		.required("Required")
		.test("Is An Valid CPF", "Must be a valid document number", (val) => {
			return validateCPF(val);
		}),
	fullName: Yup.string().required("Required").min(3, "Must be at least 3 characters"),
	phoneNumber: Yup.string().required("Required"),
	email: Yup.string().email().required("Required"),
	politicallyExposedPerson: Yup.boolean().optional(),
	password: Yup.number().required("Required").min(6, "Must be at least 6 numbers"),
	confirmPassword: Yup.number()
		.required("Required")
		.oneOf([Yup.ref("password")])
});
