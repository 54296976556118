export const onlyNumbers = (value: string) => {
	if (!value) return "";

	return value?.replace(/\D/g, "");
};

export const firstLetterCapitalized = (value: string) => {
	if (!value) return "";

	return value.charAt(0).toUpperCase() + value.slice(1);
};

export function truncateStrings(str: string, n: number) {
	return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

export function noop(): void {
	//
}

export function range(start: number, length: number): number[] {
	return Array.from({ length }, (_, i) => i + start);
}

export function omit<T extends Record<string, unknown>>(keys: string[], input: T): T {
	const output: T = Object.create({});

	for (const key in input) {
		if (!keys.includes(key)) {
			Object.assign(output, { [key]: input[key] });
		}
	}

	return output;
}
