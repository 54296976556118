import * as Styled from "./Otc.styles";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "components/BreadCrumb/BreadCrumb.component";

import OtcBanner from "assets/images/otc-main-image.png";
import OtcIcon from "assets/images/otc-operations-icon.png";
import { Icon, Typography } from "@azify/ui-web";
import GenericButton from "components/Buttons/GenericButton";

export const OtcPage = () => {
	const navigate = useNavigate();

	return (
		<Styled.Container>
			<Styled.CenterContent>
				<BreadCrumb
					items={[
						{
							label: "Configurações",
							action: () => {
								navigate("/settings");
							}
						},
						{
							label: "Planos e Serviços",
							action: () => {
								navigate("/settings/plans");
							}
						},
						{
							label: "OTC",
							action: () => {}
						}
					]}
					TextType="subtitle2"
				/>
			</Styled.CenterContent>

			<div className="content">
				<div className="left-container">
					<div className="row mt-30">
						<img src={OtcIcon} width="26px" height="28px" alt="OTC Icon" />

						<Typography type="h5" value="Mesa de Operações OTC" isBold marginLeft={12} />
					</div>

					<h1 className="title">Explore oportunidades em operações</h1>

					<p className="subtitle mt-20 mb-20">
						Garanta as melhores cotações em múltiplas moedas com o auxílio de especialistas em nosso
						mercado de balcão (OTC). Oferecemos liquidez garantida para facilitar suas operações no
						mercado.
					</p>

					<div className="list-item">
						<Icon name="check-circle-filled" size="medium" color="accent" />

						<p className="subtitle">Negociação via chat com especialista</p>
					</div>

					<div className="list-item">
						<Icon name="check-circle-filled" size="medium" color="accent" />

						<p className="subtitle">Cotações especiais para você</p>
					</div>

					<div className="list-item">
						<Icon name="check-circle-filled" size="medium" color="accent" />

						<p className="subtitle">Múltiplos criptoativos</p>
					</div>

					<div className="button-container">
						<GenericButton
							textButton="Saiba mais"
							color="inverted"
							borderColor="secondary"
							textType="subtitle1"
							verticalPadding={17}
							onClick={() => {}}
						/>

						<GenericButton
							textButton="Conheça mais"
							customTextColor="inverted"
							color="primary"
							textType="subtitle1"
							verticalPadding={17}
							onClick={() => {
								navigate("/onboardingExtension/otc");
							}}
						/>
					</div>
				</div>

				<div className="right-container">
					<img src={OtcBanner} width="479px" height="518px" alt="OTC Banner" />
				</div>
			</div>
		</Styled.Container>
	);
};
