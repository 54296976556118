import { colors } from "@azify/ui-tokens";
import styled from "styled-components";
import { motion } from "framer-motion";

export const ContainerAnimation = styled(motion.div)<{
	paddingTop?: string;
}>`
	padding: 16px;
	padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop + "px" : "130px")};
	padding-bottom: 20px;
`;

export const MainStepContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	.step-footer {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding-top: 8px;
		padding-bottom: 24px;

		.button-container {
			width: 568px;
		}
	}
`;

export const ScrollStepContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
`;

export const CenterContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 600px;
	height: 100%;

	.step-header {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: fixed;
		backdrop-filter: blur(10px);
		background-color: ${colors.transparent};
		width: 600px;
		gap: 10px;
		z-index: 1;

		padding: 24px 12px 0 12px;

		.container-arrow-progress {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}
	}

	.tile-grid-container {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
		gap: 16px;
	}

	.fixed-go-button {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		position: fixed;
		background-color: ${colors.white.light};
		height: 88px;
		bottom: 0;
		left: 0;
	}

	.container-inputs-address {
		display: flex;
		flex-direction: column;
		gap: 24px;

		.container-save-address {
			margin-top: 16px;
		}
	}

	.container-list-add {
		display: flex;
		flex-direction: column;
		margin-top: 16px;
	}

	.container-business-info {
		margin-top: 24px;
		margin-bottom: 16px;
		padding: 5px;
		width: 100%;
		border-radius: 16px;
		border: 1px solid ${colors.black.opacity["10p"]};
	}

	.rounded-icon-container {
		width: 56px;
		height: 56px;
		border-radius: 32px;
		justify-content: center;
		align-items: center;
		display: flex;
		background-color: ${colors.primary.opacity["10p"]};
	}

	.company-container {
		display: flex;

		& .icon-container {
			display: flex;
			align-items: center;
			justify-content: center;

			padding: 16px;
			border-radius: 50px;
			background-color: ${colors.primary.opacity["10p"]};
			margin-right: 16px;

			& svg rect {
				fill: transparent;
			}
		}

		& .text-container {
			display: flex;
			flex-direction: column;
		}
	}
`;
