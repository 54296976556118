import { Chip, GenericButton, HeaderNavigation } from "@azify/ui-web";
import { useStepManager } from "hooks/useStepManager";
import { When } from "react-if";
import { useNavigate } from "react-router-dom";
import InputArea from "components/Input/InputArea/InputArea.component";
import { useState } from "react";

function VerifyBillCode() {
	const { setCurrentStep } = useStepManager();

	const navigate = useNavigate();

	const [barCode, setBarCode] = useState("");

	const goToNextStep = async () => {
		setCurrentStep("ShowBillDetails");
	};

	const pasteInInput = async () => {
		const input = document.querySelector<HTMLInputElement>("#paste-here");

		const text = await navigator.clipboard.readText();

		if (input && text) {
			input.value = text;
		}
	};

	return (
		<>
			<div className="display-between">
				<div className="gap">
					<HeaderNavigation
						buttonOnTitle={false}
						title="Pagar Boleto"
						quickButtonColor="accent"
						backIconClick={() => navigate("/transfer")}
					/>

					<div className="input-and-copy-container">
						<InputArea
							placeholder="Cole a linha de código"
							value={barCode}
							setValue={setBarCode}
							id="paste-here"
						/>

						<When condition={!barCode.length}>
							<div onClick={pasteInInput}>
								<Chip id="chip-paste" hierarchy="high" title="Colar" width="intrinsec" />
							</div>
						</When>
					</div>
				</div>
				<GenericButton onClick={goToNextStep} title="Avançar" layout="standard" color="primary" />
			</div>
		</>
	);
}
export default VerifyBillCode;
