import { Chip, GenericButton, HeaderNavigation, Typography } from "@azify/ui-web";
import DragAndDrop from "components/Input/DragAndDrop/DragAndDrop.component";
import { useStepManager } from "hooks/useStepManager";
import { useEffect, useState } from "react";
import { Else, If, Then, When } from "react-if";
import { useNavigate } from "react-router-dom";
import QrcodeDecoder from "qrcode-decoder/dist/index";
import InputArea from "components/Input/InputArea/InputArea.component";

function VerifyCode({ isQr }: { isQr: boolean }) {
	const { setCurrentStep } = useStepManager();
	const qr = new QrcodeDecoder();
	const navigate = useNavigate();

	const [error, setError] = useState<string>("");
	const [brCode, setBrCode] = useState<string>("");
	const [fileList, setFileList] = useState<File[]>([]);
	const [file, setFile] = useState<{ name: string; src: string | ArrayBuffer }>({
		name: "",
		src: ""
	});

	useEffect(() => {
		if (fileList.length && isQr) {
			const reader = new FileReader();

			reader.onload = function () {
				const dataURL = reader.result;
				if (dataURL) setFile({ name: fileList[0].name, src: dataURL });
			};
			reader.readAsDataURL(fileList[0]);
		}
	}, [fileList, isQr]);

	const goToNextStep = async () => {
		const img1: HTMLImageElement | null = document.querySelector("#fileQr");

		if (!isQr) {
			//resolve pix code

			setCurrentStep("ShowCodeDetails");

			return;
		}

		if (img1) {
			const code = await qr.decodeFromImage(img1);

			if (code?.data) {
				//resolve pix code
				setCurrentStep("ShowCodeDetails");
			} else {
				setError("Não foi possível ler o QR Code, tente novamente");
			}
		}
	};

	const pasteInInput = async () => {
		const input = document.querySelector<HTMLInputElement>("#paste-here");

		const text = await navigator.clipboard.readText();

		if (input && text) {
			input.value = text;

			setBrCode(text);
		}
	};

	return (
		<>
			<div className="display-between">
				<div className="gap">
					<HeaderNavigation
						buttonOnTitle={false}
						title={isQr ? "Qr Code" : "Enviar Pix Copia e Cola"}
						quickButtonColor="accent"
						backIconClick={() => navigate("/pixArea")}
					/>

					<If condition={isQr}>
						<Then>
							<DragAndDrop maxFiles={1} fileList={fileList} setFileList={setFileList} />
							<Typography type="smaller" color="warning" value={error} />
						</Then>

						<Else>
							<div className="input-and-copy-container">
								<InputArea
									placeholder="Cole o código Pix"
									value={brCode}
									setValue={setBrCode}
									id="paste-here"
								/>

								<When condition={!brCode.length}>
									<div onClick={pasteInInput}>
										<Chip id="chip-paste" hierarchy="high" title="Colar" width="intrinsec" />
									</div>
								</When>
							</div>
						</Else>
					</If>
				</div>
				<GenericButton
					isDisabled={(!isQr && !brCode.length) || (isQr && !fileList.length)}
					onClick={goToNextStep}
					title="Avançar"
					layout="standard"
					color="primary"
				/>
			</div>

			<When condition={fileList.length}>
				<img style={{ display: "none" }} id="fileQr" src={String(file.src)} alt="your" />
			</When>
		</>
	);
}
export default VerifyCode;
