import { Chip, IconButton, Typography } from "@azify/ui-web";
import * as Styled from "./DragAndDrop.styles";
import { colors } from "@azify/ui-tokens";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { useCallback } from "react";

function DragAndDrop({
	fileList = [],
	setFileList,
	dropzoneOptions,
	maxFiles = 1
}: {
	dropzoneOptions?: DropzoneOptions;
	fileList: File[];
	setFileList: React.Dispatch<React.SetStateAction<File[]>>;
	maxFiles: number;
}) {
	const hasAllFilesLoaded = fileList.length >= maxFiles;

	const onDrop = useCallback(
		(files: File[]) => {
			setFileList((prevFileList) => [...prevFileList, ...files]);
		},
		[setFileList]
	);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: {
			"image/*": [".jpeg", ".png", ".jpg", ".bmp"],
			"application/pdf": [".pdf"]
		},
		disabled: hasAllFilesLoaded,
		...dropzoneOptions
	});

	return (
		<Styled.Container
			{...getRootProps()}
			style={{
				cursor: hasAllFilesLoaded ? "not-allowed" : "pointer"
			}}>
			<IconButton icon="add" color="primary" />
			<div>
				<Typography
					textAlign="center"
					colorCustom={colors.gray.dark}
					type="body2"
					marginBottom={8}
					value="Arraste e solte ou escolha o arquivo para enviar"
				/>

				<Chip
					id="chip-upload"
					hierarchy="high"
					title="Formatos suportados: PDF, PNG, JPG"
					width="intrinsec"
					iconRightColor="tertiary"
					iconRight="info-circle-small"
				/>
			</div>

			<Styled.HiddenInput {...getInputProps()} />
		</Styled.Container>
	);
}
export default DragAndDrop;
