import {
	Banner,
	ButtonGroup,
	GenericButton,
	IconButton,
	ItemList,
	Radio,
	SectionHeading,
	TextField,
	Tile,
	Tooltip,
	Typography
} from "@azify/ui-web";
import { Content, Container } from "./UpdatePartner.styles";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updatePartnerSchema } from "pages/Onboarding/Kyb/Validations/KybValidations";
import { useMemo, useState } from "react";
import { Partner } from "pages/Onboarding/Kyb/Kyb.types";
import Dialog from "components/Modal/Dialog/Dialog.component";
import { UpdatePartnerProps } from "./UpdatePartner.types";
import { When } from "react-if";
import { percentageMask } from "helpers/mask";
import { ToastAlert } from "components/Toast/Toast.component";
import { colors } from "@azify/ui-tokens";

function UpdatePartner({
	personalPartnersMethods,
	businessPartnersMethods,
	goBackFunction,
	hideAccountControl,
	bannerErrorMsg,
	closeModal,
	action
}: UpdatePartnerProps) {
	const [askForSure, setAskForSure] = useState(false);
	const [openAccountAccessModal, setOpenAccountAccessModal] = useState(false);

	const personalPartnersList = personalPartnersMethods.fields as Partner[];
	const businessPartnerList = businessPartnersMethods.fields as Partner[];

	const isBusiness = action.type === "update-business";

	const defaultValues = isBusiness
		? businessPartnerList[action.index]
		: personalPartnersList[action.index];

	const {
		handleSubmit,
		control,
		formState: { errors },
		watch,
		setValue
	} = useForm({
		shouldUnregister: true,
		resolver: yupResolver(updatePartnerSchema),
		defaultValues: { ...defaultValues }
	});

	const makeUpdate = (data: Omit<Partner, "uniqueId">) => {
		const businessPartnersList = businessPartnerList[action.index]?.businessPartners;

		if (isBusiness) {
			businessPartnersMethods.update(action.index, {
				...data,
				businessPartners: businessPartnersList || [],
				personalPartners: personalPartnersList || []
			});
		} else {
			personalPartnersMethods.update(action.index, {
				...data
			});
		}

		closeModal();

		if (watch("accountAccess")) {
			ToastAlert({
				title: "Código de convite enviado!",
				subtitle: `${watch("name")} recebeu o convite no e-mail.`,
				autoCloseEnable: true,
				type: "info"
			});
		}
	};

	const onRemovePartner = () => {
		if (isBusiness) {
			businessPartnersMethods.remove(action.index);
		} else {
			personalPartnersMethods.remove(action.index);
		}

		closeModal();

		ToastAlert({
			title: "Representante removido.",
			subtitle: "",
			autoCloseEnable: true,
			type: "error"
		});

		if (goBackFunction) goBackFunction();
	};

	const getRightDeleteLabel = useMemo(() => {
		return isBusiness
			? {
					headerTitle: "Deseja remover esta empresa como sócio?",
					description: `Ao confirmar a remoção, a empresa ${watch("name")} e todos seus representantes não farão mais parte da lista.`
				}
			: {
					headerTitle: "Deseja remover este representante?",
					description: `Ao confirmar a remoção, ${watch("name")} não fará mais parte da lista de sócios da empresa.`
				};
	}, [action.type, action.index]);

	const resendMail = () => {
		ToastAlert({
			title: "Código de convite reenviado!",
			subtitle: `${watch("name")} recebeu um novo convite no e-mail.`,
			autoCloseEnable: true,
			type: "info"
		});
	};

	return (
		<Container>
			<div className="close-button-wrapper">
				<div className="container-close-header-component">
					<IconButton onClick={closeModal} color="inverted" icon="close" />

					<div className="row gap-8">
						{!isBusiness && <IconButton onClick={resendMail} color="inverted" icon="mail" />}
						<IconButton onClick={() => setAskForSure(true)} color="inverted" icon="trash" />
					</div>
				</div>
				<Typography value="Editar Sócio" type="h1" isBold />
			</div>

			<Content>
				<div>
					<div>
						<When condition={bannerErrorMsg}>
							<Banner
								hierarchy="low"
								layout="inset"
								behavior="default"
								description={bannerErrorMsg}
								color="danger"
								artwork="icon"
								iconName="alert-triangle-filled"
							/>
						</When>

						<div className="container-inputs">
							<div style={{ display: "none" }}>
								<Controller
									control={control}
									name="documentNumber"
									render={({ field }) => {
										return (
											<TextField
												maxLength={22}
												label={isBusiness ? "CNPJ" : "CPF"}
												placeholder={isBusiness ? "Informe o CNPJ" : "Informe o CPF"}
												hookForm={field}
												hintText={errors.documentNumber?.message}
												hintVariant="danger"
											/>
										);
									}}
								/>

								<Controller
									control={control}
									name="name"
									render={({ field }) => (
										<TextField
											maxLength={22}
											label="Nome"
											placeholder="Informe o nome"
											hookForm={field}
											hintText={errors.name?.message}
											hintVariant="danger"
										/>
									)}
								/>
							</div>

							<div className="container-name-doc">
								<ItemList
									leadingContent="avatar"
									leadingAvatarFullName={watch("name")}
									label={watch("name")}
									paragraph={`Sócio - ${watch("documentNumber")}`}
									behavior="default"
									trailingSupportContent="none"
								/>
							</div>

							<Controller
								control={control}
								name="partnerPercentage"
								render={({ field }) => (
									<TextField
										maxLength={22}
										label="Qual o percentual dele na empresa?"
										placeholder="0,00"
										hookForm={field}
										hintText={errors.partnerPercentage?.message}
										hintVariant="danger"
										maskFunction={percentageMask}
										leadingLabel="%"
									/>
								)}
							/>

							<Controller
								control={control}
								name="phone"
								render={({ field }) => (
									<TextField
										maxLength={22}
										label="Telefone"
										placeholder="Informe o telefone"
										hookForm={field}
										hintText={errors.phone?.message}
										hintVariant="danger"
									/>
								)}
							/>

							<Controller
								control={control}
								name="email"
								render={({ field }) => (
									<TextField
										maxLength={22}
										label="E-mail"
										placeholder="Informe o e-mail"
										hookForm={field}
										hintText={errors.email?.message}
										hintVariant="danger"
									/>
								)}
							/>
						</div>
					</div>

					<When condition={!isBusiness}>
						<Controller
							control={control}
							name="politicallyExposedPerson"
							render={() => (
								<ItemList
									trailingSupportContent="customized"
									trailingContent="toggle"
									label="Esta é uma Pessoa Politicamente Exposta?"
									behavior="default"
									trailingToggleValue={watch("politicallyExposedPerson")}
									trailingToggleOnPress={() => {
										setValue("politicallyExposedPerson", !watch("politicallyExposedPerson"));
									}}
								/>
							)}
						/>
					</When>

					<When condition={!hideAccountControl}>
						<SectionHeading
							behavior="default"
							title="Liberar acesso à conta para este sócio?"
							subHeading="O sócio terá acesso à conta e poderá realizar movimentações financeiras, entre outras ações."
						/>
						<Controller
							control={control}
							name="accountAccess"
							render={() => (
								<div className="radios-container">
									<Tile
										label="Sim"
										size="medium"
										onClick={() => setValue("accountAccess", true)}
										leadingRadioColor="primary"
										leadingContent="customized"
										backgroundColor="white"
										trailingContent="customized"
										leadingContentCustomized={
											<Radio
												isChecked={watch("accountAccess")}
												onToggle={() => setValue("accountAccess", true)}
												color="default"
											/>
										}
									/>

									<Tile
										label="Não"
										size="medium"
										onClick={() => setValue("accountAccess", false)}
										leadingRadioColor="primary"
										leadingContent="customized"
										backgroundColor="white"
										trailingContent="customized"
										leadingContentCustomized={
											<Radio
												isChecked={!watch("accountAccess")}
												onToggle={() => setValue("accountAccess", false)}
												color="default"
											/>
										}
									/>
								</div>
							)}
						/>

						<Tooltip
							isVisible={!!watch("accountAccess")}
							title="Este representante precisa validar a biometria no nosso aplicativo para finalizar o cadastro. O código de convite será enviado por e-mail."
							arrowSize="small"
							arrowPosition="top"
							hierarchy="secondary"
							layout="default"
						/>
					</When>
				</div>

				<div className="footer-button">
					<GenericButton
						layout="standard"
						color="secondary"
						title="Atualizar informações"
						onClick={
							watch("accountAccess")
								? () => setOpenAccountAccessModal(true)
								: handleSubmit(makeUpdate)
						}
					/>
				</div>
			</Content>

			{/* Ask for sure delete partner */}

			<Dialog
				openDialog={askForSure}
				setOpenDialog={setAskForSure}
				headerTitle={getRightDeleteLabel.headerTitle}
				layout="alternative"
				buttonGroupProps={{
					titlePrimary: "Remover",
					titleSecondary: "Cancelar",
					variant: "default",
					onPressPrimary: onRemovePartner,
					onPressSecondary: () => setAskForSure(false)
				}}>
				<div style={{ padding: 16 }}>
					<Typography value={getRightDeleteLabel.description} type="body1" />
				</div>
			</Dialog>

			{/* Ask for sure Account access */}

			<Dialog
				openDialog={openAccountAccessModal}
				setOpenDialog={setOpenAccountAccessModal}
				headerTitle={`Permitir que ${watch("name")} tenha acesso à conta?`}
				layout="alternative"
				buttonType="group"
				buttonGroupProps={{
					onPressPrimary: handleSubmit(makeUpdate),
					onPressSecondary: () => setOpenAccountAccessModal(false),
					titlePrimary: "Permitir e enviar e-mail",
					customColorBackgroundPrimaryButton: colors.black.main,
					titleSecondary: "Cancelar",
					variant: "default"
				}}>
				<div style={{ padding: 16 }}>
					<Typography
						type="body1"
						value={`O sócio receberá um convite por e-mail para o endereço ${watch("email")}. Neste e-mail, ele encontrará as instruções para baixar nosso aplicativo e criar sua conta.`}
					/>
				</div>
			</Dialog>
		</Container>
	);
}

export default UpdatePartner;
