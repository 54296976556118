import { Case, Switch } from "react-if";
import { useStepManager } from "hooks/useStepManager";
import * as Styled from "./BuyOtc.styles";
import { ExchangeInputs } from "./Steps/0.ExchangeInputs";
import { BuyOtcSteps } from "./BuyOtc.types";
import { ExchangeSuccess } from "./Steps/1.ExChangeSuccess";

export const BuyOtcScreen = () => {
	const { currentStep } = useStepManager();
	const typedCurrentStep = currentStep as BuyOtcSteps;

	return (
		<Styled.Container>
			<Switch>
				<Case condition={typedCurrentStep === "ExchangeInputs"}>
					<ExchangeInputs />
				</Case>

				<Case condition={typedCurrentStep === "ExchangeSuccess"}>
					<ExchangeSuccess />
				</Case>
			</Switch>
		</Styled.Container>
	);
};
