import {
	Avatar,
	GenericButton,
	HeaderNavigation,
	IconButton,
	ItemList,
	Typography
} from "@azify/ui-web";
import InputPin from "components/Input/PinField/PinField.component";
import Dialog from "components/Modal/Dialog/Dialog.component";
import TransactionalInput from "components/Input/TransactionalInput/TransactionalInput.component";
import { useStepManager } from "hooks/useStepManager";
import { useState } from "react";

function ShowBillDetails() {
	const [openDialog, setOpenDialog] = useState(false);
	const { setCurrentStep } = useStepManager();

	const goToNextStep = () => setCurrentStep("StatusBill");
	const goToPreviousStep = () => setCurrentStep("VerifyBillCode");

	return (
		<>
			<div>
				<div className="display-between">
					<div>
						<HeaderNavigation
							buttonOnTitle={false}
							title="Revisar Boleto"
							quickButtonColor="accent"
							backIconClick={goToPreviousStep}
						/>
					</div>

					<div className="container-value-and-beneficiary">
						<Avatar
							onClick={() => setOpenDialog(true)}
							fullName="Master Tecnologia"
							type="initials"
							size="xlarge"
						/>

						<div className="container-input-edit">
							<div style={{ width: 40 }} />

							<TransactionalInput placeHolder="R$ 00,00" disable={false} />

							<IconButton color="primary" size="medium" icon="pencil" onClick={() => {}} />
						</div>

						<Typography
							textAlign="center"
							isBold
							type="subtitle1"
							value="Izabel Tabosa Tabanez"
							color="accent"
						/>
					</div>

					<ItemList
						label="Método de pagamento"
						supportParagraph="Conta BRL"
						leadingContent="icon"
						isDividerVisible
						leadingIconName="pix"
						behavior="default"
						trailingSupportContent="none"
					/>

					<ItemList
						label="Instituição"
						supportParagraph="BCO DO BRASIL S.A. - CNPJ: 00.416.968/0001-01"
						leadingContent="icon"
						isDividerVisible
						leadingIconName="bank-01"
						behavior="default"
						trailingSupportContent="none"
					/>

					<ItemList
						label="Vencimento"
						supportParagraph="06/12/2023"
						leadingContent="icon"
						isDividerVisible
						leadingIconName="calendar-empty"
						behavior="default"
						trailingSupportContent="none"
					/>
				</div>

				<GenericButton onClick={goToNextStep} title="Enviar" layout="standard" color="primary" />
			</div>

			<Dialog
				openDialog={openDialog}
				setOpenDialog={setOpenDialog}
				headerTitle="Validação de segurança"
				layout="standard"
				buttonGroupProps={{
					titleSecondary: "Esqueceu seu PIN?",
					isInverted: true,
					variant: "textSecondary"
				}}>
				<InputPin type="password" size={4} />
			</Dialog>
		</>
	);
}
export default ShowBillDetails;
