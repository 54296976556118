import * as React from "react";

const ErrorSVG = () => (
	<svg
		width={116}
		height={115}
		viewBox="0 0 116 115"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<rect width={116} height={115} fill="#EBEBEB" />
		<g id="Enviar PIX">
			<path
				d="M-8357 -297C-8357 -298.105 -8356.1 -299 -8355 -299H1412C1413.1 -299 1414 -298.105 1414 -297V684C1414 685.105 1413.1 686 1412 686H-8355C-8356.1 686 -8357 685.105 -8357 684V-297Z"
				fill="white"
				fillOpacity={0.1}
			/>
			<path
				d="M-8355 -298H1412V-300H-8355V-298ZM1413 -297V684H1415V-297H1413ZM1412 685H-8355V687H1412V685ZM-8356 684V-297H-8358V684H-8356ZM-8355 685C-8355.55 685 -8356 684.552 -8356 684H-8358C-8358 685.657 -8356.66 687 -8355 687V685ZM1413 684C1413 684.552 1412.55 685 1412 685V687C1413.66 687 1415 685.657 1415 684H1413ZM1412 -298C1412.55 -298 1413 -297.552 1413 -297H1415C1415 -298.657 1413.66 -300 1412 -300V-298ZM-8355 -300C-8356.66 -300 -8358 -298.657 -8358 -297H-8356C-8356 -297.552 -8355.55 -298 -8355 -298V-300Z"
				fill="black"
				fillOpacity={0.1}
			/>
			<g id="Enviando" clipPath="url(#clip0_0_1)">
				<rect width={1440} height={697} transform="translate(-662 -199)" fill="white" />
				<g id="Confirmation panel">
					<g id="_message">
						<g id="Icon content">
							<g id="Icon Large">
								<rect
									x={3.75}
									y={3.25391}
									width={108.5}
									height={108.5}
									rx={54.25}
									stroke="#E5596C"
									strokeOpacity={0.05}
									strokeWidth={6}
								/>
								<g id="Icon">
									<path
										id="Vector 543"
										d="M38.5 76.0039L77.5 39.0039"
										stroke="#C85B69"
										strokeWidth={6}
									/>
									<path
										id="Vector 544"
										d="M77.5 76.0039L38.5 39.0039"
										stroke="#C85B69"
										strokeWidth={6}
									/>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_0_1">
				<rect width={1440} height={697} fill="white" transform="translate(-662 -199)" />
			</clipPath>
		</defs>
	</svg>
);
export default ErrorSVG;
