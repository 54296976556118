import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 7%;

	width: 100%;
	padding: 16px 24px 16px 20px;

	.left-container {
		display: flex;
		flex-direction: row;
		align-items: center;

		.icon-container {
			margin-right: 24px;
		}

		.text-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}

	.right-container {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: flex-end;
		text-wrap: wrap;
	}
`;
