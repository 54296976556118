import { colors } from "@azify/ui-tokens";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.chart-container {
		width: 100%;
	}

	.container-graphs {
		display: flex;
		flex-direction: row;
		gap: 32px;

		width: 1280px;

		.row-badge-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 16px;
			margin-bottom: 32px;
			margin-left: 18px;

			.row-badge {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 4px;
			}
		}

		.credit-limits {
			display: flex;
			flex-direction: column;
			padding: 0px 24px 24px 24px;
			align-items: flex-start;
			border-radius: 16px;
			border: 1px solid ${colors.black.opacity["10p"]};
			flex: 1;
			justify-content: space-between;
		}

		.purchase-graph {
			display: flex;
			flex-direction: column;
			padding: 0px 24px;
			align-items: flex-start;
			border-radius: 16px;
			border: 1px solid ${colors.black.opacity["10p"]};
			flex: 2;
		}
	}

	.container-founds-wallets {
		display: flex;
		flex-direction: row;
		margin-bottom: 40px;
		gap: 24px;
		width: 1280px;

		.row-icon-amount {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 8px;
			width: fit-content;
		}

		.container-stocks {
			display: flex;
			flex-direction: row;
			gap: 24px;
			flex: 1;
		}

		.container-founds-deposits {
			display: flex;
			flex-direction: row;
			min-height: 162px;
			padding: 27px 24px;
			align-items: flex-start;
			gap: 77px;
			flex-shrink: 0;
			border-radius: 16px;
			border: 1px solid ${colors.black.opacity["10p"]};
			flex: 1;

			.row-between {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}

			.between {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				width: 100%;
				height: 100%;
			}
		}
	}

	.tooltip-style {
		font-family: Helvetica, sans-serif;
		font-size: 14px;
		font-weight: 500;
		border-radius: 8px;
		opacity: 1 !important;
		background-color: ${colors.black.main};
	}

	.container-title-actions {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;

		width: 1280px;
		margin-bottom: 40px;
	}

	.actions-buttons {
		display: flex;
		align-items: center;
		flex-direction: row;
		gap: 8px;
	}

	.icon-title {
		display: flex;
		align-items: center;
		flex-direction: row;
		gap: 24px;

		.center {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
`;

export const TableContainer = styled.div`
	width: 1280px;
	padding: 0 24px 24px 24px;
	border-radius: 16px;
	border: 1px solid var(--Black-Opacity-10p, rgba(39, 41, 55, 0.1));
	margin-top: 40px;

	& table {
		width: 100%;
		margin-top: 23px;

		& .header-table {
			width: 20%;
			padding-bottom: 20px;
		}

		& .header-table:first-child {
			width: 25%;
		}

		& .header-table:nth-child(5) {
			width: 16%;
		}

		& .header-table:nth-child(6) {
			width: 18%;
		}

		& .gap-table {
			& td {
				margin-bottom: 30px;
			}
		}

		& .client-section {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 18px;
		}
	}
`;
