import Header from "components/Header/Header.component";
import * as Styled from "./styles";
import { Outlet } from "react-router-dom";

const OnboardingRouterLayout = () => {
	return (
		<>
			<Header isAuth={false} />
			<Styled.Container id="page-wrapper">
				<Outlet />
			</Styled.Container>
		</>
	);
};

export default OnboardingRouterLayout;
