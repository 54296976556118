import {
	GenericButton,
	ItemList,
	Radio,
	SectionHeading,
	TextField,
	Tile,
	Tooltip,
	Typography
} from "@azify/ui-web";
import { Container } from "./CreatePartner.styles";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createPartnerSchema } from "pages/Onboarding/Kyb/Validations/KybValidations";
import { Partner } from "pages/Onboarding/Kyb/Kyb.types";
import { validaCNPJ } from "helpers/validate";
import { CreatePartnerProps } from "./CreatePartner.types";
import { cellphoneMask, maskCpfOrCnpj, percentageMask } from "helpers/mask";
import { When } from "react-if";
import Dialog from "components/Modal/Dialog/Dialog.component";
import { useState } from "react";
import { colors } from "@azify/ui-tokens";
import { ToastAlert } from "components/Toast/Toast.component";

function CreatePartner(props: CreatePartnerProps) {
	const { personalPartnersMethods, businessPartnersMethods, closeModal, hideAccountControl } =
		props;

	const [openAccountAccessModal, setOpenAccountAccessModal] = useState(false);

	const {
		handleSubmit,
		formState: { errors },
		control,
		setValue,
		watch,
		register
	} = useForm({
		resolver: yupResolver(createPartnerSchema),
		shouldUnregister: true,
		defaultValues: {
			accountAccess: false,
			politicallyExposedPerson: false
		}
	});

	const isBusiness = validaCNPJ(watch("documentNumber"));

	const makeSave = (data: Omit<Partner, "uniqueId">) => {
		if (isBusiness) {
			businessPartnersMethods.append({
				...data
			});
		} else {
			personalPartnersMethods.append({
				...data
			});
		}

		closeModal();

		if (watch("accountAccess")) {
			ToastAlert({
				title: "Código de convite enviado!",
				subtitle: `${watch("name")} recebeu o convite no e-mail.`,
				autoCloseEnable: true,
				type: "info"
			});
		}
	};

	return (
		<>
			<Container>
				<div>
					<div className="container-inputs">
						<TextField
							maxLength={50}
							label="CPF ou CNPJ"
							placeholder="Informe o CPF ou CNPJ"
							hookForm={register("documentNumber")}
							hintText={errors.documentNumber?.message}
							hintVariant="danger"
							maskFunction={maskCpfOrCnpj}
						/>

						<TextField
							maxLength={22}
							label="Nome"
							placeholder="Informe o nome"
							hookForm={register("name")}
							hintText={errors.name?.message}
							hintVariant="danger"
						/>

						<TextField
							maxLength={22}
							label="Percentual de participação"
							placeholder="0,00"
							hookForm={register("partnerPercentage")}
							hintText={errors.partnerPercentage?.message}
							hintVariant="danger"
							maskFunction={percentageMask}
							leadingLabel="%"
						/>

						<TextField
							maxLength={22}
							label="Telefone"
							placeholder="Informe o telefone"
							hookForm={register("phone")}
							hintText={errors.phone?.message}
							hintVariant="danger"
							maskFunction={cellphoneMask}
						/>

						<TextField
							maxLength={22}
							label="E-mail"
							placeholder="Informe o e-mail"
							hookForm={register("email")}
							hintText={errors.email?.message}
							hintVariant="danger"
						/>
					</div>

					<When condition={!isBusiness}>
						<Controller
							control={control}
							name="politicallyExposedPerson"
							render={() => (
								<ItemList
									trailingSupportContent="customized"
									trailingContent="toggle"
									label="Esta é uma Pessoa Politicamente Exposta?"
									behavior="default"
									trailingToggleValue={watch("politicallyExposedPerson")}
									trailingToggleOnPress={() => {
										setValue("politicallyExposedPerson", !watch("politicallyExposedPerson"));
									}}
								/>
							)}
						/>
					</When>

					<When condition={!hideAccountControl && !isBusiness}>
						<SectionHeading
							behavior="default"
							title="Liberar acesso à conta para este sócio?"
							subHeading="O sócio terá acesso à conta e poderá realizar movimentações financeiras, entre outras ações."
						/>

						<Controller
							control={control}
							name="accountAccess"
							render={() => (
								<div className="radios-container">
									<Tile
										label="Sim"
										size="medium"
										onClick={() => setValue("accountAccess", true)}
										leadingRadioColor="primary"
										leadingContent="customized"
										backgroundColor="white"
										trailingContent="customized"
										leadingContentCustomized={
											<Radio
												isChecked={watch("accountAccess")}
												onToggle={() => setValue("accountAccess", true)}
												color="default"
											/>
										}
									/>

									<Tile
										label="Não"
										size="medium"
										onClick={() => setValue("accountAccess", false)}
										leadingRadioColor="primary"
										leadingContent="customized"
										backgroundColor="white"
										trailingContent="customized"
										leadingContentCustomized={
											<Radio
												isChecked={!watch("accountAccess")}
												onToggle={() => setValue("accountAccess", false)}
												color="default"
											/>
										}
									/>
								</div>
							)}
						/>

						<Tooltip
							isVisible={!!watch("accountAccess")}
							title="Este representante precisa validar a biometria no nosso aplicativo para finalizar o cadastro. O código de convite será enviado por e-mail."
							arrowSize="small"
							arrowPosition="top"
							hierarchy="secondary"
							layout="default"
						/>
					</When>
				</div>

				<div className="footer-button">
					<GenericButton
						title="Adicionar Representante"
						color="secondary"
						onClick={
							watch("accountAccess")
								? () => setOpenAccountAccessModal(true)
								: handleSubmit(makeSave)
						}
						layout="standard"
					/>
				</div>
			</Container>

			{/* Ask for sure account access */}

			<Dialog
				openDialog={openAccountAccessModal}
				setOpenDialog={setOpenAccountAccessModal}
				headerTitle={`Permitir que ${watch("name")} tenha acesso à conta?`}
				layout="alternative"
				buttonType="group"
				buttonGroupProps={{
					onPressPrimary: handleSubmit(makeSave),
					onPressSecondary: () => setOpenAccountAccessModal(false),
					titlePrimary: "Permitir e enviar e-mail",
					customColorBackgroundPrimaryButton: colors.black.main,
					titleSecondary: "Cancelar",
					variant: "default"
				}}>
				<div style={{ padding: 16 }}>
					<Typography
						type="body1"
						value={`O sócio receberá um convite por e-mail para o endereço ${watch("email")}. Neste e-mail, ele encontrará as instruções para baixar nosso aplicativo e criar sua conta.`}
					/>
				</div>
			</Dialog>
		</>
	);
}

export default CreatePartner;
