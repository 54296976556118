import * as Styled from "../Kyc.styles";
import { useStepManager } from "hooks/useStepManager";
import { useFormContext } from "react-hook-form";
import { cameFromTheRight } from "pages/Onboarding/Onboarding.styles";
import InputPin from "components/Input/PinField/PinField.component";
import useTimer from "hooks/useTimer";
import { ToastAlert } from "components/Toast/Toast.component";
import { ButtonGroup, IconButton, ProgressBar, Typography } from "@azify/ui-web";

function VerifyEmail() {
	const { setCurrentStep } = useStepManager();
	const { timeLeft, restartTimer, counting } = useTimer(60);
	const { watch } = useFormContext();

	const goNext = () => setCurrentStep("VerifyPhoneNumber");

	const goPrev = () => {
		setCurrentStep("InputPersonalData");
	};

	const resendPinCode = () => {
		if (counting) return;

		restartTimer();

		ToastAlert({
			title: "Código reenviado!",
			subtitle: "Verifique sua caixa de entrada.",
			autoCloseEnable: true,
			type: "info"
		});
	};

	const email = watch("email");

	return (
		<Styled.MainStepContainer>
			<Styled.ScrollStepContainer>
				<Styled.CenterContent>
					<div className="step-header">
						<div className="container-arrow-progress">
							<IconButton icon="back-arrow-lg" onClick={goPrev} color="inverted" marginRight={32} />

							<ProgressBar currentStep={0} currentStepProgress={80} totalSteps={3} height={2} />
						</div>
						<Typography value="Verificar e-mail" type="h1" isBold marginBottom={16} />
					</div>

					<Styled.ContainerAnimation {...cameFromTheRight}>
						<Typography
							value={`Insira aqui o código que enviamos para ${email}. Caso não consiga encontrá-lo, verifique sua caixa de spam.`}
							type="body1"
						/>
						<div className="p-100">
							<InputPin type="password" autoFocus validate={/^\d+$/} size={6} />
						</div>
					</Styled.ContainerAnimation>
				</Styled.CenterContent>
			</Styled.ScrollStepContainer>

			<div className="step-footer">
				<div className="button-container">
					<ButtonGroup
						variant="default"
						titlePrimary="Continuar"
						titleSecondary={`${counting ? `Reenviar código (${timeLeft}s)` : "Enviar código"}`}
						isDisabledSecondary={counting}
						isInverted
						onPressPrimary={goNext}
						onPressSecondary={resendPinCode}
					/>
				</div>
			</div>
		</Styled.MainStepContainer>
	);
}

export default VerifyEmail;
