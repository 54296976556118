import * as Styled from "../Kyc.styles";
import { useStepManager } from "hooks/useStepManager";
import { useFormContext } from "react-hook-form";
import { cameFromTheRight } from "pages/Onboarding/Onboarding.styles";
import InputPin from "components/Input/PinField/PinField.component";
import useTimer from "hooks/useTimer";
import { ToastAlert } from "components/Toast/Toast.component";
import { ButtonGroup, IconButton, ProgressBar, Typography } from "@azify/ui-web";

function VerifyPhoneNumber() {
	const { setCurrentStep } = useStepManager();
	const { timeLeft, restartTimer, counting } = useTimer(60);
	const { watch } = useFormContext();

	const goNext = () => {
		setCurrentStep("ClaimAccountId");
	};

	const goPrev = () => {
		setCurrentStep("VerifyEmail");
	};

	const resendPinCode = () => {
		if (counting) return;

		restartTimer();

		ToastAlert({
			title: "Código reenviado!",
			subtitle: "	Verifique suas mensagens SMS.",
			autoCloseEnable: true,
			type: "info",
			buttonAction: () => {
				console.log("teste");
			}
		});
	};

	const phoneNumber = watch("phoneNumber");

	return (
		<Styled.MainStepContainer>
			<Styled.ScrollStepContainer>
				<Styled.CenterContent>
					<div className="step-header">
						<div className="container-arrow-progress">
							<IconButton icon="back-arrow-lg" onClick={goPrev} color="inverted" marginRight={32} />

							<ProgressBar currentStep={0} currentStepProgress={60} totalSteps={3} height={2} />
						</div>

						<Typography value="Verificar número de telefone" type="h1" isBold marginBottom={16} />
					</div>

					<Styled.ContainerAnimation {...cameFromTheRight}>
						<Typography
							value={`Insira aqui o código que enviamos para o número ${phoneNumber}.`}
							type="body1"
							marginBottom={16}
						/>

						<div className="p-100">
							<InputPin autoFocus type="password" size={6} />
						</div>
					</Styled.ContainerAnimation>
				</Styled.CenterContent>
			</Styled.ScrollStepContainer>

			<div className="step-footer">
				<div className="button-container">
					<ButtonGroup
						variant="default"
						titlePrimary="Continuar"
						titleSecondary={`${counting ? `Reenviar código (${timeLeft}s)` : "Enviar código"}`}
						isInverted
						isDisabledSecondary={counting}
						onPressPrimary={goNext}
						onPressSecondary={resendPinCode}
					/>
				</div>
			</div>
		</Styled.MainStepContainer>
	);
}

export default VerifyPhoneNumber;
