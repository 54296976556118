import { Typography } from "@azify/ui-web";
import * as Styles from "./ItemList.styles";
import { ItemListProps } from "./ItemList.types";

import GenericButton from "components/Buttons/GenericButton";

const ItemList = (props: ItemListProps) => {
	const { icon, title, subtitle, value } = props;

	return (
		<Styles.Container>
			<div className="left-container">
				{icon && <div className="icon-container">{icon}</div>}

				<div className="text-container">
					<Typography type="subtitle1" color="primary" isBold value={title} />
					<Typography type="body3" color="primary" value={subtitle} />
				</div>
			</div>

			<div className="right-container">
				<Typography type="subtitle1" color="primary" isBold value={value} width="auto" />
			</div>
		</Styles.Container>
	);
};

export default ItemList;
