import { SVGProps } from "react";

const CashSVG = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none" {...props}>
		<path
			fill="#272937"
			fillRule="evenodd"
			d="M3.248 8a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0Zm5.25-6.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5Zm.75 2.25v.75h1.75v1.5h-2.97c-.45 0-.78.338-.78.75 0 .411.33.75.78.75h1.176c1.24 0 2.28.972 2.28 2.25 0 1.262-1.016 2.227-2.236 2.25v.75h-1.5v-.75h-1.75v-1.5h3.206c.45 0 .78-.339.78-.75 0-.412-.33-.75-.78-.75H8.027c-1.24 0-2.279-.973-2.279-2.25 0-1.18.887-2.1 2-2.234V3.5h1.5Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default CashSVG;
