import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	textarea {
		width: 100%;
		height: 80px;
		resize: none;
		border: none;
		outline: none;
		text-align: center;

		font-family: Aeonik-Regular;
		font-size: 21px;
		font-style: normal;
		font-weight: 500;
		line-height: 130%;

		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}
		&::placeholder {
			color: var(--color-text);
			opacity: 0.5;
		}
	}
`;
