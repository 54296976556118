import { create } from "zustand";
import { persist } from "zustand/middleware";

const types = { increaseMain: "INCREASE_MAIN", decreaseMain: "DECREASE_MAIN" };

interface UseApplicationsProps {
	mainBalance: number;
	dispatchMain: (args: { type: string; by: number }) => void;
}

const reducer = (
	state: Omit<UseApplicationsProps, "dispatchMain">,
	{
		type,
		by
	}: {
		type: string;
		by: number;
	}
) => {
	switch (type) {
		case types.increaseMain:
			return { mainBalance: state.mainBalance + by };
		case types.decreaseMain:
			if (state.mainBalance <= 0) return { mainBalance: state.mainBalance };
			if (state.mainBalance - by < 0) return { mainBalance: 0 };
			return { mainBalance: state.mainBalance - by };
		default:
			return state;
	}
};

export const useMainAccountActions = create(
	persist<UseApplicationsProps>(
		(set) => ({
			mainBalance: 1540.33,
			dispatchMain: (args) => set((state) => reducer(state, args))
		}),
		{
			name: "mainAccount-storage"
		}
	)
);
