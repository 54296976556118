import { colors } from "@azify/ui-tokens";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;

	.close-button-wrapper {
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		display: flex;
		gap: 8px;
		padding: 28px 32px 20px 28px;
		z-index: 1;

		.container-close-header-component {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}
	}
`;

export const Content = styled.div`
	padding: 0 32px;
	margin-top: 12px;
	height: 100%;
	overflow-y: auto;
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	.container-name-doc {
		border: 1px solid ${colors.black.opacity["10p"]};
		padding: 8px;
		border-radius: 8px;
	}

	.container-inputs {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	& .tile-container {
		display: flex;
		justify-content: space-between;
		gap: 8px;

		margin-top: 36px;
	}

	.footer-button {
		display: flex;
		padding: 24px 0px;
		width: 100%;
		align-items: center;
		justify-content: center;
	}

	.radios-container {
		display: flex;
		flex-direction: row;
		gap: 16px;
		margin-top: 20px;
		margin-bottom: 16px;

		.radio-label {
			display: flex;
			flex-direction: row;
			gap: 8px;
			align-items: center;
			justify-content: center;
		}
	}
`;
