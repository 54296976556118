import { useMemo, useRef, useState } from "react";
import * as Styled from "./InputDropDown.styles";
import { InputDropDownProps } from "./InputDropDown.types";
import { Divider, Icon, Label, Typography } from "@azify/ui-web";
import { motion } from "framer-motion";
import { colors } from "@azify/ui-tokens";

function InputDropDown({
	disable,
	placeHolder,
	items = [],
	selected,
	setIsSelected,
	label,
	error
}: InputDropDownProps) {
	const [isActive, setIsActive] = useState(false);

	const inputRef = useRef<HTMLInputElement>(null);

	const inputFocus = () => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	};

	const filterItems = useMemo(() => {
		if (!selected) return items;

		const firstItem = items.find((item) => item.value.includes(selected));

		const newItems = items.filter((item) => !item.value.includes(selected));

		return [firstItem, ...newItems];
	}, [selected]);

	const verifySelected = () => {
		const allValues = items.map((item) => item.value);

		if (!allValues.includes(selected)) {
			setIsSelected("");
		}
	};

	return (
		<Styled.Container>
			<Label label={label} color="primary" />

			<Styled.ContainerDropDown disable={!!disable} onClick={inputFocus}>
				<div className="dropdown" onClick={() => setIsActive(!isActive)}>
					<div className="dropdown-btn">
						<input
							onBlur={verifySelected}
							placeholder={placeHolder}
							ref={inputRef}
							value={selected}
							onChange={(e) => {
								setIsSelected(e.target.value);
							}}
						/>

						<motion.div
							animate={{ rotate: isActive ? 0 : 180 }}
							initial={{ rotate: 0 }}
							transition={{ duration: 0.2 }}
							className="icon">
							<Icon customColor={colors.gray.dark} size="small" name="chevron-up-filled-small" />
						</motion.div>
					</div>

					<div className="dropdown-content" style={{ display: isActive ? "block" : "none" }}>
						{filterItems.map((item) => {
							if (!item) return null;

							return (
								<div className="container-list" key={item.value}>
									<div
										className="item"
										onClick={() => {
											setIsSelected(item.value);
											setIsActive(!isActive);
										}}>
										<Typography value={item.label} type="subtitle1" />
									</div>

									<Divider gap="lg" />
								</div>
							);
						})}
					</div>
				</div>
			</Styled.ContainerDropDown>
			<Typography type="subtitle2" color="negative" value={error} />
		</Styled.Container>
	);
}

export default InputDropDown;
