import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
`;

export const CenterContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 1280px;
	height: 100%;

	.container-icon-label {
		justify-content: center;
		align-items: center;
		display: flex;
		flex-direction: row;
		gap: 24px;
	}

	.container-tiles-options {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 24px;
		margin-top: 40px;
	}
`;
