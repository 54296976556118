import { useNavigate } from "react-router-dom";
import * as Styled from "../NewDebitCollection.styles";
import { GenericButton, QuickButton } from "@azify/ui-web";
import LoaderStatus from "components/LoaderStatus/LoaderStatus.component";
import { useEffect, useState } from "react";
import { formatCurrencyFn } from "helpers/currency";
import { useFormContext } from "react-hook-form";
import { ToastAlert } from "components/Toast/Toast.component";
import { useStepManager } from "hooks/useStepManager";
import { useGatewayActions } from "hooks/useGatewayActions";
import { nameCapitalize } from "@azify/ui-helpers";
import dayjs from "dayjs";

export const Success = () => {
	const navigate = useNavigate();
	const { watch } = useFormContext();
	const [isLoading, setIsLoading] = useState(true);
	const { params } = useStepManager();

	const { dispatch, dispatchGatewayMovements } = useGatewayActions();

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 4000);

		return () => clearTimeout(timer);
	}, []);

	const infoDetails = {
		...params
	} as {
		values: string;
	};

	const valueToAmount = parseFloat(infoDetails?.values.replace(",", "."));

	const valuesToDisplay = valueToAmount || 100;

	const tryToast = () => {
		window.navigator.clipboard.writeText("https://azify.com.br/pay/1520");
		ToastAlert({
			title: "Link de pagamento copiado"
		});
	};

	return (
		<Styled.CenterContainer>
			<div className="display-between">
				<div className="center-items">
					<LoaderStatus
						title={isLoading ? "Gerando cobrança..." : "Cobrança gerada!"}
						subtitle={
							isLoading
								? ""
								: `Foi gerado uma cobrança de ${formatCurrencyFn("BRL", String(valuesToDisplay))} para`
						}
						emphasis={
							isLoading ? "" : watch("name") ? nameCapitalize(watch("name")) : "Novo cliente"
						}
						status={isLoading ? "loading" : "success"}
						heightContainer="auto"
					/>

					{!isLoading && (
						<QuickButton
							leadingIcon="copy-small"
							color="primary"
							label="Copiar link de pagamento"
							onClick={tryToast}
						/>
					)}
				</div>

				<GenericButton
					onClick={() => {
						dispatch({ type: "INCREASE_PENDING_COUNT", by: 1 });
						dispatch({ type: "INCREASE_PENDING", by: valueToAmount });

						dispatchGatewayMovements({
							type: "CREATE_MOVEMENT",
							args: {
								date: dayjs().format("DD MMM, YYYY"),
								client: watch("name"),
								status: "Pendente",
								method: "Nova cobrança",
								method_desc: "Cobrança",
								value: valueToAmount,
								periodicity: "none",
								hour: dayjs().format("HH:mm"),
								recurrence: "N/A",
								recurrence_desc: "N/A",
								phone: "---"
							}
						});

						navigate("/gateway");
					}}
					isDisabled={isLoading}
					title="Concluir"
					layout="standard"
					color="primary"
				/>
			</div>
		</Styled.CenterContainer>
	);
};
