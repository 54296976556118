import { useForm, FormProvider } from "react-hook-form";
import InputDocumentNumber from "./Steps/0.InputDocumentNumber";
import InputPersonalData from "./Steps/1.InputPersonalData";
import InputAccountPassword from "./Steps/5.InputAccountPassword";
import VerifyPhoneNumber from "./Steps/3.VerifyPhoneNumber";
import VerifyEmail from "./Steps/2.VerifyEmail";
import { useStepManager } from "hooks/useStepManager";
import { OnboardingKycSteps } from "../Onboarding.types";
import { yupResolver } from "@hookform/resolvers/yup";
import { KYCValidationSchema } from "./Validations/KycVAlidations";
import ClaimAccountId from "./Steps/4.ClaimAccountId";

function Kyc() {
	const { currentStep } = useStepManager();

	const kycMethods = useForm({
		resolver: yupResolver(KYCValidationSchema),
		defaultValues: {
			politicallyExposedPerson: false
		}
	});

	const typedCurrentStep = currentStep as OnboardingKycSteps;

	const STEP_RECORDS = {
		InputPersonalDocumentNumber: <InputDocumentNumber />,
		InputPersonalData: <InputPersonalData />,
		VerifyPhoneNumber: <VerifyPhoneNumber />,
		VerifyEmail: <VerifyEmail />,
		ClaimAccountId: <ClaimAccountId />,
		InputAccountPassword: <InputAccountPassword />
	};

	return (
		<FormProvider {...kycMethods}>
			{typedCurrentStep ? STEP_RECORDS[typedCurrentStep] : null}
		</FormProvider>
	);
}

export default Kyc;
