import styled from "styled-components";
import { CheckDataProps } from "./CreatePixKey.types";

export const Container = styled.div`
	padding: 12px 32px;
	margin-top: 12px;

	height: 100%;

	& .tile-container {
		display: flex;
		justify-content: space-between;
		gap: 8px;

		margin-top: 36px;
	}
`;

export const Content = styled.div<CheckDataProps>`
	display: flex;
	flex-direction: column;
	justify-content: space-around;

	height: 80%;

	& .header {
		& .value-container {
			margin: 24px 0;
			padding-bottom: ${(props) => (props.type === "phone" || props.type === "email" ? 0 : 38)}px;
			text-align: center;
		}

		& .generic-button-container {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	& .footer {
		& .info-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			padding: 24px 30px;
			margin-bottom: 30px;
			border-radius: 16px;
			border: 1px solid var(--Black-Opacity-10p, rgba(39, 41, 55, 0.1));
		}
	}
`;

export const SubContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	height: calc(80% - 8vh);
	padding-top: 32px;

	.form-content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;

		height: calc(70% - 8vh);
	}
`;
