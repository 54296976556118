import { useStepManager } from "hooks/useStepManager";
import { OnboardingExtensionOtcSteps } from "./OtcOnboarding.types";

import { StartStep } from "./Steps/0.Start.screen";
import { AdditionalDocumentStep } from "./Steps/1.AdditionalDocument.screen";
import { RequestedDocumentsStep } from "./Steps/2.RequestedDocuments.screen";
import { PersonalDataStep } from "./Steps/3.PersonalData.screen";
import { SourceFundsStep } from "./Steps/4.SourceFunds.screen";
import { FinishStep } from "./Steps/5.Finish.screen";

export const OtcOnboarding = () => {
	const { currentStep } = useStepManager();

	const typedCurrentStep = currentStep as OnboardingExtensionOtcSteps;

	const STEP_RECORDS = {
		StartStep: <StartStep key="startStep" />,
		AdditionalDocumentStep: <AdditionalDocumentStep key="additionalDocumentStep" />,
		RequestedDocumentsStep: <RequestedDocumentsStep key="requestedDocumentsStep" />,
		PersonalDataStep: <PersonalDataStep key="personalDataStep" />,
		SourceFundsStep: <SourceFundsStep key="sourceFundsStep" />,
		FinishStep: <FinishStep key="finishStep" />
	};

	return <> {typedCurrentStep ? STEP_RECORDS[typedCurrentStep] : null}</>;
};
