import * as Style from "./InputConvert.styles";
import { forwardRef, useRef } from "react";
import { When } from "react-if";
import { InputConvertProps } from "./InputConvert.types";
import CurrencyInput from "react-currency-input-field";
import { colors } from "@azify/ui-tokens";
import { Typography } from "@azify/ui-web";

const InputConvert = forwardRef<HTMLInputElement, InputConvertProps>((props, _ref) => {
	const placeholder = props.prefix ? `${props.prefix} 0.00` : "0.00";
	const inputRef = useRef<any>(null);

	return (
		<Style.ContainerInput>
			<div className="inputWrapper">
				{/* {props.prefix && props.hasValue && (
					<Typography width="auto" isBold type="h1" value={props.prefix} marginRight={10} />
				)} */}
				<CurrencyInput
					style={{
						...props.inputStyle
					}}
					prefix={props.prefix ? props.prefix + " " : ""}
					ref={inputRef}
					intlConfig={{ locale: "en-US" }}
					allowNegativeValue={false}
					autoComplete="off"
					placeholder={placeholder}
					className="input"
					decimalsLimit={props.precision}
					maxLength={11}
					value={props.value}
					disabled={props.disabled}
					onValueChange={(value) => {
						if (props.onChangeText) {
							props.onChangeText(value as any);
						}
					}}
				/>
			</div>

			<When condition={!!props.error}>
				<Typography
					type="smaller"
					value={props.error}
					colorCustom={colors.danger.main}
					marginBottom={5}
				/>
			</When>
		</Style.ContainerInput>
	);
});

InputConvert.displayName = "InputConvert";

export default InputConvert;
