import { colors } from "@azify/ui-tokens";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
`;

export const CenterContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 600px;
	height: 100%;
	padding-top: 16px;

	.container-inputs {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 24px;
	}

	.fixed-go-button {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		position: fixed;
		background-color: ${colors.white.light};
		height: 88px;
		bottom: 0;
		left: 0;
	}

	@media (max-width: 500px) {
		width: 100%;
	}
`;
