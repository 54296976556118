import { colors } from "@azify/ui-tokens";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 12px 32px;
	margin-top: 12px;
	height: 100%;

	.container-inputs {
		display: flex;
		flex-direction: column;
		gap: 10px;

		.tile-container {
			display: flex;
			gap: 8px;

			margin-top: 20px;
		}
	}

	.footer {
		margin-top: 48px;
		padding-bottom: 24px;
	}

	.grid-inputs {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 16px;
	}
`;

export const InputSelect = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	padding: 8px 0;
	border-bottom: 1px solid ${colors.gray.main};
	cursor: pointer;
	user-select: none;
`;

export const InputNumber = styled.input`
	font-family: "Aeonik-Medium", sans-serif;
	font-size: 17px;
	color: ${colors.gray.dark};

	border: none;
	padding: 8px 0;
	border-bottom: 1px solid ${colors.gray.main};
`;
