import * as Styled from "../Kyc.styles";
import { useStepManager } from "hooks/useStepManager";
import { Controller, useFormContext } from "react-hook-form";
import { cameFromTheRight } from "pages/Onboarding/Onboarding.styles";
import {
	GenericButton,
	IconButton,
	ItemList,
	ProgressBar,
	TextField,
	Typography
} from "@azify/ui-web";
import { cellphoneMask } from "helpers/mask";

function InputPersonalData() {
	const { setCurrentStep } = useStepManager();
	const {
		register,
		formState: { errors },
		trigger,
		watch,
		setValue,
		control
	} = useFormContext();

	const goNext = () => {
		const fieldsToTrigger = ["fullName", "email", "phoneNumber"];

		trigger(fieldsToTrigger, {
			shouldFocus: true
		}).then((isValid) => {
			if (isValid) setCurrentStep("VerifyEmail");
		});
	};

	const goPrev = () => {
		setCurrentStep("InputPersonalDocumentNumber");
	};

	return (
		<Styled.MainStepContainer>
			<Styled.ScrollStepContainer>
				<Styled.CenterContent>
					<div className="step-header">
						<div className="container-arrow-progress">
							<IconButton icon="back-arrow-lg" onClick={goPrev} color="inverted" marginRight={32} />

							<ProgressBar currentStep={0} currentStepProgress={40} totalSteps={3} height={2} />
						</div>

						<Typography value="Crie o seu login de acesso" type="h1" isBold marginBottom={16} />
					</div>

					<Styled.ContainerAnimation {...cameFromTheRight}>
						<Typography
							value="Preencha os dados solicitados para criar o seu login e acessar todas as plataformas e aplicativos da Azify."
							type="body1"
						/>

						<div className="mt-24">
							<TextField
								hookForm={register("fullName")}
								maxLength={75}
								label="Nome completo"
								placeholder="Ex. Luís Almeida Santos"
								hintText={errors?.fullName?.message as string}
								hintVariant="danger"
								aria-label="full-name"
							/>
						</div>

						<div className="mt-24">
							<TextField
								hookForm={register("email")}
								maxLength={60}
								label="E-mail"
								placeholder="Ex. luis@gmail.com"
								hintText={errors?.email?.message as string}
								hintVariant="danger"
								aria-label="email"
							/>
						</div>

						<div className="mt-24">
							<TextField
								hookForm={register("phoneNumber")}
								maxLength={15}
								label="Número de telefone"
								placeholder="(00) 00000-0000"
								maskFunction={cellphoneMask}
								hintText={errors?.phoneNumber?.message as string}
								hintVariant="danger"
								aria-label="phone-number"
							/>
						</div>

						<div className="mt-24">
							<Controller
								control={control}
								name="politicallyExposedPerson"
								render={() => (
									<ItemList
										trailingSupportContent="customized"
										trailingContent="toggle"
										label="Você é uma Pessoa Politicamente Exposta?"
										behavior="default"
										trailingToggleValue={watch("politicallyExposedPerson")}
										trailingToggleOnPress={() => {
											setValue("politicallyExposedPerson", !watch("politicallyExposedPerson"));
										}}
										aria-label="politically-exposed-person"
									/>
								)}
							/>
						</div>
					</Styled.ContainerAnimation>
				</Styled.CenterContent>
			</Styled.ScrollStepContainer>

			<div className="step-footer">
				<div className="button-container">
					<GenericButton onClick={goNext} title="Continuar" layout="standard" color="primary" />
				</div>
			</div>
		</Styled.MainStepContainer>
	);
}

export default InputPersonalData;
