import { ItemList, Typography } from "@azify/ui-web";
import * as Styled from "./Organizations.styles";
import { useNavigate } from "react-router-dom";

export const Organizations = () => {
	const navigate = useNavigate();

	const comps = [
		{ name: "Azify LTDA", document: "52.491.106/0001-39", status: "Ativa" }
		// {
		// 	name: "Master Tecnologia",
		// 	document: "15.392.449/0001-74",
		// 	status: "Ativa"
		// }
	];

	const getRightColor = (status: string) => {
		switch (status) {
			case "Ativa":
				return "secondary";
			case "Inativa":
				return "danger";
			case "Em andamento":
				return "accent";
			default:
				return "accent";
		}
	};

	return (
		<Styled.Container>
			<Styled.CenterContent>
				<Typography value="Olá novamente, Gustavo." type="h1" isBold marginBottom={20} />

				<Typography
					value="Para começar, escolha uma das organizações abaixo ou crie uma conta nova."
					type="body1"
					marginBottom={16}
				/>

				{comps.map((comp) => (
					<ItemList
						key={comp.document}
						behavior="display"
						label={comp.name}
						paragraph={comp.document}
						leadingContent="avatar"
						leadingAvatarFullName={comp.name}
						trailingContent="icon"
						trailingIconName="chevron-right-small"
						trailingSupportContent="badge"
						isDividerVisible
						trailingSupportBadgeLayout="label"
						trailingSupportBadgeTitle={comp.status}
						trailingSupportBadgeColor={getRightColor(comp.status)}
						onClick={() => {
							if (comp.status !== "Ativa") return;

							localStorage.setItem("selectedOrganization", comp.name);

							navigate("/dashboard");
						}}
					/>
				))}

				<ItemList
					behavior="default"
					trailingContent="icon"
					leadingContent="icon"
					leadingIconName="add"
					leadingIconColor="accent"
					trailingSupportContent="none"
					label="Criar outra conta de empresa"
					onClick={() => {
						localStorage.setItem("selectedOrganization", "Master Consultoria");

						navigate("/onboarding", { state: { onboardingStep: "InputBusinessDocumentNumber" } });
					}}
				/>
			</Styled.CenterContent>
		</Styled.Container>
	);
};
