import { colors } from "@azify/ui-tokens";
import { transparentize } from "polished";
import styled from "styled-components";

export const Container = styled.div`
	bottom: 33px;
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 65%;
`;

export const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px;
	border-radius: 38px;
	background: ${transparentize(0.4, colors.white.main)};
	box-shadow: 0px 8px 20px 0px ${transparentize(0.84, colors.black.main)};
	backdrop-filter: blur(22px);

	.round-container {
		display: flex;
		width: 60px;
		height: 60px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 4px;
		cursor: pointer;

		border-radius: 48px;
	}

	.selected {
		background: ${colors.gray.opacity["10p"]};
	}
`;
