import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;

	.collapse-item-list {
		display: flex;
		flex-direction: row;
		width: 100%;
	}

	.spacer {
		width: 76px;
		height: 100%;

		display: flex;
		justify-content: center;
		align-items: center;

		.divider {
			width: 1px;
			height: 100%;
			background-color: #e5e5e5;
		}
	}

	.item-list-container {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.main-item-list {
		width: 100%;
		display: flex;
		flex-direction: row;
		padding: 16px 24px 16px 16px;
		justify-content: space-between;
		align-items: center;
	}

	.container-icon-text {
		display: flex;
		align-items: center;
		flex-direction: row;
		gap: 16px;
	}

	.texts-container {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}
`;
