import { useState } from "react";
import * as Styled from "../Kyc.styles";
import { useStepManager } from "hooks/useStepManager";
import { useFormContext } from "react-hook-form";
import { cameFromTheRight } from "pages/Onboarding/Onboarding.styles";
import { GenericButton, IconButton, ProgressBar, TextField, Typography } from "@azify/ui-web";

function InputAccountPassword() {
	const { setCurrentStep } = useStepManager();
	const {
		trigger,
		formState: { errors },
		register
	} = useFormContext();
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const goNext = () => {
		const fieldsToTrigger = ["password", "confirmPassword"];

		trigger(fieldsToTrigger, {
			shouldFocus: true
		}).then((isValid) => {
			if (isValid) {
				setCurrentStep("InputBusinessDocumentNumber");
			}
		});
	};

	const goPrev = () => {
		setCurrentStep("ClaimAccountId");
	};

	const passwordErrorMsg = errors?.password?.message as string;

	return (
		<Styled.MainStepContainer>
			<Styled.ScrollStepContainer>
				<Styled.CenterContent>
					<div className="step-header">
						<div className="container-arrow-progress">
							<IconButton icon="back-arrow-lg" onClick={goPrev} color="inverted" marginRight={32} />

							<ProgressBar currentStep={0} currentStepProgress={100} totalSteps={3} height={2} />
						</div>
						<Typography value="Criar senha da conta" type="h1" isBold marginBottom={16} />
					</div>

					<Styled.ContainerAnimation {...cameFromTheRight}>
						<Typography
							value="Essa será sua senha para acessar todas as plataformas e aplicativos da Azify."
							type="body1"
						/>

						<div className="mt-24">
							<TextField
								maxLength={6}
								label="Senha"
								placeholder="6 dígitos numéricos"
								type="password"
								hintText={passwordErrorMsg || "Não utilize números sequenciais ou repetições."}
								hookForm={register("password")}
								hintVariant={passwordErrorMsg ? "danger" : "info"}
							/>
						</div>

						<div className="mt-24">
							<TextField
								maxLength={6}
								label="Confirmar senha"
								placeholder="Digite sua senha"
								type="password"
								onChangeSecureTextEntry={() => setShowConfirmPassword(!showConfirmPassword)}
								secureTextEntry={showConfirmPassword}
								hintText={errors?.confirmPassword?.message as string}
								hookForm={register("confirmPassword")}
								hintVariant="danger"
							/>
						</div>
					</Styled.ContainerAnimation>
				</Styled.CenterContent>
			</Styled.ScrollStepContainer>

			<div className="step-footer">
				<div className="button-container">
					<GenericButton onClick={goNext} title="Continuar" layout="standard" color="primary" />
				</div>
			</div>
		</Styled.MainStepContainer>
	);
}

export default InputAccountPassword;
