import { useStepManager } from "hooks/useStepManager";
import { cameFromTheLeft } from "pages/Onboarding/Onboarding.styles";
import { GenericButton, Illustration, Typography } from "@azify/ui-web";
import * as Styled from "../Kyb.styles";

function SuccessSubmit() {
	const { setCurrentStep } = useStepManager();

	const goNext = () => {
		setCurrentStep("Status");
	};

	return (
		<Styled.MainStepContainer>
			<Styled.ScrollStepContainer>
				<Styled.CenterContent>
					<Styled.ContainerAnimation paddingTop="24" {...cameFromTheLeft}>
						<Illustration name="woman-winning-a-race-md" width={170} height={216} />

						<Typography
							marginBottom={20}
							marginTop={8}
							isBold
							type="h1"
							value="Tudo certo, agora é só aguardar!"
						/>

						<Typography
							marginBottom={20}
							type="body1"
							value="Validaremos todas as informações enviadas, garantindo a segurança dos seus dados. Ao término da validação, você será notificado(a) por e-mail."
						/>
					</Styled.ContainerAnimation>
				</Styled.CenterContent>
			</Styled.ScrollStepContainer>

			<div className="step-footer">
				<div className="button-container">
					<GenericButton
						layout="standard"
						color="primary"
						title="Acompanhar solicitação"
						onClick={goNext}
					/>
				</div>
			</div>
		</Styled.MainStepContainer>
	);
}

export default SuccessSubmit;
