import { GeneralStyleProps } from "../../../@types/general";
import styled from "styled-components";

export const Container = styled.div<GeneralStyleProps>`
	display: flex;
	width: 600px;
	height: ${({ height }) => (height ? height + "px" : "unset")};
	flex-direction: column;
	justify-content: center;
	align-items: center;

	input {
		height: 40px;
		border: none;
		text-align: center;
		font-family: Aeonik-Regular;
		font-size: 43px;
		font-style: normal;
		font-weight: 500;
		line-height: 130%;
		background: transparent;
	}
`;
