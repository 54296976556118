import * as Styled from "./SendPix.styles";
import { InputPixKeyAndValueStep } from "./Steps/0.InputPixKeyAndValue";
import { ReviewPixKeyStep } from "./Steps/1.ReviewPixKey";
import { StatusSendPixStep } from "./Steps/2.StatusSendPix";
import { Case, Switch } from "react-if";
import { useStepManager } from "hooks/useStepManager";
import { SendPixSteps } from "./SendPix.types";

export const SendPixScreen = () => {
	const { currentStep } = useStepManager();
	const typedCurrentStep = currentStep as SendPixSteps;

	return (
		<Styled.Container>
			<Styled.CenterContainer>
				<Switch>
					<Case condition={typedCurrentStep === "InputPixKey"}>
						<InputPixKeyAndValueStep />
					</Case>

					<Case condition={typedCurrentStep === "ReviewPixKey"}>
						<ReviewPixKeyStep />
					</Case>

					<Case condition={typedCurrentStep === "StatusSendPix"}>
						<StatusSendPixStep />
					</Case>
				</Switch>
			</Styled.CenterContainer>
		</Styled.Container>
	);
};
