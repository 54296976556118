import { Typography } from "@azify/ui-web";
import * as Styles from "./Tile.styles";
import { TileProps } from "./Tile.types";

const Tile = ({ icon, title, subtitle }: TileProps) => {
	return (
		<Styles.Container>
			{icon && icon}

			<div className="text-container">
				{title && <Typography type="subtitle2" color="primary" value={title} />}

				<Typography type="subtitle1" color="primary" isBold value={subtitle} marginTop={5} />
			</div>
		</Styles.Container>
	);
};

export default Tile;
