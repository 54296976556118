import { useNavigate } from "react-router-dom";
import { GenericButton, HeaderNavigation, ItemList, TextField } from "@azify/ui-web";
import { useStepManager } from "hooks/useStepManager";
import InputDropDown from "components/Input/InputDropDown/InputDropDown.component";
import { useState } from "react";

export const FillTransferDetails = () => {
	const navigate = useNavigate();
	const [selected, setIsSelected] = useState("");

	const { setCurrentStep, setParams } = useStepManager();

	return (
		<div className="display-between">
			<div className="center-items">
				<HeaderNavigation
					buttonOnTitle={false}
					title="Enviar TED ou Pix Manual"
					quickButtonColor="accent"
					backIconClick={() => navigate("/transfer")}
				/>

				<div className="container-search">
					<ItemList
						trailingIconName="close"
						label="Nayla Carreira Medeiros"
						paragraph="064.765.934-55"
						behavior="default"
						trailingContent="icon"
						leadingIconColor="accent"
						trailingSupportContent="none"
						leadingContent="avatar"
						trailingIconButtonIcon="dots-ver"
						leadingAvatarFullName="Nayla Carreira Medeiros"
						leadingAvatarUserPhoto="https://avatars.githubusercontent.com/u/55929908?v=4"
					/>
				</div>

				<div className="container-main-inputs">
					<TextField
						value=""
						maxLength={15}
						onChange={(e) => {}}
						label="CPF ou CNPJ"
						placeholder="000.000.000-00"
					/>

					<TextField
						value=""
						maxLength={15}
						onChange={(e) => {}}
						label="Nome completo"
						placeholder="ex: João Vieira"
					/>

					<InputDropDown
						label="Instituição financeira"
						selected={selected}
						setIsSelected={setIsSelected}
						items={[
							{
								label: "Banco do Brasil",
								value: "Banco do Brasil"
							},
							{
								label: "Caixa Econômica Federal",
								value: "Caixa Econômica Federal"
							}
						]}
						placeHolder="Selecione"
					/>
				</div>

				<div className="grid-inputs">
					<InputDropDown
						label="Tipo de conta"
						selected={selected}
						setIsSelected={setIsSelected}
						items={[
							{
								label: "Conta corrente",
								value: "Conta corrente"
							},
							{
								label: "Conta poupança",
								value: "Conta poupança"
							}
						]}
						placeHolder="Selecione"
					/>

					<InputDropDown
						label="Tipo de movimentação"
						selected={selected}
						setIsSelected={setIsSelected}
						items={[
							{
								label: "Pix Manual",
								value: "Pix Manual"
							},
							{
								label: "TED",
								value: "TED"
							}
						]}
						placeHolder="Selecione"
					/>

					<TextField
						value=""
						maxLength={15}
						onChange={(e) => {}}
						label="Agência"
						placeholder="0000"
						type="select"
					/>

					<TextField
						value=""
						maxLength={15}
						onChange={(e) => {}}
						label="Conta com dígito"
						placeholder="000000"
						type="select"
					/>
				</div>
			</div>
			<GenericButton
				onClick={() => {
					setCurrentStep("FillTransferValue");
					setParams({ pixKey: "oi" });
				}}
				title="Continuar"
				layout="standard"
				color="primary"
			/>
		</div>
	);
};
