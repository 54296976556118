import { ButtonGroup, GenericButton, IconButton, SlidingButton, Typography } from "@azify/ui-web";
import * as Styled from "./Dialog.styles";
import { Else, If, Then, When } from "react-if";
import { DialogProps } from "./Dialog.types";
import { useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";

function Dialog(props: DialogProps) {
	const {
		openDialog,
		setOpenDialog,
		headerRightIcon,
		headerRightClick,
		headerLeftIcon = "close",
		sliderOverlayText,
		sliderOnSlideDone,
		buttonGroupProps,
		headerTitle,
		children,
		layout,
		noCloseButton,
		buttonType = "group",
		genericButtonProps
	} = props;

	const isStand = layout === "standard";

	const getSideModalById = window.document.getElementById("modal-side-bar");

	// This is a side modal, so we need to handle the animation manually
	useEffect(() => {
		if (!getSideModalById) return;

		if (openDialog) {
			getSideModalById?.setAttribute("style", "transform: unset");
		} else {
			getSideModalById?.setAttribute("style", "transform: translateX(0%) translateZ(0px)");
		}
	}, [openDialog]);

	return (
		<AnimatePresence mode="wait">
			{openDialog ? (
				<motion.div
					initial={{ opacity: 0, zIndex: 1 }}
					animate={{ opacity: 1, zIndex: 1 }}
					exit={{ opacity: 0, zIndex: 1 }}
					transition={{ duration: 0.1 }}>
					<Styled.ModalBackground>
						<Styled.ModalContainer noCloseButton={noCloseButton} layout={layout}>
							<div className="dialog-header">
								<div className="row-title">
									<When condition={!noCloseButton}>
										<div>
											<IconButton
												onClick={() => setOpenDialog(false)}
												color="inverted"
												icon={headerLeftIcon}
											/>
										</div>
									</When>

									<If condition={isStand}>
										<Then>
											<div>
												<Typography textAlign="center" type="h5" isBold value={headerTitle} />
											</div>

											<div>
												<If condition={headerRightIcon}>
													<Then>
														<IconButton
															onClick={headerRightClick}
															color="primary"
															icon={headerRightIcon}
														/>
													</Then>

													<Else>
														<div className="spacer" />
													</Else>
												</If>
											</div>
										</Then>

										<Else>
											<Typography
												textAlign={isStand ? "center" : "left"}
												type="h1"
												isBold
												value={headerTitle}
											/>
										</Else>
									</If>
								</div>
							</div>

							<div className="dialog-content">{children}</div>

							<div className="dialog-bottom">
								<If condition={sliderOverlayText}>
									<Then>
										<div className="slider-container">
											<SlidingButton
												overlayText={String(sliderOverlayText)}
												onSlideDone={sliderOnSlideDone!}
											/>
										</div>
									</Then>

									<Else>
										<If condition={buttonType === "group"}>
											<Then>
												{buttonGroupProps?.variant ? <ButtonGroup {...buttonGroupProps} /> : null}
											</Then>
											<Else>
												{genericButtonProps?.layout ? (
													<GenericButton {...genericButtonProps} />
												) : null}
											</Else>
										</If>
									</Else>
								</If>
							</div>
						</Styled.ModalContainer>
					</Styled.ModalBackground>
				</motion.div>
			) : null}
		</AnimatePresence>
	);
}

export default Dialog;
