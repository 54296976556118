import { ChangeEvent } from "react";
import * as Styled from "./TransactionalInput.styles";
import CurrencyJS from "currency.js";
import { TransactionalInputProps } from "./TransactionalInput.types";

function TransactionalInput(props: TransactionalInputProps) {
	const { disable, onChange, containerHeight, placeHolder } = props;

	const currencyMask = (event: ChangeEvent<HTMLInputElement>) => {
		const formatValue = CurrencyJS(event.target.value.replace(".", "").replace(",", ""), {
			fromCents: true,
			precision: 2,
			symbol: "",
			separator: ".",
			decimal: ","
		}).format();

		return `R$ ${formatValue}`;
	};

	return (
		<Styled.Container height={containerHeight}>
			<input
				disabled={disable}
				onChange={(event) => {
					event.target.value = currencyMask(event);

					if (onChange) {
						onChange(event.target.value.replace("R$ ", ""));
					}

					return event;
				}}
				type="text"
				placeholder={placeHolder}
			/>
		</Styled.Container>
	);
}

export default TransactionalInput;
