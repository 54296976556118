import Header from "components/Header/Header.component";
import * as Styled from "./styles";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const DashRouterLayout = ({ noPagePadding }: { noPagePadding?: boolean }) => {
	const [exactPadding, setExactPadding] = useState(0);
	const location = useLocation();

	useEffect(() => {
		setTimeout(() => {
			const magicFooter = document.getElementById("magic-footer");

			if (!magicFooter) {
				setExactPadding(0);
			} else {
				const magicFooterHeight = magicFooter?.getBoundingClientRect()?.height || 0;
				const magicFooterPosY = magicFooter?.getBoundingClientRect();

				const screenHeight = window.innerHeight;

				const magicFooterContainerPaddingBottom = screenHeight - Number(magicFooterPosY?.bottom);

				const defaultPadding = 40;

				setExactPadding(magicFooterContainerPaddingBottom + magicFooterHeight + defaultPadding);
			}
		}, 300);
	}, [location.key]);

	return (
		<>
			<Header />
			<Styled.Container
				magicFooterPadding={exactPadding || 0}
				noPagePadding={noPagePadding}
				id="page-wrapper">
				<Outlet />
			</Styled.Container>
		</>
	);
};

export default DashRouterLayout;
