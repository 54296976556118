import { colors } from "@azify/ui-tokens";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	width: 100%;

	.custom-tooltip {
		border-radius: 8px;
		padding: 8px;
		width: fit-content;
		height: 50px;
		display: flex;
		align-items: center;

		span {
			color: ${colors.black.main};
			font-size: 18px;
			font-weight: 500;
			font-family: "Helvetica Now Display Medium", sans-serif;
		}
	}

	.first-column {
		display: flex;
		flex-direction: row;
		width: 100%;
		border-radius: 16px;

		gap: 24px;

		@media (max-width: 1100px) {
			flex-direction: column;
			border: none;
			gap: 24px;
		}

		.first-item {
			width: 65%;
			height: 412px;
			padding: 12px 0 32px 16px;
			border: 1px solid ${colors.black.opacity["10p"]};
			border-radius: 16px;

			@media (max-width: 1100px) {
				width: 100%;
			}
		}

		.second-item {
			width: 35%;
			height: 412px;
			padding: 16px 0;
			border-radius: 16px;
			border: 1px solid ${colors.black.opacity["10p"]};

			@media (max-width: 1100px) {
				width: 100%;
			}
		}
	}

	.chart-container {
		width: 100%;
	}

	.second-column {
		display: flex;
		flex-direction: row;
		margin-top: 40px;

		gap: 16px;
		width: 100%;
		padding-bottom: 150px;

		@media (max-width: 1100px) {
			flex-direction: column;
			padding-bottom: 150px;
		}
	}

	.icon-and-names {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 7px;
	}

	.list-contacts {
		display: grid;
		width: 100%;
		height: 100%;
		grid-template-columns: repeat(5, 1fr);
		row-gap: 32px;
	}

	.row-price-indicator {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-self: flex-start;
		margin-top: 8px;
		justify-content: center;
		align-items: center;

		.price-variation-indicator {
			display: flex;
			align-items: center;
			justify-content: center;

			padding: 3px 5px 0px 5px;
			background-color: ${colors.success.dark};
			width: fit-content;
			border-radius: 21px;
		}
	}

	.container-wallets {
		width: 100%;
		height: 200px;
		display: flex;
		flex-direction: row;
		margin-top: 40px;

		gap: 24px;
	}

	.row-gap-balance {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 20px;
		width: 100%;
	}

	.tittle-between {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-direction: row;
		width: 100%;
	}

	.quick-actions-container {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		gap: 8px;
		margin-bottom: 40px;
	}

	.container-cards {
		width: 1280px;
		height: 100%;
		display: flex;
		flex-direction: column;

		.card {
			width: 100%;
			height: 100%;
			border-radius: 16px;
			padding-top: 16px;
			padding-bottom: 16px;

			display: flex;
			flex-direction: column;
			align-items: flex-start;
			border: 1px solid ${colors.black.opacity["10p"]};
		}

		.custom-padding {
			padding: 16px;
		}
	}
`;
