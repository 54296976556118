import { GenericButton } from "@azify/ui-web";
import LoaderStatus from "components/LoaderStatus/LoaderStatus.component";
import { useStepManager } from "hooks/useStepManager";
import { useNavigate } from "react-router-dom";

function StatusBill() {
	const navigate = useNavigate();
	const { setCurrentStep } = useStepManager();

	const goToPreviousStep = () => {
		setCurrentStep("ShowBillDetails");
	};

	return (
		<div className="display-between">
			<LoaderStatus
				title="Efetuando pagamento..."
				subtitle="Seu pagamento será realizado em breve."
				status="loading"
			/>

			<GenericButton
				onClick={() => navigate("/transfer/")}
				title="Concluir"
				layout="standard"
				color="primary"
			/>
		</div>
	);
}
export default StatusBill;
