import styled from "styled-components";
import { colors } from "@azify/ui-tokens";

export const MenuContainer = styled.div<{
	widthContainer?: number;
}>`
	z-index: 99;

	.menu {
		display: flex;
		flex-direction: column;
	}

	.menu-button {
		border: none;
	}

	/* .menu-item {
    width: ${(props) => props.widthContainer || 248}px;
  } */

	.szh-menu {
		border: 1px solid var(${colors.black.opacity["5p"]}, rgba(39, 41, 55, 0.05));
		box-shadow: none;
		border-radius: 10px;
		width: ${(props) => props.widthContainer || 248}px;
		background-color: ${colors.white.light};
		box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.15);
		z-index: 99;
	}
`;
