import {
	HeaderNavigation,
	IconButton,
	ItemList,
	SearchField,
	SectionHeading,
	Typography
} from "@azify/ui-web";
import * as S from "./SendTransfer.styles";
import { useNavigate } from "react-router-dom";
import { colors } from "@azify/ui-tokens";
import { useState } from "react";
import ItemListCollapse from "components/ItemListCollapse/ItemListCollapse.component";

const SendTransfer = () => {
	const navigate = useNavigate();
	const [openBankList, setOpenBankList] = useState(false);

	return (
		<S.Container>
			<S.CenterContainer>
				<HeaderNavigation
					buttonOnTitle={false}
					title="Enviar"
					quickButtonColor="accent"
					backIconClick={() => navigate("/dashboard")}
				/>

				<ItemList
					paragraph="R$ 3.823,90"
					label="Conta BRL"
					behavior="default"
					trailingSupportContent="none"
					leadingContent="avatar"
					leadingAvatarType="currency"
					leadingAvatarCurrency="BRL"
					trailingContent="icon"
				/>

				<div className="icon-buttons-container">
					<div className="container-button-text">
						<IconButton color="primary" size="large" icon="pix" />
						<Typography isBold textAlign="center" type="subtitle2" value="Chave Pix" />
					</div>

					<div
						onClick={() => navigate("/transfer/manualTransfer")}
						className="container-button-text">
						<IconButton color="primary" size="large" icon="copy" />
						<Typography isBold textAlign="center" type="subtitle2" value={"TED ou Pix Manual"} />
					</div>

					<div className="container-button-text">
						<IconButton color="primary" size="large" icon="qr-code" />
						<Typography isBold textAlign="center" type="subtitle2" value="Copia e Cola" />
					</div>

					<div
						className="container-button-text"
						onClick={() => navigate("/pixArea/createPixQrCode")}>
						<IconButton color="primary" size="large" icon="cash-in-01" />
						<Typography isBold textAlign="center" type="subtitle2" value="QR Code" />
					</div>

					<div onClick={() => navigate("/transfer/payBill")} className="container-button-text">
						<IconButton color="primary" size="large" icon="cash-in-01" />
						<Typography isBold textAlign="center" type="subtitle2" value="Pagar Boleto" />
					</div>
				</div>

				<SectionHeading behavior="default" title="Favorecidos" />

				<div className="mb-20">
					<SearchField
						value={""}
						onChangeInput={() => {}}
						placeholder="Procurar..."
						leadingArtworkIcon="search"
					/>
				</div>

				<ItemListCollapse
					open={openBankList}
					setOpen={setOpenBankList}
					collapseItems={[
						<ItemList
							key={1}
							paragraph="001 - Ag 0123 - CC 987654-3"
							label="BANCO C6 S.A."
							behavior="default"
							trailingContent="icon"
							trailingSupportContent="none"
							leadingContent="icon"
							leadingIconCustomColor={colors.gray.dark}
							leadingIconName="bank-01"
						/>,
						<ItemList
							key={2}
							paragraph="001 - Ag 0123 - CC 987654-3"
							label="BCO DO BRASIL S.A."
							behavior="default"
							trailingContent="icon"
							trailingSupportContent="none"
							leadingContent="icon"
							leadingIconCustomColor={colors.gray.dark}
							leadingIconName="bank-01"
						/>
					]}
				/>

				<ItemList
					paragraph="001 - Ag 0123 - CC 987654-3"
					label="Sharon Mangueira Conceição"
					behavior="default"
					trailingContent="icon"
					trailingSupportContent="none"
					leadingContent="avatar"
					leadingAvatarFullName="Sharon Mangueira Conceição"
					leadingIconCustomColor={colors.gray.dark}
					leadingIconName="bank-01"
				/>
			</S.CenterContainer>
		</S.Container>
	);
};

export default SendTransfer;
