import { colors } from "@azify/ui-tokens";
import styled from "styled-components";

export const Container = styled.div`
	padding: 0 32px;
	margin-top: 12px;
	height: 100%;

	.function-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 16px;
	}

	.container-name-doc {
		border: 1px solid ${colors.black.opacity["10p"]};
		padding: 8px;
		border-radius: 8px;
	}

	.container-inputs {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	& .tile-container {
		display: flex;
		justify-content: space-between;
		gap: 8px;

		margin-top: 36px;
	}

	.footer-button {
		display: flex;
		padding: 24px 0px;
		width: 100%;
		align-items: center;
		justify-content: center;
	}
`;
