import { colors } from "@azify/ui-tokens";
import { GeneralStyleProps } from "../../../@types/general";
import styled from "styled-components";

export const ContainerInput = styled.div<GeneralStyleProps>`
	display: flex;
	align-items: baseline;
	flex-direction: column;
	min-height: 50px;
	justify-content: center;

	.inputWrapper {
		display: flex;
		align-items: baseline;
	}

	.input {
		width: 100%;
		font-family: Aeonik-Medium;
		font-weight: 500;
		font-size: 31px;
		background-color: ${colors.transparent};
		padding-bottom: ${(props) => props.paddingBottom || 0}px;
		resize: none;
		outline: none;
		border: none;
		overflow: hidden;
		margin-top: ${(props) => props.marginTop || 0}px;
		margin-bottom: ${(props) => props.marginBottom || 0}px;

		&::placeholder {
			color: ${() => colors.gray.opacity["50p"]};
		}
	}
`;
