import { colors } from "@azify/ui-tokens";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
`;

export const CenterContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 600px;
	height: 100%;
	padding-top: 16px;

	@media (max-width: 650px) {
		width: 100%;
	}

	.input-group {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	& .row {
		display: flex;
		flex-direction: row;
		align-items: center;

		& p {
			width: auto;
		}

		& .text-link {
			width: auto;
			margin-left: 5px;
			cursor: pointer;

			@media (max-width: 530px) {
				width: 100%;
				margin-left: 0px;
			}
		}

		@media (max-width: 530px) {
			flex-wrap: wrap;
		}
	}

	& .fixed-group-button {
		padding-bottom: 100px;
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		position: fixed;
		background-color: ${colors.white.light};
		height: 88px;
		bottom: 0;
		left: 0;

		@media (max-width: 650px) {
			padding: 0 32px 100px 32px;
		}

		& button {
			width: 600px;

			@media (max-width: 650px) {
				width: 100%;
			}
		}
	}
`;
