import { colors } from "@azify/ui-tokens";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding-bottom: 24px;
	justify-content: center;
	align-items: center;
`;

export const CenterContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 600px;
	height: 100%;
	padding-top: 16px;

	.display-between {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		padding: 0;
		gap: 50px;

		.center-chip-credit {
			display: flex;
			justify-content: center;
			width: 100%;
			margin-top: 24px;
		}

		.center-with-lines-horizontal {
			display: flex;
			flex-direction: row;
			justify-content: center;
			margin-top: 16px;
			margin-bottom: 16px;
			align-items: center;
			width: 100%;

			.center-line {
				border-bottom: 1px solid ${colors.gray.light};
				width: 600px;
				justify-content: center;
				align-items: center;
				display: flex;
				position: absolute;
			}
		}

		.center-items {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 100%;
		}

		.container-main-inputs {
			display: flex;
			flex-direction: column;
			width: 100%;
			gap: 24px;
		}

		.options-container {
			display: flex;
			flex-direction: column;
			width: 100%;
			gap: 10px;
		}

		.container-value-and-beneficiary {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 8px;
			align-items: center;
			margin-top: 24px;
			margin-bottom: 24px;

			.container-input-edit {
				display: flex;
				flex-direction: row;
				width: 100%;
				justify-content: space-between;
				padding: 0 16px;
			}
		}
	}

	.list-content-content.empty {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;

		text-align: center;
	}

	.sub-menu {
		position: absolute;
		background-color: green;
		width: 536px;
		z-index: 1;
		margin-top: 8px;
		padding: 10px 0px;
		box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.02);
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		background: #fff;
		gap: 8px;
	}

	.search-container {
		padding: 10px 20px 20px 20px;
	}
`;
